import * as React from "react";
import { Col, Row } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import FormTemplatesConstants from "../../../../Constants/FormTemplatesConstants";
import TemplatesList from "../../../template/containers/TemplatesList";
import { useGetFormTemplateByOrgIdQuery } from "../../../../store/features/form-template/formtemplate-api-slice";

const FormTemplateManagement: React.FC = () => {
    const { id: organisationId } = useParams<{ id: string }>();

    const searchParams = new URLSearchParams({
        organisationId: organisationId,
    });

    return (
        <Row>
            <Col>
                <Row className="top10">
                    <Col>
                        <Link
                            to={`${FormTemplatesConstants.RoutePathCreate}?${searchParams}`}
                            className="btn btn-primary float-right"
                        >
                            Create
                        </Link>
                    </Col>
                </Row>
                <Row className="top10">
                    <Col>
                        <TemplatesList
                            organisationId={organisationId}
                            getTemplate={useGetFormTemplateByOrgIdQuery}
                            redirectPath="/form/template"
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default FormTemplateManagement;
