import * as React from "react";
import { Navbar, Nav } from "react-bootstrap";
import "./NavMenu.css";
import { Link } from "react-router-dom";
import LoginMenu from "./LoginMenu";
import { useGetConfigQuery } from "../../../store/config/configApi";

const NavMenu: React.FC = () => {
    const { data: config } = useGetConfigQuery();

    let title = "DCP";
    if (
        config.Branding?.Title !== undefined &&
        config.Branding?.Title !== null
    ) {
        title = config.Branding.Title;
    }

    return (
        <header>
            <Navbar
                className="navbar-expand-sm navbar-toggleable-sm border-bottom box-shadow mb-3"
                bg="light"
                expand="lg"
            >
                <Navbar.Brand
                    as={Link}
                    className="col-sm-3 col-md-2 mr-0"
                    to="/"
                >
                    {title}
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ml-auto">
                        <LoginMenu />
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </header>
    );
};

export default NavMenu;
