import * as React from "react";
import { Button, ButtonGroup, ButtonToolbar } from "react-bootstrap";

interface Props {
    setEdit: () => void;
    onDelete: () => void;
}

const TemplateFormToolbar: React.FC<Props> = ({ setEdit, onDelete }) => {
    return (
        <ButtonToolbar className="justify-content-end">
            <ButtonGroup>
                <Button onClick={setEdit}>Edit</Button>
                <Button className="ml-1" variant="danger" onClick={onDelete}>
                    Delete
                </Button>
            </ButtonGroup>
        </ButtonToolbar>
    );
};

export default TemplateFormToolbar;
