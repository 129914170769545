import { dcpApi, Tags } from "../../../store/features/dcpApi";
import { TrackingObject } from "./types";

enum UrlPaths {
    ResourceName = "/trackingobjects",
}

export const TrackingObjectApi = dcpApi.injectEndpoints({
    endpoints: (builder) => ({
        getTrackingObjectById: builder.query<TrackingObject, string>({
            query: (objectId) => `${UrlPaths.ResourceName}/${objectId}`,
            providesTags: (_result, error, id) =>
                error
                    ? []
                    : [
                          { type: Tags.TrackingObject, id },
                          { type: Tags.TrackingObjects },
                      ],
        }),
    }),
});

export const { useGetTrackingObjectByIdQuery } = TrackingObjectApi;
