import * as React from "react";
import TemplatesListItem from "./TemplatesListItem";
import LoadingSpinner from "../../../common/components/CoveringLoadingPanel";
import { Template } from "../domain/types";
import { GetTemplatesByOrgIdQuery } from "../../../common/types/Template";

interface TemplatesListProps {
    organisationId: string;
    getTemplate: GetTemplatesByOrgIdQuery;
    redirectPath: string;
}

const TemplatesList: React.FC<TemplatesListProps> = (props) => {
    const { data: templates } = props.getTemplate(props.organisationId, {
        skip: !props.organisationId,
    });

    if (templates === undefined) return <LoadingSpinner />;
    return (
        <div>
            {templates.map((item: Template, index: number) => (
                <TemplatesListItem
                    key={index.toString()}
                    name={item.name}
                    description={item.description}
                    id={item.id}
                    lastUpdatedAt={item.lastUpdatedAt}
                    version={item.version}
                    redirectPath={props.redirectPath}
                />
            ))}
        </div>
    );
};

export default TemplatesList;
