import React from "react";
import { Col } from "react-bootstrap";
import CoveringLoadingPanel from "../../../../common/components/CoveringLoadingPanel";
import { useGetInvitationsByOrganisationIdQuery } from "../../../../store/features/invitation/invitationApiSlice";
import { Invitation } from "../../../invitation/domain/Invitation";
import InvitationListItem from "./InvitationListItem";

interface Props {
    organisationId: string;
}

const InvitationList: React.FC<Props> = ({ organisationId }) => {
    const { data: invitations, isLoading } =
        useGetInvitationsByOrganisationIdQuery(organisationId, {
            skip: organisationId === "",
        });

    if (isLoading || invitations === undefined) {
        return <CoveringLoadingPanel />;
    }

    return (
        <Col>
            {invitations &&
                invitations.length !== 0 &&
                invitations.map((invitation: Invitation, index) => (
                    <InvitationListItem key={index} invitation={invitation} />
                ))}
        </Col>
    );
};

export default InvitationList;
