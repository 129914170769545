import * as Formik from "formik";
import * as React from "react";
import { Breadcrumb, Button, Form, Spinner } from "react-bootstrap";
import * as yup from "yup";
import { CustomSelect, TextInput } from "../../../../common/components/form";
import { mapToSelectOptions } from "../../../../common/types/reactSelect/SelectOptionItem";
import OrganisationConstants from "../../../../Constants/OrganisationConstants";
import { useNavigation } from "../../../../router/useNavigate";
import { useGetUsersByOrgIdQuery } from "../../../../store/features/organisation/organisation-api-slice";
import {
    TemplateContentFilter,
    TemplateContentFilterOperator,
    TemplateContentFilterProperty,
} from "../../../common/filters/domain/types";
import { withUserFilter } from "../../../common/filters/helpers/userFilters";
import { useOrganisationId } from "../../../organisation/hooks/useOrganisationId";
import SelectAssetFormInput from "../components/SelectAssetFormInput";
import { ReactiveExamFormModel } from "../domain/ReactiveExamCreatorDtos";
import { useReactiveExamCreatorMutation } from "../queries/reactiveExamCreatorQueries";

const pageTitle = "Reactive Exam Creator";

const usersFilter: TemplateContentFilter = {
    property: TemplateContentFilterProperty.UserGroup,
    operator: TemplateContentFilterOperator.Any,
    values: ["Examiner"],
};

const processTemplateNames = [
    { value: "Rapid Response Vehicle", label: "Rapid Response Vehicle" },
    {
        value: "Rapid Response Non-Vehicle",
        label: "Rapid Response Non-Vehicle",
    },
    {
        value: "Preliminary Advice Notification",
        label: "Preliminary Advice Notification",
    },
];

const formInitialValues: ReactiveExamFormModel = {
    carrsExamId: "",
    processTemplate: null,
    assignedUser: null,
    asset: {
        carrsGuid: "",
        id: "",
    },
};

const ReactiveExamCreatorPage: React.FC = () => {
    const { navigateToOrgPath: navigateToPath } = useNavigation();
    const { organisationId } = useOrganisationId();
    const { data: organisationUsers } = useGetUsersByOrgIdQuery(organisationId);

    const assignedToOptions = React.useMemo(() => {
        return mapToSelectOptions(
            withUserFilter(organisationUsers, usersFilter),
            (i) => i.userId,
            (i) => i.username,
        );
    }, [organisationUsers]);

    const validation = yup.object({
        processTemplate: yup.mixed().required("Required"),
        assignedUser: yup.mixed().required("Required"),
        asset: yup.object({
            id: yup.string().required("Required"),
        }),
    });

    const reactiveExamCreator = useReactiveExamCreatorMutation(organisationId);

    const navigateToProcess = React.useCallback(
        (result) => {
            navigateToPath(
                `/:orgShortName${OrganisationConstants.Process}/${result.id}`,
            );
        },
        [navigateToPath],
    );

    const onSubmit = React.useCallback(
        (
            {
                carrsExamId,
                processTemplate,
                assignedUser,
                asset,
            }: ReactiveExamFormModel,
            { setSubmitting }: Formik.FormikHelpers<ReactiveExamFormModel>,
        ): void => {
            reactiveExamCreator.mutate(
                {
                    processTemplate,
                    carrsExamId,
                    assetId: asset.id,
                    assignedUser,
                },
                {
                    onSuccess: (result) => navigateToProcess(result),
                    onError: () => {
                        setSubmitting(false);
                    },
                },
            );
        },
        [navigateToProcess, reactiveExamCreator],
    );

    return (
        <div>
            <Breadcrumb className="top10">
                <Breadcrumb.Item active>{pageTitle}</Breadcrumb.Item>
            </Breadcrumb>
            {pageTitle && <h1>{pageTitle}</h1>}

            <Formik.Formik
                validationSchema={validation}
                enableReinitialize
                initialValues={formInitialValues}
                onSubmit={onSubmit}
            >
                {({ isSubmitting }) => (
                    <Formik.Form>
                        <Form.Group>
                            <CustomSelect
                                options={processTemplateNames}
                                name="processTemplate"
                                label="Select Report Type"
                                placeholder="Select Report Type..."
                            />
                        </Form.Group>
                        <TextInput
                            label="CARRS Exam Id"
                            placeholder="CARRS Exam Id"
                            name="carrsExamId"
                        />
                        <CustomSelect
                            name="assignedUser"
                            label="Select Assigned User"
                            placeholder="Select user..."
                            options={assignedToOptions}
                            isMulti={false}
                        />
                        <SelectAssetFormInput organisationId={organisationId} />
                        <Form.Group>
                            <Button
                                variant="primary"
                                type="submit"
                                disabled={isSubmitting}
                            >
                                {isSubmitting && (
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                )}
                                Create
                            </Button>
                        </Form.Group>
                    </Formik.Form>
                )}
            </Formik.Formik>
        </div>
    );
};

export default ReactiveExamCreatorPage;
