import * as Formik from "formik";
import React, { FC } from "react";
import { Row, Col, Button, Form } from "react-bootstrap";
import {
    AssetModel,
    useDeleteAssetMutation,
    usePutAssetByIdMutation,
} from "../../../store/features/asset/asset-api-slice";
import { Errors } from "../../../common/types/Errors";
import cx from "classnames";
import PermissionConstants from "../../../common/permissions/PermissionConstants";
import { hasUserOrgPermission } from "../../../store/features/user/user-api-slice";
import { useAppDispatch } from "../../../store/hooks";
import { push } from "connected-react-router";
import OrganisationConstants from "../../../Constants/OrganisationConstants";

interface FormValues {
    name: string;
    description: string;
}

interface AssetDetailsProps {
    asset: AssetModel;
    orgShortName: string;
}

const AssetDetails: FC<AssetDetailsProps> = ({ asset, orgShortName }) => {
    const [updateAsset] = usePutAssetByIdMutation();
    const [isReadOnly, setReadOnly] = React.useState(true);
    const [deleteAsset] = useDeleteAssetMutation();
    const getInitialValues = (): FormValues => {
        return {
            name: asset.name,
            description: asset.description,
        };
    };
    const canEditAsset = hasUserOrgPermission(
        asset?.organisationId,
        PermissionConstants.OrgAsset.update,
    ).hasPermission;
    const canDeleteAsset = hasUserOrgPermission(
        asset?.organisationId,
        PermissionConstants.OrgAsset.delete,
    ).hasPermission;

    const dispatch = useAppDispatch();

    const handleEdit = () => {
        setReadOnly(false);
    };
    const handleDelete = () => {
        if (window.confirm(`Are you sure you want to delete ${asset.name} ?`)) {
            const redirectPath = asset.parent
                ? `/${orgShortName}${OrganisationConstants.Asset}/${asset.parent.id}`
                : `/${orgShortName}${OrganisationConstants.Asset}`;

            deleteAsset(asset).then(() => dispatch(push(redirectPath)));
        }
    };

    const validate = (values: FormValues) => {
        const errors: Errors = {};

        if (!values.name) {
            errors.name = "Required";
        }

        return errors;
    };
    return (
        <>
            <Row>
                {isReadOnly && (
                    <Col>
                        <div className="float-right mt-2">
                            {canEditAsset && (
                                <Button variant="primary" onClick={handleEdit}>
                                    Edit
                                </Button>
                            )}
                            {canDeleteAsset && (
                                <Button
                                    className="ml-1"
                                    variant="danger"
                                    onClick={handleDelete}
                                >
                                    Delete
                                </Button>
                            )}
                        </div>
                    </Col>
                )}
            </Row>
            <Row>
                <Col>
                    <Formik.Formik
                        enableReinitialize
                        initialValues={getInitialValues()}
                        validate={validate}
                        onSubmit={async (
                            values: FormValues,
                            { setSubmitting },
                        ) => {
                            // When button submits asset and asset is in the process of submitting, submit button is disabled
                            setSubmitting(true);

                            updateAsset({
                                id: asset.id,
                                organisationId: asset.organisationId,
                                ...values,
                            }).then(() => {
                                setSubmitting(false);
                                setReadOnly(true);
                            });
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleSubmit,
                            getFieldProps,
                            resetForm,
                        }) => (
                            <Formik.Form onSubmit={handleSubmit}>
                                <Form.Group controlId="processName">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Name"
                                        name="name"
                                        {...getFieldProps("name")}
                                        value={values.name}
                                        className={cx({
                                            "process-control": true,
                                            "is-invalid":
                                                touched.name && errors.name,
                                        })}
                                        readOnly={isReadOnly}
                                    />
                                    <Formik.ErrorMessage
                                        name="name"
                                        component="div"
                                        className="invalid-feedback display"
                                    />

                                    <Form.Text className="text-muted">
                                        The name for your asset.
                                    </Form.Text>
                                </Form.Group>
                                <Form.Group controlId="processDescription">
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={3}
                                        name="description"
                                        {...getFieldProps("description")}
                                        value={values.description}
                                        readOnly={isReadOnly}
                                    />
                                </Form.Group>

                                <div className="top10 float-right">
                                    {!isReadOnly && (
                                        <Button variant="primary" type="submit">
                                            Update
                                        </Button>
                                    )}
                                    {!isReadOnly && (
                                        <Button
                                            variant="secondary"
                                            className="ml-1"
                                            onClick={() => {
                                                resetForm();
                                                setReadOnly(true);
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                    )}
                                </div>
                            </Formik.Form>
                        )}
                    </Formik.Formik>
                </Col>
            </Row>
        </>
    );
};

export default AssetDetails;
