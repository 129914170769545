import { Form, Formik } from "formik";
import * as React from "react";
import { Button, FormControl, FormText, InputGroup } from "react-bootstrap";
import { useHistory } from "react-router";
import OrganisationConstants from "../../../Constants/OrganisationConstants";
import {
    buildInitialQuery,
    buildSearchQuery,
    checkValidSearch,
    FileSearchForm,
} from "../viewModel/FileSearch";

interface FileSearchProps {
    initialQuery?: FileSearchForm;
    orgShortName: string;
}

const { fileSearch } = OrganisationConstants;

const FileSearch: React.FC<FileSearchProps> = ({
    initialQuery,
    orgShortName,
}): JSX.Element => {
    const history = useHistory();

    const handleSearch = React.useCallback(
        (values: FileSearchForm): void => {
            if (!values.name) return;

            history.push(
                `/${orgShortName}/${fileSearch}?${buildSearchQuery(values)}`,
            );
        },
        [history, orgShortName],
    );

    const initialSearchForm = initialQuery ?? buildInitialQuery();

    return (
        <Formik initialValues={initialSearchForm} onSubmit={handleSearch}>
            {({ values, handleChange }) => (
                <Form className="m-0">
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text>Files and folders</InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                            type="text"
                            name="name"
                            onChange={handleChange}
                            value={values.name}
                        />
                        <InputGroup.Append>
                            <Button
                                disabled={!checkValidSearch(values)}
                                type="submit"
                            >
                                Search
                            </Button>
                        </InputGroup.Append>
                    </InputGroup>
                    <FormText className="text-muted">
                        Search lookup is case sensitive.
                    </FormText>
                </Form>
            )}
        </Formik>
    );
};

export default FileSearch;
