import cx from "classnames";
import * as React from "react";
import { ChangeEventHandler } from "react";
import { Button } from "react-bootstrap";
import { ButtonProps } from "react-bootstrap/Button";
import "./file-button.css";

interface FileButtonProps extends ButtonProps {
    multiple?: boolean;
    onChange: ChangeEventHandler<HTMLInputElement>;
}

export function FileButton({
    onChange,
    className,
    multiple,
    ...props
}: FileButtonProps): JSX.Element {
    return (
        <Button
            variant="outline-secondary"
            className={cx("position-relative", "overflow-hidden", className)}
            {...props}
        >
            {props.children}
            <input
                type="file"
                className="file-button__input"
                onChange={(ev) => {
                    onChange(ev);
                    ev.target.value = "";
                }}
                role="button"
                multiple={multiple}
            />
        </Button>
    );
}
