import { dcpApi, Tags } from "../dcpApi";
import UserConstants from "../../../Constants/UserConstants";
import RoleConstants from "../../../Constants/RoleConstants";
import { Permission, User } from "../../../modules/organisation/domain/types";
import { useIsAuthenticated } from "@azure/msal-react";
import { HttpMethod } from "../../../common/http/HttpMethod";

export const userApi = dcpApi.injectEndpoints({
    endpoints: (builder) => ({
        getAvailableUsers: builder.query<User[], void>({
            query: () => `${UserConstants.RoutePath}`,
        }),
        getCurrentUserVerified: builder.query<void, void>({
            query: () =>
                `${UserConstants.DomainName}${UserConstants.CurrentUser}${UserConstants.VerifyB2c}`,
            providesTags: () => [{ type: Tags.UserCurrentVerified }],
        }),
        getCurrentUserRoles: builder.query<string[], void>({
            query: () =>
                `${UserConstants.DomainName}${UserConstants.CurrentUser}${UserConstants.Role}`,
            providesTags: () => [{ type: Tags.UserCurrentRoles }],
        }),
        getCurrentUserOrgPermissions: builder.query<Permission[], string>({
            query: (organisationId) =>
                `${UserConstants.DomainName}${UserConstants.CurrentUser}${UserConstants.Permission}?organisationId=${organisationId}`,
            providesTags: () => [{ type: Tags.UserCurrentOrgPermissions }],
        }),
        updateCurrentUser: builder.mutation<void, string>({
            query: (name: string) => ({
                url: `${UserConstants.DomainName}${UserConstants.CurrentUserUpdate}`,
                method: HttpMethod.Put,
                body: {
                    name,
                },
            }),
        }),
    }),
});

export const {
    useGetAvailableUsersQuery,
    useGetCurrentUserRolesQuery,
    useGetCurrentUserOrgPermissionsQuery,
    useUpdateCurrentUserMutation,
    useGetCurrentUserVerifiedQuery,
} = userApi;

export function useIsCurrentUserAdmin(): ReturnType<
    typeof useGetCurrentUserRolesQuery
> {
    const isAuthenticated = useIsAuthenticated();
    const { isSuccess } = userApi.useGetCurrentUserVerifiedQuery(undefined, {
        skip: !isAuthenticated,
    });
    return userApi.useGetCurrentUserRolesQuery(undefined, {
        selectFromResult: ({ data, isLoading, isFetching }) => ({
            isAdmin: data?.indexOf(RoleConstants.Roles.Admin) > -1 ?? false,
            isLoading,
            isFetching,
        }),
        skip: !isSuccess,
    });
}

export function hasUserOrgPermission(
    organisationId: string,
    permission: string,
): ReturnType<typeof useGetCurrentUserOrgPermissionsQuery> {
    return userApi.useGetCurrentUserOrgPermissionsQuery(organisationId, {
        skip: !organisationId,
        selectFromResult: ({ data, isLoading, isFetching }) => ({
            hasPermission: data?.some((a) => a.name === permission) ?? false,
            isLoading,
            isFetching,
        }),
    });
}
