import cx from "classnames";
import * as React from "react";
import { Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import { useParams } from "react-router";
import { useGetOrgSimpleByShortNameQuery } from "../../store/features/organisation/organisation-api-slice";
import CoveringLoadingPanel from "../components/CoveringLoadingPanel";
import SideMenu from "../components/navigation-sidebar/SideMenu";
import OrganisationNavMenu from "../components/navigation-top/OrganisationNavMenu";

const OrganisationLayout: React.FC = (props: {
    children?: React.ReactNode;
}) => {
    const params = useParams<{ orgShortName: string }>();

    const { orgShortName } = params;

    const [sideMenuExpanded, setSideMenuExpanded] = React.useState(true);

    const { data: organisation, isLoading } = useGetOrgSimpleByShortNameQuery(
        orgShortName,
        {
            skip: !params.orgShortName,
        },
    );

    if (isLoading) return <CoveringLoadingPanel />;
    return (
        <React.Fragment>
            {organisation && (
                <OrganisationNavMenu orgName={organisation.name} />
            )}
            <Container fluid>
                <Row className="flex-nowrap">
                    <SideMenu
                        className={cx("side-column-width width-transition", {
                            narrow: !sideMenuExpanded,
                        })}
                        isExpanded={sideMenuExpanded}
                        toggleExpansion={(): void =>
                            setSideMenuExpanded((v) => !v)
                        }
                        organisation={organisation}
                    />
                    <main
                        role="main"
                        className="main-width col ml-sm-auto px-4"
                    >
                        <div>{props.children}</div>
                    </main>
                </Row>
            </Container>
        </React.Fragment>
    );
};
export default OrganisationLayout;
