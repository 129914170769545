import * as React from "react";
import { Breadcrumb } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { RouteComponentProps } from "react-router";
import { Link } from "react-router-dom";
import CoveringLoadingPanel from "../../../common/components/CoveringLoadingPanel";
import { useOrgTitleById } from "../../../common/hooks/useTitle";
import OrganisationConstants from "../../../Constants/OrganisationConstants";
import { useGetOrgSimpleByIdQuery } from "../../../store/features/organisation/organisation-api-slice";
import HelpSectionForm from "../containers/HelpSectionForm";
import { helpSectionTitleFormat } from "./pageTitles";

const HelpSectionPage: React.FC<
    RouteComponentProps<{ orgId: string; itemId: string }>
> = (props) => {
    const organisationId = props.match.params.orgId;
    const helpItemId = props.match.params.itemId;
    const pageContext = helpItemId ? "Manage" : "Create";

    const { data: organisation, isLoading: organisationIsLoading } =
        useGetOrgSimpleByIdQuery(organisationId);

    useOrgTitleById(organisationId, helpSectionTitleFormat(pageContext));

    if (organisationIsLoading || organisation === undefined) {
        return <CoveringLoadingPanel />;
    }

    return (
        <React.Fragment>
            <Row>
                <Col>
                    <Breadcrumb>
                        <Breadcrumb.Item
                            linkAs={Link}
                            linkProps={{
                                to: `${OrganisationConstants.RoutePath}/${organisationId}`,
                            }}
                        >
                            Manage
                        </Breadcrumb.Item>
                        <Breadcrumb.Item
                            linkAs={Link}
                            linkProps={{
                                to: `${OrganisationConstants.RoutePath}/${organisationId}`,
                            }}
                        >
                            Help Items
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>{pageContext}</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h1>{organisation.name}</h1>
                    <h2>{pageContext} help item</h2>
                </Col>
            </Row>
            <Row>
                <Col>
                    <HelpSectionForm
                        organisationId={organisationId}
                        helpItemId={helpItemId}
                    />
                </Col>
            </Row>
            <Row className="top30"></Row>
        </React.Fragment>
    );
};
export default HelpSectionPage;
