import { goBack, push } from "connected-react-router";
import * as Formik from "formik";
import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import TextArea from "../../../common/components/form/TextArea";
import TextInput from "../../../common/components/form/TextInput";
import LoadingSpinner from "../../../common/components/CoveringLoadingPanel";
import { HelpSectionFormMode } from "../../../Constants/HelpSectionConstants";
import OrganisationConstants from "../../../Constants/OrganisationConstants";
import {
    useAddHelpItemMutation,
    useDeleteHelpItemMutation,
    useGetHelpItemByIdQuery,
    usePutHelpItemMutation,
} from "../../../store/features/help-items/help-items-api-slice";
import { HelpItem } from "../domain/types";

interface Props {
    organisationId: string;
    helpItemId?: string | null;
}

const validation = yup.object({
    name: yup.string().trim().required("Required"),
    description: yup.string().trim().required("Required"),
    email: yup.string().email("Invalid email"),
    phone: yup.number().typeError("Invalid phone number"),
});

const HelpSectionForm: React.FC<Props> = ({ organisationId, helpItemId }) => {
    const dispatch = useDispatch();
    const [formMode, setFormMode] = React.useState(
        helpItemId ? HelpSectionFormMode.View : HelpSectionFormMode.Create,
    );

    const [addHelpItem] = useAddHelpItemMutation();
    const [updateHelpItem] = usePutHelpItemMutation();
    const [deleteHelpItem] = useDeleteHelpItemMutation();
    const { data: helpItem, isLoading: helpItemIsLoading } =
        useGetHelpItemByIdQuery(
            { helpItemId, organisationId },
            { skip: !helpItemId },
        );

    const initialFormValues: HelpItem = React.useMemo(() => {
        return {
            id: helpItem?.id ?? "",
            name: helpItem?.name ?? "",
            description: helpItem?.description ?? "",
            email: helpItem?.email ?? "",
            phone: helpItem?.phone ?? "",
        };
    }, [helpItem]);

    const redirectToManagePage = () =>
        dispatch(push(`${OrganisationConstants.RoutePath}/${organisationId}`));

    const handleSubmit = async (formValues: HelpItem) => {
        switch (formMode) {
            case HelpSectionFormMode.Create:
                await addHelpItem({
                    organisationId: organisationId,
                    helpItem: formValues,
                }).unwrap();
                redirectToManagePage();
                break;
            case HelpSectionFormMode.Edit:
                await updateHelpItem({
                    organisationId: organisationId,
                    helpItem: formValues,
                }).unwrap();
                redirectToManagePage();
                break;
            default:
                break;
        }
    };

    const handleDelete = async () => {
        if (window.confirm("Are you sure you want to delete this item?")) {
            await deleteHelpItem({ organisationId, helpItemId: helpItem.id });
            redirectToManagePage();
        }
    };
    const handleCancel = (resetForm: () => void) => {
        resetForm();
        formMode === HelpSectionFormMode.Edit
            ? setFormMode(HelpSectionFormMode.View)
            : dispatch(goBack());
    };

    if (helpItemId && (helpItemIsLoading || helpItem === undefined)) {
        return <LoadingSpinner />;
    }

    return (
        <>
            <Row className="top10">
                {formMode === HelpSectionFormMode.View && (
                    <Col>
                        <div className="float-right">
                            <Button
                                onClick={() =>
                                    setFormMode(HelpSectionFormMode.Edit)
                                }
                            >
                                Edit
                            </Button>
                            <Button
                                className="ml-1"
                                variant="danger"
                                onClick={handleDelete}
                            >
                                Delete
                            </Button>
                        </div>
                    </Col>
                )}
            </Row>
            <Formik.Formik
                initialValues={initialFormValues}
                onSubmit={handleSubmit}
                validationSchema={validation}
            >
                {({ resetForm }) => (
                    <Formik.Form>
                        <TextInput
                            label="Name"
                            placeholder="Name"
                            name="name"
                            readOnly={formMode === HelpSectionFormMode.View}
                        />
                        <TextInput
                            label="Phone"
                            placeholder="Phone"
                            name="phone"
                            readOnly={formMode === HelpSectionFormMode.View}
                        />
                        <TextInput
                            label="Email"
                            placeholder="Email"
                            name="email"
                            readOnly={formMode === HelpSectionFormMode.View}
                        />
                        <TextArea
                            label="Description"
                            name="description"
                            readOnly={formMode === HelpSectionFormMode.View}
                            rows={5}
                        />
                        {formMode !== HelpSectionFormMode.View && (
                            <Button variant="primary" type="submit">
                                {formMode === HelpSectionFormMode.Create
                                    ? "Save"
                                    : "Update"}
                            </Button>
                        )}
                        {(formMode === HelpSectionFormMode.Create ||
                            formMode === HelpSectionFormMode.Edit) && (
                            <Button
                                variant="danger"
                                className="ml-1"
                                onClick={() => handleCancel(resetForm)}
                            >
                                Cancel
                            </Button>
                        )}
                    </Formik.Form>
                )}
            </Formik.Formik>
        </>
    );
};

export default HelpSectionForm;
