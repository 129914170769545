import * as React from "react";
import { Col, Row } from "react-bootstrap";
import { ProcessActivityPreview } from "../domain/types";
import ActivityNavigationMenu from "./ActivityNavigationMenu";

interface ActivityLayoutProps {
    activity: ProcessActivityPreview;
    children: React.ReactNode;
}

const ActivityLayout: React.FC<ActivityLayoutProps> = ({
    activity,
    children,
}) => {
    return (
        <Row className="flex-nowrap h-100">
            <Col xs="auto" className="border-right pr-0 pl-0">
                <ActivityNavigationMenu activity={activity} />
            </Col>
            <Col className="process-activity-column">{children}</Col>
        </Row>
    );
};

export default ActivityLayout;
