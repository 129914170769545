import cx from "classnames";
import * as React from "react";
import { Button, Form, Nav } from "react-bootstrap";
import { FaAngleDoubleLeft, FaAngleDoubleRight } from "react-icons/fa";
import { useParams } from "react-router-dom";
import InfiniteScrollWrapper from "../../../common/components/InfiniteScrollWrapper";
import InlineCenteredLoader from "../../../common/components/InlineCenteredLoader";
import { SortAndFilterOptions } from "../../../common/types/SortAndFilterOptions";
import { useGetProcessActivityNavigationActivitiesByActivityIdQuery } from "../../../store/features/process-activity/processactivity-api-slice";
import { ProcessActivityPreview } from "../domain/types";
import { useActivityNavigation } from "../hooks/useActivityNavigation";
import { useGetInfiniteProcessNavigatableItems } from "../query/processActivitiesQuery";
import ProcessActivityNavigationBarItem from "./ProcessActivityNavigationBarItem";

interface Props {
    activity: ProcessActivityPreview;
}

const ActivityNavigationMenu: React.FC<Props> = ({ activity }) => {
    const [searchTerm, setSearchTerm] = React.useState("");
    const [isHidden, setIsHidden] = React.useState(false);

    const params = useParams<{
        processId: string;
        orgShortName: string;
        activityId: string;
    }>();

    const { getActivityUrl } = useActivityNavigation(
        params.orgShortName,
        params.processId,
    );

    const { data: navigator, isLoading } =
        useGetProcessActivityNavigationActivitiesByActivityIdQuery(
            { activity, processId: params.processId },
            {
                skip: !activity,
            },
        );

    const query = React.useMemo((): SortAndFilterOptions => {
        if (searchTerm === "") return {};
        return {
            filterParam: [
                {
                    field: "displayName",
                    dataType: "string",
                    isCustomField: false,
                    value: searchTerm,
                    operator: "contains",
                },
            ],
        };
    }, [searchTerm]);

    const {
        data,
        fetchNextPage,
        hasNextPage,
        isLoading: firstLoading,
    } = useGetInfiniteProcessNavigatableItems(activity.id, query);

    const listItems = React.useMemo(() => {
        if (!data) return [];
        return data?.pages?.map((page) => page.data).flat() || [];
    }, [data]);

    if (!activity || isLoading) return <InlineCenteredLoader />;

    return (
        <div
            className={cx("position-relative h-100-without-topnav pr-2", {
                "side-column-width": !isHidden,
            })}
        >
            {!isHidden && <h5 className="mt-4">{navigator?.parentName}</h5>}
            <div className="d-flex flow-row justify-content-between">
                {!isHidden && (
                    <Form.Control
                        type="text"
                        id="actSearchTerm"
                        placeholder="Search"
                        name="actSearchTerm"
                        className="mr-2"
                        onChange={(event): void =>
                            setSearchTerm(event.target.value)
                        }
                        value={searchTerm}
                        autoComplete="off"
                    />
                )}
                <Button
                    className={isHidden ? "mt-4" : ""}
                    onClick={(): void => setIsHidden(!isHidden)}
                    variant="light"
                >
                    {isHidden ? <FaAngleDoubleRight /> : <FaAngleDoubleLeft />}
                </Button>
            </div>
            {!isHidden && (
                <Nav className="nav flex-column mt-3" activeKey={activity?.id}>
                    <InfiniteScrollWrapper
                        fetchNextPage={fetchNextPage}
                        hasNextPage={hasNextPage}
                    >
                        {listItems.map((pActivity) => (
                            <ProcessActivityNavigationBarItem
                                data={pActivity}
                                activityUrl={getActivityUrl(pActivity.id)}
                                key={pActivity.id}
                            />
                        ))}
                    </InfiniteScrollWrapper>
                </Nav>
            )}
            {firstLoading && <InlineCenteredLoader />}
        </div>
    );
};

export default ActivityNavigationMenu;
