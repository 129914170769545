import * as React from "react";
import { Button } from "react-bootstrap";
import { mapDataToLabelValuePairs } from "../../../../../helpers/labelValueDataHelper";
import { useNavigation } from "../../../../../router/useNavigate";
import { ExaminationReview } from "../../domain/examinationsReview";
import "./reviewSummary.scss";

export const summaryLabelsMap = {
    elr: "ELR",
    miles: "Start Miles",
    yards: "Start Yards",
    assetType: "Asset Type",
    structureName: "Struct Name",
    examType: "Exam Type",
    structureReferenceNumber: "Struct No",
    examinationDate: "Exam Date",
    assignedExaminer: "Examiner",
    assignedEngineer: "Engineer",
    chains: "Chains",
};

const renderRow = (label: string, value: string, classExt = "") => (
    <div className={`summary-row ${classExt}`} key={`${label}-${value}`}>
        <div className="summary-label">{label}</div>
        <div>{value}</div>
    </div>
);

interface SummaryProps {
    processId: string;
    reivew: ExaminationReview;
}

const ReviewSummary: React.FC<SummaryProps> = ({ processId, reivew }) => {
    const { navigateToOrgPath } = useNavigation();
    const summary = mapDataToLabelValuePairs(reivew, summaryLabelsMap);

    return (
        <>
            <div className="pt-2">
                <Button
                    variant="primary"
                    className="mr-1"
                    onClick={() => {
                        navigateToOrgPath(
                            `/:orgShortName/process/${processId}/activity/${reivew.id}`,
                        );
                    }}
                >
                    View
                </Button>
            </div>
            <div className="pt-2 review-summary">
                {/* Header */}
                {renderRow("Field", "Value", "summary-header")}
                {/* Table */}
                {summary.map(([label, value]) => renderRow(label, value))}
            </div>
        </>
    );
};

export default ReviewSummary;
