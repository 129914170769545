import consumerApiService from "../../../../../customization/api/consumerApiService";
import { getData, postData } from "../../../../../helpers/ApiHelper";
import { ApprovedExam } from "../domain/approvedExam";
import {
    ApprovedExamDto,
    ReturnExamReasonModel,
} from "../domain/approvedExamDtos";

enum UrlPaths {
    BaseResource = "organisation",
    ExaminationsRejection = "examinationsrejection",
    approvedExams = "approved-exams",
    Return = "return",
}

const getBaseUrl = (organisationId: string) =>
    `${consumerApiService.baseApiUrl}/${UrlPaths.BaseResource}/${organisationId}`;

export async function getApprovedExams(
    organisationId: string,
): Promise<ApprovedExam[]> {
    const url = `${getBaseUrl(organisationId)}/${
        UrlPaths.ExaminationsRejection
    }/${UrlPaths.approvedExams}`;

    return getData<ApprovedExamDto[]>(url);
}

interface ReturnToEngineerArgs {
    organisationId: string;
    processId: string;
    postModel: ReturnExamReasonModel;
}

export function returnToEngineer({
    organisationId,
    processId,
    postModel,
}: ReturnToEngineerArgs): Promise<void> {
    const url = `${getBaseUrl(organisationId)}/${
        UrlPaths.ExaminationsRejection
    }/${processId}/${UrlPaths.Return}`;
    return postData(url, postModel);
}
