import React from "react";
import { useInfiniteQuery, UseInfiniteQueryResult } from "react-query";
import { DataContinuationResult } from "../../../../common/types/DataResult";
import { getWork } from "../services/examinationsPlanningService";
import { Work } from "../domain/Work";

export const useGetInfiniteWork = (
    organisationId: string,
): UseInfiniteQueryResult<DataContinuationResult<Work>> => {
    const getData = React.useCallback(
        ({ pageParam = null }): Promise<DataContinuationResult<Work>> => {
            const continuationToken = pageParam;

            return getWork({
                organisationId,
                query: { continuationToken },
            });
        },
        [organisationId],
    );

    const queryKeys = React.useMemo(
        () => ["myWork", organisationId],
        [organisationId],
    );

    return useInfiniteQuery<
        DataContinuationResult<Work>,
        [string, { page: number }]
    >(queryKeys, getData, {
        getNextPageParam: (lastResult) => {
            return lastResult.continuationToken || undefined;
        },
    });
};
