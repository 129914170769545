import * as React from "react";
import { RouteComponentProps } from "react-router";
import Breadcrumbs from "../../../../common/components/breadcrumbs/Breadcrumbs";
import CoveringLoadingPanel from "../../../../common/components/CoveringLoadingPanel";
import { useOrgTitleWithShortName } from "../../../../common/hooks/useTitle";
import { useOrganisationId } from "../../../organisation/hooks/useOrganisationId";
import ExaminationReviewsGrid from "../components/ExaminationReviewsGrid/ExaminationReviewsGrid";
import ExaminationsCount from "../components/ExaminationsCount/ExaminationsCount";
import pageTitles from "./pageTitles";

const ExaminationReviewsPage: React.FC<
    RouteComponentProps<{ orgShortName: string }>
> = () => {
    const { organisationId, isLoadingOrgId } = useOrganisationId();

    useOrgTitleWithShortName(pageTitles.reviewExaminations);

    if (isLoadingOrgId) return <CoveringLoadingPanel />;

    return (
        <>
            <div className="pt-2">
                <Breadcrumbs
                    items={[[pageTitles.reviewExaminations]]}
                ></Breadcrumbs>
            </div>
            <div>
                <h1>{pageTitles.reviewExaminations}</h1>
            </div>
            <div className="pt-2">
                <ExaminationsCount organisationId={organisationId} />
            </div>
            <div className="pt-4">
                <ExaminationReviewsGrid organisationId={organisationId} />
            </div>
        </>
    );
};

export default ExaminationReviewsPage;
