import React from "react";
import { Button } from "react-bootstrap";
import { useRemoveUserFromOrganisationMutation } from "../../../store/features/organisation/organisation-api-slice";
import { TableUser } from "./UsersTable";
import { hasUserOrgPermission } from "../../../store/features/user/user-api-slice";
import PermissionConstants from "../../../common/permissions/PermissionConstants";

interface Props {
    user: TableUser;
    organisationId: string;
}

const UsersTableActions: React.FC<Props> = ({ user, organisationId }) => {
    const [removeUser] = useRemoveUserFromOrganisationMutation();
    const canDelete = hasUserOrgPermission(
        organisationId,
        PermissionConstants.OrgUser.delete,
    ).hasPermission;

    const clickRemove = (): void => {
        if (window.confirm("Are you sure you want to remove this user?")) {
            removeUser({ userId: user.userId, organisationId });
        }
    };

    return (
        <>
            {!user.isOwner && canDelete && (
                <Button
                    className="top5 ml-1"
                    variant="danger"
                    onClick={clickRemove}
                >
                    Remove
                </Button>
            )}
        </>
    );
};

export default UsersTableActions;
