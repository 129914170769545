import { useField } from "formik";
import React from "react";
import { Form, Button } from "react-bootstrap";
import FormControlError from "../../../../common/components/dynamic-form/FormControlError";
import { AssetWithPropsModel } from "../../../../store/features/asset/asset-api-slice";
import { AssetProps } from "../domain/ReactiveExamCreatorDtos";
import ReactiveAddAssetsModal from "./ReactiveAddAssetsModal";

const SelectAssetFormInput: React.FC<{ organisationId: string }> = ({
    organisationId,
}) => {
    const [addActive, setAddActive] = React.useState<boolean>(false);
    const [field, meta, helpers] = useField<AssetProps>("asset");

    const AssetModelOnClose = React.useCallback(() => {
        setAddActive(false);
    }, []);

    const AssetModelOpen = React.useCallback(() => {
        setAddActive(true);
    }, []);

    const handleSubmitOfAssetModal = React.useCallback(
        (assets: AssetWithPropsModel[]) => {
            const assetValue: AssetProps = {
                id: assets.map((x) => x.id)[0],
                carrsGuid: assets.map(
                    (x) =>
                        x.properties.find(function (prop) {
                            return prop.id === "carrsGuid";
                        }).value,
                )[0],
            };
            helpers.setValue(assetValue);
        },
        [helpers],
    );

    console.log(meta.error);

    return (
        <>
            {addActive && (
                <ReactiveAddAssetsModal
                    organisationId={organisationId}
                    multiple={false}
                    onClose={AssetModelOnClose}
                    onSubmit={handleSubmitOfAssetModal}
                />
            )}
            <Form.Group>
                <Form.Label>{"CARRS GUID"}</Form.Label>
                <div className="form-group">
                    <Button
                        variant="secondary"
                        type="button"
                        onClick={AssetModelOpen}
                    >
                        Select Asset
                    </Button>
                </div>
                <Form.Control
                    type="text"
                    name={field.name}
                    readOnly={true}
                    value={field.value.carrsGuid}
                />
                <FormControlError field={"asset.id"} />
            </Form.Group>
        </>
    );
};

export default SelectAssetFormInput;
