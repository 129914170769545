import * as React from "react";
import { formatIsoDate } from "../../../helpers/dateTimeHelpers";
import { ApprovalResponse, ApprovalResponseType } from "../domain/types";

const ApprovalResponseItem: React.FC<{ data: ApprovalResponse }> = (props) => {
    const { data } = props;
    return (
        <li className="d-flex">
            <span>{data.approver.userEmail}</span>

            <span className="px-1">
                <strong>{ApprovalResponseType[data.responseType]}</strong>
            </span>

            <span className="px-1 text-break">
                {data.comments.length > 0 && (
                    <>
                        with comments <strong>{data.comments[0]}</strong>
                    </>
                )}
            </span>

            <span className="flex-shrink-0 ml-auto">
                {formatIsoDate(data.performedAt)}
            </span>
        </li>
    );
};

export default ApprovalResponseItem;
