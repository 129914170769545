import * as React from "react";
import { formatIsoDate } from "../../../helpers/dateTimeHelpers";
import { ApprovalResponse, ApprovalResponseType } from "../domain/types";

const ApprovalResponseInfo: React.FC<{ data: ApprovalResponse }> = (props) => {
    const { data } = props;
    return (
        <div>
            <div className="d-flex">
                <span className="mr-1">
                    <strong>{ApprovalResponseType[data.responseType]}</strong>
                </span>
                <span className="mr-1">by {data.approver.userEmail}</span>
                <span>at {formatIsoDate(data.performedAt)}</span>
            </div>
            <div>Comments: {data.comments[0] || "-"}</div>
        </div>
    );
};

export default ApprovalResponseInfo;
