import React from "react";
import { Col, Row } from "react-bootstrap";
import useOrgPermission from "../../../../common/hooks/useOrgPermission";
import PermissionConstants from "../../../../common/permissions/PermissionConstants";
import { IdAndName } from "../../../../common/types/IdAndName";
import UserGroupAddUserForm from "./UserGroupAddUserForm";
import UserGroupUsersTable from "./UserGroupUsersTable";

interface Props {
    orgId: string;
    userGroupId: string;
    addedUsers: IdAndName[];
    orgUsers: IdAndName[];
    updatedCallback: () => void;
}

export const UserGroupUsers: React.FC<Props> = (props) => {
    const getAvailableUsers = (): IdAndName[] => {
        return props.orgUsers.filter(
            (perm) => !props.addedUsers.some((p) => p.id === perm.id),
        );
    };

    const canManageUsers = useOrgPermission(
        PermissionConstants.OrgGroupUsers.manage,
    );

    return (
        <Row>
            <Col>
                {canManageUsers && (
                    <Row className="top10">
                        <Col>
                            <UserGroupAddUserForm
                                updatedCallback={props.updatedCallback}
                                orgId={props.orgId}
                                groupId={props.userGroupId}
                                users={getAvailableUsers()}
                            ></UserGroupAddUserForm>
                        </Col>
                    </Row>
                )}
                <Row className="top10">
                    <Col>
                        <UserGroupUsersTable
                            updatedCallback={props.updatedCallback}
                            orgId={props.orgId}
                            userGroupId={props.userGroupId}
                            users={props.addedUsers}
                            canRemoveUsers={canManageUsers}
                        ></UserGroupUsersTable>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};
