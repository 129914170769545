import * as React from "react";
import { useRef } from "react";
import DynamicForm from "../../../common/components/dynamic-form/DynamicForm";
import FormValuesHelper from "../../../common/components/dynamic-form/helpers/FormValuesHelper";
import { DynamicFormFormikProps } from "../../../common/components/dynamic-form/types/dynamicFormTypes";
import { getActivityFileResUrl } from "../../process-activity/helpers/urlBuilder";
import { TemplateContent } from "../../template/domain/types";

interface Props {
    files: TemplateContent[];
    latestProcessActivityId: string;
}

const TrackingObjectFiles: React.FC<Props> = ({
    files,
    latestProcessActivityId,
}) => {
    const formikRef = useRef<DynamicFormFormikProps>();
    const initialValues = FormValuesHelper.getInitialValues(files);

    const getFileUrl = React.useCallback(() => {
        return getActivityFileResUrl(latestProcessActivityId);
    }, [latestProcessActivityId]);

    return (
        <DynamicForm
            initialValues={initialValues}
            onSubmitCallback={null}
            saveProps={{
                onSaveDraftCallback: null,
                saveResult: null,
            }}
            isFormReadOnly={true}
            contentConfig={files}
            getFileUrl={getFileUrl()}
            formikRef={formikRef}
            hiddenFields={[]}
        />
    );
};

export default TrackingObjectFiles;
