import * as React from "react";
import { useSelector } from "react-redux";
import DynamicForm from "../../../common/components/dynamic-form/DynamicForm";
import FormValuesHelper from "../../../common/components/dynamic-form/helpers/FormValuesHelper";
import {
    DynamicFormFormikHelpers,
    DynamicFormRef,
    DynamicFormValues,
} from "../../../common/components/dynamic-form/types/dynamicFormTypes";
import { ApplicationState } from "../../../store";
import { resolveActivityState } from "../../process-activity/helpers/activityStateHelpers";
import { getActivityFileResUrl } from "../../process-activity/helpers/urlBuilder";
import { ProcessActivityForm } from "../domain/types";
import { usePostProcessActivityFormUpdateContentMutation } from "../store/processActivityFormApiSlice";

interface Props {
    activity: ProcessActivityForm;
    formikRef: DynamicFormRef;
    onComplete: () => void;
}

const ProcessActivityFormForm: React.FC<Props> = ({
    activity,
    formikRef,
    onComplete,
}) => {
    const { canEdit } = resolveActivityState(activity);

    const [updateFormContent, updateResult] =
        usePostProcessActivityFormUpdateContentMutation();

    //todo: Switch to the hidden fields in DynamicFormContextProvider
    const hiddenFields = useSelector(
        (state: ApplicationState) =>
            state.processActivityForm?.ui.viewForm.hiddenFields ?? [],
    );

    const initialStoredValues = FormValuesHelper.getInitialValues(
        activity?.content ?? [],
    );

    // Add any default state values which need to be tracked by the form
    const initialValues: DynamicFormValues = {
        ...initialStoredValues,
    };

    const onSubmit = (
        values: DynamicFormValues,
        { setSubmitting }: DynamicFormFormikHelpers,
    ): void => {
        // Convert the form value objects into html form data
        const formData = new FormData();
        FormValuesHelper.convertValuesToHtmlFormData(
            values,
            activity.content,
            formData,
            hiddenFields,
        );

        updateFormContent({
            activityId: activity.id,
            formData,
            shouldInvalidateActivity: false,
        }).then(() => {
            onComplete();
            setSubmitting(false);
        });
    };

    const onSaveDraft = (values: DynamicFormValues): void => {
        // Convert the form value objects into html form data
        const formData = new FormData();
        FormValuesHelper.convertValuesToHtmlFormData(
            values,
            activity.content,
            formData,
            hiddenFields,
        );

        updateFormContent({
            activityId: activity.id,
            formData,
            shouldInvalidateActivity: true,
        });
    };

    const getFileUrl = React.useCallback(() => {
        return getActivityFileResUrl(activity?.id);
    }, [activity]);

    return (
        <>
            <DynamicForm
                initialValues={initialValues}
                onSubmitCallback={onSubmit}
                saveProps={{
                    onSaveDraftCallback: onSaveDraft,
                    saveResult: updateResult,
                }}
                isFormReadOnly={!canEdit}
                contentConfig={activity.content}
                formikRef={formikRef}
                getFileUrl={getFileUrl()}
                hiddenFields={hiddenFields}
            />
        </>
    );
};

export default ProcessActivityFormForm;
