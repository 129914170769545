import * as React from "react";
import { Alert } from "react-bootstrap";
import { ProcessActivity, ProcessActivityAlert } from "../domain/types";
import ProcessActivityAlertButton from "./ProcessActivityAlertButton";

interface Props {
    activity: ProcessActivity;
}

const ProcessActivityAlertBar: React.FC<Props> = ({ activity }) => {
    const generateAlert = (alert: ProcessActivityAlert) => {
        return (
            <Alert key={alert.type} variant={alert.displayType}>
                <Alert.Heading>{alert.title}</Alert.Heading>
                <p className="mb-0">{alert.message}</p>
                {alert.buttons?.length ? (
                    alert.buttons.map((alertButton) => (
                        <ProcessActivityAlertButton
                            key={alertButton.type}
                            alertButton={alertButton}
                        ></ProcessActivityAlertButton>
                    ))
                ) : (
                    <></>
                )}
            </Alert>
        );
    };

    if (!activity.alerts) return null;

    const alerts = activity.alerts.map(generateAlert);

    return <div>{alerts}</div>;
};

export default ProcessActivityAlertBar;
