import React from "react";
import { Button, NavItem, NavLink } from "reactstrap";
import { Link } from "react-router-dom";
import { useIsCurrentUserAdmin } from "../../../store/features/user/user-api-slice";
import { useIsAuthenticated } from "@azure/msal-react";
import authService from "../../../modules/authorization/services/AuthService";
import { useAppSelector } from "../../../store/hooks";

const LoginMenu: React.FC = () => {
    const isAuthenticated = useIsAuthenticated();
    const user = useAppSelector((state) => state.authUser);

    const displayName = React.useMemo(
        () =>
            user.displayName
                ? `${user.displayName} (${user.username})`
                : `${user.username}`,
        [user],
    );

    const { isAdmin } = useIsCurrentUserAdmin();

    const login = () => authService.signIn();
    const logout = () => authService.signOut();
    const editProfile = () => authService.editProfile();

    const logoutButton = () => {
        return (
            <Button onClick={logout} color="light">
                Logout
            </Button>
        );
    };
    const userButton = () => {
        return (
            <Button onClick={editProfile} color="light">
                Hello {displayName}
            </Button>
        );
    };

    const authenticatedView = () => {
        return (
            <React.Fragment>
                {userButton()}
                {logoutButton()}
            </React.Fragment>
        );
    };

    const administratorView = (adminPath: string) => {
        return (
            <React.Fragment>
                {userButton()}
                <NavItem>
                    <NavLink tag={Link} className="text-dark" to={adminPath}>
                        Admin
                    </NavLink>
                </NavItem>
                {logoutButton()}
            </React.Fragment>
        );
    };

    const anonymousView = () => {
        return (
            <React.Fragment>
                <Button onClick={login} color="primary">
                    Login
                </Button>
            </React.Fragment>
        );
    };

    if (!isAuthenticated) {
        return anonymousView();
    } else if (isAdmin) {
        const adminPath = `${"/admin"}`;

        return administratorView(adminPath);
    } else {
        return authenticatedView();
    }
};

export default LoginMenu;
