import * as React from "react";
import { Row, Col, Breadcrumb } from "react-bootstrap";
import CoveringLoadingPanel from "../../../common/components/CoveringLoadingPanel";
import { useOrgTitleWithShortName } from "../../../common/hooks/useTitle";
import { useOrganisationId } from "../../organisation/hooks/useOrganisationId";
import HelpSectionList from "../containers/HelpSectionList";

import "./helpSection.css";
import { HelpSectionPageTitles } from "./pageTitles";

const OrgHelpListPage = (): JSX.Element => {
    const { organisationId, isLoadingOrgId } = useOrganisationId();

    useOrgTitleWithShortName(HelpSectionPageTitles.MainOrgView);

    if (isLoadingOrgId || organisationId === undefined) {
        return <CoveringLoadingPanel />;
    }

    return (
        <>
            <Row className="top10">
                <Col>
                    <Breadcrumb>
                        <Breadcrumb.Item active>Help</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h1>Help</h1>
                </Col>
            </Row>
            <Row className="top10">
                <Col>
                    <HelpSectionList organisationId={organisationId} />
                </Col>
            </Row>
        </>
    );
};
export default OrgHelpListPage;
