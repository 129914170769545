import {
    GridFieldsMap,
    GridFieldType,
} from "../../../../../common/components/table/kendo/GridFieldsMap";
import {
    AssetInfoContentFieldNames,
    PlanningInfoContentFieldNames,
} from "../../../constants/ProcessActivityContentFieldsConsts";

export const gridFieldsMap: GridFieldsMap = {
    processId: {
        field: PlanningInfoContentFieldNames.ProcessId,
        label: "Process Id",
        dataType: "string",
        fieldType: GridFieldType.Text,
        sortable: false,
    },
    carrsGUID: {
        field: PlanningInfoContentFieldNames.CarrsGuid,
        label: "CARRS GUID",
        dataType: "string",
        fieldType: GridFieldType.Text,
        isCustomField: true,
        filterable: false,
    },
    nrExamType: {
        field: PlanningInfoContentFieldNames.NrExamType,
        label: "NR Exam Type",
        dataType: "string",
        fieldType: GridFieldType.Text,
        isCustomField: true,
        filterable: false,
    },
    carrsExamId: {
        field: PlanningInfoContentFieldNames.CarrsExamId,
        label: "CARRS Exam ID",
        dataType: "string",
        fieldType: GridFieldType.Text,
        isCustomField: true,
        filterable: false,
    },
    contractYear: {
        field: PlanningInfoContentFieldNames.ContractYear,
        label: "Contract Year",
        dataType: "string",
        fieldType: GridFieldType.Text,
        isCustomField: true,
        filterable: false,
    },
    elr: {
        field: PlanningInfoContentFieldNames.Elr,
        label: "ELR",
        dataType: "string",
        fieldType: GridFieldType.Text,
        isCustomField: true,
        filterable: false,
    },
    miles: {
        field: PlanningInfoContentFieldNames.Miles,
        label: "Miles",
        dataType: "string",
        fieldType: GridFieldType.Text,
        isCustomField: true,
        filterable: false,
    },
    yards: {
        field: PlanningInfoContentFieldNames.Yards,
        label: "Yards",
        dataType: "string",
        fieldType: GridFieldType.Text,
        isCustomField: true,
        filterable: false,
    },
    chains: {
        field: PlanningInfoContentFieldNames.Chains,
        label: "Chains",
        dataType: "string",
        fieldType: GridFieldType.Text,
        isCustomField: true,
        filterable: false,
    },
    structureRefNo: {
        field: AssetInfoContentFieldNames.StructureReferenceNumber,
        label: "Structure Ref No",
        dataType: "string",
        fieldType: GridFieldType.Text,
        isCustomField: true,
        filterable: false,
    },
};

export const gridFilterOperators = {
    text: [
        {
            text: "grid.filterContainsOperator",
            operator: "contains",
        },
    ],
    date: [
        {
            text: "grid.filterEqOperator",
            operator: "eq",
        },
    ],
};
