import { saveAs } from "file-saver";
import React from "react";
import { Button } from "react-bootstrap";
import { FaDownload } from "react-icons/fa";
import ProcessReportTemplateConstants from "../../../Constants/ProcessReportTemplateConstants";
import authFetch from "../../../helpers/ApiHelper";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { ReportTemplateFile } from "../../../store/features/process-report-template/process-report-template-api-slice";

interface Props {
    files: ReportTemplateFile[];
}

type CellProps = {
    dataItem: { fileName: string; dataItem: string };
};

const ReportTemplateFilesList: React.FC<Props> = (props) => {
    const downloadFile = (fileName: string, blobFileName: string): void => {
        authFetch(
            `${ProcessReportTemplateConstants.ApiEndpoint}${ProcessReportTemplateConstants.ReportTemplateFilePath}/${blobFileName}`,
            {
                method: "GET",
            },
        )
            .then((res) => res.blob())
            .then((blob) => saveAs(blob, fileName));
    };

    return (
        <Grid data={props.files}>
            <Column
                field="fileName"
                title="File"
                headerClassName="grid-header"
            />
            <Column
                field="version"
                title="Version"
                headerClassName="grid-header"
            />
            <Column
                field=""
                width="80px"
                headerClassName="grid-header"
                cell={(cellProps: CellProps) => (
                    <td>
                        <Button
                            size="sm"
                            className="ml-1"
                            onClick={(): void =>
                                downloadFile(
                                    cellProps.dataItem["fileName"],
                                    cellProps.dataItem["blobFileName"],
                                )
                            }
                        >
                            <FaDownload />
                        </Button>
                    </td>
                )}
            />
        </Grid>
    );
};
export default ReportTemplateFilesList;
