import * as React from "react";
import { Button, ButtonGroup, ButtonToolbar } from "react-bootstrap";

interface Props {
    isEdit: boolean;
    isCreate: boolean;
    isSubmitting: boolean;
    resetForm: () => void;
    onCancel: () => void;
}

const TemplateFormEditToolbar: React.FC<Props> = ({
    isEdit,
    isCreate,
    isSubmitting,
    onCancel,
    resetForm,
}) => {
    return (
        <ButtonToolbar className="justify-content-end">
            <ButtonGroup>
                <Button variant="primary" type="submit" disabled={isSubmitting}>
                    {isEdit && "Update"}
                    {isCreate && "Create"}
                </Button>
                <Button
                    variant="danger"
                    className="ml-1"
                    onClick={() => {
                        resetForm();
                        onCancel();
                    }}
                >
                    Cancel
                </Button>
            </ButtonGroup>
        </ButtonToolbar>
    );
};

export default TemplateFormEditToolbar;
