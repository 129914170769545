import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import * as Formik from "formik";
import * as React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useHistory } from "react-router";
import CoveringLoadingPanel from "../../../common/components/CoveringLoadingPanel";
import {
    CustomSelect,
    TextArea,
    TextInput,
} from "../../../common/components/form";
import useError from "../../../common/hooks/useError";
import { mapToSelectOptions } from "../../../common/types/reactSelect/SelectOptionItem";
import {
    isMutationData,
    isMutationError,
} from "../../../common/types/rtkQuery/MutationResult";
import OrganisationConstants from "../../../Constants/OrganisationConstants";
import { useAddOrganisationMutation } from "../../../store/features/organisation/organisation-api-slice";
import { useGetAvailableUsersQuery } from "../../../store/features/user/user-api-slice";
import { OrganisationCreateRequestData } from "../domain/types";
import {
    getInitialOrgForm,
    handleMutationError,
    OrganisationFormValues,
    orgValidationSchema,
} from "../viewModel/organisationForm";
import OrgFormError from "./OrgFormError";

const OrganisationCreateForm: React.FC = () => {
    const history = useHistory();

    const { data: availableUsers, isLoading } = useGetAvailableUsersQuery();

    const { isError, error, setError, clearError } =
        useError<FetchBaseQueryError>();

    const [addOrganisation] = useAddOrganisationMutation();

    const onSuccessfulCreate = (res: { id: string }): void => {
        history.push(`${OrganisationConstants.RoutePath}/${res.id}`);
    };

    const cancel = (): void => {
        history.goBack();
    };

    const onSubmit = async (
        values: OrganisationFormValues,
        { setSubmitting, setFieldError },
    ): Promise<void> => {
        const requestData: OrganisationCreateRequestData = {
            name: values.name,
            description: values.description,
            ownerId: values.owner?.value,
            shortName: values.shortName,
        };

        const response = await addOrganisation(requestData);

        if (isMutationData(response)) {
            onSuccessfulCreate(response.data);
        }

        if (isMutationError(response)) {
            console.log("Request failed:", response.error.status);

            handleMutationError(setFieldError, response.error);
            setError(response.error);
        }

        setSubmitting(false);
    };

    if (isLoading) return <CoveringLoadingPanel />;

    return (
        <div className="pt-2">
            <Formik.Formik
                enableReinitialize
                initialValues={getInitialOrgForm()}
                validationSchema={orgValidationSchema}
                onSubmit={onSubmit}
            >
                {({ isSubmitting }): JSX.Element => (
                    <Formik.Form translate="yes">
                        <Row>
                            <Col>
                                <TextInput
                                    label="Organisation Name"
                                    name="name"
                                />
                            </Col>
                            <Col>
                                <TextInput
                                    label="Short Name"
                                    name="shortName"
                                    description="This text is used in the url bar i.e 'Amey Consulting' should be 'ameyconsulting'."
                                />
                            </Col>
                        </Row>
                        <CustomSelect
                            name="owner"
                            label="Organisation Owner"
                            placeholder="Select owner..."
                            options={mapToSelectOptions(
                                availableUsers,
                                (i) => i.id,
                                (i) => i.username,
                            )}
                        />
                        <TextArea
                            label="Organisation Description"
                            rows={5}
                            name="description"
                        />
                        <div className="form-footer-control">
                            {isError && (
                                <OrgFormError
                                    error={error}
                                    onClear={clearError}
                                />
                            )}
                            <div>
                                <Button
                                    variant="primary"
                                    type="submit"
                                    disabled={isSubmitting}
                                >
                                    Create
                                </Button>
                            </div>
                            <div>
                                <Button
                                    variant="danger"
                                    className="ml-1"
                                    onClick={cancel}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    </Formik.Form>
                )}
            </Formik.Formik>
        </div>
    );
};

export default OrganisationCreateForm;
