import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import * as Formik from "formik";
import { ErrorMessage } from "formik";
import * as React from "react";
import { Button, Form } from "react-bootstrap";
import { Input } from "reactstrap";
import * as yup from "yup";
import { CustomSelect } from "../../../../common/components/form";
import { mapToSelectOptions } from "../../../../common/types/reactSelect/SelectOptionItem";
import { UploadWorkbankParams } from "../store/WorkbankApiSlice";

interface Props {
    organisationId: string;
    onClose: () => void;
    uploadWorkbank: (arg: UploadWorkbankParams) => void;
}

interface WorkbankUploadFormValues {
    contractYear: { label: string; value: string };
    workbankFile: File;
}

function getContractYears(): { label: string; value: string }[] {
    const result = [] as { label: string; value: string }[];
    const yearsForward = 11;
    let year = new Date("2021").getFullYear();
    for (let index = 0; index < yearsForward; index++) {
        result.push({
            label: `${year}/${year + 1}`,
            value: `${year}/${year + 1}`,
        });
        year++;
    }
    return result;
}

const WorkbankUploadDialog: React.FC<Props> = ({
    organisationId,
    onClose,
    uploadWorkbank,
}) => {
    const initialFormValues: WorkbankUploadFormValues = {
        contractYear: null,
        workbankFile: null,
    };

    const validation = yup.object({
        contractYear: yup.mixed().required("Required"),
        workbankFile: yup.mixed().required("Required"),
    });

    const handleUploadSubmit = ({
        workbankFile,
        contractYear,
    }: WorkbankUploadFormValues): void => {
        uploadWorkbank({
            organisationId,
            workbankFile,
            contractYear: contractYear.value,
        });
        onClose();
    };

    return (
        <Dialog title="Workbank Upload" onClose={() => onClose()} width={400}>
            <Formik.Formik
                initialValues={initialFormValues}
                validationSchema={validation}
                onSubmit={handleUploadSubmit}
            >
                {({ setFieldValue, touched, errors }) => (
                    <Formik.Form>
                        <CustomSelect
                            name="contractYear"
                            label="Contract year"
                            placeholder="Select contract year..."
                            options={mapToSelectOptions(
                                getContractYears(),
                                (i) => i.value,
                                (i) => i.label,
                            )}
                        />
                        <Form.Group className="py-4">
                            <Input
                                type="file"
                                name="workbankFile"
                                className={
                                    touched.workbankFile && errors.workbankFile
                                        ? "is-invalid"
                                        : ""
                                }
                                role="button"
                                onChange={(e) => {
                                    setFieldValue(
                                        "workbankFile",
                                        e.target.files[0],
                                    );
                                }}
                            />
                            <ErrorMessage
                                name={"workbankFile"}
                                className="invalid-feedback"
                                component="div"
                            />
                        </Form.Group>
                        <DialogActionsBar layout="end">
                            <div className="pt-3">
                                <Button
                                    variant="link"
                                    onClick={() => onClose()}
                                >
                                    Back
                                </Button>
                                <Button variant="primary" type="submit">
                                    Confirm
                                </Button>
                            </div>
                        </DialogActionsBar>
                    </Formik.Form>
                )}
            </Formik.Formik>
        </Dialog>
    );
};

export default WorkbankUploadDialog;
