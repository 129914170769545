import cx from "classnames";
import * as React from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import CoveringLoadingPanel from "../../../../../common/components/CoveringLoadingPanel";
import { useAppSelector } from "../../../../../store/hooks";
import ApprovalResponseInfo from "../../../../process-activity-approval/components/ApprovalResponseInfo";
import { ApprovalResponse } from "../../../../process-activity-approval/domain/types";
import { useGetActivityByIdQuery } from "../../../../process-activity-approval/store/processActivityApprovalApiSlice";
import { resolveActivityState } from "../../../../process-activity/helpers/activityStateHelpers";
import { useActivityNavigation } from "../../../../../modules/process-activity/hooks/useActivityNavigation";
import "./examinationApproval.scss";
import TargetFileCard from "../../../../process-activity-approval/components/TargetFileCard";
import TargetLinkCard from "../../../../process-activity-approval/components/TargetLinkCard";

interface Props {
    activityId: string;
    processId: string;
    orgShortName: string;
}

const ExaminationApproval: React.FC<Props> = ({
    activityId,
    processId,
    orgShortName,
}) => {
    const userName = useAppSelector((state) => state.authUser?.username);
    const { getActivityUrl } = useActivityNavigation(orgShortName, processId);
    const { data: activity, isFetching } = useGetActivityByIdQuery(
        { activityId, processId },
        {
            skip: !activityId,
        },
    );

    const lastApprovalResponse: ApprovalResponse = React.useMemo(
        () =>
            activity?.approvalResponses[activity.approvalResponses.length - 1],
        [activity],
    );

    const activeApproval = React.useMemo(
        () => activity?.approvals[0],
        [activity],
    );

    const { canEdit } = resolveActivityState(activity);
    const inApprovalEditMode = React.useMemo(
        () => canEdit && activeApproval?.approver.userEmail === userName,
        [canEdit, activeApproval, userName],
    );

    if (isFetching) return <CoveringLoadingPanel />;

    return (
        <div className="mt-2">
            <div className="mt-4">
                <TargetFileCard
                    target={activity.targetFile}
                    activityId={activity.id}
                />
            </div>
            <div className="mt-4">
                <TargetLinkCard target={activity.targetLink} />
            </div>
            <Card>
                <Card.Body>
                    <Card.Title>{"Engineer's Approval"}</Card.Title>
                    <div className="mb-3">
                        {lastApprovalResponse ? (
                            <div
                                className={cx({
                                    "last-response": inApprovalEditMode,
                                })}
                            >
                                {inApprovalEditMode && (
                                    <div>Last response:</div>
                                )}
                                <ApprovalResponseInfo
                                    data={lastApprovalResponse}
                                ></ApprovalResponseInfo>
                            </div>
                        ) : (
                            "No approval provided yet"
                        )}
                    </div>
                    {inApprovalEditMode && (
                        <Link
                            to={getActivityUrl(activityId)}
                            className="btn btn-primary"
                        >
                            Go to Approval
                        </Link>
                    )}
                </Card.Body>
            </Card>
        </div>
    );
};

export default ExaminationApproval;
