import { range } from "lodash";
import any from "lodash/some";
import * as React from "react";
import {
    useReplaceFileMutation,
    useUploadFolderFileMutation,
} from "../../../store/features/file/fileApiSlice";
import FolderUploadButton from "../components/FolderUploadButton";
import ProgressDialog from "../components/ProgressDialog";
import UploadConfirmDialog from "../components/UploadConfirmDialog";
import { getFolderUploadDiff } from "../services/folderService";
import {
    buildFolderUpload,
    ExtendedUploadInfo,
} from "../viewModel/folderUploadHelpers";

interface FolderUploadProps {
    organisationId: string;
    folderId: string;
}

interface UploadState {
    uploading: boolean;
    uploaded?: number;
}

const FolderUpload: React.FC<FolderUploadProps> = ({
    organisationId,
    folderId,
}) => {
    const fileRef =
        React.useRef<{ path: string[]; file: File; index: number }[]>(null);
    const uploadRef = React.useRef<(i: number) => Promise<void>>(null);

    const [showConfirm, setShowConfirm] = React.useState<boolean>(false);
    const [uploadInfo, setUploadInfo] =
        React.useState<ExtendedUploadInfo>(null);

    const [uploadFolderFile] = useUploadFolderFileMutation();
    const [replaceFile] = useReplaceFileMutation();

    const [uploadStatus, setUploadingStatus] = React.useState<UploadState>({
        uploading: false,
        uploaded: 0,
    });
    const [fileCount, setFileCount] = React.useState<number>(0);

    const setStartUpload = (newFileCount: number) => {
        setShowConfirm(false);
        setUploadingStatus({
            uploading: true,
            uploaded: 0,
        });
        setFileCount(newFileCount);
    };

    const onStopUpload = () => {
        setUploadInfo(null);
        setUploadingStatus({ uploading: false });
        fileRef.current = null;

        uploadRef.current = async () => {
            setUploadingStatus({
                uploading: false,
            });
        };
    };

    const onCancelUpload = () => {
        setShowConfirm(false);
        setUploadInfo(null);
        fileRef.current = null;
    };

    const onConfirmReplaceUpload = async () => {
        setStartUpload(fileRef.current.length);

        uploadRef.current = async (i: number) => {
            if (i === fileRef.current.length) {
                setUploadingStatus({
                    uploading: false,
                });

                return;
            }

            const file = fileRef.current[i];

            const existingFile = uploadInfo.existingFiles.find(
                (pFileInfo) => pFileInfo.index === file.index,
            );

            if (existingFile) {
                await replaceFile({
                    fileId: existingFile.id,
                    organisationId,
                    folderId: existingFile.folderId,
                    file: file.file,
                });
            } else {
                await uploadFolderFile({
                    organisationId,
                    folderId,
                    path: file.path,
                    file: file.file,
                });
            }

            setUploadingStatus({
                uploading: true,
                uploaded: i + 1,
            });

            uploadRef.current(i + 1);
        };

        uploadRef.current(0);
    };

    const onConfirmNewUpload = async () => {
        setStartUpload(uploadInfo.newFiles.length);

        uploadRef.current = async (i: number) => {
            if (i === uploadInfo.newFiles.length) {
                setUploadingStatus({
                    uploading: false,
                });

                return;
            }

            const file = fileRef.current[uploadInfo.newFiles[i]];

            await uploadFolderFile({
                organisationId,
                folderId,
                path: file.path,
                file: file.file,
            });

            setUploadingStatus({
                uploading: true,
                uploaded: i + 1,
            });

            uploadRef.current(i + 1);
        };

        uploadRef.current(0);
    };

    const handleStartUpload = (files: FileList) => {
        const { uploadForm, parsedFiles } = buildFolderUpload(files);

        fileRef.current = parsedFiles;

        getFolderUploadDiff(organisationId, folderId, uploadForm).then(
            (folderUploadInfo) => {
                const extendedUploadInfo = {
                    ...folderUploadInfo,
                    newFiles: range(0, fileRef.current.length).filter(
                        (i) =>
                            !any(
                                folderUploadInfo.existingFiles,
                                (exFile) => exFile.index === i,
                            ),
                    ),
                };

                setUploadInfo(extendedUploadInfo);
                setShowConfirm(true);
            },
        );
    };

    const { uploading } = uploadStatus;

    return (
        <>
            {showConfirm && (
                <UploadConfirmDialog
                    onCancel={onCancelUpload}
                    onConfirmNew={onConfirmNewUpload}
                    onConfirmReplace={onConfirmReplaceUpload}
                    uploadInfo={uploadInfo}
                />
            )}
            {uploading && (
                <ProgressDialog
                    count={fileCount}
                    doneCount={uploadStatus.uploaded}
                    onClose={onStopUpload}
                />
            )}
            <FolderUploadButton
                key={`${showConfirm}${uploading}`}
                onUpload={handleStartUpload}
            />
        </>
    );
};

export default FolderUpload;
