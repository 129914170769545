/* eslint-disable @typescript-eslint/ban-types */
/*** Disable eslint rules for {} in extend that is common to make generic component} ***/

import { goBack, push } from "connected-react-router";
import { FormikHelpers } from "formik";
import OrganisationConstants from "../../Constants/OrganisationConstants";
import { BaseTemplateFormProps } from "../../modules/template/viewModel/templateForm";
import { TemplateFormValues } from "../../modules/template/viewModel/TemplateFormValues";
import { useAppDispatch } from "../../store/hooks";
import { HookUiResult } from "./useUiState";

interface HookResult<TArg> {
    onSubmit: (
        values: TArg,
        { setSubmitting }: FormikHelpers<TemplateFormValues>,
    ) => Promise<void>;
    onCancel: () => void;
    onDelete: () => void;
}

interface HookUiResultWithParam extends HookUiResult {
    setView: (param?: unknown) => void;
}

export const useFormActions = <
    TArg extends TemplateFormValues,
    T1 extends {},
    T2 extends {},
>(
    props: BaseTemplateFormProps<TArg, T1, T2>,
    { uiState, setView }: HookUiResultWithParam,
    templateName = "",
): HookResult<TArg> => {
    const appDispatch = useAppDispatch();

    const createSuccessful = (res: { id: string }): void => {
        appDispatch(push(`${props.createRedirectPath}/${res?.id}`));
    };

    const onCancel = (): void => {
        if (uiState.isEdit) {
            setView();
        } else {
            appDispatch(goBack());
        }
    };

    const onDelete = (): void => {
        if (
            props.deleteTemplate &&
            window.confirm(
                `Are you sure you want to delete this ${templateName}${
                    templateName !== "" ? " " : ""
                }template?`,
            )
        ) {
            props.deleteTemplate
                .actionCallback()
                .unwrap()
                .then(() => {
                    appDispatch(
                        push(
                            `${OrganisationConstants.RoutePath}/${props.organisationId}?tab=${props.deleteRedirectTabKey}`,
                        ),
                    );
                });
        }
    };

    const onSubmit = async (values: TArg, { setSubmitting }) => {
        if (props.createTemplate && uiState.isCreate) {
            props.createTemplate
                .actionCallback({ values })
                .unwrap()
                .then((payload) => {
                    createSuccessful(payload);
                })
                .catch(() => {
                    setSubmitting(false);
                });
        } else {
            if (props.updateTemplate && props.templateId) {
                props.updateTemplate
                    .actionCallback({ values })
                    .unwrap()
                    .then((param) => {
                        setView(param);
                    })
                    .finally(() => {
                        setSubmitting(false);
                    });
            }
        }
    };

    return { onCancel, onDelete, onSubmit };
};
