import * as Formik from "formik";
import * as React from "react";
import * as yup from "yup";
import { Button } from "react-bootstrap";
import CustomSelect from "../../../common/components/form/CustomSelect";
import { useState } from "react";
import {
    useAddUserToOrganisationMutation,
    useGetAvailableUsersToAddQuery,
    useGetUserGroupsByOrgIdQuery,
} from "../../../store/features/organisation/organisation-api-slice";
import {
    mapToSelectOptions,
    SelectOptionItem,
} from "../../../common/types/reactSelect/SelectOptionItem";

interface FormValues {
    selectedUser: SelectOptionItem;
    selectedGroups: SelectOptionItem[];
}

const validation = yup.object({
    selectedUser: yup.object().nullable().required("Required"),
    selectedGroups: yup
        .array()
        .required("Required")
        .min(1, "You must select at least one user group"),
});

const AddUserForm: React.FC<{ organisationId: string }> = ({
    organisationId,
}) => {
    const [isAdding, setIsAdding] = useState(false);
    const [addUser] = useAddUserToOrganisationMutation();

    const { data: users } = useGetAvailableUsersToAddQuery(organisationId, {
        skip: !organisationId,
    });

    const { data: userGroups } = useGetUserGroupsByOrgIdQuery(organisationId, {
        skip: !organisationId,
    });

    const initialFormValues: FormValues = React.useMemo(() => {
        return { selectedUser: null, selectedGroups: [] };
    }, []);

    const submitForm = (
        values: FormValues,
        { setSubmitting, resetForm }: Formik.FormikHelpers<FormValues>,
    ): void => {
        setSubmitting(true);
        addUser({
            organisationId,
            body: {
                userId: values.selectedUser?.value,
                userGroupIds: values.selectedGroups?.map((item) => item.value),
            },
        });
        setSubmitting(false);
        resetForm();
    };

    if (isAdding) {
        return (
            <>
                <Formik.Formik
                    enableReinitialize
                    initialValues={initialFormValues}
                    validationSchema={validation}
                    onSubmit={submitForm}
                >
                    {({ handleSubmit, isSubmitting }): JSX.Element => (
                        <Formik.Form translate="yes" onSubmit={handleSubmit}>
                            <CustomSelect
                                name="selectedUser"
                                label="Add user"
                                placeholder="Select user..."
                                options={mapToSelectOptions(
                                    users,
                                    (u) => u.id,
                                    (u) => u.username,
                                )}
                            />
                            <CustomSelect
                                name="selectedGroups"
                                label="User Groups"
                                placeholder="Select user group..."
                                isMulti
                                options={mapToSelectOptions(
                                    userGroups,
                                    (g) => g.id,
                                    (g) => g.name,
                                )}
                            />
                            <Button
                                variant="primary"
                                type="submit"
                                disabled={isSubmitting}
                            >
                                Add
                            </Button>
                            <Button
                                variant="outline-secondary"
                                className="ml-1"
                                onClick={() => setIsAdding(false)}
                            >
                                Cancel
                            </Button>
                        </Formik.Form>
                    )}
                </Formik.Formik>
            </>
        );
    } else {
        return (
            <div className="d-flex justify-content-end">
                <Button onClick={() => setIsAdding(true)}>Add user</Button>
            </div>
        );
    }
};

export default AddUserForm;
