import { GridColumn } from "@progress/kendo-react-grid";
import { Switch } from "@progress/kendo-react-inputs";
import { push } from "connected-react-router";
import * as React from "react";
import { useDispatch } from "react-redux";
import CoveringLoadingPanel from "../../../common/components/CoveringLoadingPanel";
import { ColumnMenuFilter } from "../../../common/components/table/kendo/columnFilters";
import { GridOfIdAndName } from "../../../common/components/table/kendo/GridOfIdAndName";
import { jSDateFromIso } from "../../../helpers/dateTimeHelpers";
import { useOrganisationId } from "../../organisation/hooks/useOrganisationId";
import { getUrlForAssignedItem } from "../helpers/getUrlForAssignedItem";
import { useGetProccessAssignedListQuery } from "../store/processAssignedApiSlice";

interface AssignedTableProps {
    orgShortName: string;
}

/**
 *  Table to display an ogranisation's users and actions
 */
const AssignedTable: React.FC<AssignedTableProps> = ({ orgShortName }) => {
    const [hideCompleted, setHideCompleted] = React.useState(true);
    const dispatch = useDispatch();
    const { organisationId, isLoadingOrgId } = useOrganisationId();
    const { data, isLoading } = useGetProccessAssignedListQuery(
        { organisationId, hideCompleted },
        {
            skip: isLoadingOrgId,
            refetchOnMountOrArgChange: true,
        },
    );

    const gridData = React.useMemo(
        () =>
            data
                ? data.map((item) => ({
                      ...item,
                      scheduledStart:
                          jSDateFromIso(item.scheduledStart) || null,
                      scheduledEnd: jSDateFromIso(item.scheduledEnd) || null,
                  }))
                : [],
        [data],
    );

    if (isLoading) return <CoveringLoadingPanel />;

    return (
        <>
            <div className="d-flex justify-content-end mb-2">
                <div className="d-flex">
                    <div className="mr-2">Hide completed</div>
                    <Switch
                        onChange={() => {
                            setHideCompleted(!hideCompleted);
                        }}
                        checked={hideCompleted}
                    ></Switch>
                </div>
            </div>

            <GridOfIdAndName
                data={gridData}
                onRowDoubleClick={(ev) =>
                    dispatch(
                        push(getUrlForAssignedItem(orgShortName, ev.dataItem)),
                    )
                }
            >
                {({ columnMenuCheckboxFilter }) => [
                    <GridColumn
                        key="parentName"
                        field="parentName"
                        title="Parent"
                        sortable
                        width="auto"
                        columnMenu={columnMenuCheckboxFilter}
                    />,
                    <GridColumn
                        key="scheduledStart"
                        field="scheduledStart"
                        title="Scheduled start"
                        sortable
                        width="auto"
                        filter="date"
                        format="{0:d MMM y h:mm a}"
                        columnMenu={ColumnMenuFilter}
                    />,
                    <GridColumn
                        key="scheduledEnd"
                        field="scheduledEnd"
                        title="Scheduled end"
                        sortable
                        width="auto"
                        filter="date"
                        format="{0:d MMM y h:mm a}"
                        columnMenu={ColumnMenuFilter}
                    />,
                    <GridColumn
                        key="processState"
                        field="processState"
                        title="Process State"
                        sortable
                        width="auto"
                        columnMenu={columnMenuCheckboxFilter}
                    />,
                ]}
            </GridOfIdAndName>
        </>
    );
};

export default AssignedTable;
