import * as React from "react";
import { Alert } from "react-bootstrap";
import CoveringLoadingPanel from "../../../common/components/CoveringLoadingPanel";
import NoAccess from "../../../common/components/NoAccess";
import { useOrgTitleWithShortName } from "../../../common/hooks/useTitle";
import {
    isQueryErrorForbidden,
    QueryResult,
} from "../../../common/types/rtkQuery/QueryResult";
import { Process } from "../../process/domain/types";
import { ProcessActivity } from "../domain/types";
import { resolveActivityState } from "../helpers/activityStateHelpers";
import { formatActivityPageTitle } from "../pages/pageTitles";
import ProcessActivityAlertBar from "./ProcessActivityAlertBar";
import ProcessActivityMainTopBar from "./ProcessActivityMainTopBar";
import ProcessActivityStatusBar, {
    ActivityStatusBarActionProps,
} from "./ProcessActivityStatusBar";

interface Props {
    processQuery: [process: Process, processResult: QueryResult<Process>];
    activityQuery: [
        activity: ProcessActivity,
        activityResult: QueryResult<ProcessActivity>,
    ];
    activityActions: ActivityStatusBarActionProps;
    orgShortName: string;
}

const ProcessActivityWrapper: React.FC<Props> = ({
    orgShortName,
    processQuery: [process, processResult],
    activityQuery: [activity, activityResult],
    activityActions: { complete, notApplicable, open },
    children,
}) => {
    const { isDisabled } = resolveActivityState(activity);

    useOrgTitleWithShortName(
        formatActivityPageTitle(
            process?.name,
            activity?.displayName,
            !process || !activity,
        ),
    );

    if (processResult.isFetching || activityResult.isFetching)
        return <CoveringLoadingPanel />;

    if (
        isQueryErrorForbidden(activityResult) ||
        isQueryErrorForbidden(processResult)
    ) {
        return <NoAccess />;
    }

    if (!process || !activity) return <></>;

    return (
        <div>
            <ProcessActivityMainTopBar
                process={process}
                activity={activity}
                orgShortName={orgShortName}
            ></ProcessActivityMainTopBar>

            {isDisabled ? (
                <Alert variant="warning">This activity is disabled</Alert>
            ) : (
                <>
                    <ProcessActivityStatusBar
                        activity={activity}
                        complete={complete}
                        notApplicable={notApplicable}
                        open={open}
                    />
                    <ProcessActivityAlertBar activity={activity} />
                    <div className="pt-3">{children}</div>
                </>
            )}
        </div>
    );
};

export default ProcessActivityWrapper;
