import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { DateTime } from "luxon";
import * as React from "react";
import CoveringLoadingPanel from "../../../../../common/components/CoveringLoadingPanel";
import {
    ColumnMenuFilter,
    getColumnMenuCheckboxFilter,
} from "../../../../../common/components/table/kendo/columnFilters";
import useGridProps from "../../../../../common/components/table/kendo/useGridProps";
import { AppRoutes } from "../../../../../router/AppRoutes";
import { useNavigation } from "../../../../../router/useNavigate";
import { useGetBatchesQuery } from "../../queries/BatchingApiSlice";

type ProcessListTableProps = {
    organisationId: string;
};

type BatchGridRow = {
    id: string;
    name: string;
    state: string;
    createdAt: Date;
    examsCount: number;
};

const BatchesGrid: React.FC<ProcessListTableProps> = ({ organisationId }) => {
    const { navigateToWithOrg } = useNavigation();
    const { data, isLoading } = useGetBatchesQuery(
        { organisationId },
        { refetchOnFocus: true, skip: !organisationId },
    );

    const parsedData =
        data?.map<BatchGridRow>(({ createdAt, ...other }) => {
            return {
                // Example: ISO date string to JS date with timezone
                createdAt: DateTime.fromISO(createdAt, { zone: "utc" })
                    .setZone(Intl.DateTimeFormat().resolvedOptions().timeZone)
                    .toJSDate(),
                ...other,
            };
        }) ?? [];

    const { gridProps } = useGridProps(parsedData, {
        filterable: true,
        sortable: true,
        pageable: true,
        initialSort: [{ field: "createdAt", dir: "desc" }],
    });

    if (isLoading) return <CoveringLoadingPanel />;

    const columnMenuCheckboxFilter = getColumnMenuCheckboxFilter(parsedData);

    return (
        <Grid
            {...gridProps}
            onRowDoubleClick={(ev) =>
                navigateToWithOrg(AppRoutes.BatchingDetails, {
                    batchId: ev.dataItem.id,
                })
            }
            className="mt-3"
        >
            <GridColumn
                field="name"
                title="Name"
                width="auto"
                sortable
                columnMenu={ColumnMenuFilter}
            />
            <GridColumn
                key="createdAt"
                field="createdAt"
                title="Created date"
                format="{0:d MMM y h:mm a}"
                sortable
                width="auto"
                columnMenu={columnMenuCheckboxFilter}
            />
            <GridColumn
                key="state"
                field="state"
                title="State"
                sortable
                width="auto"
                columnMenu={columnMenuCheckboxFilter}
            />
            <GridColumn
                key="examsCount"
                field="examsCount"
                title="Exams"
                filterable={false}
                sortable
                width="auto"
                columnMenu={columnMenuCheckboxFilter}
            />
        </Grid>
    );
};

export default BatchesGrid;
