enum ProcessActivityUrlPaths {
    ApiEndpoint = "/api/process-activities",
}

export const getActivityFileResUrl = (processActivityId: string) => {
    return (fileId: string): string =>
        processActivityId && fileId
            ? `${ProcessActivityUrlPaths.ApiEndpoint}/${processActivityId}/processactivityfile/${fileId}`
            : "";
};
