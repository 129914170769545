import { useFormikContext } from "formik";
import * as React from "react";
import { Form } from "react-bootstrap";
import { ShowCondition } from "../../../modules/template/domain/types";
import FormControlBase from "./FormControlBase";
import FormPromptComponent from "./FormPromptComponent";

interface FormControlTimeConfig {
    field: string;
    label: string;
    style: any;
    prompt: string;
    uri: string;
    isReadOnly: boolean;
    showConditions: ShowCondition[];
}

interface Props {
    config: FormControlTimeConfig;
}

const FormControlTime: React.FC<Props> = (props) => {
    const { config } = props;
    const formik = useFormikContext();

    return (
        <FormControlBase
            contentConfig={{
                field: config.field,
                showConditions: config.showConditions,
            }}
        >
            <Form.Label htmlFor={config.field}>{config.label}</Form.Label>
            <Form.Control
                type="time"
                name={config.field}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                style={{ ...config.style }}
                value={formik.values[config.field] ?? ""}
                className={formik.errors[config.field] ? "is-invalid" : ""}
                readOnly={config.isReadOnly}
            />
            <FormPromptComponent config={config} />
        </FormControlBase>
    );
};

export default FormControlTime;
