import * as React from "react";
import { Breadcrumb, Button, Col, Row } from "react-bootstrap";
import { RouteComponentProps } from "react-router";
import { Link } from "react-router-dom";
import CoveringLoadingPanel from "../../../common/components/CoveringLoadingPanel";
import CustomMultiSelect from "../../../common/components/CustomMultiSelect";
import { useOrgTitleWithShortName } from "../../../common/hooks/useTitle";
import PermissionConstants from "../../../common/permissions/PermissionConstants";
import OrganisationConstants from "../../../Constants/OrganisationConstants";
import { GridColumnDef } from "../../../store/features/assets-grid/assetsGridSlice";
import { hasUserOrgPermission } from "../../../store/features/user/user-api-slice";
import { useOrganisationId } from "../../organisation/hooks/useOrganisationId";
import OrgAssetList from "../containers/OrgAssetList";
import { usePersistedAssetsGridState } from "../hooks/usePersistedAssetsGridState";
import "./asset.scss";
import AssetPageTitles from "./pageTitles";

const defaultColumns: GridColumnDef[] = [
    {
        field: "name",
        title: "Name",
    },
    {
        field: "description",
        title: "Description",
    },
];

const AssetListPage: React.FC<RouteComponentProps<{ orgShortName: string }>> = (
    props,
) => {
    const orgShortName = props.match.params.orgShortName;
    const { organisationId, isLoadingOrgId } = useOrganisationId();
    const defaultColumnNames = defaultColumns.map((c) => c.title);

    useOrgTitleWithShortName(AssetPageTitles.List);

    const canCreateAsset = hasUserOrgPermission(
        organisationId,
        PermissionConstants.OrgAsset.create,
    ).hasPermission;

    const { templatesSelectProps, columnsSelectProps, clearFilters } =
        usePersistedAssetsGridState(organisationId, defaultColumns);

    if (isLoadingOrgId) return <CoveringLoadingPanel />;

    return (
        <>
            <Row className="top10">
                <Col>
                    <Breadcrumb>
                        <Breadcrumb.Item active>Assets</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h1>Assets</h1>
                </Col>
            </Row>
            <Row className="top10">
                <Col xs="auto">
                    {canCreateAsset && (
                        <Link
                            to={`/${orgShortName}${OrganisationConstants.AssetCreate}`}
                            className="btn btn-primary"
                        >
                            Create new asset
                        </Link>
                    )}
                </Col>
                <Col className="assets-templates-filters">
                    <CustomMultiSelect
                        {...templatesSelectProps}
                        defaultOptions={[]}
                    />
                    <CustomMultiSelect
                        {...columnsSelectProps}
                        defaultOptions={defaultColumnNames}
                    />
                </Col>
                <Col xs="auto">
                    <Button
                        color="warning"
                        onClick={clearFilters}
                        variant="outline-secondary"
                    >
                        Clear Filters
                    </Button>
                </Col>
            </Row>
            <Row className="top10">
                <Col>
                    <OrgAssetList
                        organisationId={organisationId}
                        orgShortName={orgShortName}
                    />
                </Col>
            </Row>
        </>
    );
};

export default AssetListPage;
