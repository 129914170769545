import { useFormikContext } from "formik";
import * as React from "react";
import { Form } from "react-bootstrap";
import { ShowCondition } from "../../../modules/template/domain/types";
import FormControlBase from "./FormControlBase";
import FormPromptComponent from "./FormPromptComponent";

interface FormControlCheckboxConfig {
    field: string;
    label: string;
    style: any;
    prompt: string;
    uri: string;
    isReadOnly: boolean;
    showConditions: ShowCondition[];
}

interface Props {
    config: FormControlCheckboxConfig;
}

const FormControlCheckbox: React.FC<Props> = (props) => {
    const { config } = props;
    const formik = useFormikContext();

    const value = formik.values[config.field] as string;
    const errors = formik.errors[config.field];

    const onChange = (e: any) => {
        formik.setFieldValue(config.field, e.target.checked ? "true" : "false");
    };

    if (value === "") {
        formik.setFieldValue(config.field, "false");
    }

    return (
        <FormControlBase
            contentConfig={{
                field: config.field,
                showConditions: config.showConditions,
            }}
        >
            <div className="mb-3">
                <Form.Check
                    type="checkbox"
                    name={config.field}
                    onChange={onChange}
                    onBlur={formik.handleBlur}
                    style={{ ...config.style }}
                    checked={value?.toLowerCase() === "true" ? true : false}
                    className={errors ? "is-invalid" : ""}
                    readOnly={config.isReadOnly}
                    disabled={config.isReadOnly}
                    label={config.label}
                />
            </div>
            <FormPromptComponent config={config} />
        </FormControlBase>
    );
};

export default FormControlCheckbox;
