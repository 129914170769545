import * as React from "react";
import { Col, Row } from "react-bootstrap";
import AddPermissionForm from "./AddPermissionForm";
import PermissionTable from "./PermissionTable";

const PermissionManagement: React.FC = () => {
    return (
        <Row>
            <Col>
                <Row className="top10">
                    <Col>
                        <AddPermissionForm></AddPermissionForm>
                    </Col>
                </Row>
                <Row className="top10">
                    <Col>
                        <PermissionTable></PermissionTable>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default PermissionManagement;
