import * as Formik from "formik";
import React from "react";
import { Button, ButtonToolbar } from "react-bootstrap";
import ButtonSpinner from "../../../common/components/button/ButtonSpinner";
import TextArea from "../../../common/components/form/TextArea";
import { ProcessActivity } from "../../process-activity/domain/types";
import { Approval } from "../domain/types";

export interface ApproveActionProps {
    actionCallback: (arg: {
        activity: ProcessActivity;
        approvalId: string;
        comment: string;
    }) => Promise<unknown>;
    actionResult: {
        isLoading: boolean;
        isError: boolean;
        isSuccess: boolean;
        error?: unknown;
    };
}

export enum ApprovalType {
    Approve = "Approve",
    Reject = "Reject",
}

interface FormValues {
    comment: string;
}

interface Props {
    activity: ProcessActivity;
    approval: Approval;

    approve: ApproveActionProps;
    reject: ApproveActionProps;
}

const ApproveRejectForm: React.FC<Props> = ({
    activity,
    approval,
    approve,
    reject,
}) => {
    const [approvalType, setApprovalType] = React.useState(
        ApprovalType.Approve,
    );
    const [isApproving, setIsApproving] = React.useState(false);

    React.useEffect(() => {
        if (!approve.actionResult.isLoading) {
            setIsApproving(false);
        }
    }, [approve.actionResult.isLoading]);

    React.useEffect(() => {
        if (!reject.actionResult.isLoading) {
            setIsApproving(false);
        }
    }, [reject.actionResult.isLoading]);

    const initialValues: FormValues = React.useMemo(
        () => ({
            comment: "",
            approvalType: approvalType,
        }),
        [approvalType],
    );

    const validateValues = React.useCallback(
        (values: FormValues): Formik.FormikErrors<FormValues> => {
            const errors: Formik.FormikErrors<FormValues> = {};

            if (approvalType === ApprovalType.Reject && !values.comment) {
                errors.comment = "Required";
            }

            return errors;
        },
        [approvalType],
    );

    const handleApprove = (): void => {
        setApprovalType(ApprovalType.Approve);
        setIsApproving(true);
    };

    const handleReject = (): void => {
        setApprovalType(ApprovalType.Reject);
        setIsApproving(true);
    };

    const handleSubmitApproval = React.useCallback(
        ({ comment }: FormValues): void => {
            if (approvalType === ApprovalType.Approve) {
                approve.actionCallback({
                    activity,
                    approvalId: approval.id,
                    comment,
                });
            }

            if (approvalType === ApprovalType.Reject) {
                reject.actionCallback({
                    activity,
                    approvalId: approval.id,
                    comment,
                });
            }
        },
        [activity, approval.id, approvalType, approve, reject],
    );

    if (!isApproving)
        return (
            <ButtonToolbar className="pt-2">
                <Button onClick={handleApprove}>Approve</Button>
                <Button
                    className="ml-1"
                    variant="danger"
                    onClick={handleReject}
                >
                    Reject
                </Button>
            </ButtonToolbar>
        );

    return (
        <div className="pt-2">
            <Formik.Formik
                initialValues={initialValues}
                validate={validateValues}
                onSubmit={handleSubmitApproval}
                enableReinitialize={true}
            >
                {({ values, isSubmitting }): JSX.Element => (
                    <Formik.Form>
                        <TextArea
                            label="Comment"
                            name="comment"
                            value={values.comment}
                            rows={5}
                        />
                        <ButtonToolbar>
                            <Button
                                type="submit"
                                variant="primary"
                                disabled={isSubmitting}
                            >
                                {approvalType === ApprovalType.Approve
                                    ? "Approve"
                                    : "Reject"}
                                {isSubmitting && <ButtonSpinner />}
                            </Button>
                            <Button
                                variant="secondary"
                                className="ml-1"
                                onClick={(): void => setIsApproving(false)}
                            >
                                Cancel
                            </Button>
                        </ButtonToolbar>
                    </Formik.Form>
                )}
            </Formik.Formik>
        </div>
    );
};

export default ApproveRejectForm;
