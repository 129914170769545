import { saveAs } from "file-saver";
import ReportConstants from "../Constants/ReportConstants";
import authFetch from "../helpers/ApiHelper";
import {
    GenerateResult,
    ReportFormListItem,
    ReportTemplate,
    ReportTemplateListItem,
} from "../modules/report/domain/types";

interface CreateResponse {
    id: string;
}

enum ReportUrlPaths {
    ApiEndpoint = "/api/process-activities",
    ResourceName = "/reportfiles",
}

const getReportFilesUrl = (activityId: string, reportRequestId: string) =>
    `${ReportUrlPaths.ApiEndpoint}/${activityId}${ReportUrlPaths.ResourceName}/${reportRequestId}`;

const reciveJson = <T = CreateResponse>(response: Response): Promise<T> => {
    if (!response.ok) throw Error(response.statusText);

    return response.json();
};

const reciveResponse = (response: Response): true => {
    if (!response.ok) throw Error(response.statusText);

    return true;
};

const ReportService = {
    Create: (
        name: string,
        description: string,
        configurationId: string,
        formTemplateId: string,
        formSelection: string[],
        organisationId: string,
    ): Promise<CreateResponse> => {
        return authFetch(ReportConstants.ApiEndpoint, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                id: "",
                name: name,
                description: description,
                configurationId: configurationId,
                formTemplateId: formTemplateId,
                formIds: formSelection,
                organisationId: organisationId,
            }),
        }).then(reciveJson);
    },
    Update: (
        id: string,
        name: string,
        description: string,
        configurationId: string,
        formSelection: string[],
    ): Promise<boolean> => {
        return authFetch(ReportConstants.ApiEndpoint, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                id: id,
                name: name,
                description: description,
                configurationId: configurationId,
                formIds: formSelection,
            }),
        }).then((response) => {
            if (response.ok) {
                return true;
            } else {
                // Raise an exception to reject the promise and trigger the outer .catch() handler.
                // By default, an error response status (4xx, 5xx) does NOT cause the promise to reject!
                throw Error(response.statusText);
            }
        });
    },
    Generate: (reportTemplateId: string): Promise<GenerateResult | void> => {
        return authFetch(`${ReportConstants.ApiPostGenerateReport}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                reportTemplateId: reportTemplateId,
            }),
        })
            .then((response) => reciveJson<GenerateResult>(response))
            .then((result) => {
                return ReportService.DownloadReport(
                    reportTemplateId,
                    result.id,
                    result.fileName,
                );
            });
    },
    DownloadReport: (
        reportTemplateId: string,
        reportId: string,
        fileName: string,
    ): Promise<void> => {
        return authFetch(
            `${ReportConstants.ApiEndpoint}${ReportConstants.ReportTemplate}/${reportTemplateId}${ReportConstants.ResourceName}/${reportId}`,
            {
                method: "GET",
            },
        )
            .then((res) => res.blob())
            .then((blob) => saveAs(blob, fileName));
    },
    GetFormListItemsByTemplate: (
        formTemplateId: string,
    ): Promise<ReportFormListItem[]> => {
        const params = { templateId: formTemplateId };
        const searchParams = new URLSearchParams(params).toString();

        return authFetch(
            `${ReportConstants.ApiReportFormListItems}?${searchParams}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            },
        ).then((response) => reciveJson<ReportFormListItem[]>(response));
    },
    GetFormListItemsByReport: (
        reportId: string,
    ): Promise<ReportFormListItem[]> => {
        const params = { reportId: reportId };
        const searchParams = new URLSearchParams(params).toString();
        return authFetch(
            `${ReportConstants.ApiReportFormListItems}?${searchParams}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            },
        ).then((response) => reciveJson<ReportFormListItem[]>(response));
    },
    GetOrgTemplateListItems: (
        organisationId: string,
    ): Promise<ReportTemplateListItem[]> => {
        const params = { organisationId: organisationId };
        const searchParams = new URLSearchParams(params).toString();

        return authFetch(
            `${ReportConstants.ApiGetReportTemplateListItems}?${searchParams}`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            },
        ).then((response) => reciveJson<ReportTemplateListItem[]>(response));
    },
    GetReportTemplate: (reportTemplateId: string): Promise<ReportTemplate> => {
        const params = { reportTemplateId: reportTemplateId };
        const searchParams = new URLSearchParams(params).toString();

        return authFetch(`${ReportConstants.ApiEndpoint}?${searchParams}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        }).then((response) => reciveJson<ReportTemplate>(response));
    },
    Delete: (reportTemplateId: string): Promise<boolean> => {
        return authFetch(`${ReportConstants.ApiEndpoint}/${reportTemplateId}`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
            },
        }).then(reciveResponse);
    },
    DeleteReport: (
        reportTemplateId: string,
        reportId: string,
    ): Promise<boolean> => {
        return authFetch(
            `${ReportConstants.ApiEndpoint}${ReportConstants.ReportTemplate}/${reportTemplateId}${ReportConstants.ResourceName}/${reportId}`,
            {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                },
            },
        ).then(reciveResponse);
    },
    DownloadFile: (
        processActivityId: string,
        fileId: string,
    ): Promise<void> => {
        return authFetch(getReportFilesUrl(processActivityId, fileId), {
            method: "GET",
        })
            .then(async (res) => {
                const filename = res.headers
                    .get("Content-Disposition")
                    .split("filename=")[1]
                    .split(";")[0];
                return { blob: await res.blob(), filename };
            })
            .then(({ blob, filename }) => {
                return saveAs(blob, filename);
            });
    },
};
export default ReportService;
