import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import * as React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { CellProps } from "react-table";
import BaseTable from "../../../../common/components/table/BaseTable";
import { useRemovePermissionFromGroupMutation } from "../../../../store/features/organisation/organisation-api-slice";
import { Permission } from "../../../organisation/domain/types";

interface Props {
    orgId: string;
    userGroupId: string;
    permissions: Permission[];
    updatedCallback: () => void;
    canRemovePermissions: boolean;
}

const permissionColumn = {
    Header: "Permission",
    accessor: (row: Permission): string => row.name,
};

const UserGroupPermissionsTable: React.FC<Props> = (props) => {
    const [removePermissionFromGroup] = useRemovePermissionFromGroupMutation();

    const { canRemovePermissions } = props;

    const columns = React.useMemo(
        () =>
            canRemovePermissions
                ? [
                      permissionColumn,
                      {
                          Header: "Actions",
                          accessor: (row: Permission): string => row.name,

                          Cell: function TableActions({
                              row,
                          }: CellProps<Permission>): JSX.Element {
                              const handleRemovePermissionFromGroup = async (
                                  id: string,
                              ) => {
                                  const result =
                                      await removePermissionFromGroup({
                                          orgId: props.orgId,
                                          groupId: props.userGroupId,
                                          permissionId: id,
                                      });

                                  if (
                                      !(
                                          result as {
                                              error:
                                                  | FetchBaseQueryError
                                                  | SerializedError;
                                          }
                                      ).error
                                  ) {
                                      props.updatedCallback();
                                  }
                              };

                              const onRemove = async (
                                  id: string,
                              ): Promise<void> => {
                                  if (
                                      window.confirm(
                                          "Are you sure you want to delete this item?",
                                      )
                                  ) {
                                      await handleRemovePermissionFromGroup(id);
                                  }
                              };

                              return (
                                  <Button
                                      variant="danger"
                                      onClick={async (): Promise<void> =>
                                          onRemove(row.original.id)
                                      }
                                  >
                                      Remove
                                  </Button>
                              );
                          },
                      },
                  ]
                : [permissionColumn],
        [canRemovePermissions, props, removePermissionFromGroup],
    );

    const data = React.useMemo(() => props.permissions, [props.permissions]);

    return (
        <Row className="top10">
            <Col>
                <BaseTable columns={columns} data={data}></BaseTable>
            </Col>
        </Row>
    );
};

export default UserGroupPermissionsTable;
