import { DateTime } from "luxon";

// deprecated - replace usages with formatIsoAsDateTime
export function formatIsoDate(isoDate: string | null): string | null {
    if (isoDate === null) {
        return null;
    }
    return DateTime.fromISO(isoDate).toLocaleString(DateTime.DATETIME_MED);
}

/* `Date iso string` formating (for UI display) */
export function formatIsoAsDate(isoDate?: string): string {
    if (!isoDate) {
        return "";
    }
    return DateTime.fromISO(isoDate).toLocaleString(DateTime.DATE_MED);
}

export function formatIsoAsDateTime(isoDate?: string): string {
    if (!isoDate) {
        return "";
    }
    return DateTime.fromISO(isoDate).toLocaleString(DateTime.DATETIME_MED);
}

/* JS Date formating (for UI display) */
export function formatJSDateAsDate(jsDate?: Date): string {
    if (!jsDate) {
        return "";
    }
    return DateTime.fromJSDate(jsDate).toLocaleString(DateTime.DATE_MED);
}

export function formatJSDateAsDateTime(jsDate?: Date): string {
    if (!jsDate) {
        return "";
    }
    return DateTime.fromJSDate(jsDate).toLocaleString(DateTime.DATETIME_MED);
}

/* Translate JS Date from `Date iso string` */
export function jSDateFromIso(isoDate?: string): Date | undefined {
    if (!isoDate) {
        return;
    }
    return DateTime.fromISO(isoDate).toJSDate();
}

export function jSDateFromDateIso(isoDate: string): Date | undefined {
    if (!isoDate) {
        return;
    }

    const isoDateString = isoDate.substring(0, 10);
    return DateTime.fromFormat(isoDateString, "yyyy-MM-dd").toJSDate();
    //ex: 2022-05-16
}

/* Translate JS Date to `Date iso string` */
export function jSDateToIso(jsDate?: Date): string {
    if (!jsDate) {
        return "";
    }
    return DateTime.fromJSDate(jsDate).toISO();
    //ex: 2022-05-16T22:55:00.000+02:00
}

export function jSDateToDateIso(jsDate?: Date): string {
    if (!jsDate) {
        return "";
    }
    return DateTime.fromJSDate(jsDate).toISO().substring(0, 10);
    //ex: 2022-05-16
}

export function jSDateToDateTimeIso(jsDate?: Date): string {
    if (!jsDate) {
        return "";
    }
    return DateTime.fromJSDate(jsDate).toISO().substring(0, 16);
    //ex: 2022-05-16T22:55
}

export const getCurrentDate = (): string =>
    new Date().toLocaleString("en-CA", {
        month: "numeric",
        year: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: false,
    });
