import React from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../../store";
import * as AdminStore from "../../../../store/admin/store";

interface Props {
    userId: string;
}

const RoleUserTableActions: React.FC<Props> = (props) => {
    const dispatch = useDispatch();

    const roleId = useSelector((state: ApplicationState) => {
        return (
            state.admin?.domain.role.id ??
            AdminStore.unloadedState.domain.role.id
        );
    });

    const click = () => {
        if (window.confirm("Are you sure you want to remove this User?")) {
            dispatch(
                AdminStore.actionCreators.removeRoleUser(roleId, props.userId),
            );
        }
    };

    return (
        <>
            <Button variant="danger" className="top5 ml-1" onClick={click}>
                Remove
            </Button>
        </>
    );
};

export default RoleUserTableActions;
