import { configureStore } from "@reduxjs/toolkit";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import { combineReducers } from "redux";
import {
    FLUSH,
    PAUSE,
    PERSIST,
    persistReducer,
    persistStore,
    PURGE,
    REGISTER,
    REHYDRATE,
} from "redux-persist";
import autoMergeLevel2 from "redux-persist/es/stateReconciler/autoMergeLevel2";
import storage from "redux-persist/es/storage";
import thunk from "redux-thunk";
import { BaseUrl } from "../Constants/ApiAuthorizationConstants";
import { consumerApi } from "../customization/api/consumerRTKQueryApi";
import { reducers } from "./";
import { configApi } from "./config/configApi";
import { dcpApi } from "./features/dcpApi";
import { globalErrorNotificationMiddleware } from "./middleware/globalErrorNotificationMiddleware";
import { logoutWhenUnauthenticated } from "./middleware/logoutWhenUnauthenticated";

export const history = createBrowserHistory({ basename: BaseUrl });

const middleware = [
    thunk,
    routerMiddleware(history),
    configApi.middleware,
    dcpApi.middleware,
    consumerApi.middleware,
    globalErrorNotificationMiddleware,
    logoutWhenUnauthenticated,
];

const rootReducer = combineReducers({
    ...reducers,
    [dcpApi.reducerPath]: dcpApi.reducer,
    [consumerApi.reducerPath]: consumerApi.reducer,
    [configApi.reducerPath]: configApi.reducer,
    router: connectRouter(history),
});

// Persistor WHITELIST
const persistConfig = {
    key: "root",
    storage: storage,
    whitelist: ["assetGridFilters"],
    stateReconciler: autoMergeLevel2,
};

export const store = configureStore({
    reducer: persistReducer(persistConfig, rootReducer),
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [
                    FLUSH,
                    REHYDRATE,
                    PAUSE,
                    PERSIST,
                    PURGE,
                    REGISTER,
                ],
            },
        }).concat(...middleware),
});

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
