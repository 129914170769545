import noop from "lodash/noop";
import React, { useRef } from "react";
import { useSelector } from "react-redux";
import DynamicForm from "../../../common/components/dynamic-form/DynamicForm";
import FormConfigHelper from "../../../common/components/dynamic-form/helpers/FormConfigHelper";
import FormValuesHelper from "../../../common/components/dynamic-form/helpers/FormValuesHelper";
import {
    DynamicFormFormikHelpers,
    DynamicFormFormikProps,
    DynamicFormValues,
} from "../../../common/components/dynamic-form/types/dynamicFormTypes";
import ErrorBoundary from "../../../common/error-boundry/ErrorBoundary";
import { ApplicationState } from "../../../store";

interface Props {
    configPreview: string;
}

const TemplatePreview: React.FC<Props> = ({ configPreview }) => {
    const parsedConfigResult = FormConfigHelper.parseConfig(configPreview);
    const initialValues = FormValuesHelper.getInitialValues(
        parsedConfigResult.output,
    );

    const hiddenFields = useSelector(
        (state: ApplicationState) =>
            state.processActivityForm?.ui.viewForm.hiddenFields ?? [],
    );

    const onsubmit = (
        values: DynamicFormValues,
        actions: DynamicFormFormikHelpers,
    ): void => {
        const formData = new FormData();
        FormValuesHelper.convertValuesToHtmlFormData(
            values,
            parsedConfigResult.output,
            formData,
            hiddenFields,
        );
        actions.setSubmitting(false);
    };

    const formikRef = useRef<DynamicFormFormikProps>();

    const getFileUrl = React.useCallback(() => {
        return () => "";
    }, []);

    if (parsedConfigResult.error) {
        return <>Error: {parsedConfigResult.error}</>;
    } else if (parsedConfigResult.output) {
        return (
            <ErrorBoundary>
                <DynamicForm
                    initialValues={initialValues}
                    onSubmitCallback={onsubmit}
                    isFormReadOnly={false}
                    contentConfig={parsedConfigResult.output}
                    formikRef={formikRef}
                    saveProps={{
                        onSaveDraftCallback: noop,
                        saveResult: {
                            isError: false,
                            isLoading: false,
                        },
                    }}
                    getFileUrl={getFileUrl()}
                    hiddenFields={hiddenFields}
                />
            </ErrorBoundary>
        );
    } else {
        return <></>;
    }
};

export default TemplatePreview;
