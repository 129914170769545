import * as React from "react";

import { Button, ButtonToolbar, Col, Row } from "react-bootstrap";
import {
    useDeleteBatchMutation,
    useGetBatchByIdQuery,
    useSubmitBatchMutation,
} from "../queries/BatchingApiSlice";

import { useParams } from "react-router";
import Breadcrumbs from "../../../../common/components/breadcrumbs/Breadcrumbs";
import CoveringLoadingPanel from "../../../../common/components/CoveringLoadingPanel";
import { useOrgTitleWithShortName } from "../../../../common/hooks/useTitle";
import { AppRoutes } from "../../../../router/AppRoutes";
import { useNavigation } from "../../../../router/useNavigate";
import { useOrganisationId } from "../../../organisation/hooks/useOrganisationId";
import BatchingStateConstants from "../BatchingConstants";
import AddExamsModal from "../components/batching/AddExamsModal";
import BatchDownloadButton from "../components/batching/BatchDownloadButton";
import { BatchExamsGrid } from "../components/batching/BatchExamsGrid";
import InvalidExamsAlert, {
    isInvalidBatch,
} from "../components/batching/InvalidExamsAlert";
import pageTitles from "./pageTitles";
import CesSubmissionStatus from "../components/batching/CesSubmissionStatus";
import BatchErrorDetailsAlert from "../components/batching/BatchErrorDetailsAlert";
import { batch } from "react-redux";

const BatchingDetailsPage: React.FC = () => {
    useOrgTitleWithShortName(pageTitles.batchCreate);
    const { organisationId } = useOrganisationId();
    const { batchId } = useParams<{
        batchId: string;
    }>();

    const [showAdd, setShowAdd] = React.useState(false);
    const { navigateToWithOrg, getOrgLink: getLink } = useNavigation();

    const [deleteBatch] = useDeleteBatchMutation();
    const [batchSubmit] = useSubmitBatchMutation();
    const { data, isLoading } = useGetBatchByIdQuery(
        {
            id: batchId,
            organisationId,
        },
        {
            skip: !organisationId || !batchId,
        },
    );

    const handleDelete = () => {
        if (window.confirm("Are you sure you want to delete this batch?")) {
            deleteBatch({ id: batchId, organisationId });
            navigateToWithOrg(AppRoutes.Batching);
        }
    };

    const handleSubmit = () => {
        if (
            window.confirm(
                "Do you confirm you have manually delivered these exams through CES?",
            )
        ) {
            batchSubmit({ id: batchId, organisationId });
            navigateToWithOrg(AppRoutes.Batching);
        }
    };

    if (isLoading) return <CoveringLoadingPanel />;
    return (
        <>
            <div className="mt-3">
                <Breadcrumbs
                    items={[
                        ["Batching", getLink(AppRoutes.Batching)],
                        [data.name],
                    ]}
                ></Breadcrumbs>
            </div>
            <div>
                <h1>
                    {data.name}: {data.state}{" "}
                </h1>
            </div>
            <CesSubmissionStatus batch={data} />
            <BatchErrorDetailsAlert batch={data} />
            <InvalidExamsAlert batch={data} />
            <Row>
                <Col>
                    <ButtonToolbar>
                        {!isInvalidBatch(data) &&
                            data.state ===
                                BatchingStateConstants.StateDraft && (
                                <Button
                                    className="ml-1"
                                    variant="primary"
                                    onClick={handleSubmit}
                                >
                                    Manually Submitted
                                </Button>
                            )}
                        {!isInvalidBatch(data) &&
                            data.state !== BatchingStateConstants.Pending && (
                                <>
                                    <div className="ml-1">
                                        <BatchDownloadButton
                                            batch={data}
                                            schemaType="CES"
                                        />
                                    </div>
                                </>
                            )}
                        {data.state === BatchingStateConstants.StateDraft && (
                            <Button
                                className="ml-1"
                                variant="danger"
                                onClick={handleDelete}
                            >
                                Delete
                            </Button>
                        )}
                    </ButtonToolbar>
                </Col>
            </Row>
            {data.state === BatchingStateConstants.StateDraft && (
                <Row className="mt-2">
                    <Col>
                        <Button
                            variant="outline-secondary"
                            type="button"
                            onClick={() => setShowAdd(true)}
                        >
                            Add exam
                        </Button>
                    </Col>
                </Row>
            )}
            {showAdd && (
                <AddExamsModal
                    organisationId={organisationId}
                    batchId={data.id}
                    onClose={() => setShowAdd(false)}
                />
            )}
            <Row>
                <Col>
                    <BatchExamsGrid
                        batch={data}
                        organisationId={organisationId}
                    />
                </Col>
            </Row>
        </>
    );
};

export default BatchingDetailsPage;
