import * as React from "react";
import { Row, Col, Breadcrumb } from "react-bootstrap";
import { RouteComponentProps } from "react-router";
import { Link } from "react-router-dom";
import OrganisationConstants from "../../../Constants/OrganisationConstants";
import PermissionConstants from "../../../common/permissions/PermissionConstants";
import { ProcessPageTitles } from "./pageTitles";
import { useGetOrgSimpleByShortNameQuery } from "../../../store/features/organisation/organisation-api-slice";
import { hasUserOrgPermission } from "../../../store/features/user/user-api-slice";
import { useOrgTitleWithShortName } from "../../../common/hooks/useTitle";
import ProcessInfiniteGrid from "../containers/ProcessInfiniteGrid";

const ProcessesListPage: React.FC<
    RouteComponentProps<{ orgShortName: string }>
> = (props) => {
    const orgShortName = props.match.params.orgShortName;

    const { data: organisation } = useGetOrgSimpleByShortNameQuery(
        orgShortName,
        {
            skip: !orgShortName,
        },
    );

    const canProcessCreate = hasUserOrgPermission(
        organisation.id,
        PermissionConstants.OrgProcess.create,
    ).hasPermission;

    useOrgTitleWithShortName(ProcessPageTitles.List);

    return (
        <>
            <Row className="top10">
                <Col>
                    <Breadcrumb>
                        <Breadcrumb.Item active>Processes</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h1>Processes</h1>
                </Col>
            </Row>
            <Row className="top10">
                <Col>
                    {canProcessCreate && (
                        <Link
                            to={`/${orgShortName}${OrganisationConstants.ProcessCreate}`}
                            className="btn btn-primary"
                        >
                            Create new process
                        </Link>
                    )}
                </Col>
            </Row>
            <Row className="top10">
                <Col>
                    <ProcessInfiniteGrid organisationId={organisation.id} />
                </Col>
            </Row>
        </>
    );
};

export default ProcessesListPage;
