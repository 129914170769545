import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { useField } from "formik";
import React, { createRef, LegacyRef, useMemo } from "react";
import { useResizeDetector } from "react-resize-detector";
import { commonFilterOperators } from "../../../../common/components/table/kendo/columnFilters";
import CustomPagingFooter from "../../../../common/components/table/kendo/CustomPagingFooter";
import GridLoadingPanel from "../../../../common/components/table/kendo/GridLoadingPanel";
import useInfiniteGridProps from "../../../../common/components/table/kendo/useInfiniteGridProps";
import useAssetsSortAndFitler, {
    dynamicAssetPropPrefix,
} from "../../../../common/hooks/useSortAndFilter";
import {
    AssetTemplateModel,
    AssetWithPropsModel,
} from "../../../../store/features/asset/asset-api-slice";
import { usePersistedAssetsGridState } from "../../../asset/hooks/usePersistedAssetsGridState";
import {
    useGetInfiniteOrgAssets,
    useGetInfiniteOrgAssetsCount,
} from "../../../asset/query/assetQueries";
import { reactiveExamAssetGridDefaultColumns } from "./ReactiveExamAssetGridDefaultColumns";

export const ACTIVITY_ASSET_SELECTED_FIELD = "assets";

interface OrgAssetLinkListProps {
    organisationId: string;
    selectMultiple: boolean;
}
const minimumColumnWidth = 250;

const defaultColumns = reactiveExamAssetGridDefaultColumns;

const emptyAssetTemplateModel: AssetTemplateModel[] = [];

const ReactiveAddAssetsGrid: React.FC<OrgAssetLinkListProps> = ({
    organisationId,
    selectMultiple,
}) => {
    const gridWrapperRef = createRef<HTMLElement>();
    const { width, ref: gridWidthSourceRef } = useResizeDetector();
    const { gridState, setGridState } = usePersistedAssetsGridState(
        organisationId,
        defaultColumns,
    );

    const sortAndFilter = useAssetsSortAndFitler(
        gridState,
        emptyAssetTemplateModel,
        defaultColumns,
    );
    const {
        data: assets,
        fetchNextPage,
        hasNextPage,
        isFetching,
        isFetchingNextPage,
    } = useGetInfiniteOrgAssets(organisationId, sortAndFilter);

    const {
        data: count,
        isFetching: isFetchingCount,
        isLoading: isLoadingCount,
    } = useGetInfiniteOrgAssetsCount(organisationId, sortAndFilter);

    const flatAssetData = useMemo(() => {
        if (!assets) return [];

        return assets.pages
            .map((asset) => {
                return asset.data.map((r) => {
                    const result = {
                        ...r,
                    };

                    r.properties.forEach(
                        (prop) =>
                            (result[dynamicAssetPropPrefix + prop.id] =
                                prop.value),
                    );

                    return result;
                });
            })
            .flat();
    }, [assets]);

    const [, , helper] = useField<AssetWithPropsModel[]>(
        ACTIVITY_ASSET_SELECTED_FIELD,
    );

    const reactiveAssetLinkGridProps =
        useInfiniteGridProps<AssetWithPropsModel>({
            data: flatAssetData,
            dataState: gridState,
            hasNextPage,
            setDataState: setGridState,
            loading: isFetching || isFetchingNextPage,
            fetchMore: () => {
                fetchNextPage();
            },
            gridRef: gridWrapperRef,
            dataItemKey: "id",
            filterable: true,
            selectable: {
                config: {
                    enabled: true,
                    drag: true,
                    mode: selectMultiple ? "multiple" : "single",
                },
                onSelectChange: (newSelected) => {
                    helper.setValue(newSelected);
                },
            },
        });

    return (
        <div ref={gridWidthSourceRef} style={{ width: "100%" }}>
            <div ref={gridWrapperRef as LegacyRef<HTMLDivElement>}>
                <Grid
                    style={{ width: `${width}px` }}
                    className="asset-grid-h"
                    {...reactiveAssetLinkGridProps}
                    filterOperators={commonFilterOperators}
                >
                    {defaultColumns.map(({ field, title, ...rest }) => {
                        const props = { field, title };
                        Object.entries(rest).forEach(([key, value]) => {
                            if (value) {
                                props[key] = value;
                            }
                        });

                        return (
                            <GridColumn
                                {...props}
                                key={title}
                                headerClassName={"grid-header"}
                                width={
                                    defaultColumns.length > 2 &&
                                    minimumColumnWidth *
                                        defaultColumns.length >=
                                        width
                                        ? minimumColumnWidth
                                        : (width * 0.98) / defaultColumns.length
                                }
                            />
                        );
                    })}
                </Grid>
                {(isFetching || isFetchingNextPage || isFetchingCount) && (
                    <GridLoadingPanel gridRef={gridWrapperRef} />
                )}
                <CustomPagingFooter
                    total={count}
                    loading={
                        !reactiveAssetLinkGridProps.data ||
                        isFetchingCount ||
                        isLoadingCount
                    }
                    dataCount={reactiveAssetLinkGridProps.data.length}
                />
            </div>
        </div>
    );
};

export default ReactiveAddAssetsGrid;
