import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Form, Formik } from "formik";
import React from "react";
import { Button } from "react-bootstrap";
import { AssetWithPropsModel } from "../../../../store/features/asset/asset-api-slice";
import { ACTIVITY_ASSET_SELECTED_FIELD } from "../../../process-activity/components/OrgAssetLinkList";
import ReactiveAddAssetsGrid from "./ReactiveAddAssetsGrid";
import "./ReactiveAddAssetsModal.scss";

interface AddAssetsModalProps {
    organisationId: string;
    multiple?: boolean;
    onClose: () => void;
    onSubmit: (AssetWithPropsModel) => void;
}

const initialFormValue = () => ({ [ACTIVITY_ASSET_SELECTED_FIELD]: [] });

const ReactiveAddAssetsModal: React.FC<AddAssetsModalProps> = ({
    multiple,
    organisationId,
    onClose,
    onSubmit,
}) => {
    const onSubmitHandler = React.useCallback(
        async (values) => {
            const assets: AssetWithPropsModel[] =
                values[ACTIVITY_ASSET_SELECTED_FIELD];
            onSubmit(assets);
            onClose();
        },
        [multiple, onClose, organisationId],
    );

    return (
        <Dialog
            title={multiple ? "Add Assets" : "Select Asset"}
            onClose={onClose}
            width="calc(100vw - 40px)"
            height="calc(100vh - 40px)"
            className="add-assets-modal"
        >
            <Formik
                onSubmit={onSubmitHandler}
                initialValues={initialFormValue()}
                style={{ height: "100%" }}
            >
                {({ values, handleSubmit, isSubmitting }) => (
                    <Form onSubmit={handleSubmit} className="assets-form">
                        <ReactiveAddAssetsGrid
                            organisationId={organisationId}
                            selectMultiple={multiple}
                        />
                        <div className="action-buttons">
                            <DialogActionsBar>
                                <Button
                                    type="button"
                                    variant="outline-secondary"
                                    onClick={onClose}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    type="submit"
                                    variant="primary"
                                    disabled={
                                        !values[ACTIVITY_ASSET_SELECTED_FIELD]
                                            ?.length || isSubmitting
                                    }
                                >
                                    Submit
                                </Button>
                            </DialogActionsBar>
                        </div>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
};

ReactiveAddAssetsModal.defaultProps = {
    multiple: false,
};

export default ReactiveAddAssetsModal;
