import React, { useState } from "react";
import { Card, Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { formatIsoDate } from "../../../helpers/dateTimeHelpers";
import { FileComment } from "../domain/file";
import { FaEllipsisV } from "react-icons/fa";
import moment from "moment";
import { FileCommentForm } from "./FileCommentForm";
import { useDeleteFileCommentMutation } from "../../../store/features/file/file-comments-api-slice";
import MutationResultStatus from "../../../common/components/MutationResultStatus";
import { FileCommentProps } from "./FileComments";
import "./FileCommentEntry.css";
import DOMPurify from "dompurify";
import PermissionConstants from "../../../common/permissions/PermissionConstants";
import { hasUserOrgPermission } from "../../../store/features/user/user-api-slice";
import AuditActionsList from "../../../common/components/audit/AuditActionsList";

interface Props {
    comment: FileComment;
    commentProps: FileCommentProps;
}

enum Mode {
    VIEWING = "VIEWING",
    EDITING = "EDITING",
    HISTORY = "HISTORY",
}

export const FileCommentEntry: React.FC<Props> = ({
    comment,
    commentProps,
}) => {
    const [mode, setMode] = useState<Mode>(Mode.VIEWING);
    const [deleteComment, deleteCommentResult] = useDeleteFileCommentMutation();
    const organisationId = commentProps.organisationId;

    const canUpdateComments = hasUserOrgPermission(
        organisationId,
        PermissionConstants.OrgFilesFileComment.update,
    ).hasPermission;

    const canDeleteComments = hasUserOrgPermission(
        organisationId,
        PermissionConstants.OrgFilesFileComment.delete,
    ).hasPermission;

    const onDelete = () => {
        if (window.confirm("Are you sure you want to delete this item?")) {
            deleteComment({
                ...commentProps,
                commentId: comment?.id,
            });
        }
    };

    return (
        <>
            <MutationResultStatus
                mutationResult={deleteCommentResult}
                showLoading
            />

            <Card className={"mb-2"}>
                <Card.Header
                    className={
                        "d-flex justify-content-between align-items-center"
                    }
                >
                    <div>
                        {comment.createdBy}{" "}
                        <OverlayTrigger
                            placement="auto"
                            overlay={
                                <Tooltip id={comment.id}>
                                    {formatIsoDate(comment.lastUpdatedAt)}
                                </Tooltip>
                            }
                        >
                            <small className="text-muted">
                                {moment(comment.lastUpdatedAt).fromNow()}
                            </small>
                        </OverlayTrigger>
                    </div>
                    <div>
                        <Dropdown>
                            <Dropdown.Toggle variant="light" bsPrefix="p-0">
                                <FaEllipsisV />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item
                                    eventKey="1"
                                    onClick={() =>
                                        mode === Mode.HISTORY
                                            ? setMode(Mode.VIEWING)
                                            : setMode(Mode.HISTORY)
                                    }
                                >
                                    {mode === Mode.HISTORY
                                        ? "Comment"
                                        : "History"}
                                </Dropdown.Item>
                                <Dropdown.Item
                                    eventKey="1"
                                    onClick={() => setMode(Mode.EDITING)}
                                    disabled={
                                        !canUpdateComments || !comment.canEdit
                                    }
                                >
                                    Edit
                                </Dropdown.Item>
                                <Dropdown.Item
                                    eventKey="2"
                                    onClick={onDelete}
                                    disabled={
                                        !canDeleteComments || !comment.canEdit
                                    }
                                >
                                    Remove
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </Card.Header>
                <Card.Body>
                    {mode === Mode.VIEWING && (
                        <Card.Text>
                            <div
                                className={"comment-holder"}
                                dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(comment.data),
                                }}
                            />
                        </Card.Text>
                    )}
                    {mode === Mode.EDITING && (
                        <FileCommentForm
                            onCompleted={() => setMode(Mode.VIEWING)}
                            isEdit
                            comment={comment}
                            props={commentProps}
                        />
                    )}
                    {mode === Mode.HISTORY && (
                        <AuditActionsList auditActions={comment.auditActions} />
                    )}
                </Card.Body>
            </Card>
        </>
    );
};
