import * as Formik from "formik";
import React from "react";
import { Col, Row } from "react-bootstrap";
import * as yup from "yup";
import TextArea from "../../../common/components/form/TextArea";
import TextInput from "../../../common/components/form/TextInput";
import { CreateUserGroup } from "../domain/types";

interface UserGroupFormProps {
    userGroup: CreateUserGroup;
    onSubmit: (model: CreateUserGroup) => Promise<void>;
    editable: boolean;
    children: (isSubmitting: boolean) => React.ReactNode;
}

const validation = yup.object({
    name: yup.string().trim().required("Required"),
    description: yup.string().trim().required("Required"),
});

export const UserGroupForm: React.FC<UserGroupFormProps> = ({
    userGroup,
    onSubmit,
    editable,
    children,
}) => {
    return (
        <Row className="top10">
            <Col>
                <Formik.Formik
                    enableReinitialize
                    initialValues={{
                        name: userGroup.name,
                        description: userGroup.description,
                    }}
                    validationSchema={validation}
                    onSubmit={onSubmit}
                >
                    {({
                        isSubmitting,
                    }: Formik.FormikProps<CreateUserGroup>): JSX.Element => (
                        <Formik.Form>
                            <TextInput
                                label="Name"
                                placeholder="Name"
                                name="name"
                                readOnly={isSubmitting || !editable}
                            />
                            <TextArea
                                label="Description"
                                name="description"
                                readOnly={isSubmitting || !editable}
                                rows={3}
                            />
                            {children(isSubmitting)}
                        </Formik.Form>
                    )}
                </Formik.Formik>
            </Col>
        </Row>
    );
};
