import { hasUserOrgPermission } from "../../store/features/user/user-api-slice";
import PermissionConstants from "../permissions/PermissionConstants";

export interface PermissionHookResponse {
    canViewProcesses: boolean;
    canViewScheduler: boolean;
    canReadFolders: boolean;
    canReadFiles: boolean;
    canViewAssets: boolean;
    canViewSearch: boolean;
    canViewHelpSection: boolean;
    canViewFiles: boolean;
    canViewUsers: boolean;
    canInviteUsers: boolean;
    canViewGroups: boolean;
    canManageGroupPermissions: boolean;
    canViewReports: boolean;
    canViewWorkbanks: boolean;
    canViewExaminationsSchedule: boolean;
    canViewExaminationsReview: boolean;
    canViewCommercialDownloadPage: boolean;
    canViewReportDownloadPage: boolean;
    canViewReactiveExamCreatorPage: boolean;
    canViewBatching: boolean;
    canViewMissingCarrsExamToolPage: boolean;
    canViewApprovedExams: boolean;
    canViewExaminationsBatches: boolean;
    canViewCommercialMessUpdater: boolean;
    canViewExaminationsMassRemoval: boolean;
    canViewCesAssetDetails: boolean;
    canViewCesExamRequest: boolean;
    canViewExamTools: boolean;
}

const useViewPermissions = (organisationId: string): PermissionHookResponse => {
    const rawPermissions = {
        canViewProcesses: hasUserOrgPermission(
            organisationId,
            PermissionConstants.OrgProcess.read,
        ).hasPermission,
        canViewScheduler: hasUserOrgPermission(
            organisationId,
            PermissionConstants.OrgSchedulerRead,
        ).hasPermission,
        canReadFolders: hasUserOrgPermission(
            organisationId,
            PermissionConstants.OrgFilesFolder.read,
        ).hasPermission,
        canReadFiles: hasUserOrgPermission(
            organisationId,
            PermissionConstants.OrgFilesFile.read,
        ).hasPermission,
        canViewAssets: hasUserOrgPermission(
            organisationId,
            PermissionConstants.OrgAsset.read,
        ).hasPermission,
        canViewSearch: hasUserOrgPermission(
            organisationId,
            PermissionConstants.OrgFileSearch,
        ).hasPermission,
        canViewHelpSection: hasUserOrgPermission(
            organisationId,
            PermissionConstants.OrgHelpSection.read,
        ).hasPermission,
        canViewUsers: hasUserOrgPermission(
            organisationId,
            PermissionConstants.OrgUser.read,
        ).hasPermission,
        canCreateUsers: hasUserOrgPermission(
            organisationId,
            PermissionConstants.OrgUser.create,
        ).hasPermission,
        canManageGroupUsers: hasUserOrgPermission(
            organisationId,
            PermissionConstants.OrgGroupUsers.manage,
        ).hasPermission,
        canManageGroupPermissions: hasUserOrgPermission(
            organisationId,
            PermissionConstants.OrgGroupPermission.manage,
        ).hasPermission,
        canViewGroups: hasUserOrgPermission(
            organisationId,
            PermissionConstants.OrgUserGroup.read,
        ).hasPermission,
        canViewReports: hasUserOrgPermission(
            organisationId,
            PermissionConstants.OrgReport.read,
        ).hasPermission,
        canViewWorkbanks: hasUserOrgPermission(
            organisationId,
            PermissionConstants.OrgWorkbank.read,
        ).hasPermission,
        canViewExaminationsSchedule: hasUserOrgPermission(
            organisationId,
            PermissionConstants.Examinations.view,
        ).hasPermission,
        canViewExaminationsReview: hasUserOrgPermission(
            organisationId,
            PermissionConstants.ExaminationReviews.view,
        ).hasPermission,
        canViewCommercialDownloadPage: hasUserOrgPermission(
            organisationId,
            PermissionConstants.CommercialReportDownloadPage,
        ).hasPermission,
        canViewReportDownloadPage: hasUserOrgPermission(
            organisationId,
            PermissionConstants.ReportDownloadPage,
        ).hasPermission,
        canViewReactiveExamCreatorPage: hasUserOrgPermission(
            organisationId,
            PermissionConstants.ReactiveExamCreatorPage,
        ).hasPermission,
        canViewBatching: hasUserOrgPermission(
            organisationId,
            PermissionConstants.Batching,
        ).hasPermission,
        canViewMissingCarrsExamToolPage: hasUserOrgPermission(
            organisationId,
            PermissionConstants.OrgMissingCarrsExamIdTool,
        ).hasPermission,
        canViewApprovedExams: hasUserOrgPermission(
            organisationId,
            PermissionConstants.OrgApprovedExamsReport,
        ).hasPermission,
        canViewExaminationsBatches: hasUserOrgPermission(
            organisationId,
            PermissionConstants.OrgExaminationsBulkUpdate.read,
        ).hasPermission,
        canViewCommercialMessUpdater: hasUserOrgPermission(
            organisationId,
            PermissionConstants.OrgCommercialMessUpdater.read,
        ).hasPermission,
        canViewExaminationsMassRemoval: hasUserOrgPermission(
            organisationId,
            PermissionConstants.OrgExaminationsMassRemoval.read,
        ).hasPermission,
        canViewCesAssetDetails: hasUserOrgPermission(
            organisationId,
            PermissionConstants.CesAssetDetails,
        ).hasPermission,
        canViewCesExamRequest: hasUserOrgPermission(
            organisationId,
            PermissionConstants.CesExamRequests,
        ).hasPermission,
    };

    return {
        ...rawPermissions,
        canInviteUsers:
            rawPermissions.canViewGroups &&
            rawPermissions.canCreateUsers &&
            rawPermissions.canManageGroupUsers,
        canViewFiles:
            rawPermissions.canReadFiles && rawPermissions.canReadFolders,
        canViewExamTools:
            rawPermissions.canViewMissingCarrsExamToolPage ||
            rawPermissions.canViewApprovedExams ||
            rawPermissions.canViewBatching,
    };
};

export default useViewPermissions;
