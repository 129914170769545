import React from "react";
import { TemplateContent } from "../../../modules/template/domain/types";
import FormContentBuilder from "./FormContentBuilder";
import { DynamicFormFormikProps } from "./types/dynamicFormTypes";

interface Props {
    config: TemplateContent[];
    formik: DynamicFormFormikProps;
    isFormReadOnly: boolean;
}

const RecursiveFormComponent: React.FC<Props> = ({
    config,
    formik,
    isFormReadOnly,
}: Props) => {
    return (
        <>
            {config.map((itemConfig: TemplateContent, index: number) => {
                return (
                    <FormContentBuilder
                        config={itemConfig}
                        isFormReadOnly={isFormReadOnly}
                        key={index.toString()}
                    />
                );
            })}
        </>
    );
};

export default RecursiveFormComponent;
