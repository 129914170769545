import * as React from "react";
import { Button, ButtonProps } from "react-bootstrap";
import PermissionConstants from "../../../common/permissions/PermissionConstants";
import { hasUserOrgPermission } from "../../../store/features/user/user-api-slice";

interface Props {
    organisationId: string;
    buttonProps: ButtonProps;
}
export const CreateFolderButton: React.FC<Props> = (props) => {
    const canCreate = hasUserOrgPermission(
        props.organisationId,
        PermissionConstants.OrgFilesFolder.create,
    ).hasPermission;

    if (!canCreate) {
        return null;
    }
    return (
        <Button variant="outline-secondary" {...props.buttonProps}>
            New folder
        </Button>
    );
};
