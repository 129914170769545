import * as React from "react";
import { Col, Row } from "react-bootstrap";
import { Redirect, useParams } from "react-router";
import useViewPermissions, {
    PermissionHookResponse,
} from "../../../common/hooks/useViewPermissions";
import { WithOrgShortName } from "../../../common/types/WithOrg";
import OrganisationConstants from "../../../Constants/OrganisationConstants";
import { AppRoutes } from "../../../router/AppRoutes";
import { useGetOrgSimpleByShortNameQuery } from "../../../store/features/organisation/organisation-api-slice";

const getRedirectRoute = (
    orgShortName: string,
    permissions: PermissionHookResponse,
): string => {
    if (permissions.canViewProcesses)
        return `/${orgShortName}${OrganisationConstants.Process}`;

    if (permissions.canViewAssets)
        return `/${orgShortName}${OrganisationConstants.Asset}`;

    if (permissions.canViewScheduler)
        return AppRoutes.Scheduler.toLink({ orgShortName });

    if (permissions.canViewFiles)
        return `/${orgShortName}/${OrganisationConstants.files}`;

    if (permissions.canViewHelpSection)
        return `/${orgShortName}/${OrganisationConstants.help}`;

    if (permissions.canViewUsers)
        return `/${orgShortName}/${OrganisationConstants.users}`;

    return "";
};

const OrganisationHomeRedirectPage: React.FC = () => {
    const { orgShortName } = useParams<WithOrgShortName>();

    const { data: organisation } = useGetOrgSimpleByShortNameQuery(
        orgShortName,
        {
            skip: !orgShortName,
        },
    );

    const permissions = useViewPermissions(organisation.id);

    const {
        canViewProcesses,
        canViewAssets,
        canViewScheduler,
        canViewFiles,
        canViewHelpSection,
    } = permissions;

    const canRedirect =
        canViewProcesses ||
        canViewAssets ||
        canViewScheduler ||
        canViewFiles ||
        canViewHelpSection;

    if (canRedirect)
        return <Redirect to={getRedirectRoute(orgShortName, permissions)} />;

    return (
        <Row className="top10">
            <Col>
                <p className="text-center">
                    Please request access to {organisation.name} resources from
                    Organisation Administrators.
                </p>
            </Col>
        </Row>
    );
};

export default OrganisationHomeRedirectPage;
