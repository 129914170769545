import { GridFieldsMap } from "../../../../../common/components/table/kendo/GridFieldsMap";

export const recommendationsGridMap: GridFieldsMap = {
    description: {
        field: "description",
        label: "Description",
        dataType: "string",
    },
    location: { field: "location", label: "Location", dataType: "string" },
    quantityValue: {
        field: "quantityValue",
        label: "Quantity Value",
        dataType: "string",
    },
    quantityUnits: {
        field: "quantityUnits",
        label: "Quantity Units",
        dataType: "Date",
    },
    worksCategory: {
        field: "worksCategory",
        label: "Works ategory",
        dataType: "string",
    },
    estimatePrice: {
        field: "estimatePrice",
        label: "Estimate Price",
        dataType: "string",
    },
    priority: {
        field: "priority",
        label: "Priority",
        dataType: "string",
    },
    sme: {
        field: "sme",
        label: "SME",
        dataType: "string",
    },
    effect: {
        field: "effect",
        label: "Effect",
        dataType: "string",
    },
    probability: {
        field: "probability",
        label: "Probability",
        dataType: "Date",
    },
};
