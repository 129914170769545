import * as React from "react";
import { Alert, Form } from "react-bootstrap";
import { ProcessActivity } from "../../process-activity/domain/types";
import { useActivityNavigation } from "../../process-activity/hooks/useActivityNavigation";
import { ProcessActivityGroup } from "../domain/types";
import ProcessActivityGroupListItem from "./ProcessActivityGroupListItem";

interface Props {
    activity: ProcessActivityGroup;
    orgShortName: string;
}

const ProcessActivityGroupActivityList: React.FC<Props> = ({
    activity,
    orgShortName,
}) => {
    const [searchFilter, setSearchFilter] = React.useState("");

    const { getActivityUrl } = useActivityNavigation(
        orgShortName,
        activity.processId,
    );

    const listItems = React.useMemo(() => {
        return (
            activity?.activities?.filter((a) =>
                a.displayName
                    .toLowerCase()
                    .includes(searchFilter.toLowerCase()),
            ) ?? []
        );
    }, [activity, searchFilter]);

    return (
        <>
            <div className="mt-3 w-75">
                <Form.Control
                    type="text"
                    placeholder="Search All ..."
                    onChange={(e) => setSearchFilter(e.target.value)}
                    aria-describedby="basic-addon1"
                    value={searchFilter}
                />
            </div>
            <div className="pt-2">
                {listItems.map((item: ProcessActivity, index: number) => (
                    <ProcessActivityGroupListItem
                        key={index.toString()}
                        activity={item}
                        activityUrl={getActivityUrl(item.id)}
                    />
                ))}
                {listItems.length === 0 && (
                    <Alert variant="primary">
                        No items found {searchFilter && "in search results"}
                    </Alert>
                )}
            </div>
        </>
    );
};

export default ProcessActivityGroupActivityList;
