import React from "react";
import { Table } from "react-bootstrap";
import { Column, useTable } from "react-table";

export interface BaseTableProps<T extends object> {
    columns: Column<T>[];
    data: T[];
}

const BaseTable = <T extends object>({
    data,
    columns,
}: BaseTableProps<T>): JSX.Element => {
    const tableInstance = useTable({ columns, data });
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
        tableInstance;

    return (
        <Table {...getTableProps()}>
            <thead>
                {
                    // Loop over the header rows
                    headerGroups.map((headerGroup, gridx) => (
                        // Apply the header row props
                        <tr key={gridx} {...headerGroup.getHeaderGroupProps()}>
                            {
                                // Loop over the headers in each row
                                headerGroup.headers.map((column, colidx) => (
                                    // Apply the header cell props
                                    <th
                                        key={colidx}
                                        {...column.getHeaderProps()}
                                        className={`th-${column.id}`}
                                    >
                                        {
                                            // Render the header
                                            column.render("Header")
                                        }
                                    </th>
                                ))
                            }
                        </tr>
                    ))
                }
            </thead>
            <tbody {...getTableBodyProps()}>
                {
                    // Loop over the table rows
                    rows.map((row, rowidx) => {
                        // Prepare the row for display
                        prepareRow(row);
                        return (
                            // Apply the row props
                            <tr key={rowidx} {...row.getRowProps()}>
                                {
                                    // Loop over the rows cells
                                    row.cells.map((cell, cellidx) => {
                                        // Apply the cell props
                                        return (
                                            <td
                                                key={cellidx}
                                                {...cell.getCellProps()}
                                            >
                                                {
                                                    // Render the cell contents
                                                    cell.render("Cell")
                                                }
                                            </td>
                                        );
                                    })
                                }
                            </tr>
                        );
                    })
                }
            </tbody>
        </Table>
    );
};

export default BaseTable;
