import React from "react";
import { Breadcrumb, Col, Row } from "react-bootstrap";
import { RouteComponentProps, useParams } from "react-router";
import { useOrgTitleWithShortName } from "../../../common/hooks/useTitle";
import AssignedTable from "../containers/AssignedTable";
import { ProcessPageTitles } from "./pageTitles";

const MyAssignedPage: React.FC<
    RouteComponentProps<{
        orgShortName: string;
    }>
> = () => {
    const { orgShortName } = useParams<{ orgShortName: string }>();

    useOrgTitleWithShortName(ProcessPageTitles.MyAssigned);

    return (
        <>
            <Row className="top10">
                <Col>
                    <Breadcrumb>
                        <Breadcrumb.Item active>Processes</Breadcrumb.Item>
                        <Breadcrumb.Item active>MyAssigned</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h1>My Assigned</h1>
                </Col>
            </Row>
            <Row className="top10">
                <Col>
                    <AssignedTable orgShortName={orgShortName} />
                </Col>
            </Row>
        </>
    );
};

export default MyAssignedPage;
