import * as React from "react";
import { Alert } from "react-bootstrap";
import { ExaminationReview } from "../domain/examinationsReview";

const EngineerAssignedAlert: React.FC<{
    review: ExaminationReview;
}> = ({ review }) => {
    if (review.assignedEngineer) {
        return (
            <div className="py-2">
                <Alert variant="info">
                    <div>{`This review is assigned to ${review.assignedEngineer}`}</div>
                </Alert>
            </div>
        );
    } else {
        return (
            <Alert variant="warning">
                <div>
                    This exam has no assigned engineer. Only the assigned
                    engineer can approve exams
                </div>
            </Alert>
        );
    }
};
export default EngineerAssignedAlert;
