import { TypedGridCellProps } from "../../../../common/types/TypedGridCellProps";
import { EntryType, FileViewEntry } from "../../viewModel/FileViewEntry";
import { CenteredCell } from "../../../../common/components/table/kendo/CenteredCell";
import { FaFolder, FaLevelUpAlt, FaRegFile } from "react-icons/fa";
import * as React from "react";
import { IconType } from "react-icons";

function getIcon(entryType: EntryType): IconType {
    switch (entryType) {
        case EntryType.File:
            return FaRegFile;
        case EntryType.NavigateUp:
            return FaLevelUpAlt;
        case EntryType.NewFolder:
        case EntryType.Folder:
            return FaFolder;
        default:
            throw new Error(
                `Not implemented for entry type ${EntryType[entryType]}`,
            );
    }
}

export const FileViewEntryIconCell = ({
    dataItem,
    selectionChange,
}: TypedGridCellProps<FileViewEntry>): JSX.Element => {
    const Icon = getIcon(dataItem.type);
    return (
        <CenteredCell
            role={dataItem.inEdit ? undefined : "button"}
            onClick={(ev) => {
                selectionChange?.({ syntheticEvent: ev });
            }}
        >
            <Icon />
        </CenteredCell>
    );
};
