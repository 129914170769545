import { State } from "@progress/kendo-data-query";
import {
    Grid,
    GridColumn,
    GridNoRecords,
    GridRowDoubleClickEvent,
    GridRowProps,
} from "@progress/kendo-react-grid";
import classNames from "classnames";
import * as React from "react";
import { Button, ButtonToolbar } from "react-bootstrap";
import { commonFilterOperators } from "../../../../../common/components/table/kendo/columnFilters";
import CustomPagingFooter from "../../../../../common/components/table/kendo/CustomPagingFooter";
import GridLoadingPanel from "../../../../../common/components/table/kendo/GridLoadingPanel";
import { resolveSortAndFilterOptions } from "../../../../../common/components/table/kendo/helpers";
import useInfiniteGridProps from "../../../../../common/components/table/kendo/useInfiniteGridProps";
import useInfiniteDataResult from "../../../../../common/hooks/useInfiniteDataResult";
import PermissionConstants from "../../../../../common/permissions/PermissionConstants";
import { AppRoutes } from "../../../../../router/AppRoutes";
import { useNavigation } from "../../../../../router/useNavigate";
import { hasUserOrgPermission } from "../../../../../store/features/user/user-api-slice";
import {
    TemplateContentFilter,
    TemplateContentFilterOperator,
    TemplateContentFilterProperty,
} from "../../../../common/filters/domain/types";
import {
    AssetInfoContentFieldNames,
    PlanningInfoContentFieldNames,
} from "../../../constants/ProcessActivityContentFieldsConsts";
import { ExaminationReview } from "../../domain/examinationsReview";
import {
    useAssignExaminationReviewsMutation,
    useGetInfiniteExaminationReviews,
} from "../../query/examinationsReviewQueries";
import AssignModal from "../AssignModal";
import { DaysSinceExamFilterCell } from "./DaysSinceExamFilterCell";
import "./examinationReviewsGrid.scss";
import { examinationReviewsGridFieldsMap } from "./examinationReviewsGridFieldsMap";
import { BetweenDatesFilterCell } from "./BetweenDatesFilterCell";

const usersFilter: TemplateContentFilter = {
    property: TemplateContentFilterProperty.UserGroup,
    operator: TemplateContentFilterOperator.Any,
    values: ["Engineer"],
};

interface Props {
    organisationId: string;
}

const ExaminationReviewsGrid: React.FC<Props> = ({ organisationId }) => {
    const { navigateToWithOrg } = useNavigation();
    const wrapperRef = React.createRef<HTMLElement>();
    const initialDataState: State = {
        sort: [{ field: "daysSinceExamDate", dir: "desc" }],
    };
    const [dataState, setDataState] = React.useState<State>(initialDataState);
    const [selectedItems, setSelectedItems] = React.useState<
        ExaminationReview[]
    >([]);
    const [assignActive, setAssignActive] = React.useState<boolean>(false);

    const canAssignUsers = hasUserOrgPermission(
        organisationId,
        PermissionConstants.ExaminationReviews.assign,
    ).hasPermission;

    const query = React.useMemo(
        () =>
            resolveSortAndFilterOptions(
                dataState,
                examinationReviewsGridFieldsMap,
            ),
        [dataState],
    );

    const {
        data: examinations,
        fetchNextPage,
        hasNextPage,
        isFetching,
        isFetchingNextPage,
    } = useGetInfiniteExaminationReviews(organisationId, query);

    const assignMutation = useAssignExaminationReviewsMutation(organisationId);

    const {
        flatData: flatReviewsData,
        totalCount: totalReviews,
        dataCount,
    } = useInfiniteDataResult(examinations);

    const isLoadingReviews = isFetching || isFetchingNextPage;

    const reviewsGridProps = useInfiniteGridProps({
        data: flatReviewsData,
        dataState,
        hasNextPage,
        setDataState,
        initialDataState: initialDataState,
        loading: isLoadingReviews,
        fetchMore: () => {
            fetchNextPage();
        },
        gridRef: wrapperRef,
        selectable: {
            config: {
                enabled: true,
                drag: true,
                mode: "multiple",
            },
            onSelectChange: (newSelected) => {
                setSelectedItems([...newSelected]);
            },
        },
    });

    const rowRender = (
        row: React.ReactElement<HTMLTableRowElement>,
        rowProps: GridRowProps,
    ) => {
        const item = rowProps.dataItem;
        const trProps = {
            ...row.props,
            className: classNames(
                {
                    "table-danger":
                        (item.daysSinceExamDate >= 28 && !item.selected) ??
                        false,
                    "table-warning":
                        (item.daysSinceExamDate >= 21 &&
                            item.daysSinceExamDate < 28) ??
                        false,
                },
                row.props.className,
            ),
        };
        return React.cloneElement(row, { ...trProps }, row.props.children);
    };

    const onNavigate = React.useCallback(
        (e: GridRowDoubleClickEvent) => {
            navigateToWithOrg(AppRoutes.EngineerReviewDetails, {
                reviewId: e.dataItem.id,
            });
        },
        [navigateToWithOrg],
    );
    return (
        <div>
            {assignActive && (
                <AssignModal
                    title="Assign Engineer"
                    examinations={selectedItems}
                    organisationId={organisationId}
                    usersFilter={usersFilter}
                    assignMutation={assignMutation}
                    onClose={() => setAssignActive(false)}
                />
            )}
            <ButtonToolbar>
                <Button
                    variant="primary"
                    onClick={() => {
                        setAssignActive(true);
                    }}
                    disabled={selectedItems.length === 0 && canAssignUsers}
                >
                    Assign Engineer
                </Button>
            </ButtonToolbar>
            <div
                ref={wrapperRef as React.RefObject<HTMLDivElement>}
                className="examinations-grid-h pt-2"
            >
                <Grid
                    {...reviewsGridProps}
                    filterable
                    filterOperators={commonFilterOperators}
                    onRowDoubleClick={onNavigate}
                    className="examinations-grid-h"
                    rowRender={rowRender}
                    sortable={true}
                >
                    <GridNoRecords>There is no data available</GridNoRecords>
                    <GridColumn
                        key={PlanningInfoContentFieldNames.ContractYear}
                        field={PlanningInfoContentFieldNames.ContractYear}
                        title={"Contract Year"}
                        width={200}
                    />
                    <GridColumn
                        key={PlanningInfoContentFieldNames.Elr}
                        field={PlanningInfoContentFieldNames.Elr}
                        title={"ELR"}
                        width={180}
                    />
                    <GridColumn
                        key={PlanningInfoContentFieldNames.Miles}
                        field={PlanningInfoContentFieldNames.Miles}
                        title={"Miles"}
                        width={180}
                    />
                    <GridColumn
                        key={PlanningInfoContentFieldNames.Yards}
                        field={PlanningInfoContentFieldNames.Yards}
                        title={"Yards"}
                        width={180}
                    />
                    <GridColumn
                        key={PlanningInfoContentFieldNames.Chains}
                        field={PlanningInfoContentFieldNames.Chains}
                        title={"Chains"}
                        width={180}
                    />
                    <GridColumn
                        key={AssetInfoContentFieldNames.StructureName}
                        field={AssetInfoContentFieldNames.StructureName}
                        title={"Structure Name"}
                        width={300}
                    />
                    <GridColumn
                        key={"structureReferenceNumber"}
                        field={"structureReferenceNumber"}
                        title={"Structure Ref"}
                        width={300}
                    />
                    <GridColumn
                        key={"examType"}
                        field={"examType"}
                        title={"Exam Type"}
                        width={300}
                    />
                    <GridColumn
                        key={"examinationDate"}
                        field={"examinationDate"}
                        title={"Examination Date"}
                        width={300}
                        filter={"date"}
                        format="{0:d MMM y}"
                        filterCell={BetweenDatesFilterCell}
                    />
                    <GridColumn
                        key={"defectsTracked"}
                        field={"defectsTracked"}
                        title={"Defects Tracked"}
                        width={180}
                    />
                    <GridColumn
                        key={"assignedExaminer"}
                        field={"assignedExaminer"}
                        title={"Assigned Examiner"}
                        width={300}
                    />
                    <GridColumn
                        key={"assignedEngineer"}
                        field={"assignedEngineer"}
                        title={"Assigned Engineer"}
                        width={300}
                    />
                    <GridColumn
                        key={"assetType"}
                        field={"assetType"}
                        title={"Asset Type"}
                        width={150}
                    />
                    <GridColumn
                        key={"daysSinceExamDate"}
                        field={"daysSinceExamDate"}
                        title={"Days Since Exam"}
                        width={180}
                        filter={"numeric"}
                        filterCell={DaysSinceExamFilterCell}
                    />
                    <GridColumn
                        key={"state"}
                        field={"state"}
                        title={"State"}
                        width={200}
                    />
                </Grid>
                {isLoadingReviews && <GridLoadingPanel gridRef={wrapperRef} />}
                <CustomPagingFooter
                    total={totalReviews}
                    loading={isLoadingReviews}
                    dataCount={dataCount}
                />
            </div>
        </div>
    );
};

export default ExaminationReviewsGrid;
