import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import * as Formik from "formik";
import * as React from "react";
import { useState } from "react";
import { Button } from "react-bootstrap";
import * as yup from "yup";
import CustomSelect from "../../../../common/components/form/CustomSelect";
import {
    mapToSelectOptions,
    SelectOptionItem,
} from "../../../../common/types/reactSelect/SelectOptionItem";
import { useAddPermissionToGroupMutation } from "../../../../store/features/organisation/organisation-api-slice";
import { Permission } from "../../../organisation/domain/types";

interface FormValues {
    selectedPermission: SelectOptionItem;
}

const validation = yup.object({
    selectedPermission: yup.object().nullable().required("Required"),
});

interface Props {
    orgId: string;
    groupId: string;
    permissions: Permission[];
    updatedCallback: () => void;
}

const UserGroupAddPermissionForm: React.FC<Props> = (props) => {
    const [isAddingPermission, setIsAddingPermission] = useState(false);
    const [addPermissionToGroup] = useAddPermissionToGroupMutation();

    const initialPermissionFormValues: FormValues = React.useMemo(() => {
        return { selectedPermission: null };
    }, []);

    const onSubmit = async (
        values: FormValues,
        { setSubmitting, resetForm }: Formik.FormikHelpers<FormValues>,
    ): Promise<void> => {
        setSubmitting(true);
        const result = await addPermissionToGroup({
            orgId: props.orgId,
            groupId: props.groupId,
            body: { permissionId: values.selectedPermission?.value },
        });
        if (
            !(result as { error: FetchBaseQueryError | SerializedError }).error
        ) {
            props.updatedCallback();
        }
        resetForm();
        setSubmitting(false);
    };

    if (isAddingPermission) {
        return (
            <div className="pt-2">
                <Formik.Formik
                    enableReinitialize
                    initialValues={initialPermissionFormValues}
                    validationSchema={validation}
                    onSubmit={onSubmit}
                >
                    {({ handleSubmit, isSubmitting }): JSX.Element => (
                        <Formik.Form translate="yes" onSubmit={handleSubmit}>
                            <CustomSelect
                                name="selectedPermission"
                                label="Add permission"
                                placeholder="Select permission..."
                                options={mapToSelectOptions(
                                    props.permissions,
                                    (u) => u.id,
                                    (u) => u.name,
                                )}
                            />
                            <Button
                                variant="primary"
                                type="submit"
                                disabled={isSubmitting}
                            >
                                Add
                            </Button>
                            <Button
                                variant="outline-secondary"
                                className="ml-1"
                                onClick={() => setIsAddingPermission(false)}
                            >
                                Cancel
                            </Button>
                        </Formik.Form>
                    )}
                </Formik.Formik>
            </div>
        );
    } else {
        return (
            <div className="d-flex justify-content-end">
                <Button onClick={() => setIsAddingPermission(true)}>
                    Add permission
                </Button>
            </div>
        );
    }
};

export default UserGroupAddPermissionForm;
