export enum ExaminationState {
    Planned = "Planned",
    Unallocated = "Unallocated",
    ExaminationInProgress = "Examination in Progress",
    AwaitingEngineer = "Awaiting Engineer",
    AtEngineer = "At Engineer",
    ReturnedToExaminer = "Returned To Examiner",
    AwaitingBatching = "Awaiting Batching",
    RejectedByNetworkRail = "Rejected By Network Rail",
    Delivered = "Delivered",
    Submitting = "Submitting",
    Submitted = "CES Submitted",
}
