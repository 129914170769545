import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import * as Formik from "formik";
import * as React from "react";
import { Button, Col, Row } from "react-bootstrap";
import CoveringLoadingPanel from "../../../common/components/CoveringLoadingPanel";
import {
    CustomSelect,
    TextArea,
    TextInput,
} from "../../../common/components/form";
import useError from "../../../common/hooks/useError";
import { mapToSelectOptions } from "../../../common/types/reactSelect/SelectOptionItem";
import { isMutationError } from "../../../common/types/rtkQuery/MutationResult";
import {
    useGetOrgByIdQuery,
    useGetUsersByOrgIdQuery,
    useUpdateOrganisationMutation,
} from "../../../store/features/organisation/organisation-api-slice";
import { OrganisationUpdateRequestData } from "../domain/organisation";
import {
    getInitialEditOrgForm,
    handleMutationError,
    OrganisationFormValues,
    orgValidationSchema,
} from "../viewModel/organisationForm";
import OrgFormError from "./OrgFormError";

interface OrganisationProps {
    organisationId?: string;
}

enum FormState {
    View,
    Edit,
}

const OrganisationForm: React.FC<OrganisationProps> = ({ organisationId }) => {
    const { data: organisation, isLoading } = useGetOrgByIdQuery(
        organisationId,
        { skip: !organisationId },
    );

    const { data: organisationUsers } = useGetUsersByOrgIdQuery(
        organisationId,
        { skip: !organisationId },
    );

    const [updateOrganisation] = useUpdateOrganisationMutation();

    const [viewState, setViewState] = React.useState<FormState>(FormState.View);

    const isView = viewState === FormState.View;

    const { isError, error, setError, clearError } =
        useError<FetchBaseQueryError>();

    const usersOptions = React.useMemo(
        () =>
            mapToSelectOptions(
                organisationUsers,
                (i) => i.userId,
                (i) => i.username,
            ),
        [organisationUsers],
    );

    const formValues = React.useMemo(
        () => getInitialEditOrgForm(organisation, usersOptions),
        [organisation, usersOptions],
    );

    const onSubmit = async (
        values: OrganisationFormValues,
        { setSubmitting, setFieldError },
    ): Promise<void> => {
        const requestData: OrganisationUpdateRequestData = {
            name: values.name,
            description: values.description,
            ownerId: values.owner?.value,
            shortName: values.shortName,
        };

        const response = await updateOrganisation({
            organisationId,
            model: requestData,
        });

        if (isMutationError(response)) {
            handleMutationError(setFieldError, response.error);
            setError(response.error);
        } else {
            setViewState(FormState.View);
        }

        setSubmitting(false);
    };

    if (isLoading) return <CoveringLoadingPanel />;

    return (
        <div className="pt-2">
            {isView && (
                <div className="d-flex justify-content-end">
                    <Button
                        className="float-right"
                        onClick={() => setViewState(FormState.Edit)}
                    >
                        Edit
                    </Button>
                </div>
            )}

            <Formik.Formik
                enableReinitialize
                initialValues={formValues}
                validationSchema={orgValidationSchema}
                onSubmit={onSubmit}
            >
                {({ isSubmitting, resetForm }): JSX.Element => (
                    <Formik.Form translate="yes">
                        <Row>
                            <Col>
                                <TextInput
                                    label="Organisation Name"
                                    name="name"
                                    readOnly={isView}
                                />
                            </Col>
                            <Col>
                                <TextInput
                                    label="Short Name"
                                    name="shortName"
                                    description="This text is used in the url bar i.e 'Amey Consulting' should be 'ameyconsulting'."
                                    readOnly={isView}
                                />
                            </Col>
                        </Row>

                        <CustomSelect
                            name="owner"
                            label="Organisation Owner"
                            placeholder="Select owner..."
                            options={usersOptions}
                            readOnly={isView}
                        />
                        <TextArea
                            label="Organisation Description"
                            rows={5}
                            name="description"
                            readOnly={isView}
                        />
                        {!isView && (
                            <div className="form-footer-control">
                                {isError && (
                                    <OrgFormError
                                        error={error}
                                        onClear={clearError}
                                    />
                                )}
                                <div>
                                    <Button
                                        variant="primary"
                                        type="submit"
                                        disabled={isSubmitting}
                                    >
                                        Update
                                    </Button>
                                </div>
                                <div>
                                    <Button
                                        variant="danger"
                                        className="ml-1"
                                        onClick={() => {
                                            resetForm();
                                            setViewState(FormState.View);
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                </div>
                            </div>
                        )}
                    </Formik.Form>
                )}
            </Formik.Formik>
        </div>
    );
};

export default OrganisationForm;
