import {
    Grid,
    GridColumn,
    GridColumnProps,
    GridNoRecords,
    GridRowProps,
} from "@progress/kendo-react-grid";
import cx from "classnames";
import * as React from "react";
import {
    ColumnMenuFilter,
    commonFilterOperators,
} from "../../../../../common/components/table/kendo/columnFilters";
import { GridFieldsMap } from "../../../../../common/components/table/kendo/GridFieldsMap";
import GridLoadingPanel from "../../../../../common/components/table/kendo/GridLoadingPanel";
import { AppRoutes } from "../../../../../router/AppRoutes";
import { useNavigation } from "../../../../../router/useNavigate";
import "./examinationDefectGridMap.scss";

interface Props<T> {
    data: T[];
    columnsDefinitions: GridFieldsMap;
    isLoading: boolean;
    processId: string;
}

function DefectsGrid<TData>({
    data,
    columnsDefinitions,
    isLoading,
    processId,
}: Props<TData>): ReturnType<React.FC<Props<TData>>> {
    const { navigateToWithOrg } = useNavigation();
    const wrapperRef = React.createRef<HTMLElement>();

    const rowRender = (
        row: React.ReactElement<HTMLTableRowElement>,
        rowProps: GridRowProps,
    ) => {
        const closed = rowProps.dataItem.status === "Closed";

        const trProps = {
            ...row.props,
            className: closed
                ? cx("closed", row.props.className)
                : row.props.className,
        };

        return React.cloneElement(row, { ...trProps }, row.props.children);
    };

    return (
        <div
            ref={wrapperRef as React.RefObject<HTMLDivElement>}
            className="examinations-grid-h pt-2"
        >
            {isLoading && <GridLoadingPanel gridRef={wrapperRef} />}
            <Grid
                data={data}
                filterOperators={commonFilterOperators}
                onRowDoubleClick={({ dataItem }) =>
                    navigateToWithOrg(AppRoutes.ProcessActivity, {
                        processId,
                        activityId: dataItem.activityId,
                    })
                }
                className="examinations-grid-h"
                rowRender={rowRender}
            >
                <GridNoRecords>No data</GridNoRecords>
                {Object.values(columnsDefinitions).map(
                    ({ field, label, dataType }, index) => {
                        const key = `${field}-${index}`;
                        const props: GridColumnProps = {
                            field: field,
                            title: label || field,
                            width: field.includes("description") ? 300 : 170,
                        };
                        if (dataType === "Date") {
                            props.filter = "date";
                            props.format = "{0:dd/MM/yyyy}";
                        }

                        return (
                            <GridColumn
                                columnMenu={ColumnMenuFilter}
                                key={key}
                                {...props}
                            />
                        );
                    },
                )}
            </Grid>
        </div>
    );
}

export default DefectsGrid;
