import * as React from "react";
import { useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { downloadFile } from "../../../services/FileDownloadService";
import { ProcessActivityAlertButtonModel } from "../domain/types";

interface Props {
    alertButton: ProcessActivityAlertButtonModel;
}

const ProcessActivityAlertButton: React.FC<Props> = ({ alertButton }) => {
    const [isLoading, setIsLoading] = useState(false);

    const executeButtonGetRequest = () => {
        setIsLoading(true);

        if (alertButton.actionType === "FileDownload") {
            handleFileDnownloadAction();
        } else {
            console.error(
                "Incorrect ProcessActivityAlertButtonModel action type",
                alertButton.actionType,
            );
        }
    };

    const handleFileDnownloadAction = () => {
        const params = alertButton.actionParams;
        if (!params || params.length !== 2) {
            console.error(
                "Incorrect ProcessActivityAlertButtonModel action params",
                params,
            );
        }

        downloadFile(params[0], params[1]).finally(() => setIsLoading(false));
    };

    return (
        <div key={alertButton.type} className="d-flex justify-content-end">
            <Button
                variant="info"
                onClick={executeButtonGetRequest}
                disabled={isLoading}
            >
                {isLoading ? (
                    <>
                        <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                        <span
                            className="visually-hidden"
                            style={{ marginLeft: "5px" }}
                        >
                            Loading...
                        </span>
                    </>
                ) : (
                    <span>{alertButton.title}</span>
                )}
            </Button>
        </div>
    );
};

export default ProcessActivityAlertButton;
