import {
    Grid,
    GridColumn,
    GridColumnReorderEvent,
} from "@progress/kendo-react-grid";
import { useField } from "formik";
import React, { createRef, LegacyRef, useMemo } from "react";
import { useResizeDetector } from "react-resize-detector";
import { commonFilterOperators } from "../../../common/components/table/kendo/columnFilters";
import CustomPagingFooter from "../../../common/components/table/kendo/CustomPagingFooter";
import GridLoadingPanel from "../../../common/components/table/kendo/GridLoadingPanel";
import useInfiniteGridProps from "../../../common/components/table/kendo/useInfiniteGridProps";
import useAssetsSortAndFitler, {
    dynamicAssetPropPrefix,
} from "../../../common/hooks/useSortAndFilter";
import { AssetWithPropsModel } from "../../../store/features/asset/asset-api-slice";
import { GridColumnDef } from "../../asset/hooks/useCombinedAssetsTemplates";
import { usePersistedAssetsGridState } from "../../asset/hooks/usePersistedAssetsGridState";
import {
    useGetInfiniteOrgAssetsCount,
    useGetInfiniteSuggestedAssets,
} from "../../process-activity/query/processActivitiesQuery";

export const ACTIVITY_ASSET_SELECTED_FIELD = "assets";

interface OrgAssetLinkListProps {
    organisationId: string;
    activityId: string;
    selectMultiple: boolean;
}

const defaultColumns: GridColumnDef[] = [
    {
        field: "name",
        title: "Name",
    },
    {
        field: "description",
        title: "Description",
    },
];

const minimumColumnWidth = 250;

const OrgAssetLinkList: React.FC<OrgAssetLinkListProps> = ({
    organisationId,
    activityId,
    selectMultiple,
}) => {
    const gridWrapperRef = createRef<HTMLElement>();
    const { width, ref: gridWidthSourceRef } = useResizeDetector();
    const {
        selectedTemplates,
        selectedColumns,
        setColumnsState,
        gridState,
        setGridState,
    } = usePersistedAssetsGridState(organisationId, defaultColumns);

    const sortAndFilter = useAssetsSortAndFitler(
        gridState,
        selectedTemplates,
        selectedColumns,
    );

    const {
        data: assetData,
        fetchNextPage,
        hasNextPage,
        isFetching,
        isFetchingNextPage,
    } = useGetInfiniteSuggestedAssets(
        organisationId,
        activityId,
        sortAndFilter,
    );

    const {
        data: count,
        isFetching: isFetchingCount,
        isLoading: isLoadingCount,
    } = useGetInfiniteOrgAssetsCount(organisationId, sortAndFilter, activityId);

    const processedData = useMemo(() => {
        if (!assetData) return [];

        return assetData.pages
            .map((asset) => {
                return asset.data.map((r) => {
                    const result = {
                        ...r,
                    };

                    r.properties.forEach(
                        (prop) =>
                            (result[dynamicAssetPropPrefix + prop.id] =
                                prop.value),
                    );

                    return result;
                });
            })
            .flat();
    }, [assetData]);

    const [, , helpers] = useField<AssetWithPropsModel[]>(
        ACTIVITY_ASSET_SELECTED_FIELD,
    );

    const assetLinkGridProps = useInfiniteGridProps<AssetWithPropsModel>({
        data: processedData,
        dataState: gridState,
        hasNextPage,
        setDataState: setGridState,
        loading: isFetching || isFetchingNextPage,
        fetchMore: () => {
            fetchNextPage();
        },
        gridRef: gridWrapperRef,
        dataItemKey: "id",
        filterable: true,
        selectable: {
            config: {
                enabled: true,
                drag: true,
                mode: selectMultiple ? "multiple" : "single",
            },
            onSelectChange: (newSelected) => {
                helpers.setValue(newSelected);
            },
        },
    });

    return (
        <div ref={gridWidthSourceRef} style={{ width: "100%" }}>
            <div ref={gridWrapperRef as LegacyRef<HTMLDivElement>}>
                <Grid
                    style={{ width: `${width}px` }}
                    className="asset-grid-h"
                    onColumnReorder={(e: GridColumnReorderEvent) =>
                        setColumnsState(e.columns as GridColumnDef[])
                    }
                    {...assetLinkGridProps}
                    filterOperators={commonFilterOperators}
                >
                    {selectedColumns.map(({ field, title, ...rest }) => {
                        const props = { field, title };
                        Object.entries(rest).forEach(([key, value]) => {
                            if (value) {
                                props[key] = value;
                            }
                        });

                        return (
                            <GridColumn
                                {...props}
                                key={title}
                                headerClassName={"grid-header"}
                                width={
                                    (selectedTemplates.length > 0 ||
                                        selectedColumns.length > 2) &&
                                    minimumColumnWidth *
                                        selectedColumns.length >=
                                        width
                                        ? minimumColumnWidth
                                        : (width * 0.98) /
                                          selectedColumns.length
                                }
                            />
                        );
                    })}
                </Grid>
                {(isFetching || isFetchingNextPage || isFetchingCount) && (
                    <GridLoadingPanel gridRef={gridWrapperRef} />
                )}
                <CustomPagingFooter
                    total={count}
                    loading={
                        !assetLinkGridProps.data ||
                        isFetchingCount ||
                        isLoadingCount
                    }
                    dataCount={assetLinkGridProps.data.length}
                />
            </div>
        </div>
    );
};

export default OrgAssetLinkList;
