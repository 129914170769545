import * as React from "react";
import { Nav } from "react-bootstrap";
import { IconType } from "react-icons";
import { NavLink } from "react-router-dom";

export interface NavSubLink {
    label: string;
    to: string;
    icon: IconType;
}

const NavSubMenuItem: React.FC<NavSubLink> = ({
    label,
    icon: Icon,
    to,
}): JSX.Element => (
    <Nav.Item className="bg-white">
        <NavLink
            to={to}
            className="d-flex align-items-center nav-link text-truncate"
            exact
        >
            <Icon size="14" className="menu-icon-small" />
            <span className="nav-text-small">{label}</span>
        </NavLink>
    </Nav.Item>
);
export default NavSubMenuItem;
