import {
    Grid,
    GridColumn,
    GridColumnReorderEvent,
} from "@progress/kendo-react-grid";
import { Dispatch } from "@reduxjs/toolkit";
import { CallHistoryMethodAction, push } from "connected-react-router";
import React, { createRef, LegacyRef, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useResizeDetector } from "react-resize-detector";
import { commonFilterOperators } from "../../../common/components/table/kendo/columnFilters";
import CustomPagingFooter from "../../../common/components/table/kendo/CustomPagingFooter";
import GridLoadingPanel from "../../../common/components/table/kendo/GridLoadingPanel";
import useInfiniteGridProps from "../../../common/components/table/kendo/useInfiniteGridProps";
import useAssetsSortAndFitler, {
    dynamicAssetPropPrefix,
} from "../../../common/hooks/useSortAndFilter";
import OrganisationConstants from "../../../Constants/OrganisationConstants";
import { AssetWithPropsModel } from "../../../store/features/asset/asset-api-slice";
import { GridColumnDef } from "../../../store/features/assets-grid/assetsGridSlice";
import { usePersistedAssetsGridState } from "../hooks/usePersistedAssetsGridState";
import {
    useGetInfiniteOrgAssets,
    useGetInfiniteOrgAssetsCount,
} from "../query/assetQueries";

interface OrgAssetListProps {
    organisationId: string;
    orgShortName: string;
}

const defaultColumns: GridColumnDef[] = [
    {
        field: "name",
        title: "Name",
    },
    {
        field: "description",
        title: "Description",
    },
];

const navigateToAsset = (
    dispatch: Dispatch<CallHistoryMethodAction<[string, unknown?]>>,
    orgShortName: string,
    id: string,
): void => {
    dispatch(push(`/${orgShortName}${OrganisationConstants.Asset}/${id}`));
};

const minimumColumnWidth = 250;

const OrgAssetList: React.FC<OrgAssetListProps> = ({
    organisationId,
    orgShortName,
}) => {
    const dispatch = useDispatch();
    const gridWrapperRef = createRef<HTMLElement>();
    const { width, ref: gridWidthSourceRef } = useResizeDetector();

    const {
        selectedTemplates,
        selectedColumns,
        setColumnsState,
        gridState,
        setGridState,
    } = usePersistedAssetsGridState(organisationId, defaultColumns);

    const sortAndFilter = useAssetsSortAndFitler(
        gridState,
        selectedTemplates,
        selectedColumns,
    );

    const {
        data: pageData,
        fetchNextPage,
        hasNextPage,
        isFetching,
        isFetchingNextPage,
    } = useGetInfiniteOrgAssets(organisationId, sortAndFilter);

    const {
        data: count,
        isFetching: isFetchingCount,
        isLoading: isLoadingCount,
    } = useGetInfiniteOrgAssetsCount(organisationId, sortAndFilter);

    const flatData = useMemo(() => {
        if (!pageData) return [];
        return pageData.pages
            .map((page) => {
                return page.data.map((r) => {
                    const result = {
                        ...r,
                    };

                    r.properties.forEach(
                        (prop) =>
                            (result[dynamicAssetPropPrefix + prop.id] =
                                prop.value),
                    );

                    return result;
                });
            })
            .flat();
    }, [pageData]);

    const gridProps = useInfiniteGridProps<AssetWithPropsModel>({
        data: flatData,
        dataState: gridState,
        hasNextPage,
        setDataState: setGridState,
        loading: isFetching || isFetchingNextPage,
        fetchMore: () => {
            fetchNextPage();
        },
        gridRef: gridWrapperRef,
        dataItemKey: "id",
        filterable: true,
    });

    return (
        <div ref={gridWidthSourceRef} style={{ width: "100%" }}>
            <div ref={gridWrapperRef as LegacyRef<HTMLDivElement>}>
                <Grid
                    style={{ width: `${width}px` }}
                    className="asset-grid-h"
                    onColumnReorder={(e: GridColumnReorderEvent) =>
                        setColumnsState(e.columns as GridColumnDef[])
                    }
                    onRowDoubleClick={(e) =>
                        navigateToAsset(dispatch, orgShortName, e.dataItem.id)
                    }
                    {...gridProps}
                    filterOperators={commonFilterOperators}
                >
                    {selectedColumns.map(({ field, title, ...rest }) => {
                        const props = {
                            field,
                            title,
                            headerClassName: "grid-header",
                            width:
                                (selectedTemplates.length > 0 ||
                                    selectedColumns.length > 2) &&
                                minimumColumnWidth * selectedColumns.length >=
                                    width
                                    ? minimumColumnWidth
                                    : (width * 0.98) / selectedColumns.length,
                        };
                        Object.entries(rest).forEach(([key, value]) => {
                            if (value) {
                                props[key] = value;
                            }
                        });

                        return <GridColumn {...props} key={title} />;
                    })}
                </Grid>
                {(isFetching || isFetchingNextPage || isFetchingCount) && (
                    <GridLoadingPanel gridRef={gridWrapperRef} />
                )}
                <CustomPagingFooter
                    total={count}
                    loading={
                        !gridProps.data || isFetchingCount || isLoadingCount
                    }
                    dataCount={gridProps.data.length}
                />
            </div>
        </div>
    );
};

export default OrgAssetList;
