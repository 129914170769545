import { DataContinuationResult } from "../../../common/types/DataResult";
import { SortAndFilterOptions } from "../../../common/types/SortAndFilterOptions";
import ApiConstants from "../../../Constants/ApiConstants";
import { postData } from "../../../helpers/ApiHelper";
import { ProcessActivityNavigator } from "../domain/types";

enum UrlPaths {
    BaseResource = "ProcessActivity",
    Paginated = "paginated",
    NavigatableActivities = "navigatableActivities",
}

export interface ProcessActivitySearchOptions extends SortAndFilterOptions {
    continuationToken?: string;
}

interface GetProcessesArgs {
    activityId: string;
    query: ProcessActivitySearchOptions;
}

const baseUrl = `${ApiConstants.ApiPrefix}/${UrlPaths.BaseResource}`;

export async function getInfiniteProcessActivityNavigatableItems({
    activityId,
    query,
}: GetProcessesArgs): Promise<
    DataContinuationResult<ProcessActivityNavigator>
> {
    const url = `${baseUrl}/${activityId}/${UrlPaths.NavigatableActivities}/${UrlPaths.Paginated}`;
    const result = postData<DataContinuationResult<ProcessActivityNavigator>>(
        url,
        query,
    );

    const response = await result;
    if (!response.data) return;
    return {
        ...response,
        data: response.data,
    };
}
