import { useFormikContext } from "formik";
import * as React from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import FormTemplatesConstants from "../../../../Constants/FormTemplatesConstants";
import {
    ShowCondition,
    TemplateContentOption,
} from "../../../../modules/template/domain/types";
import FormControlBase from "../FormControlBase";
import FormPromptComponent from "../FormPromptComponent";
import { DynamicFormValues } from "../types/dynamicFormTypes";
import { StateMulti } from "./StateMulti";
import { StateSingle } from "./StateSingle";

export interface FormControlSelectConfig {
    field: string;
    label: string;
    style: unknown;
    prompt: string;
    uri: string;
    isReadOnly: boolean;
    options: TemplateContentOption[];
    showConditions: ShowCondition[];
    isMulti: boolean;
}

interface Props {
    config: FormControlSelectConfig;
}

export interface Option {
    label: string;
    value: string;
}

export interface State {
    convertFormikValueToOption(): Option[] | Option;
    onChangeHandler(newValue: Option[] | Option): void;
    disabledFieldValidation(): void;
}

const FormControlSelect: React.FC<Props> = (props) => {
    const { config } = props;
    const formik = useFormikContext<DynamicFormValues>();
    const checkCondition = (showCondition: ShowCondition): boolean => {
        // Replace with switch when more operators are supported
        if (
            showCondition.operator ==
            FormTemplatesConstants.Conditions.Operator.Equals
        ) {
            return formik.values[showCondition.field] === showCondition.value;
        } else {
            throw Error(
                `Select show condition operator '${showCondition.operator}' not supported`,
            );
        }
    };

    const options = config.options
        .filter((a) => {
            if (a.showConditions === undefined || a.showConditions === null)
                return true;

            const orConditions = a.showConditions.filter(
                (b) => b.logic === FormTemplatesConstants.Conditions.Logic.Or,
            );

            const andConditions = a.showConditions.filter(
                (b) => b.logic === FormTemplatesConstants.Conditions.Logic.And,
            );
            // If no conditions
            return (
                a.showConditions.length === 0 ||
                // If one or condition is met and no and
                (orConditions.some(checkCondition) &&
                    andConditions.every(checkCondition))
            );
        })
        .map((option) => {
            return {
                label: option.display,
                value: option.value,
                isDisabled: option.disabled ?? false,
            };
        });

    const state: State = config.isMulti
        ? new StateMulti(formik, options, config)
        : new StateSingle(formik, options, config);
    const value = state.convertFormikValueToOption();

    // If stored value is no longer in options selection then reset the value
    if (formik.values[config.field] && value === undefined) {
        formik.setFieldValue(config.field, "");
    }

    state.disabledFieldValidation();

    return (
        <FormControlBase
            contentConfig={{
                field: config.field,
                showConditions: config.showConditions,
            }}
        >
            <Form.Label htmlFor={config.field}>{config.label}</Form.Label>
            <Select
                onChange={state.onChangeHandler}
                options={options}
                value={value || null}
                classNamePrefix="react-select"
                className={formik.errors[config.field] ? "is-invalid" : ""}
                isDisabled={config.isReadOnly}
                isClearable={true}
                isMulti={config.isMulti}
            ></Select>
            <FormPromptComponent config={config} />
        </FormControlBase>
    );
};

export default FormControlSelect;
