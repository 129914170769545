import * as React from "react";
import { Button } from "react-bootstrap";
import CoveringLoadingPanel from "../../../../../common/components/CoveringLoadingPanel";
import DynamicForm from "../../../../../common/components/dynamic-form/DynamicForm";
import FormValuesHelper from "../../../../../common/components/dynamic-form/helpers/FormValuesHelper";
import { useNavigation } from "../../../../../router/useNavigate";
import { useGetReviewFindingsFormByReviewId } from "../../query/examinationsReviewQueries";

interface Props {
    reviewId: string;
    processId: string;
    organisationId: string;
}

const ReviewFindings: React.FC<Props> = ({
    reviewId,
    processId,
    organisationId,
}) => {
    const { navigateToOrgPath } = useNavigation();
    const { data: reviewFindings, isFetching } =
        useGetReviewFindingsFormByReviewId(organisationId, reviewId);

    if (isFetching) return <CoveringLoadingPanel />;

    const initialValues = FormValuesHelper.getInitialValues(
        reviewFindings.contents,
    );

    return (
        <>
            <div className="pt-2">
                <Button
                    onClick={() => {
                        navigateToOrgPath(
                            `/:orgShortName/process/${processId}/activity/${reviewFindings.activityId}`,
                        );
                    }}
                >
                    Edit
                </Button>
            </div>
            <DynamicForm
                initialValues={initialValues}
                onSubmitCallback={null}
                saveProps={null}
                isFormReadOnly={true}
                contentConfig={reviewFindings.contents}
                getFileUrl={null}
                formikRef={null}
                hiddenFields={[]}
            />
        </>
    );
};

export default ReviewFindings;
