import { FormikErrors } from "formik";
import * as React from "react";
import { useRef } from "react";
import { Button, Col, Row } from "react-bootstrap";
import DynamicForm from "../../../common/components/dynamic-form/DynamicForm";
import FormValuesHelper from "../../../common/components/dynamic-form/helpers/FormValuesHelper";
import {
    DynamicFormFormikHelpers,
    DynamicFormFormikProps,
    DynamicFormValues,
} from "../../../common/components/dynamic-form/types/dynamicFormTypes";
import PermissionConstants from "../../../common/permissions/PermissionConstants";
import {
    AssetModel,
    usePostAssetContentMutation,
} from "../../../store/features/asset/asset-api-slice";
import { hasUserOrgPermission } from "../../../store/features/user/user-api-slice";
import { getAssetFileResUrl } from "../helpers/urlBuilder";

interface AssetDetailsFormProps {
    asset: AssetModel;
}

const AssetDetailsForm: React.FC<AssetDetailsFormProps> = ({ asset }) => {
    const formikRef = useRef<DynamicFormFormikProps>();
    const [updateAssetContents, updateAssetResult] =
        usePostAssetContentMutation();
    const initialStoredValues = FormValuesHelper.getInitialValues(
        asset?.contents ?? [],
    );
    const [isReadOnly, setReadOnly] = React.useState(true);

    const canEditAsset = hasUserOrgPermission(
        asset?.organisationId,
        PermissionConstants.OrgAsset.update,
    ).hasPermission;

    const cancel = () => {
        formikRef.current.resetForm();
        setReadOnly(true);
    };

    // Add any default state values which need to be tracked by the form
    const initialValues: DynamicFormValues = {
        ...initialStoredValues,
    };

    const onSaveDraft = (
        values: DynamicFormValues,
        _actions: DynamicFormFormikHelpers,
        errors: FormikErrors<DynamicFormValues>,
    ): void => {
        // Convert the form value objects into html form data
        const formData = new FormData();
        FormValuesHelper.convertValuesToHtmlFormData(
            values,
            asset.contents,
            formData,
            [],
        );
        updateAssetContents({
            assetParams: {
                assetId: asset.id,
                organisationId: asset.organisationId,
            },
            formData,
        });
    };
    /**
     * Get the form values on a submit trigger
     * @param values form values from formik
     */
    const onSubmit = (
        values: DynamicFormValues,
        { setSubmitting }: DynamicFormFormikHelpers,
    ): void => {
        const formData = new FormData();
        FormValuesHelper.convertValuesToHtmlFormData(
            values,
            asset.contents,
            formData,
            [],
        );

        updateAssetContents({
            assetParams: {
                assetId: asset.id,
                organisationId: asset.organisationId,
            },
            formData,
        }).then(() => {
            setSubmitting(false);
        });
    };

    const getFileUrl = React.useCallback(() => {
        return getAssetFileResUrl(asset);
    }, [asset]);

    return (
        <>
            <Row>
                {isReadOnly && (
                    <Col>
                        <div className="float-right mt-2">
                            {canEditAsset && (
                                <Button
                                    variant="primary"
                                    onClick={() => setReadOnly(false)}
                                >
                                    Edit
                                </Button>
                            )}
                        </div>
                    </Col>
                )}
            </Row>
            <DynamicForm
                initialValues={initialValues}
                onSubmitCallback={onSubmit}
                onCancelCallback={cancel}
                saveProps={{
                    onSaveDraftCallback: onSaveDraft,
                    saveResult: updateAssetResult,
                }}
                isFormReadOnly={isReadOnly}
                contentConfig={asset.contents}
                getFileUrl={getFileUrl()}
                formikRef={formikRef}
                hiddenFields={[]}
            />
        </>
    );
};

export default AssetDetailsForm;
