import * as React from "react";
import { useParams } from "react-router";
import CoveringLoadingPanel from "../../../common/components/CoveringLoadingPanel";
import { useOrgTitleWithShortName } from "../../../common/hooks/useTitle";
import { WithOrgShortName } from "../../../common/types/WithOrg";
import { useGetOrgSimpleByShortNameQuery } from "../../../store/features/organisation/organisation-api-slice";
import FolderContents from "../containers/FolderContents";
import FilePageTitles from "../pageTitles";

export const FolderRootPage: React.FC = () => {
    const { orgShortName } = useParams<WithOrgShortName>();

    const {
        data: organisation,
        isLoading,
        isFetching,
    } = useGetOrgSimpleByShortNameQuery(orgShortName, {
        skip: !orgShortName,
    });

    useOrgTitleWithShortName(FilePageTitles.Main);

    if (isLoading || isFetching) return <CoveringLoadingPanel />;

    return (
        <FolderContents
            organisationId={organisation.id}
            folderId={organisation.rootFolderId}
            orgShortName={orgShortName}
        />
    );
};
