import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { CellProps } from "react-table";
import BaseTable from "../../../common/components/table/BaseTable";
import OrganisationConstants from "../../../Constants/OrganisationConstants";
import { useGetUserGroupsByOrgIdQuery } from "../../../store/features/organisation/organisation-api-slice";

export interface TableUserGroup {
    id: string;
    name: string;
}

interface Props {
    organisationId: string;
    orgShortName: string;
}

export const UserGroupTable: React.FC<Props> = ({
    organisationId,
    orgShortName,
}) => {
    const { data: userGroups } = useGetUserGroupsByOrgIdQuery(organisationId, {
        skip: !organisationId,
    });

    const columns = React.useMemo(
        () => [
            {
                Header: "Group",
                accessor: (row: TableUserGroup): string => row.name,
            },
            {
                Header: "Actions",
                accessor: (row: TableUserGroup): string => row.name,

                Cell: function RowActions({
                    row,
                }: CellProps<TableUserGroup>): JSX.Element {
                    return (
                        <Button
                            as={Link}
                            to={`/${orgShortName}/${OrganisationConstants.userGroups}/${row.original.id}`}
                            variant="secondary"
                            className="top5 ml-1"
                        >
                            Manage
                        </Button>
                    );
                },
            },
        ],
        [orgShortName],
    );

    const data = React.useMemo(
        () =>
            userGroups?.map((a): TableUserGroup => {
                return {
                    id: a.id,
                    name: a.name,
                };
            }) ?? [],
        [userGroups],
    );
    return (
        <Row>
            <Col>
                <BaseTable columns={columns} data={data} />
            </Col>
        </Row>
    );
};
