import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import * as React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { CellProps } from "react-table";
import BaseTable from "../../../../common/components/table/BaseTable";
import { IdAndName } from "../../../../common/types/IdAndName";
import { useRemoveUserFromGroupMutation } from "../../../../store/features/organisation/organisation-api-slice";

interface Props {
    orgId: string;
    userGroupId: string;
    users: IdAndName[];
    updatedCallback: () => void;
    canRemoveUsers: boolean;
}

const userColumn = {
    Header: "User",
    accessor: (row: IdAndName): string => row.name,
};

const UserGroupUsersTable: React.FC<Props> = (props) => {
    const [removeUserFromGroup] = useRemoveUserFromGroupMutation();

    const { canRemoveUsers } = props;

    const columns = React.useMemo(
        () =>
            canRemoveUsers
                ? [
                      userColumn,
                      {
                          Header: "Actions",
                          accessor: (row: IdAndName): string => row.name,

                          Cell: function TableActions({
                              row,
                          }: CellProps<IdAndName>): JSX.Element {
                              const handleRemoveUserFromGroup = async (
                                  userId: string,
                              ) => {
                                  const result = await removeUserFromGroup({
                                      orgId: props.orgId,
                                      groupId: props.userGroupId,
                                      userId,
                                  });

                                  if (
                                      !(
                                          result as {
                                              error:
                                                  | FetchBaseQueryError
                                                  | SerializedError;
                                          }
                                      )?.error
                                  ) {
                                      props.updatedCallback();
                                  }
                              };

                              const onRemove = async (id: string) => {
                                  if (
                                      window.confirm(
                                          "Are you sure you want to delete this item?",
                                      )
                                  ) {
                                      await handleRemoveUserFromGroup(id);
                                  }
                              };

                              return (
                                  <Button
                                      variant="danger"
                                      onClick={async (): Promise<void> =>
                                          onRemove(row.original.id)
                                      }
                                  >
                                      Remove
                                  </Button>
                              );
                          },
                      },
                  ]
                : [userColumn],
        [canRemoveUsers, props, removeUserFromGroup],
    );

    const data = React.useMemo(() => props.users, [props.users]);

    return (
        <Row className="top10">
            <Col>
                <BaseTable columns={columns} data={data}></BaseTable>
            </Col>
        </Row>
    );
};

export default UserGroupUsersTable;
