import {
    Chip,
    ChipList,
    ChipListDataChangeEvent,
    ChipProps,
} from "@progress/kendo-react-buttons";
import { ComboBox, ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import React, { createRef, FC, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { FaPlusCircle } from "react-icons/fa";
interface TagProperties {
    canCreateTags: boolean;
    canReadTags?: boolean;
    canDeleteTags: boolean;
    availableTags: unknown[];
    elementTags: unknown[];
    addTag: (event: ComboBoxChangeEvent) => void;
    removeTag: (event: ChipListDataChangeEvent) => void;
}

const Tags: FC<TagProperties> = ({
    canCreateTags,
    canReadTags,
    canDeleteTags,
    availableTags,
    elementTags,
    addTag,
    removeTag,
}) => {
    const [value, setValue] = useState(null);
    const [tagInputVisible, setTagInputVisibility] = useState(false);
    const handleClick = () => {
        setTagInputVisibility(true);
        tagsRef.current.focus();
    };

    const handleOnChange = (event: ComboBoxChangeEvent) => {
        setTagInputVisibility(false);
        addTag(event);
        setValue(null);
    };

    function handleClose() {
        setTagInputVisibility(false);
    }

    const tagsRef = createRef<ComboBox>();

    return (
        <Row>
            <Col>
                <div>
                    {canReadTags && (
                        <ChipList
                            data={elementTags}
                            onDataChange={removeTag}
                            chip={(props: ChipProps) => (
                                <Chip
                                    className="small mt-1"
                                    removable={canDeleteTags}
                                    {...props}
                                />
                            )}
                            textField="text"
                            valueField="id"
                            className="flex-wrap"
                        />
                    )}
                    {canCreateTags && !tagInputVisible && (
                        <FaPlusCircle
                            onClick={handleClick}
                            title="Add tag"
                            className="mt-2 ml-2"
                        />
                    )}

                    {canCreateTags && (
                        <ComboBox
                            data={availableTags}
                            textField="text"
                            dataItemKey="id"
                            value={value}
                            onChange={handleOnChange}
                            allowCustom={true}
                            onClose={handleClose}
                            suggest={true}
                            ref={tagsRef}
                            style={{
                                visibility: tagInputVisible
                                    ? "visible"
                                    : "hidden",
                            }}
                            opened={tagInputVisible ? true : false}
                        />
                    )}
                </div>
            </Col>
        </Row>
    );
};

Tags.defaultProps = {
    canReadTags: true,
};

export default Tags;
