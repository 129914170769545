import { State } from "@progress/kendo-data-query";
import {
    Grid,
    GridColumn,
    GridNoRecords,
    GridRowProps,
    GridToolbar,
} from "@progress/kendo-react-grid";
import { Switch } from "@progress/kendo-react-inputs";
import cx from "classnames";
import * as React from "react";
import CustomPagingFooter from "../../../../../common/components/table/kendo/CustomPagingFooter";
import {
    MultiTextContainsFilterCell,
    MultiTextFilterCell,
} from "../../../../../common/components/table/kendo/filterCells/MultiTextFilterCell";
import {
    GridFieldDef,
    GridFieldType,
} from "../../../../../common/components/table/kendo/GridFieldsMap";
import GridLoadingPanel from "../../../../../common/components/table/kendo/GridLoadingPanel";
import { resolveSortAndFilterOptions } from "../../../../../common/components/table/kendo/helpers";
import useInfiniteGridProps from "../../../../../common/components/table/kendo/useInfiniteGridProps";
import useInfiniteDataResult from "../../../../../common/hooks/useInfiniteDataResult";
import {
    ExaminationPlanning,
    ExaminationScheduleProgressStatus,
    ExaminationStatusType,
} from "../../domain/examinationsPlanning";
import { useGetInfiniteExaminations } from "../../query/examinationsPlanningQueries";
import "./examinationSchedulesGrid.scss";
import ExaminationSchedulesGridActions from "./ExaminationSchedulesGridActions";
import { ExaminationStatusFilterCell } from "./ExaminationStatusFilterCell";
import { gridFieldsMap, gridFilterOperators } from "./gridConfiguration";
import ProgressBar from "./ProgressBar";

const grisColProps = (field: GridFieldDef, index: number) => ({
    key: index,
    field: field.field,
    title: field.label || field.field,
    width: 300,
    sortable: !field.isCustomField,
});

interface Props {
    organisationId: string;
}

interface ScheduleExaminationStatus {
    isProcessing: boolean;
    total?: number;
    completed?: number;
}

const ExaminationSchedulesGrid: React.FC<Props> = ({ organisationId }) => {
    const [scheduleExamStatus, setScheduleExamStatus] =
        React.useState<ScheduleExaminationStatus>({
            isProcessing: false,
            total: 0,
            completed: 0,
        });

    const wrapperRef = React.createRef<HTMLElement>();
    const [dataState, setDataState] = React.useState<State>({});
    const [selectedItems, setSelectedItems] = React.useState<
        ExaminationPlanning[]
    >([]);
    const [onlyUnallocated, setOnlyUnallocated] = React.useState(true);

    const query = React.useMemo(
        () => ({
            ...resolveSortAndFilterOptions(dataState, gridFieldsMap),
            onlyUnallocated,
        }),
        [dataState, onlyUnallocated],
    );

    const {
        data: examinations,
        fetchNextPage,
        hasNextPage,
        isFetching,
        isFetchingNextPage,
    } = useGetInfiniteExaminations(organisationId, query);

    const {
        flatData: flatExaminationsData,
        totalCount: totalExaminations,
        dataCount,
    } = useInfiniteDataResult(examinations);

    const isLoadingExaminations = isFetching || isFetchingNextPage;

    React.useMemo(() => {
        if (!flatExaminationsData) return [];

        const remainingScheduleToProcess = flatExaminationsData.filter(
            (obj) => {
                return (
                    obj.examinationScheduleStatus !==
                    ExaminationScheduleProgressStatus.Todo
                );
            },
        ).length;

        if (remainingScheduleToProcess > 0) {
            const completedSchedules =
                scheduleExamStatus.total - remainingScheduleToProcess;

            setScheduleExamStatus({
                isProcessing: true,
                total: scheduleExamStatus.total,
                completed: completedSchedules,
            });
        } else {
            setScheduleExamStatus({
                isProcessing: false,
            });
        }
    }, [flatExaminationsData]);

    const examinationsGridProps = useInfiniteGridProps({
        data: flatExaminationsData,
        dataState,
        hasNextPage,
        setDataState,
        loading: isLoadingExaminations,
        fetchMore: () => {
            fetchNextPage();
        },
        gridRef: wrapperRef,
        selectable: {
            config: {
                enabled: true,
                mode: "multiple",
            },
            onSelectChange: (newSelected) => {
                const selectedScheduleExams = newSelected.filter((obj) => {
                    return (
                        obj.examinationScheduleStatus ===
                        ExaminationScheduleProgressStatus.Todo
                    );
                });

                if (!scheduleExamStatus.isProcessing) {
                    setScheduleExamStatus({
                        total: selectedScheduleExams.length,
                        isProcessing: false,
                    });
                }
                setSelectedItems([...selectedScheduleExams]);
            },
        },
    });

    const rowRender = (
        row: React.ReactElement<HTMLTableRowElement>,
        rowProps: GridRowProps,
    ) => {
        const item = rowProps.dataItem as ExaminationPlanning;
        const planned = item.processState === ExaminationStatusType.Planned;

        const trProps = {
            ...row.props,
            className: planned
                ? cx("completed", row.props.className)
                : row.props.className,
        };
        return React.cloneElement(row, { ...trProps }, row.props.children);
    };

    const switchColumnType = React.useCallback(
        (field: GridFieldDef, index: number): JSX.Element => {
            switch (field.fieldType) {
                case GridFieldType.ExamStatus: {
                    return (
                        <GridColumn
                            {...grisColProps(field, index)}
                            filterCell={ExaminationStatusFilterCell}
                        />
                    );
                }
                case GridFieldType.MultiText: {
                    return (
                        <GridColumn
                            {...grisColProps(field, index)}
                            filterCell={MultiTextFilterCell}
                        />
                    );
                }
                case GridFieldType.MultiTextContains: {
                    return (
                        <GridColumn
                            {...grisColProps(field, index)}
                            filterCell={MultiTextContainsFilterCell}
                        />
                    );
                }
                case GridFieldType.Date: {
                    return (
                        <GridColumn
                            {...grisColProps(field, index)}
                            filter={"date"}
                            format="{0:dd/MM/yyyy}"
                        />
                    );
                }
                default: {
                    return <GridColumn {...grisColProps(field, index)} />;
                }
            }
        },
        [],
    );
    const togglableSwitch = (
        label: string,
        onChange: () => void,
        checked: boolean,
    ) => {
        return (
            <>
                <div className="ml-2 mr-2">{label}</div>
                <Switch onChange={onChange} checked={checked}></Switch>
            </>
        );
    };

    const toolbar = (
        <GridToolbar>
            <ExaminationSchedulesGridActions
                organisationId={organisationId}
                selectedItems={selectedItems}
            ></ExaminationSchedulesGridActions>
            <div className="flex-grow-1 justify-content-end">
                {togglableSwitch(
                    "Only Unallocated",
                    () => setOnlyUnallocated(!onlyUnallocated),
                    onlyUnallocated,
                )}
            </div>
        </GridToolbar>
    );

    return (
        <div
            ref={wrapperRef as React.RefObject<HTMLDivElement>}
            className="examinations-grid-h pt-2"
        >
            <Grid
                {...examinationsGridProps}
                filterable
                filterOperators={gridFilterOperators}
                className="examinations-grid-h"
                rowRender={rowRender}
            >
                {toolbar}
                <GridNoRecords>There is no data available</GridNoRecords>
                {Object.values(gridFieldsMap).map(switchColumnType)}
            </Grid>

            {scheduleExamStatus.isProcessing && (
                <ProgressBar
                    count={scheduleExamStatus.total}
                    doneCount={scheduleExamStatus.completed}
                />
            )}

            {isLoadingExaminations && <GridLoadingPanel gridRef={wrapperRef} />}
            <CustomPagingFooter
                total={totalExaminations}
                loading={isLoadingExaminations}
                dataCount={dataCount}
            />
        </div>
    );
};

export default ExaminationSchedulesGrid;
