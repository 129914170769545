import cx from "classnames";
import React from "react";
import { FaAngleDoubleLeft, FaCog } from "react-icons/fa";
import OrganisationConstants from "../../../Constants/OrganisationConstants";
import { OrganisationSimple } from "../../../modules/organisation/domain/types";
import { useIsCurrentUserAdmin } from "../../../store/features/user/user-api-slice";
import { IconButton } from "../icon-button/IconButton";
import NavLink from "../NavLink";

interface SideMenuFooterProps {
    organisation: OrganisationSimple;
    isExpanded: boolean;
    toggleExpansion: () => void;
}

const SideMenuFooter: React.FC<SideMenuFooterProps> = ({
    isExpanded,
    toggleExpansion,
    organisation,
}) => {
    const { isAdmin } = useIsCurrentUserAdmin();

    return (
        <div
            className={cx("d-flex justify-content-between", {
                "flex-wrap": !isExpanded,
            })}
        >
            {isAdmin && (
                <NavLink
                    to={`${OrganisationConstants.RoutePath}/${organisation.id}`}
                    label="Manage"
                    icon={FaCog}
                    eventKey="manage"
                    additionalClasses="flex-fill"
                />
            )}
            <IconButton
                icon={FaAngleDoubleLeft}
                onClick={toggleExpansion}
                className={cx("align-self-end transition-transform ml-auto", {
                    "rotated-180": !isExpanded,
                })}
            />
        </div>
    );
};

export default SideMenuFooter;
