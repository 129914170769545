import React, { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import QueryResultStatus from "../../../common/components/QueryResultStatus";
import PermissionConstants from "../../../common/permissions/PermissionConstants";
import { useGetFileCommentsQuery } from "../../../store/features/file/file-comments-api-slice";
import { hasUserOrgPermission } from "../../../store/features/user/user-api-slice";
import { FileCommentEntry } from "./FileCommentEntry";
import { FileCommentForm } from "./FileCommentForm";

export interface FileCommentProps {
    fileId: string;
    folderId: string;
    organisationId: string;
}

export const FileComments: React.FC<FileCommentProps> = (props) => {
    const [isAddMode, setIsAddMode] = useState(false);

    const canReadComments = hasUserOrgPermission(
        props.organisationId,
        PermissionConstants.OrgFilesFileComment.read,
    ).hasPermission;

    const { data: comments, ...queryResult } = useGetFileCommentsQuery(props, {
        skip: !canReadComments,
    });

    const canCreateComments = hasUserOrgPermission(
        props.organisationId,
        PermissionConstants.OrgFilesFileComment.create,
    ).hasPermission;

    return (
        <>
            {canReadComments && (
                <>
                    <Row className={"py-4"}>
                        <Col>
                            <hr />
                            <h4>Comments</h4>
                            <br />
                            {comments &&
                                canCreateComments &&
                                (isAddMode ? (
                                    <FileCommentForm
                                        props={props}
                                        onCompleted={() => setIsAddMode(false)}
                                    />
                                ) : (
                                    <Button
                                        variant="outline-secondary"
                                        type="submit"
                                        onClick={() => setIsAddMode(true)}
                                    >
                                        Add
                                    </Button>
                                ))}
                        </Col>
                    </Row>
                    <Row className={"mb-2"}>
                        <Col>
                            <QueryResultStatus queryResult={queryResult} />
                            {comments &&
                                comments.map((comment) => (
                                    <FileCommentEntry
                                        key={comment.id}
                                        comment={comment}
                                        commentProps={props}
                                    />
                                ))}
                        </Col>
                    </Row>
                </>
            )}
        </>
    );
};
