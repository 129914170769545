import produce from "immer";
import { Action, Reducer } from "redux";
import * as Actions from "./actions";
import { ProcessActivityFormState } from "./store";

export type KnownAction =
    | Actions.ProcessActivityFormContentHiddenFieldAdd
    | Actions.ProcessActivityFormContentHiddenFieldRemove;

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const unloadedState: ProcessActivityFormState = {
    ui: {
        viewForm: {
            hiddenFields: [],
        },
    },
};

const handlers = {} as Record<
    string,
    (
        state: ProcessActivityFormState,
        action: unknown,
    ) => ProcessActivityFormState
>;

handlers[Actions.PROCESS_ACTIVITY_FORM_CONTENT_HIDDEN_FIELD_ADD] = (
    state: ProcessActivityFormState,
    action: Actions.ProcessActivityFormContentHiddenFieldAdd,
): ProcessActivityFormState => {
    return produce(state, (newState) => {
        newState.ui.viewForm.hiddenFields.push(action.data);
    });
};

handlers[Actions.PROCESS_ACTIVITY_FORM_CONTENT_HIDDEN_FIELD_REMOVE] = (
    state: ProcessActivityFormState,
    action: Actions.ProcessActivityFormContentHiddenFieldRemove,
): ProcessActivityFormState => {
    return produce(state, (newState) => {
        newState.ui.viewForm.hiddenFields.splice(
            newState.ui.viewForm.hiddenFields.indexOf(action.data),
            1,
        );
    });
};

export const reducer: Reducer<ProcessActivityFormState> = (
    state: ProcessActivityFormState | undefined,
    incomingAction: Action,
): ProcessActivityFormState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    const handler = handlers[action.type];
    if (handler) {
        return handler(state, action);
    }
    return state;
};
