import { push } from "connected-react-router";
import * as React from "react";
import { Table } from "react-bootstrap";
import { useDispatch } from "react-redux";
import LinkRow from "../../../common/components/link-row";
import { TrackingObjectHistoryRecord } from "../store/types";

interface Props {
    inspectionHistory: TrackingObjectHistoryRecord[];
    orgShortName: string;
}

const TrackingObjectHistory: React.FC<Props> = ({
    inspectionHistory,
    orgShortName,
}) => {
    const dispatch = useDispatch();

    const onNavigate = (processId: string, activityId: string) => () => {
        dispatch(
            push(
                `/${orgShortName}/process/${processId}/activity/${activityId}`,
            ),
        );
    };

    return (
        <div className="pt-4">
            <hr />
            <h4>Inspection history</h4>
            <Table borderless={true}>
                <tbody>
                    {inspectionHistory.map((historyRecord) => (
                        <LinkRow
                            key={historyRecord.processActivityId}
                            title={historyRecord.name}
                            onNavigate={onNavigate(
                                historyRecord.processId,
                                historyRecord.processActivityId,
                            )}
                        />
                    ))}
                </tbody>
            </Table>
        </div>
    );
};

export default TrackingObjectHistory;
