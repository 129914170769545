import * as React from "react";
import { useParams } from "react-router";
import { FilesRouteParams } from "../viewModel/FilesRouteParams";
import FilePageTitles from "../pageTitles";
import FolderContents from "../containers/FolderContents";
import { useOrganisationId } from "../../organisation/hooks/useOrganisationId";
import { useOrgTitleWithShortName } from "../../../common/hooks/useTitle";

export const FolderPage: React.FC = () => {
    const { folderId, orgShortName } = useParams<FilesRouteParams>();

    const { organisationId } = useOrganisationId();

    useOrgTitleWithShortName(FilePageTitles.Main);

    return (
        <FolderContents
            organisationId={organisationId}
            folderId={folderId}
            orgShortName={orgShortName}
        />
    );
};
