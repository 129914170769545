import { HttpMethod } from "../../../common/http/HttpMethod";
import { FileDetails } from "../../../modules/files/domain/file";
import { FileSearchItem } from "../../../modules/files/domain/folder";
import {
    getListedFolderTag,
    OrganisationAndFolderIds,
} from "../../../modules/files/utils/OrganisationAndFolderIds";
import { FileSearchForm } from "../../../modules/files/viewModel/FileSearch";
import { dcpApi, Tags } from "../dcpApi";

export interface UploadFileParams extends OrganisationAndFolderIds {
    files: FileList;
}

export interface UploadFolderFileParams extends OrganisationAndFolderIds {
    file: File;
    path: string[];
}

export interface FileIdsParams extends OrganisationAndFolderIds {
    fileId: string;
}
export interface FileFavoriteParams extends FileIdsParams {
    isFavorite: boolean;
}

interface ChangeFileNameParams extends FileIdsParams {
    fileName: string;
}

interface TagParams extends OrganisationAndFolderIds {
    fileId: string;
    tag: string;
}

export interface ReplaceFileParams extends OrganisationAndFolderIds {
    file: File;
    fileId: string;
}

const getBaseUrl = ({
    organisationId,
    folderId,
}: OrganisationAndFolderIds): string =>
    `organisation/${organisationId}/folder/${folderId}/file`;

const listedFolderAndFileDetailsTags = (result, error, params) =>
    error
        ? []
        : [
              getListedFolderTag(params),
              { type: Tags.FileDetails, id: params.fileId },
              {
                  type: Tags.FileSearch,
              },
          ];

export const fileApi = dcpApi.injectEndpoints({
    endpoints: (builder) => ({
        getFile: builder.query<FileDetails, FileIdsParams>({
            query: (params) =>
                [getBaseUrl(params), params.fileId, "details"].join("/"),
            providesTags: (_, error, params) =>
                error ? [] : [{ type: Tags.FileDetails, id: params.fileId }],
        }),
        uploadFile: builder.mutation<void, UploadFileParams>({
            query: (params) => {
                const body = new FormData();
                Array.from(params.files || []).forEach((file) =>
                    body.append("files", file),
                );
                return {
                    url: getBaseUrl(params),
                    method: HttpMethod.Post,
                    body,
                };
            },
            invalidatesTags: (_, error, params) =>
                error
                    ? []
                    : [
                          getListedFolderTag(params),
                          {
                              type: Tags.FileSearch,
                          },
                      ],
        }),
        uploadFolderFile: builder.mutation<void, UploadFolderFileParams>({
            query: ({ path, file, ...params }) => {
                const searchParams = new URLSearchParams({
                    path: path.join("/"),
                });

                const body = new FormData();
                body.append("file", file, file.name);
                return {
                    url: `${getBaseUrl(
                        params,
                    )}/folder?${searchParams.toString()}`,
                    method: HttpMethod.Post,
                    body,
                };
            },
            invalidatesTags: (_, error, params) =>
                error
                    ? []
                    : [
                          getListedFolderTag(params),
                          {
                              type: Tags.FileSearch,
                          },
                      ],
        }),
        changeFileName: builder.mutation<void, ChangeFileNameParams>({
            query({ organisationId, folderId, fileId, fileName }) {
                return {
                    url: `organisation/${organisationId}/folder/${folderId}/file/${fileId}`,
                    method: HttpMethod.Patch,
                    body: { name: fileName },
                };
            },
            invalidatesTags: listedFolderAndFileDetailsTags,
        }),
        deleteFile: builder.mutation<void, FileIdsParams>({
            query: (params) => ({
                url: [getBaseUrl(params), params.fileId].join("/"),
                method: HttpMethod.Delete,
            }),
            invalidatesTags: (_, error, params) =>
                error
                    ? []
                    : [
                          getListedFolderTag(params),
                          {
                              type: Tags.FileSearch,
                          },
                      ],
        }),
        searchFilesAndFolders: builder.query<
            FileSearchItem[],
            { organisationId: string; query: FileSearchForm }
        >({
            query: ({ organisationId, query }) => {
                const searchParams = new URLSearchParams(query);

                return {
                    url: `organisation/${organisationId}/folder/search?${searchParams.toString()}`,
                    method: HttpMethod.Get,
                };
            },
            providesTags: () => [
                {
                    type: Tags.FileSearch,
                },
            ],
            transformResponse: (data: FileSearchItem[]): FileSearchItem[] => {
                return data.map(({ createdOn, lastUpdatedAt, ...other }) => ({
                    dateModified: lastUpdatedAt || createdOn,
                    createdOn,
                    lastUpdatedAt,
                    ...other,
                }));
            },
        }),
        favoriteFile: builder.mutation<void, FileFavoriteParams>({
            query: (params) => ({
                url: [
                    getBaseUrl(params),
                    params.fileId,
                    "set-favorite",
                    params.isFavorite,
                ].join("/"),
                method: HttpMethod.Patch,
            }),
            invalidatesTags: (_, error, params) =>
                error ? [] : [{ type: Tags.FileDetails, id: params.fileId }],
        }),
        replaceFile: builder.mutation<string, ReplaceFileParams>({
            query: (params) => {
                const body = new FormData();
                body.append("file", params.file, params.file.name);
                return {
                    url: [getBaseUrl(params), params.fileId].join("/"),
                    method: HttpMethod.Post,
                    body,
                };
            },
            invalidatesTags: listedFolderAndFileDetailsTags,
        }),
        addFileTag: builder.mutation<void, TagParams>({
            query: ({ organisationId, folderId, tag, fileId }) => ({
                url: `organisation/${organisationId}/folder/${folderId}/file/${fileId}/tags`,
                method: HttpMethod.Post,
                body: { tag },
            }),
            invalidatesTags: (_, error, params) =>
                error
                    ? []
                    : [
                          { type: Tags.FileDetails, id: params.fileId },
                          {
                              type: Tags.OrganisationTagHints,
                              id: params.organisationId,
                          },
                      ],
        }),
        patchFileTags: builder.mutation<void, TagParams>({
            query: ({ organisationId, folderId, tag, fileId }) => ({
                url: `organisation/${organisationId}/folder/${folderId}/file/${fileId}/tags`,
                method: HttpMethod.Patch,
                body: { tag },
            }),
            invalidatesTags: (_, error, params) =>
                error ? [] : [{ type: Tags.FileDetails, id: params.fileId }],
        }),
    }),
});

export const {
    useSearchFilesAndFoldersQuery,
    useUploadFileMutation,
    useUploadFolderFileMutation,
    useChangeFileNameMutation,
    useDeleteFileMutation,
    useGetFileQuery,
    useAddFileTagMutation,
    usePatchFileTagsMutation,
    useFavoriteFileMutation,
    useReplaceFileMutation,
} = fileApi;
