import * as React from "react";
import { Row, Col, Breadcrumb } from "react-bootstrap";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { useOrgTitleWithShortName } from "../../../common/hooks/useTitle";
import OrganisationConstants from "../../../Constants/OrganisationConstants";
import { useOrganisationId } from "../../organisation/hooks/useOrganisationId";
import ProcessCreateForm from "../containers/ProcessCreateForm";
import { ProcessPageTitles } from "./pageTitles";

const ProcessCreatePage: React.FC = () => {
    const params = useParams<{ orgShortName: string }>();
    const queryParams = new URLSearchParams(window.location.search);
    const { organisationId } = useOrganisationId();
    const scheduledStartDate =
        queryParams.get(OrganisationConstants.queryParamScheduledStart) ?? "";

    useOrgTitleWithShortName(ProcessPageTitles.Create);

    return (
        <>
            <Row className="top10">
                <Col>
                    <Breadcrumb>
                        <Breadcrumb.Item linkAs={"div"}>
                            <Link
                                to={`/${params.orgShortName}${OrganisationConstants.Process}`}
                            >
                                Processes
                            </Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>Create</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h1>Create Process</h1>
                </Col>
            </Row>
            <Row>
                <Col>
                    <ProcessCreateForm
                        organisationId={organisationId}
                        scheduledStart={scheduledStartDate}
                    ></ProcessCreateForm>
                </Col>
            </Row>
        </>
    );
};

export default ProcessCreatePage;
