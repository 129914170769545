import * as React from "react";
import { Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";
import OrganisationConstants from "../../../Constants/OrganisationConstants";
import { AssetModel } from "../../../store/features/asset/asset-api-slice";

interface Props {
    asset: AssetModel;
    orgShortName: string;
}

function getAssetParents(asset: AssetModel): AssetModel[] {
    const result: AssetModel[] = [];
    while (asset) {
        result.unshift(asset);
        asset = asset.parent;
    }
    return result;
}

const AssetViewBreadcrumbs: React.FC<Props> = ({ asset, orgShortName }) => {
    const assetParents = getAssetParents(asset.parent);

    return (
        <Breadcrumb>
            <Breadcrumb.Item linkAs={"span"}>
                <Link to={`/${orgShortName}${OrganisationConstants.Asset}`}>
                    Assets
                </Link>
            </Breadcrumb.Item>
            {assetParents.length > 0 &&
                assetParents.map((parent) => (
                    <Breadcrumb.Item linkAs={"span"} key={parent.id}>
                        <Link
                            to={`/${orgShortName}${OrganisationConstants.Asset}/${parent.id}`}
                        >
                            {parent.name}
                        </Link>
                    </Breadcrumb.Item>
                ))}
            <Breadcrumb.Item active>{asset.name}</Breadcrumb.Item>
        </Breadcrumb>
    );
};

export default AssetViewBreadcrumbs;
