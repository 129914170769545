import { FileAndFoldersGrid } from "./FileAndFoldersGrid";
import React from "react";
import { DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "react-bootstrap";
import {
    EntryType,
    mapToViewEntries,
    WithDataItem,
} from "../viewModel/FileViewEntry";
import { useListFolderQuery } from "../../../store/features/folder/folderApiSlice";
import CoveringLoadingPanel from "../../../common/components/CoveringLoadingPanel";
import "./select-folder-dialog.css";
import { DirectoryBreadcrumb } from "./DirectoryBreadcrumb";
import { useOrganisationId } from "../../organisation/hooks/useOrganisationId";

interface Props {
    initialFolderId: string;
    onClose: () => void;
    onSelect: (folderId: string) => void;
}

const navigateToSubFolder =
    (setFolderId: (folderId: string) => void) =>
    ({ dataItem }: WithDataItem): void => {
        const { type, id } = dataItem;
        switch (type) {
            case EntryType.NavigateUp:
            case EntryType.Folder:
                setFolderId(id);
                break;
        }
    };

export function SelectFolderDialog({
    initialFolderId,
    onClose,
    onSelect,
}: Props): JSX.Element {
    const [folderId, setFolderId] = React.useState(initialFolderId);
    const { organisationId, isLoadingOrgId } = useOrganisationId();
    const { data: folder, isLoading: isLoadingFolder } = useListFolderQuery(
        { organisationId: organisationId, folderId },
        { skip: !organisationId },
    );
    const entries = mapToViewEntries({ ...folder, files: [] });
    const isLoading = isLoadingOrgId || isLoadingFolder;
    return (
        <>
            {isLoading && <CoveringLoadingPanel />}
            <div className="select-folder-dialog__content">
                <DirectoryBreadcrumb
                    path={folder?.path}
                    activeId={folderId}
                    onFolderClick={setFolderId}
                />
                <div className="pt-3">
                    <FileAndFoldersGrid
                        entries={entries}
                        onRowDoubleClick={navigateToSubFolder(setFolderId)}
                    />
                </div>
            </div>
            <DialogActionsBar layout="end">
                <Button variant="link" onClick={onClose}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={() => onSelect(folderId)}>
                    Move
                </Button>
            </DialogActionsBar>
        </>
    );
}
