import * as React from "react";
import { Process } from "../../process/domain/types";
import { ProcessActivity } from "../domain/types";
import ProcessActivityBreadcrumb from "./ProcessActivityBreadcrumb";
import ProcessActivityNavigationBar from "./ProcessActivityNavigationBar";

interface Props {
    process: Process;
    activity: ProcessActivity;
    orgShortName: string;
}

const ProcessActivityMainTopBar: React.FC<Props> = ({
    process,
    activity,
    orgShortName,
}) => {
    return (
        <>
            <ProcessActivityBreadcrumb
                process={process}
                activity={activity}
                orgShortName={orgShortName}
            ></ProcessActivityBreadcrumb>
            <h1>{activity?.displayName ?? ""}</h1>
            <hr />
            <ProcessActivityNavigationBar
                process={process}
                activity={activity}
                orgShortName={orgShortName}
            ></ProcessActivityNavigationBar>
            <hr />
        </>
    );
};

export default ProcessActivityMainTopBar;
