import React from "react";
import { Col, Row } from "react-bootstrap";
import useOrgPermission from "../../../../common/hooks/useOrgPermission";
import PermissionConstants from "../../../../common/permissions/PermissionConstants";
import { Permission } from "../../../organisation/domain/types";
import UserGroupAddPermissionForm from "./UserGroupAddPermissionForm";
import UserGroupPermissionsTable from "./UserGroupPermissionsTable";

interface Props {
    orgId: string;
    userGroupId: string;
    addedPermissions: Permission[];
    orgPermissions: Permission[];
    updatedCallback: () => void;
}

export const UserGroupPermissions: React.FC<Props> = (props) => {
    const getAvailablePermissions = (): Permission[] => {
        return props.orgPermissions.filter(
            (perm) => !props.addedPermissions.some((p) => p.id === perm.id),
        );
    };

    const canManagePermissions = useOrgPermission(
        PermissionConstants.OrgGroupPermission.manage,
    );

    return (
        <Row>
            <Col>
                {canManagePermissions && (
                    <Row className="top10">
                        <Col>
                            <UserGroupAddPermissionForm
                                updatedCallback={props.updatedCallback}
                                orgId={props.orgId}
                                groupId={props.userGroupId}
                                permissions={getAvailablePermissions()}
                            ></UserGroupAddPermissionForm>
                        </Col>
                    </Row>
                )}
                <Row className="top10">
                    <Col>
                        <UserGroupPermissionsTable
                            updatedCallback={props.updatedCallback}
                            orgId={props.orgId}
                            userGroupId={props.userGroupId}
                            permissions={props.addedPermissions}
                            canRemovePermissions={canManagePermissions}
                        ></UserGroupPermissionsTable>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};
