import * as React from "react";

import { Alert, Button } from "react-bootstrap";

import { ActionBarActionProps } from "./ProcessActivityStatusActionBar";
import ButtonSpinner from "../../../common/components/button/ButtonSpinner";
import { ProcessActivity } from "../domain/types";
import { formatIsoDate } from "../../../helpers/dateTimeHelpers";
import { resolveActivityState } from "../helpers/activityStateHelpers";

interface Props {
    activity: ProcessActivity;
    open: ActionBarActionProps;
}

const ActivityNotApplicableAlert: React.FC<Props> = (props) => {
    const { activity, open } = props;
    const { canChangeStatus } = resolveActivityState(activity);
    const clickOpen = (): void => {
        if (
            window.confirm(
                "Are you sure you want to open this activity? Opening this item will open all parent activities.",
            )
        ) {
            open.actionCallback({ activity });
        }
    };

    const getAlertText = () => {
        return (
            <p>
                This activity was set as not applicable by{" "}
                {activity.completedBy} at {formatIsoDate(activity.completedAt)}
                {activity.statusTypeData?.reason &&
                    ` due to "${activity.statusTypeData.reason}"`}
                {activity.statusTypeData?.comment &&
                    ` with a comment: "${activity.statusTypeData.comment}"`}
                .
            </p>
        );
    };

    if (canChangeStatus) {
        return (
            <Alert variant="dark">
                <Alert.Heading>Activity Not Applicable</Alert.Heading>
                {getAlertText()}
                <p>You can reset the form into the open state below.</p>
                <hr />
                <div className="d-flex justify-content-end">
                    <Button
                        variant="danger"
                        onClick={clickOpen}
                        disabled={open.actionResult.isLoading}
                    >
                        Open
                        {open.actionResult.isLoading && <ButtonSpinner />}
                    </Button>
                </div>
            </Alert>
        );
    } else {
        return (
            <Alert variant="dark">
                <Alert.Heading>Activity Not Applicable</Alert.Heading>
                {getAlertText()}
            </Alert>
        );
    }
};

export default ActivityNotApplicableAlert;
