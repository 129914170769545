import * as React from "react";
import { Breadcrumb, Card } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import QueryResultStatus from "../../../common/components/QueryResultStatus";
import { useOrgTitleById } from "../../../common/hooks/useTitle";
import { TemplateTypes } from "../../../common/types/TemplateTypes";
import OrganisationConstants from "../../../Constants/OrganisationConstants";
import { cleanJson } from "../../../helpers/jsonHelper";
import {
    useDeleteAssetTemplateMutation,
    useGetAssetTemplateByIdQuery,
    usePutAssetTemplateByIdMutation,
} from "../../../store/features/asset-template/assettemplate-api-slice";
import {
    useDeleteFormTemplateMutation,
    useGetFormTemplateByIdQuery,
    usePutFormTemplateByIdMutation,
} from "../../../store/features/form-template/formtemplate-api-slice";
import { useGetOrgByIdQuery } from "../../../store/features/organisation/organisation-api-slice";
import TemplateForm, {
    ValidatedCallback,
} from "../../template/containers/TemplateForm";
import TemplatePreview from "../../template/containers/TemplatePreview";
import TemplateAuditActionList from "../containers/TemplateAuditActionList";
import { TemplateFormActionArg } from "../viewModel/TemplateFormActions";
import { TemplateFormValues } from "../viewModel/TemplateFormValues";
import { formatTemplateViewTitle } from "./pageTitles";

const unloadedTemplate = {
    id: "",
    name: "",
    description: "",
    contents: [],
    auditActions: [],
    organisationId: "",
    version: -1,
    lastUpdatedAt: "",
    configOptions: [],
    trackedObjects: null,
};

interface TemplateViewPageProps {
    TemplateType: TemplateTypes;
}
const TemplateViewPage: React.FC<TemplateViewPageProps> = (props) => {
    const params = useParams<{ id: string }>();

    const [configPreview, setConfigPreview] = React.useState("");

    const [updateFormTemplate, updateFormTemplateResult] =
        usePutFormTemplateByIdMutation();
    const [deleteFormTemplate, deleteFormTemplateResult] =
        useDeleteFormTemplateMutation();
    const [updateAssetTemplate, updateAssetTemplateResult] =
        usePutAssetTemplateByIdMutation();
    const [deleteAssetTemplate, deleteAssetTemplateResult] =
        useDeleteAssetTemplateMutation();

    const getTemplate =
        props.TemplateType === TemplateTypes.Form
            ? useGetFormTemplateByIdQuery
            : useGetAssetTemplateByIdQuery;

    const [updateTemplate, updateTemplateResult] =
        props.TemplateType === TemplateTypes.Form
            ? [updateFormTemplate, updateFormTemplateResult]
            : [updateAssetTemplate, updateAssetTemplateResult];
    const updateTemplateActionCallback = ({
        values,
    }: TemplateFormActionArg<TemplateFormValues>) => {
        return updateTemplate({
            id: params.id,
            ...values,
            contents: JSON.parse(values.config),
            trackedObjects:
                values.trackedObjects && JSON.parse(values.trackedObjects),
        });
    };
    const [deleteTemplate, deleteTemplateResult] =
        props.TemplateType === TemplateTypes.Form
            ? [deleteFormTemplate, deleteFormTemplateResult]
            : [deleteAssetTemplate, deleteAssetTemplateResult];
    const deleteTemplateActionCallback = () => {
        return deleteTemplate({
            id: params.id,
            organisationId: orgId,
        });
    };

    const redirectPath =
        props.TemplateType === TemplateTypes.Form
            ? "/form/template"
            : "/asset-template";
    const redirectTabKey =
        props.TemplateType === TemplateTypes.Form
            ? "formTemplates"
            : "assetTemplates";

    const { data: template = unloadedTemplate, ...templateQueryResult } =
        getTemplate(params.id, {
            skip:
                !params.id ||
                deleteTemplateResult.isLoading ||
                deleteTemplateResult.isSuccess,
        });

    const orgId = template?.organisationId ?? "";

    useOrgTitleById(orgId, formatTemplateViewTitle(props.TemplateType));

    const { data: organisation, ...organisationQueryResult } =
        useGetOrgByIdQuery(orgId, {
            skip: !orgId,
        });

    const initialValues = {
        name: template.name,
        description: template.description,
        config: template.contents
            ? JSON.stringify(
                  template.contents.map((a) => cleanJson(a)),
                  null,
                  4,
              )
            : "",
        trackedObjects: template.trackedObjects
            ? JSON.stringify(
                  template.trackedObjects.map((a) => cleanJson(a)),
                  null,
                  4,
              )
            : "",
        organisationId: orgId,
    };

    const handleConfigValidated: ValidatedCallback = ({
        value,
        isValid,
    }): void => {
        if (isValid) {
            setConfigPreview(value);
        }
    };

    if (!template)
        return <QueryResultStatus queryResult={templateQueryResult} />;
    if (!organisation)
        return <QueryResultStatus queryResult={organisationQueryResult} />;

    return (
        <>
            <Row>
                <Col>
                    <Breadcrumb>
                        <Breadcrumb.Item linkAs={"span"}>
                            <Link
                                to={`${OrganisationConstants.RoutePath}/${template.organisationId}`}
                            >
                                Manage
                            </Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item linkAs={"span"}>
                            <Link
                                to={`${OrganisationConstants.RoutePath}/${template.organisationId}?tab=${redirectTabKey}`}
                            >
                                {props.TemplateType} Template
                            </Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item linkAs={"span"} active>
                            View{" "}
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h1>{organisation.name}</h1>
                    <h2>{props.TemplateType} Template</h2>
                </Col>
            </Row>
            <Row className="py-3">
                <Col>
                    <Tabs defaultActiveKey="home" id="tab">
                        <Tab eventKey="home" title="Form">
                            <Row className="pt-3">
                                <Col>
                                    <TemplateForm
                                        templateId={template.id}
                                        organisationId={template.organisationId}
                                        initialValues={initialValues}
                                        updateTemplate={{
                                            actionCallback:
                                                updateTemplateActionCallback,
                                            actionResult: updateTemplateResult,
                                        }}
                                        deleteTemplate={{
                                            actionCallback:
                                                deleteTemplateActionCallback,
                                            actionResult: deleteTemplateResult,
                                        }}
                                        createRedirectPath={redirectPath}
                                        deleteRedirectTabKey={redirectTabKey}
                                        onConfigValidated={
                                            handleConfigValidated
                                        }
                                        disableConfigFields
                                    ></TemplateForm>
                                </Col>
                                <Col>
                                    <Card>
                                        <Card.Header as="h5">
                                            {props.TemplateType} Preview
                                        </Card.Header>
                                        <Card.Body>
                                            {configPreview && (
                                                <TemplatePreview
                                                    configPreview={
                                                        configPreview
                                                    }
                                                ></TemplatePreview>
                                            )}
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Tab>
                        <Tab eventKey="history" title="History">
                            <TemplateAuditActionList
                                template={template}
                            ></TemplateAuditActionList>
                        </Tab>
                    </Tabs>
                </Col>
            </Row>
        </>
    );
};
export default TemplateViewPage;
