import * as React from "react";
import { GridFilterCellProps } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import {
    DatePicker,
    DatePickerChangeEvent,
} from "@progress/kendo-react-dateinputs";
import { DateTime } from "luxon";

export const BetweenDatesFilterCell = (props: GridFilterCellProps) => {
    const hasValue = (minDate: Date, maxDate: Date) =>
        Boolean(minDate || maxDate);

    const [minDate, setMinDate] = React.useState<Date>(null);
    const [maxDate, setMaxDate] = React.useState<Date>(null);

    const betweenDates = (
        current: string,
        { minDate, maxDate }: { minDate: Date; maxDate: Date },
    ) => {
        const date = DateTime.fromFormat(current, "dd/MM/yyyy").toJSDate();
        return (
            (minDate === null || date >= minDate) &&
            (maxDate === null || date <= maxDate)
        );
    };

    const onClearButtonClick = (event) => {
        event.preventDefault();
        setMinDate(null);
        setMaxDate(null);
        props.onChange({
            value: {
                minDate: null,
                maxDate: null,
            },
            operator: betweenDates,
            syntheticEvent: event,
        });
    };

    const onMinDateChange = React.useCallback(
        (e: DatePickerChangeEvent) => {
            setMinDate(e.value);
            props.onChange({
                value: {
                    minDate: e.value,
                    maxDate: maxDate,
                },
                operator: betweenDates,
                syntheticEvent: e.syntheticEvent,
            });
        },
        [maxDate, props],
    );

    const onMaxDateChange = React.useCallback(
        (e: DatePickerChangeEvent) => {
            setMaxDate(e.value);
            props.onChange({
                value: {
                    minDate: minDate,
                    maxDate: e.value,
                },
                operator: betweenDates,
                syntheticEvent: e.syntheticEvent,
            });
        },
        [minDate, props],
    );

    return (
        <div className="k-filtercell">
            <div className="k-filtercell-wrapper">
                <DatePicker value={minDate} onChange={onMinDateChange} />
                <DatePicker value={maxDate} onChange={onMaxDateChange} />
                <div className="k-filtercell-operator">
                    <Button
                        title="Clear"
                        disabled={!hasValue(minDate, maxDate)}
                        onClick={onClearButtonClick}
                        icon="filter-clear"
                    />
                </div>
            </div>
        </div>
    );
};
