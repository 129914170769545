import {
    Formik,
    FormikErrors,
    validateYupSchema,
    yupToFormErrors,
} from "formik";
import React from "react";
import { Alert, Button, Col, Form, Row, Spinner } from "react-bootstrap";
import { TemplateContent } from "../../../modules/template/domain/types";
import { MutationResult } from "../../types/rtkQuery/MutationResult";
import ButtonSpinner from "../button/ButtonSpinner";
import MutationResultStatus from "../MutationResultStatus";
import "./dynamicForm.scss";
import DynamicFormContextProvider, {
    GetFileUrlFunc,
} from "./DynamicFormContextProvider";
import RecursiveFormComponent from "./RecursiveFormComponent";
import {
    DynamicFormFormikHelpers,
    DynamicFormFormikProps,
    DynamicFormRef,
    DynamicFormValues,
} from "./types/dynamicFormTypes";
import { getYupSchemaFromMetaData } from "./yupValidation/YupValidation";

interface Props {
    initialValues: DynamicFormValues;
    isFormReadOnly: boolean;
    saveProps: SaveProps;
    onCancelCallback?: () => void;
    onSubmitCallback: (
        values: DynamicFormValues,
        actions: DynamicFormFormikHelpers,
    ) => void;
    contentConfig: TemplateContent[];
    formikRef: DynamicFormRef;
    getFileUrl: GetFileUrlFunc;
    hiddenFields: string[];
}

interface SaveProps {
    onSaveDraftCallback: (
        values: DynamicFormValues,
        actions: DynamicFormFormikHelpers,
        errors: FormikErrors<DynamicFormValues>,
    ) => void;

    saveResult: MutationResult;
}

const DynamicForm: React.FC<Props> = ({
    initialValues,
    isFormReadOnly,
    onSubmitCallback,
    onCancelCallback,
    saveProps,
    contentConfig,
    formikRef,
    getFileUrl,
    hiddenFields,
}: Props) => {
    const baseValidationSchema = React.useMemo(
        () => getYupSchemaFromMetaData(contentConfig, [], []),
        [contentConfig],
    );
    const validate = React.useCallback(
        async (values: DynamicFormValues): Promise<FormikErrors<unknown>> => {
            try {
                // Pass in the list of hidden fields so that the validation schema can ignore them
                await validateYupSchema<DynamicFormValues>(
                    values,
                    baseValidationSchema,
                    true,
                    {
                        hiddenFields: hiddenFields,
                    },
                );
            } catch (err) {
                return yupToFormErrors(err);
            }

            return {};
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [baseValidationSchema, JSON.stringify(hiddenFields)],
    );

    return (
        <DynamicFormContextProvider getFileUrl={getFileUrl}>
            <Formik
                innerRef={formikRef}
                initialValues={initialValues}
                onSubmit={onSubmitCallback}
                validate={validate}
                enableReinitialize={true}
            >
                {(formikProps: DynamicFormFormikProps): JSX.Element => {
                    return (
                        <>
                            <Row className="top30">
                                <Col>
                                    <Form
                                        noValidate
                                        onSubmit={formikProps.handleSubmit}
                                    >
                                        <RecursiveFormComponent
                                            config={contentConfig}
                                            formik={formikProps}
                                            isFormReadOnly={isFormReadOnly}
                                        />
                                        {!isFormReadOnly && (
                                            <>
                                                {formikProps.isSubmitting ? (
                                                    <>
                                                        <Button
                                                            variant="primary"
                                                            disabled
                                                        >
                                                            <Spinner
                                                                as="span"
                                                                animation="border"
                                                                size="sm"
                                                                role="status"
                                                                aria-hidden="true"
                                                            />
                                                            <span>
                                                                {" "}
                                                                Saving...
                                                            </span>
                                                        </Button>
                                                        <Alert
                                                            className="top10"
                                                            variant="warning"
                                                        >
                                                            Large files may take
                                                            a while to upload.
                                                        </Alert>
                                                    </>
                                                ) : (
                                                    <>
                                                        <Button
                                                            variant="primary"
                                                            onClick={(): void => {
                                                                // Save form values without validation
                                                                if (saveProps) {
                                                                    saveProps.onSaveDraftCallback(
                                                                        formikProps.values,
                                                                        formikProps,
                                                                        formikProps.errors,
                                                                    );
                                                                }
                                                            }}
                                                            disabled={
                                                                saveProps &&
                                                                saveProps
                                                                    .saveResult
                                                                    .isLoading
                                                            }
                                                        >
                                                            Save{" "}
                                                            {saveProps &&
                                                                saveProps
                                                                    .saveResult
                                                                    .isLoading && (
                                                                    <ButtonSpinner />
                                                                )}
                                                        </Button>
                                                        {!!onCancelCallback && (
                                                            <Button
                                                                className="ml-1"
                                                                variant="secondary"
                                                                onClick={
                                                                    onCancelCallback
                                                                }
                                                            >
                                                                Cancel
                                                            </Button>
                                                        )}

                                                        <MutationResultStatus
                                                            mutationResult={
                                                                saveProps.saveResult
                                                            }
                                                        />
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </Form>
                                </Col>
                            </Row>
                        </>
                    );
                }}
            </Formik>
        </DynamicFormContextProvider>
    );
};

export default DynamicForm;
