import { useMemo } from "react";
import { ProcessActivityApproval } from "../domain/types";
import { useGetUsersByOrgIdQuery } from "../../../store/features/organisation/organisation-api-slice";
import { BaseUser } from "../../organisation/domain/types";
import { filterItems } from "../../common/filters/helpers/filtersHelper";

interface UseActivityApprovalApproversState {
    approvers: BaseUser[];
}

export function useActivityApprovalApprovers(
    organisationId: string,
    activity: ProcessActivityApproval,
): UseActivityApprovalApproversState {
    const { data: organisationUsers } = useGetUsersByOrgIdQuery(organisationId);

    const approvers: BaseUser[] = useMemo(
        () =>
            (organisationUsers || [])
                .filter(
                    (u) =>
                        !activity.approvals.find(
                            (s) => s.approver.userId === u.userId,
                        ),
                )
                .filter((u) => filterItems(activity?.approversFilter, u))
                .map(({ userId, username, userGroups }) => ({
                    userId,
                    username,
                    userGroups,
                })),
        [organisationUsers, activity.approvals, activity?.approversFilter],
    );

    return { approvers };
}
