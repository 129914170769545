import React from "react";
import { ApprovalResponse } from "../domain/types";
import ApprovalResponseItem from "./ApprovalResponseItem";

interface Props {
    responses: ApprovalResponse[];
}

const ApprovalResponsesHistory: React.FC<Props> = ({ responses }) => {
    return (
        <div>
            <ul className="timeline">
                {responses.map((item: ApprovalResponse, index: number) => (
                    <ApprovalResponseItem key={index.toString()} data={item} />
                ))}
            </ul>
        </div>
    );
};

export default ApprovalResponsesHistory;
