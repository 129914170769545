import { CallHistoryMethodAction, push } from "connected-react-router";
import React, { Dispatch } from "react";
import { ButtonGroup, ButtonToolbar } from "react-bootstrap";
import { FaRegStar, FaStar } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { IconButton } from "../../../common/components/icon-button/IconButton";
import { MultiMutationResultStatus } from "../../../common/components/MutationResultStatus";
import QueryResultStatus from "../../../common/components/QueryResultStatus";
import { emptyFilterDescriptor } from "../../../common/components/table/kendo/columnFilters";
import { SelectedState } from "../../../common/components/table/kendo/SelectedState";
import { useWindowSize } from "../../../common/hooks/useWindowSize";
import { breakpointLg } from "../../../common/layout/breakpoints";
import PermissionConstants from "../../../common/permissions/PermissionConstants";
import OrganisationConstants from "../../../Constants/OrganisationConstants";
import {
    useChangeFileNameMutation,
    useDeleteFileMutation,
    useUploadFileMutation,
} from "../../../store/features/file/fileApiSlice";
import { setName } from "../../../store/features/folder/fileOrFolderNameSlice";
import {
    useAddNewFolderMutation,
    useChangeFolderNameMutation,
    useDeleteFolderMutation,
    useFavoriteFolderMutation,
    useListFolderQuery,
} from "../../../store/features/folder/folderApiSlice";
import { hasUserOrgPermission } from "../../../store/features/user/user-api-slice";
import { useAppSelector } from "../../../store/hooks";
import { CreateFolderButton } from "../components/CreateFolderButton";
import { DirectoryBreadcrumb } from "../components/DirectoryBreadcrumb";
import { FileAndFoldersGrid } from "../components/FileAndFoldersGrid";
import { FileOrFolderContextMenu } from "../components/FileOrFolderContextMenu";
import { FileUploadButton } from "../components/FileUploadButton";
import FolderTags from "../components/FolderTags";
import { MoveCoordinator } from "../components/MoveCoordinator";
import { MoveSelectedEntriesButton } from "../components/MoveSelectedEntriesButton";
import NameChangeContextProvider from "../components/NameChangeContextProvider";
import { isNewNameDifferent } from "../domain/folder";
import { useContextMenuState } from "../hooks/useContextMenu";
import { useFilesPageState } from "../hooks/useFilesPageState";
import { downloadFile } from "../services/downloadFile";
import {
    EntryType,
    getSelectedViewEntries,
    isContextMenuNeeded,
    mapToViewEntries,
    WithDataItem,
} from "../viewModel/FileViewEntry";
import FolderUpload from "./FolderUpload";

const navigateToSubFolderOrFile =
    (
        dispatch: Dispatch<CallHistoryMethodAction<string[]>>,
        orgShortName: string,
        folderId: string,
        beforeNavigation: () => void,
    ) =>
    ({ dataItem }: WithDataItem): void => {
        const { type, id } = dataItem;
        const consts = OrganisationConstants;
        switch (type) {
            case EntryType.NavigateUp:
            case EntryType.Folder:
                beforeNavigation();
                dispatch(push(`/${orgShortName}/${consts.folder}/${id}`));
                break;
            case EntryType.File:
                beforeNavigation();
                dispatch(
                    push(
                        `/${orgShortName}/${consts.folder}/${folderId}/${consts.file}/${id}`,
                    ),
                );
        }
    };

interface Props {
    organisationId: string;
    orgShortName: string;
    folderId: string;
}

const FolderContents: React.FC<Props> = ({
    organisationId,
    folderId,
    orgShortName,
}) => {
    const dispatch = useDispatch();

    const { data: folder, ...folderQueryResult } = useListFolderQuery(
        { organisationId, folderId },
        { skip: !organisationId },
    );
    const gridContainerRef = React.useRef<HTMLDivElement>(null);
    const [state, stateActions] = useFilesPageState();

    const canCreateFiles = hasUserOrgPermission(
        organisationId,
        PermissionConstants.OrgFilesFile.create,
    ).hasPermission;
    const canDeleteFiles = hasUserOrgPermission(
        organisationId,
        PermissionConstants.OrgFilesFile.delete,
    ).hasPermission;
    const canUpdateFiles = hasUserOrgPermission(
        organisationId,
        PermissionConstants.OrgFilesFile.update,
    ).hasPermission;
    const canMoveFiles = hasUserOrgPermission(
        organisationId,
        PermissionConstants.OrgFilesFile.move,
    ).hasPermission;
    const canReplaceFiles = hasUserOrgPermission(
        organisationId,
        PermissionConstants.OrgFilesFileReplace,
    ).hasPermission;

    const canDeleteFolders = hasUserOrgPermission(
        organisationId,
        PermissionConstants.OrgFilesFolder.delete,
    ).hasPermission;
    const canUpdateFolders = hasUserOrgPermission(
        organisationId,
        PermissionConstants.OrgFilesFolder.update,
    ).hasPermission;
    const canMoveFolders = hasUserOrgPermission(
        organisationId,
        PermissionConstants.OrgFilesFolder.move,
    ).hasPermission;
    const canCreateFolders = hasUserOrgPermission(
        organisationId,
        PermissionConstants.OrgFilesFolder.create,
    ).hasPermission;

    const { offset, dataItem, closeContextMenu, rowRender } =
        useContextMenuState(
            isContextMenuNeeded(canDeleteFolders || canUpdateFolders),
        );

    const fileOrFolderName = useAppSelector((s) => s.fileOrFolderName);

    const [addNewFolder, addNewFolderResult] = useAddNewFolderMutation();
    const [changeFolderName, changeFolderNameResult] =
        useChangeFolderNameMutation();
    const [changeFileName, changeFileNameResult] = useChangeFileNameMutation();
    const [deleteFolder, deleteFolderResult] = useDeleteFolderMutation();
    const [uploadFile, uploadFileResult] = useUploadFileMutation();
    const [deleteFile, deleteFileResult] = useDeleteFileMutation();
    const [setFavorite] = useFavoriteFolderMutation();

    const isAboveLg = useWindowSize().width >= breakpointLg;
    const [filter, setFilter] = React.useState(emptyFilterDescriptor);
    const clearFilter = () => setFilter(emptyFilterDescriptor);
    const [selectedState, setSelectedState] = React.useState<SelectedState>({});
    const clearSelection = () => setSelectedState({});

    const startAddingNewFolder = () => {
        stateActions.startAddingNew();
        dispatch(setName("New folder name"));
    };
    const startEditingFileOrFolder = () => {
        stateActions.startEditing(dataItem.id, dataItem.type);
        dispatch(setName(dataItem.label));
    };

    const canSetFavorites = hasUserOrgPermission(
        organisationId,
        PermissionConstants.OrgFilesFolder.favorite,
    ).hasPermission;

    const onSetFavorite = () => {
        setFavorite({
            organisationId: organisationId,
            folderId: folderId,
            isFavorite: !folder.isFavorite,
        });
    };

    const getNameChangeMutation = (): Promise<unknown> => {
        switch (state.mode) {
            case "AddNew": {
                return addNewFolder({
                    organisationId,
                    folderName: fileOrFolderName,
                    folderId: folderId,
                });
            }
            case "EditExisting": {
                if (
                    !isNewNameDifferent(folder, {
                        id: state.id,
                        name: fileOrFolderName,
                    })
                ) {
                    return null;
                } else if (state.type === EntryType.Folder) {
                    return changeFolderName({
                        folderId: state.id,
                        parentFolderId: folderId,
                        organisationId,
                        folderName: fileOrFolderName,
                    });
                } else {
                    return changeFileName({
                        fileId: state.id,
                        folderId: folderId,
                        organisationId,
                        fileName: fileOrFolderName,
                    });
                }
            }
            default:
                return null;
        }
    };

    const submitFileOrFolderNameChange = (): void => {
        getNameChangeMutation()?.then(clearFilter);
        stateActions.goBackToDefaultMode();
    };

    const cancelNameChange = (): void => {
        stateActions.goBackToDefaultMode();
    };

    const entries = mapToViewEntries(folder, state, selectedState);
    const selectedEntries = getSelectedViewEntries(entries);

    return (
        <NameChangeContextProvider
            submitNameChange={submitFileOrFolderNameChange}
            cancelNameChange={cancelNameChange}
        >
            <div className="pt-4">
                <DirectoryBreadcrumb
                    path={folder?.path || []}
                    activeId={folderId}
                />
            </div>
            {folder && (
                <FolderTags
                    folder={folder}
                    folderId={folderId}
                    organisationId={organisationId}
                />
            )}
            <div className="pt-4">
                <ButtonToolbar>
                    <ButtonGroup className="mr-2">
                        <CreateFolderButton
                            organisationId={organisationId}
                            buttonProps={{
                                onClick:
                                    state.mode === "Default"
                                        ? startAddingNewFolder
                                        : undefined,
                                disabled: state.mode !== "Default",
                            }}
                        />
                        <FileUploadButton
                            organisationId={organisationId}
                            folderId={folderId}
                            uploadFile={uploadFile}
                        />
                        {canCreateFiles &&
                            canCreateFolders &&
                            canUpdateFiles &&
                            canUpdateFolders &&
                            canReplaceFiles && (
                                <FolderUpload
                                    organisationId={organisationId}
                                    folderId={folderId}
                                />
                            )}
                    </ButtonGroup>
                    <ButtonGroup>
                        <MoveSelectedEntriesButton
                            selectedFiles={selectedEntries.fileEntries}
                            selectedFolders={selectedEntries.folderEntries}
                            organisationId={organisationId}
                        />
                    </ButtonGroup>
                    {folder && canSetFavorites && (
                        <ButtonGroup className="ml-auto">
                            <IconButton
                                icon={folder.isFavorite ? FaStar : FaRegStar}
                                onClick={onSetFavorite}
                                iconSize="20"
                            />
                        </ButtonGroup>
                    )}
                </ButtonToolbar>
            </div>
            <FileOrFolderContextMenu
                folderId={folderId}
                dataItem={dataItem}
                offset={offset}
                close={closeContextMenu}
                canDeleteFiles={canDeleteFiles}
                canUpdateFiles={canUpdateFiles}
                canMoveFiles={canMoveFiles}
                canDeleteFolders={canDeleteFolders}
                canUpdateFolders={canUpdateFolders}
                canMoveFolders={canMoveFolders}
                onEditClick={startEditingFileOrFolder}
                onDeleteFolderClick={() =>
                    deleteFolder({
                        organisationId,
                        folderId: dataItem.id,
                        parentFolderId: folderId,
                    })
                }
                onDeleteFileClick={() =>
                    deleteFile({
                        organisationId,
                        folderId,
                        fileId: dataItem.id,
                    })
                }
                onDownloadClick={async () =>
                    downloadFile({
                        fileId: dataItem.id,
                        fileName: dataItem.label,
                        folderId,
                        organisationId,
                    })
                }
            />
            <div className="pt-4" ref={gridContainerRef}>
                <QueryResultStatus queryResult={folderQueryResult} />
                <FileAndFoldersGrid
                    entries={entries}
                    additionalColumns={{
                        dateModified: isAboveLg,
                        modifiedBy: isAboveLg,
                        menu: true,
                    }}
                    filterState={[filter, setFilter]}
                    filterByContains
                    rowRender={rowRender}
                    onRowDoubleClick={navigateToSubFolderOrFile(
                        dispatch,
                        orgShortName,
                        folderId,
                        clearSelection,
                    )}
                    selectedStateAccessors={[selectedState, setSelectedState]}
                />
            </div>
            <MultiMutationResultStatus
                results={[
                    addNewFolderResult,
                    changeFolderNameResult,
                    changeFileNameResult,
                    deleteFolderResult,
                    uploadFileResult,
                    deleteFileResult,
                ]}
            />
            <MoveCoordinator folderId={folderId} />
        </NameChangeContextProvider>
    );
};

export default FolderContents;
