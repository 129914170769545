import * as React from "react";
import { Tab, Tabs } from "react-bootstrap";
import AuditActionsList from "../../../common/components/audit/AuditActionsList";
import { useOrgTitleWithShortName } from "../../../common/hooks/useTitle";
import { useGetProcessByIdQuery } from "../../../store/features/process/process-api-slice";
import AssetLinkTab from "../../process-activity/components/AssetLinkTab";
import ProcessActivityDetailsForm from "../../process-activity/components/ProcessActivityDetailsForm";
import ProcessActivityWrapper from "../../process-activity/components/ProcessActivityWrapper";
import { ProcessActivityExtensions } from "../../process-activity/domain/ProcessActivityExtensions";
import { ProcessActivityType } from "../../process-activity/domain/types";
import { resolveActivityState } from "../../process-activity/helpers/activityStateHelpers";
import { formatActivityPageTitle } from "../../process-activity/pages/pageTitles";
import ProcessActivityFilesSection from "../../processActivityFile/ProcessActivityFilesSection";
import {
    useGetProcessActivityListByIdQuery,
    usePatchProcessActivityListMutation,
    usePostProcessActivityListCompleteMutation,
    usePostProcessActivityListNotApplicableMutation,
    usePostProcessActivityListOpenMutation,
} from "../store/processActivityListApiSlice";
import ProcessActivityListList from "./ProcessActivityListList";

interface Props {
    orgShortName: string;
    processId: string;
    activityId: string;
}

const ProcessActivityListWrapper: React.FC<Props> = ({
    orgShortName,
    processId,
    activityId,
}) => {
    const { data: process, ...processResult } = useGetProcessByIdQuery(
        processId,
        { skip: !processId },
    );
    const { data: activityList, ...activityListResult } =
        useGetProcessActivityListByIdQuery(
            { activityId, processId },
            {
                skip: !activityId,
            },
        );

    useOrgTitleWithShortName(
        formatActivityPageTitle(
            process?.name,
            activityList?.displayName,
            !process || !activityList,
        ),
    );

    const [completeList, completeListResult] =
        usePostProcessActivityListCompleteMutation();
    const [setListNotApplicable, notApplicableListResult] =
        usePostProcessActivityListNotApplicableMutation();
    const [openList, openListResult] = usePostProcessActivityListOpenMutation();
    const [updateList, updateListResult] =
        usePatchProcessActivityListMutation();

    const { canEdit } = resolveActivityState(activityList);
    const assetLinkEnabled = activityList?.assetLink?.enabled;
    const canDisplayOptions =
        ProcessActivityExtensions.canDisplayOptions(activityList);

    return (
        <ProcessActivityWrapper
            processQuery={[process, processResult]}
            activityQuery={[activityList, activityListResult]}
            activityActions={{
                complete: {
                    actionCallback: completeList,
                    actionResult: completeListResult,
                },
                notApplicable: {
                    actionCallback: setListNotApplicable,
                    actionResult: notApplicableListResult,
                },
                open: {
                    actionCallback: openList,
                    actionResult: openListResult,
                },
            }}
            orgShortName={orgShortName}
        >
            {process && activityList && (
                <Tabs defaultActiveKey="content" id="tab">
                    <Tab eventKey="content" title="Activities">
                        <ProcessActivityListList
                            activity={activityList}
                            orgShortName={orgShortName}
                        />
                    </Tab>
                    {canDisplayOptions && (
                        <Tab eventKey="details" title="Options">
                            <ProcessActivityDetailsForm
                                organisationId={process.organisationId}
                                activity={activityList}
                                updateProps={{
                                    actionCallback: updateList,
                                    actionResult: updateListResult,
                                }}
                            />
                        </Tab>
                    )}
                    {assetLinkEnabled && (
                        <Tab eventKey="asset" title="Assets">
                            <AssetLinkTab
                                orgShortName={orgShortName}
                                activity={activityList}
                            />
                        </Tab>
                    )}
                    <Tab eventKey="history" title="History">
                        <AuditActionsList
                            auditActions={activityList.auditActions ?? []}
                        />
                    </Tab>
                    {activityList.fileManagementTabEnabled && (
                        <Tab eventKey="files" title="Files">
                            <ProcessActivityFilesSection
                                paFiles={activityList.files}
                                activityId={activityList.id}
                                paType={ProcessActivityType.List}
                                readOnly={!canEdit}
                            />
                        </Tab>
                    )}
                </Tabs>
            )}
        </ProcessActivityWrapper>
    );
};

export default ProcessActivityListWrapper;
