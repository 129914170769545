import { wrapTagsWithQueriesInvalidation } from "../../../query/configureQuery";
import { dcpApi, Tags } from "../../../store/features/dcpApi";
import {
    ActivityActionParams,
    PostNotApplicableParams,
    ProcessActivityPatchModel,
} from "../../../store/features/process-activity/types";
import { missingCarrsExamQueryKeysTranslator } from "../../cefa/exam-tools/missing-carrs-exam/queries/missingCarrsExamQueryKeysTranslator";
import { examinationQueryKeysTranslator } from "../../cefa/examinations/query/examinationQueryKeysTranslator";
import { ProcessActivity } from "../../process-activity/domain/types";
import { createActivityTags } from "../../process-activity/store/activityTag";
import { ProcessActivityForm } from "../domain/types";
import { InvalidateTag } from "./../../../store/features/dcpApi";

enum UrlPaths {
    ResourceName = "/processactivityform",
    Complete = "/complete",
    NotApplicable = "/not-applicable",
    Open = "/open",
    Form = "/form",
    Content = "/content",
}

const activityFormTags = (
    activity: ProcessActivity,
    otherTags?: { type: Tags; id?: string }[],
) => createActivityTags(activity, otherTags);

const { tagsToQueryKeys: tagsToExaminationQueryKeys } =
    examinationQueryKeysTranslator;

const { tagsToQueryKeys: tagsToMissingCarrsExamQueryKeys } =
    missingCarrsExamQueryKeysTranslator;

export const processActivityFormApi = dcpApi.injectEndpoints({
    endpoints: (builder) => ({
        getProcessActivityFormById: builder.query<
            ProcessActivityForm,
            { activityId: string; processId: string }
        >({
            query: ({ activityId }) => `${UrlPaths.ResourceName}/${activityId}`,
            providesTags: (result, error, arg) => [
                { type: Tags.ProcessActivity, id: arg.activityId },
                { type: Tags.ProcessActivitiesForProcess, id: arg.processId },
            ],
        }),
        postProcessActivityFormComplete: builder.mutation<
            ProcessActivityForm,
            ActivityActionParams
        >({
            query: ({ activity }) => ({
                url:
                    `${UrlPaths.ResourceName}/${activity.id}` +
                    UrlPaths.Complete,
                method: "POST",
            }),
            invalidatesTags: (result, error, { activity }) =>
                error
                    ? []
                    : activityFormTags(activity, [
                          {
                              type: Tags.ProcessAssignedList,
                          },
                      ]),
        }),
        postProcessActivityFormNotApplicable: builder.mutation<
            void,
            PostNotApplicableParams
        >({
            query: ({ activity, comment, reason }) => ({
                url: `${UrlPaths.ResourceName}/${activity.id}${UrlPaths.NotApplicable}`,
                method: "POST",
                body: { comment, reason },
            }),
            invalidatesTags: (result, error, { activity }) =>
                error ? [] : activityFormTags(activity),
        }),
        postProcessActivityFormOpen: builder.mutation<
            ProcessActivityForm,
            ActivityActionParams
        >({
            query: ({ activity }) => ({
                url: `${UrlPaths.ResourceName}/${activity.id}` + UrlPaths.Open,
                method: "POST",
            }),
            invalidatesTags: (result, error, { activity }) =>
                error ? [] : activityFormTags(activity),
        }),
        postProcessActivityFormUpdateContent: builder.mutation<
            boolean,
            {
                activityId: string;
                formData: FormData;
                shouldInvalidateActivity: boolean;
            }
        >({
            query: ({ activityId, formData }) => ({
                url:
                    `${UrlPaths.ResourceName}/${activityId}` + UrlPaths.Content,
                method: "POST",
                body: formData,
            }),
            invalidatesTags: (
                result,
                error,
                { activityId, shouldInvalidateActivity },
            ) =>
                shouldInvalidateActivity
                    ? [
                          { type: Tags.ProcessActivity, id: activityId },
                          { type: Tags.ProcessActivities },
                          { type: Tags.BatchExams },
                      ]
                    : [{ type: Tags.BatchExams }],
        }),
        patchProcessActivityForm: builder.mutation<
            ProcessActivityForm,
            ProcessActivityPatchModel
        >({
            query: (patchModel) => ({
                url: `${UrlPaths.ResourceName}/${patchModel.activityId}`,
                method: "PATCH",
                body: patchModel,
            }),
            invalidatesTags: (_result, _error, params) =>
                wrapTagsWithQueriesInvalidation(
                    [
                        { type: Tags.ProcessActivity, id: params.activityId },
                        { type: Tags.Process, id: params.processId },
                        { type: Tags.ProcessAssignedList },
                        ...(params.assetIds
                            ? params.assetIds
                                  .map(
                                      (assetId): InvalidateTag => ({
                                          type: Tags.Asset,
                                          id: assetId,
                                      }),
                                  )
                                  .concat({ type: Tags.TrackingObjects })
                            : []),
                    ],
                    [
                        tagsToExaminationQueryKeys,
                        tagsToMissingCarrsExamQueryKeys,
                    ],
                ),
        }),
    }),
});

export const {
    usePostProcessActivityFormCompleteMutation,
    usePostProcessActivityFormNotApplicableMutation,
    usePostProcessActivityFormOpenMutation,
    usePostProcessActivityFormUpdateContentMutation,
    useGetProcessActivityFormByIdQuery,
    usePatchProcessActivityFormMutation,
} = processActivityFormApi;
