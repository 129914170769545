import * as React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import ReportService from "../../../services/ReportService";
import * as ReportStore from "../../../store/report/store";
import { formatIsoDate } from "../../../helpers/dateTimeHelpers";

const GeneratedReportListItem: React.FC<{
    reportId: string;
    reportTemplateId: string;
    fileName: string;
    performedBy: string;
    performedAt: string;
}> = (props) => {
    const dispatch = useDispatch();

    const deleteClick = (): void => {
        if (window.confirm("Are you sure you want to delete this form?")) {
            dispatch(
                ReportStore.actionCreators.deleteReport(
                    props.reportTemplateId,
                    props.reportId,
                ),
            );
        }
    };

    return (
        <li>
            <Row>
                <Col>
                    <p>{formatIsoDate(props.performedAt)}</p>
                    <p>Generated by {props.performedBy}</p>
                </Col>
                <Col>
                    <div className="float-right">
                        <Button
                            onClick={(): void => {
                                ReportService.DownloadReport(
                                    props.reportTemplateId,
                                    props.reportId,
                                    props.fileName,
                                ).catch(() => {
                                    console.error("failed to generate file");
                                });
                            }}
                        >
                            Download
                        </Button>
                        <Button
                            onClick={deleteClick}
                            className="ml-1"
                            variant="danger"
                        >
                            Delete
                        </Button>
                    </div>
                </Col>
            </Row>
        </li>
    );
};

export default GeneratedReportListItem;
