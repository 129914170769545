import * as React from "react";
import { Tab, Tabs } from "react-bootstrap";
import AuditActionsList from "../../../common/components/audit/AuditActionsList";
import { useOrgTitleWithShortName } from "../../../common/hooks/useTitle";
import { useGetProcessByIdQuery } from "../../../store/features/process/process-api-slice";
import AssetLinkTab from "../../process-activity/components/AssetLinkTab";
import ProcessActivityDetailsForm from "../../process-activity/components/ProcessActivityDetailsForm";
import ProcessActivityWrapper from "../../process-activity/components/ProcessActivityWrapper";
import { ProcessActivityExtensions } from "../../process-activity/domain/ProcessActivityExtensions";
import { ProcessActivityType } from "../../process-activity/domain/types";
import { resolveActivityState } from "../../process-activity/helpers/activityStateHelpers";
import { formatActivityPageTitle } from "../../process-activity/pages/pageTitles";
import ProcessActivityFilesSection from "../../processActivityFile/ProcessActivityFilesSection";
import {
    useGetProcessActivityGroupByIdQuery,
    usePatchProcessActivityGroupMutation,
    usePostProcessActivityGroupCompleteMutation,
    usePostProcessActivityGroupNotApplicableMutation,
    usePostProcessActivityGroupOpenMutation,
} from "../store/processActivityGroupApiSlice";
import ProcessActivityGroupActivityList from "./ProcessActivityGroupActivityList";

interface Props {
    orgShortName: string;
    processId: string;
    activityId: string;
}

const ProcessActivityGroupWrapper: React.FC<Props> = ({
    orgShortName,
    processId,
    activityId,
}) => {
    const { data: process, ...processResult } = useGetProcessByIdQuery(
        processId,
        { skip: !processId },
    );
    const { data: activityGroup, ...activityGroupResult } =
        useGetProcessActivityGroupByIdQuery(
            { activityId, processId },
            {
                skip: !activityId,
            },
        );

    useOrgTitleWithShortName(
        formatActivityPageTitle(
            process?.name,
            activityGroup?.displayName,
            !process || !activityGroup,
        ),
    );

    const [completeGroup, completeGroupResult] =
        usePostProcessActivityGroupCompleteMutation();
    const [setGroupNotApplicable, notApplicableGroupResult] =
        usePostProcessActivityGroupNotApplicableMutation();
    const [openGroup, openGroupResult] =
        usePostProcessActivityGroupOpenMutation();
    const [updateGroup, updateGroupResult] =
        usePatchProcessActivityGroupMutation();

    const { canEdit } = resolveActivityState(activityGroup);
    const assetLinkEnabled = activityGroup?.assetLink?.enabled;
    const canDisplayOptions =
        ProcessActivityExtensions.canDisplayOptions(activityGroup);

    return (
        <ProcessActivityWrapper
            processQuery={[process, processResult]}
            activityQuery={[activityGroup, activityGroupResult]}
            activityActions={{
                complete: {
                    actionCallback: completeGroup,
                    actionResult: completeGroupResult,
                },
                notApplicable: {
                    actionCallback: setGroupNotApplicable,
                    actionResult: notApplicableGroupResult,
                },
                open: {
                    actionCallback: openGroup,
                    actionResult: openGroupResult,
                },
            }}
            orgShortName={orgShortName}
        >
            {process && activityGroup && (
                <Tabs defaultActiveKey="content" id="tab">
                    <Tab eventKey="content" title="Activities">
                        <ProcessActivityGroupActivityList
                            activity={activityGroup}
                            orgShortName={orgShortName}
                        />
                    </Tab>
                    {canDisplayOptions && (
                        <Tab eventKey="details" title="Options">
                            <ProcessActivityDetailsForm
                                organisationId={process.organisationId}
                                activity={activityGroup}
                                updateProps={{
                                    actionCallback: updateGroup,
                                    actionResult: updateGroupResult,
                                }}
                            />
                        </Tab>
                    )}
                    {assetLinkEnabled && (
                        <Tab eventKey="asset" title="Assets">
                            <AssetLinkTab
                                orgShortName={orgShortName}
                                activity={activityGroup}
                            />
                        </Tab>
                    )}
                    <Tab eventKey="history" title="History">
                        <AuditActionsList
                            auditActions={activityGroup.auditActions ?? []}
                        />
                    </Tab>
                    {activityGroup.fileManagementTabEnabled && (
                        <Tab eventKey="files" title="Files">
                            <ProcessActivityFilesSection
                                paFiles={activityGroup.files}
                                activityId={activityGroup.id}
                                paType={ProcessActivityType.Group}
                                readOnly={!canEdit}
                            />
                        </Tab>
                    )}
                </Tabs>
            )}
        </ProcessActivityWrapper>
    );
};

export default ProcessActivityGroupWrapper;
