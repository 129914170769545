import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import * as React from "react";
import { Button } from "react-bootstrap";
import CoveringLoadingPanel from "../../../../../common/components/CoveringLoadingPanel";
import { SimplifiedMenuFilter } from "../../../../../common/components/table/kendo/columnFilters";
import useGridProps from "../../../../../common/components/table/kendo/useGridProps";
import { GridSelectionMode } from "../../../../../common/components/table/kendo/useGridSelectableProps";
import { ApprovedExam } from "../domain/approvedExam";
import { useGetApprovedExamsQuery } from "../queries/approvedExamsQueries";
import ReturnReportDialog from "./ReturnReportDialog";

type Props = {
    organisationId: string;
};

const ApprovedExamsGrid: React.FC<Props> = ({ organisationId }) => {
    const [showReturnProcessDialog, setShowReturnProcessDialog] =
        React.useState(false);
    const [selectedProcess, setSelectedProcess] =
        React.useState<ApprovedExam>(null);

    const { data, isLoading } = useGetApprovedExamsQuery(organisationId);

    const { gridProps } = useGridProps(data, {
        selectable: {
            config: {
                enabled: true,
                mode: GridSelectionMode.Single,
            },
            onSelectChange: (newSelected) => {
                setSelectedProcess(newSelected[0]);
            },
        },
    });

    if (isLoading) return <CoveringLoadingPanel />;

    return (
        <>
            <Grid {...gridProps} className="mt-3">
                <GridToolbar>
                    <Button
                        variant="primary"
                        onClick={() => setShowReturnProcessDialog(true)}
                        disabled={!selectedProcess?.id}
                    >
                        Return to Engineer
                    </Button>
                </GridToolbar>
                <GridColumn
                    field="name"
                    title="Name"
                    columnMenu={SimplifiedMenuFilter}
                />
                <GridColumn
                    field="state"
                    title="State"
                    columnMenu={SimplifiedMenuFilter}
                />
                <GridColumn
                    field="type"
                    title="Type"
                    columnMenu={SimplifiedMenuFilter}
                />
                <GridColumn
                    field="carrsExamId"
                    title="Carrs Exam Id"
                    columnMenu={SimplifiedMenuFilter}
                />
            </Grid>
            {showReturnProcessDialog && (
                <ReturnReportDialog
                    onClose={() => setShowReturnProcessDialog(false)}
                    organisationId={organisationId}
                    processId={selectedProcess.id}
                />
            )}
        </>
    );
};

export default ApprovedExamsGrid;
