import React from "react";
import { Nav } from "react-bootstrap";
import { FaCogs } from "react-icons/fa";
import { NavLink, Route } from "react-router-dom";
import PermissionConstants from "../../../common/permissions/PermissionConstants";
import OrganisationConstants from "../../../Constants/OrganisationConstants";
import { hasUserOrgPermission } from "../../../store/features/user/user-api-slice";
import { OrganisationSimple } from "../../organisation/domain/types";
import MyAssignedMenuItem from "./MyAssignedMenuItem";

const ProcessesMenu: React.FC<{ organisation: OrganisationSimple }> = ({
    organisation,
}) => {
    const permission = hasUserOrgPermission(
        organisation.id,
        PermissionConstants.OrgProcess.read,
    ).hasPermission;

    if (permission) {
        return (
            <>
                <Nav.Item>
                    <NavLink
                        to={`/${organisation.shortName}${OrganisationConstants.Process}`}
                        className="d-flex align-items-center nav-link"
                        exact
                        key="processes"
                    >
                        <FaCogs size="16" className="menu-icon" />
                        Processes
                    </NavLink>
                </Nav.Item>
                <Route
                    path={`/${organisation.shortName}${OrganisationConstants.Process}`}
                >
                    <MyAssignedMenuItem organisation={organisation} />
                </Route>
            </>
        );
    } else {
        return null;
    }
};

export default ProcessesMenu;
