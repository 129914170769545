import * as React from "react";
import { ApplicationState } from "../../../store";
import { useDispatch, useSelector } from "react-redux";
import * as Store from "../../../store/report/store";
import { Button, Col, Row } from "react-bootstrap";
import GeneratedReportList from "./GeneratedReportList";
import ReportService from "../../../services/ReportService";

const getReportTemplateId = (state: ApplicationState): string =>
    state.report?.domain.reportTemplate.id ?? "";

const OrgReportItemDetails: React.FC = () => {
    const dispatch = useDispatch();
    const reportTemplateId = useSelector(getReportTemplateId);

    const updateSuccessful = (): void => {
        dispatch(Store.actionCreators.initEdit(reportTemplateId));
    };

    return (
        <>
            <Row className="top30">
                <Col>
                    <Button
                        onClick={(): void => {
                            ReportService.Generate(reportTemplateId)
                                .then(() => {
                                    updateSuccessful();
                                })
                                .catch(() => {
                                    console.error("failed to generate file");
                                });
                        }}
                        variant="primary"
                    >
                        Generate New File
                    </Button>
                </Col>
            </Row>
            <Row className="top10">
                <Col>
                    <GeneratedReportList />
                </Col>
            </Row>
        </>
    );
};
export default OrgReportItemDetails;
