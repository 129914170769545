import * as React from "react";
import { FileButton } from "../../../common/components/button/FileButton";
import PermissionConstants from "../../../common/permissions/PermissionConstants";
import { UploadFileParams } from "../../../store/features/file/fileApiSlice";
import { hasUserOrgPermission } from "../../../store/features/user/user-api-slice";

interface FileUploadButtonProps {
    organisationId: string;
    folderId: string;
    uploadFile: (params: UploadFileParams) => void;
}

export const FileUploadButton: React.FC<FileUploadButtonProps> = ({
    organisationId,
    folderId,
    uploadFile,
}) => {
    const canCreate = hasUserOrgPermission(
        organisationId,
        PermissionConstants.OrgFilesFile.create,
    ).hasPermission;
    const handleFileSelection = (ev: React.ChangeEvent<HTMLInputElement>) => {
        uploadFile({ organisationId, folderId, files: ev.target.files });
    };

    return canCreate ? (
        <FileButton onChange={handleFileSelection} multiple={true}>
            Upload file
        </FileButton>
    ) : null;
};
