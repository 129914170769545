import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import OrganisationConstants from "../../../Constants/OrganisationConstants";
import { OrganisationListItem } from "../domain/organisation";

interface Props {
    organisation: OrganisationListItem;
}

export const OrganisationRowItem: React.FC<Props> = ({ organisation }) => {
    return (
        <tr>
            <td>{organisation.name}</td>
            <td>{organisation.description}</td>
            <td>
                <Button
                    as={Link}
                    variant="primary"
                    to={`${organisation.shortName}`}
                    className="top5 ml-1"
                >
                    View
                </Button>
                {organisation.canManage && (
                    <Button
                        variant="secondary"
                        as={Link}
                        to={
                            OrganisationConstants.RoutePath +
                            "/" +
                            organisation.id
                        }
                        className="top5 ml-1"
                    >
                        Manage
                    </Button>
                )}
            </td>
        </tr>
    );
};
