import { GridFieldsMap } from "../../../../../common/components/table/kendo/GridFieldsMap";
import {
    AssetInfoContentFieldNames,
    PlanningInfoContentFieldNames,
} from "../../../constants/ProcessActivityContentFieldsConsts";

export const examinationReviewsGridFieldsMap: GridFieldsMap = {
    contractYear: {
        field: PlanningInfoContentFieldNames.ContractYear,
        label: "Contract Year",
        dataType: "string",
    },
    elr: {
        field: AssetInfoContentFieldNames.Elr,
        label: "ELR",
        dataType: "string",
    },
    miles: {
        field: AssetInfoContentFieldNames.Miles,
        label: "Miles",
        dataType: "string",
    },
    yards: {
        field: AssetInfoContentFieldNames.Yards,
        label: "Yards",
        dataType: "string",
    },
    chains: {
        field: AssetInfoContentFieldNames.Chains,
        label: "Chains",
        dataType: "string",
    },
    structureName: {
        field: "structureName",
        label: "Structure Name",
        dataType: "string",
    },
    structureReferenceNumber: {
        field: "structureReferenceNumber",
        label: "Structure Reference Number",
        dataType: "string",
    },
    examType: {
        field: "examType",
        label: "Exam Type",
        dataType: "string",
    },
    examinationDate: {
        field: "examinationDate",
        label: "Examination Date",
        dataType: "Date",
    },
    defectsTracked: {
        field: "defectsTracked",
        label: "Defects Tracked",
        dataType: "string",
    },
    assignedExaminer: {
        field: "assignedExaminer",
        label: "Assigned Examiner",
        dataType: "string",
    },
    assignedEngineer: {
        field: "assignedEngineer",
        label: "Assigned Engineer",
        dataType: "string",
    },
    assetType: {
        field: "assetType",
        label: "Asset Type",
        dataType: "string",
    },
    daysSinceExamDate: {
        field: "daysSinceExamDate",
        label: "Days Since Exam",
        dataType: "number",
    },
    state: {
        field: "state",
        label: "State",
        dataType: "string",
    },
};
