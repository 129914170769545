import * as React from "react";
import { Row, Col } from "react-bootstrap";
import AddUserForm from "../components/AddUserForm";
import UserTable from "../components/UsersTable";
import PermissionConstants from "../../../common/permissions/PermissionConstants";
import { useOrganisationId } from "../../organisation/hooks/useOrganisationId";
import useOrgPermission from "../../../common/hooks/useOrgPermission";
import UserManagmentPageTitles from "./pageTitles";
import { useOrgTitleById } from "../../../common/hooks/useTitle";

const userAddPermissions = [
    PermissionConstants.OrgUser.create,
    PermissionConstants.OrgUserGroup.read,
    PermissionConstants.OrgGroupUsers.manage,
];

const UsersManagementPage: React.FC = () => {
    const { organisationId } = useOrganisationId();
    const canAdd = useOrgPermission(userAddPermissions);

    useOrgTitleById(organisationId, UserManagmentPageTitles.UserList);

    return (
        <>
            <h1 className="top30">Users</h1>
            <Row>
                <Col>
                    {canAdd && (
                        <Row className="mt-3">
                            <Col>
                                <AddUserForm organisationId={organisationId} />
                            </Col>
                        </Row>
                    )}
                    <Row className="mt-3">
                        <Col>
                            <UserTable organisationId={organisationId} />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
};

export default UsersManagementPage;
