import { Grid, GridColumn } from "@progress/kendo-react-grid";
import * as React from "react";
import { ColumnMenuFilter, getColumnMenuCheckboxFilter } from "./columnFilters";
import { IdAndName } from "../../../types/IdAndName";
import { GridProps } from "@progress/kendo-react-grid/dist/npm/interfaces/GridProps";
import useGridProps from "./useGridProps";

type ColumnsFactoryParameters = {
    columnMenuCheckboxFilter: ReturnType<typeof getColumnMenuCheckboxFilter>;
};

type Props = {
    children: (params: ColumnsFactoryParameters) => JSX.Element[];
    data: IdAndName[];
    onRowDoubleClick?: GridProps["onRowDoubleClick"];
};

export const GridOfIdAndName: React.FC<Props> = ({
    children,
    data,
    onRowDoubleClick,
}) => {
    const { gridProps } = useGridProps(data, {
        filterable: true,
        sortable: true,
        pageable: true,
    });

    const columnMenuCheckboxFilter = getColumnMenuCheckboxFilter(data);

    return (
        <Grid
            {...gridProps}
            onRowDoubleClick={onRowDoubleClick}
            className="mt-3"
        >
            <GridColumn
                field="name"
                title="Name"
                width="auto"
                sortable
                columnMenu={ColumnMenuFilter}
            />
            {children({ columnMenuCheckboxFilter })}
        </Grid>
    );
};
