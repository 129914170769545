import * as React from "react";
import { Breadcrumb } from "react-bootstrap";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { IdAndName } from "../../../common/types/IdAndName";
import OrganisationConstants from "../../../Constants/OrganisationConstants";
import { FilesRouteParams } from "../viewModel/FilesRouteParams";
import "./directory-breadcrumb.css";

interface DirectoryBreadcrumbProps {
    path: IdAndName[];
    activeId: string;
    onFolderClick?: (folderId: string) => void;
}

interface BreadcrumbItemProps {
    active: boolean;
    linkTo: string;
    onClick: (() => void) | null;
    children?: React.ReactNode;
}

const BreadcrumbItem: React.FC<BreadcrumbItemProps> = ({
    active,
    linkTo,
    onClick,
    children,
}) => {
    const suppliedClick = (
        <a
            href="#"
            onClick={(ev) => {
                ev.preventDefault();
                onClick();
            }}
        >
            {children}
        </a>
    );

    const suppliedOrDefault = onClick ? (
        suppliedClick
    ) : (
        <Link to={linkTo}>{children}</Link>
    );

    const content = active ? children : suppliedOrDefault;

    return (
        <Breadcrumb.Item active={active} linkAs={"span"}>
            {content}
        </Breadcrumb.Item>
    );
};

export const DirectoryBreadcrumb: React.FC<DirectoryBreadcrumbProps> = ({
    path,
    activeId,
    onFolderClick,
}) => {
    const { orgShortName } = useParams<FilesRouteParams>();
    const { folder } = OrganisationConstants;
    const orgFiles = `/${orgShortName}/${folder}`;

    return (
        <Breadcrumb className="margin-0">
            {path.map(({ id, name }) => (
                <BreadcrumbItem
                    key={id}
                    active={id === activeId}
                    linkTo={`${orgFiles}/${id}`}
                    onClick={
                        onFolderClick === null ? null : () => onFolderClick(id)
                    }
                >
                    {name}
                </BreadcrumbItem>
            ))}
        </Breadcrumb>
    );
};
