import React from "react";
import { FaClipboard } from "react-icons/fa";
import NavSubMenuItem from "../../../common/components/navigation-sidebar/NavSubMenuItem";
import PermissionConstants from "../../../common/permissions/PermissionConstants";
import OrganisationConstants from "../../../Constants/OrganisationConstants";
import { hasUserOrgPermission } from "../../../store/features/user/user-api-slice";
import { OrganisationSimple } from "../../organisation/domain/types";

const MyAssignedMenuItem: React.FC<{ organisation: OrganisationSimple }> = ({
    organisation,
}) => {
    const permission = hasUserOrgPermission(
        organisation.id,
        PermissionConstants.OrgMyAssigned,
    ).hasPermission;

    if (permission) {
        return (
            <>
                <NavSubMenuItem
                    key={"myassigned"}
                    label={"My Assigned"}
                    to={`/${organisation.shortName}${OrganisationConstants.Process}/myassigned`}
                    icon={FaClipboard}
                />
                <hr className="m-0" />
            </>
        );
    } else {
        return null;
    }
};

export default MyAssignedMenuItem;
