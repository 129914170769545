import { useField, useFormikContext } from "formik";
import * as React from "react";
import { Button, Form } from "react-bootstrap";
import { ShowCondition } from "../../../modules/template/domain/types";
import FormControlBase from "./FormControlBase";
import FormPromptComponent from "./FormPromptComponent";

interface FormControlLocationConfig {
    field: string;
    label: string;
    style: any;
    prompt: string;
    uri: string;
    isReadOnly: boolean;
    showConditions: ShowCondition[];
}
interface Props {
    config: FormControlLocationConfig;
}

const FormControlLocation: React.FC<Props> = (props) => {
    const { config } = props;
    const [isGpsLoading, setGpsLoading] = React.useState(false);
    const [latField, updateLatField] = React.useState("");
    const [lgtField, updateLgtField] = React.useState("");
    const [isGeoAvailable, setGeoAvailable] = React.useState(false);
    const formik = useFormikContext();

    const [, , coordsHelpers] = useField(config.field);

    React.useEffect(() => {
        if (latField.length === 0 && lgtField.length === 0) {
            //case for when user wants to remove location completely
            coordsHelpers.setValue("");
        }
        const latRegex =
            /^[+-]?(?:90(?:(?:\.0{1,6})?)|(?:\d|[1-8]\d)(?:(?:\.\d{1,6})?))$/;
        if (latRegex.test(latField) === false) {
            coordsHelpers.setError("Wrong latitude value");
            return;
        }

        const lngRegex =
            /^[+-]?(?:180(?:(?:\.0{1,6})?)|(?:\d|[1-9]\d|1[0-7]\d)(?:(?:\.\d{1,6})?))$/;
        if (lngRegex.test(lgtField) === false) {
            coordsHelpers.setError("Wrong longitude value");
            return;
        }

        coordsHelpers.setValue(`${latField},${lgtField}`);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lgtField, latField]);

    React.useEffect(() => {
        const value = (formik.values[config.field] as string) ?? "";
        if (value !== "") {
            const splittedValue = value.split(",");
            updateLatField(splittedValue[0]);
            updateLgtField(splittedValue[1]);
        }

        if ("geolocation" in navigator) setGeoAvailable(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const useGps = () => {
        setGpsLoading(true);
        const options = {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0,
        };

        navigator.geolocation.getCurrentPosition(
            (position) => {
                updateLatField(position.coords.latitude.toFixed(6));
                updateLgtField(position.coords.longitude.toFixed(6));
            },
            (err) => {
                alert(
                    "Failed getting your current location. See console for details.",
                );
                console.warn(err);
            },
            options,
        );

        setGpsLoading(false);
    };

    return (
        <>
            <FormControlBase
                contentConfig={{
                    field: config.field,
                    showConditions: config.showConditions,
                }}
            >
                <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                >
                    <Form.Label htmlFor={config.field}>
                        {config.label}
                    </Form.Label>
                    {isGpsLoading ? (
                        <Button variant="info" disabled={true}>
                            Loading...
                        </Button>
                    ) : (
                        <Button
                            variant="info"
                            disabled={!isGeoAvailable}
                            onClick={useGps}
                        >
                            Use GPS
                        </Button>
                    )}
                </div>
                <br />
                <Form.Label style={{ marginTop: 10 }}>Latitude:</Form.Label>
                <Form.Control
                    type="text"
                    name="latitude"
                    onChange={(e) => updateLatField(e.target.value)}
                    onBlur={formik.handleBlur}
                    style={{ ...config.style }}
                    value={latField}
                    className={formik.errors[config.field] ? "is-invalid" : ""}
                    readOnly={config.isReadOnly}
                />
                <Form.Label style={{ marginTop: 10 }}>Longitude:</Form.Label>
                <Form.Control
                    type="text"
                    name="longitude"
                    onChange={(e) => updateLgtField(e.target.value)}
                    onBlur={formik.handleBlur}
                    style={{ ...config.style }}
                    value={lgtField}
                    className={formik.errors[config.field] ? "is-invalid" : ""}
                    readOnly={config.isReadOnly}
                />
                <FormPromptComponent config={config} />
            </FormControlBase>
        </>
    );
};

export default FormControlLocation;
