import * as AdminStore from "./admin/store";
import * as AssetGridFiltersStore from "./features/assets-grid/assetsGridSlice";
import * as AuthUserStore from "./features/auth/authUserSlice";
import * as FileOrFolderNameStore from "./features/folder/fileOrFolderNameSlice";
import * as MoveFilesAndFoldersStore from "./features/folder/moveFilesAndFoldersSlice";
import * as NavigationTabsStore from "./features/navigation-tabs/navigationTabsSlice";
import * as NotificationsStore from "./features/notifications/notificationsSlice";
import * as NotificationsMessagesStore from "./features/notificationsMessages/notificationsMessagesSlice";
import * as ProcessActivityFormStore from "./process-activity-form/store";
import * as ProcessTemplate from "./process-template/store";
import * as ReportStore from "./report/store";

// The top-level state object
export interface ApplicationState {
    processTemplate: ProcessTemplate.ProcessTemplateState | undefined;
    report: ReportStore.ReportState | undefined;
    notifications: NotificationsStore.NotificationState | undefined;
    notificationsMessages:
        | NotificationsMessagesStore.NotificationMessagesState
        | undefined;
    navigationTab: NavigationTabsStore.TabState | undefined;
    admin: AdminStore.AdminState | undefined;
    processActivityForm:
        | ProcessActivityFormStore.ProcessActivityFormState
        | undefined;
    fileOrFolderName: FileOrFolderNameStore.NameState;
    moveFilesAndFolders: MoveFilesAndFoldersStore.MoveFilesAndFoldersState;
    authUser: AuthUserStore.AuthUserState | undefined;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    processTemplate: ProcessTemplate.reducer,
    notifications: NotificationsStore.reducer,
    notificationsMessages: NotificationsMessagesStore.reducer,
    navigationTab: NavigationTabsStore.reducer,
    report: ReportStore.reducer,
    admin: AdminStore.reducer,
    processActivityForm: ProcessActivityFormStore.reducer,
    fileOrFolderName: FileOrFolderNameStore.reducer,
    moveFilesAndFolders: MoveFilesAndFoldersStore.reducer,
    authUser: AuthUserStore.reducer,
    assetGridFilters: AssetGridFiltersStore.reducer,
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
    (
        dispatch: (action: TAction) => void,
        getState: () => ApplicationState,
    ): void;
}
