import { State } from "@progress/kendo-data-query";
import { Grid, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import React from "react";
import CustomPagingFooter from "../../../../../common/components/table/kendo/CustomPagingFooter";
import { GridFieldDef } from "../../../../../common/components/table/kendo/GridFieldsMap";
import GridLoadingPanel from "../../../../../common/components/table/kendo/GridLoadingPanel";
import { resolveSortAndFilterOptions } from "../../../../../common/components/table/kendo/helpers";
import useInfiniteGridProps from "../../../../../common/components/table/kendo/useInfiniteGridProps";
import useInfiniteDataResult from "../../../../../common/hooks/useInfiniteDataResult";
import { SortAndFilterOptions } from "../../../../../common/types/SortAndFilterOptions";
import { useNavigation } from "../../../../../router/useNavigate";
import { useGetInfiniteMissingCarrsExamQuery } from "../queries/missingCarrsExamQueries";
import {
    gridFieldsMap,
    gridFilterOperators,
} from "./MissingCarrsExamInfiniteGridConfiguration";

const grisColProps = (field: GridFieldDef, index: number) => ({
    key: index,
    field: field.field,
    title: field.label || field.field,
    sortable: (field.sortable ?? true) && !field.isCustomField,
    filterable: field.filterable ?? true,
    width: 150,
});

interface Props {
    organisationId: string;
    onFilterSortChange?: (params: SortAndFilterOptions) => void;
}

const MissingCarrsExamInfiniteGrid: React.FC<Props> = ({
    organisationId,
    onFilterSortChange,
    children,
}) => {
    const wrapperRef = React.createRef<HTMLElement>();
    const [dataState, setDataState] = React.useState<State>({});
    const { navigateToOrgPath: navigateToPath } = useNavigation();

    const query = React.useMemo(() => {
        const params = {
            ...resolveSortAndFilterOptions(dataState, gridFieldsMap),
        };
        if (onFilterSortChange) {
            onFilterSortChange(params);
        }
        return params;
    }, [dataState]);

    const { data, fetchNextPage, hasNextPage, isFetching, isFetchingNextPage } =
        useGetInfiniteMissingCarrsExamQuery(organisationId, query);

    const { flatData, totalCount, dataCount } = useInfiniteDataResult(data);

    const isLoading = isFetching || isFetchingNextPage;

    const gridProps = useInfiniteGridProps({
        data: flatData,
        dataState,
        hasNextPage,
        setDataState,
        loading: isLoading,
        fetchMore: () => {
            fetchNextPage();
        },
        gridRef: wrapperRef,
    });

    const switchColumnType = React.useCallback(
        (field: GridFieldDef, index: number): JSX.Element => {
            return <GridColumn {...grisColProps(field, index)} />;
        },
        [],
    );

    const onNavigate = React.useCallback(
        (id: string) => {
            navigateToPath(`/:orgShortName/process/${id}`);
        },
        [navigateToPath],
    );

    return (
        <div
            ref={wrapperRef as React.RefObject<HTMLDivElement>}
            className="examinations-grid-h pt-2"
        >
            <Grid
                {...gridProps}
                filterable
                filterOperators={gridFilterOperators}
                className="examinations-grid-h"
                onRowDoubleClick={(e) => onNavigate(e.dataItem.processId)}
            >
                {children}
                <GridNoRecords>There is no data available</GridNoRecords>
                {Object.values(gridFieldsMap).map(switchColumnType)}
            </Grid>

            {isLoading && <GridLoadingPanel gridRef={wrapperRef} />}
            <CustomPagingFooter
                total={totalCount}
                loading={isLoading}
                dataCount={dataCount}
            />
        </div>
    );
};

export default MissingCarrsExamInfiniteGrid;
