import cx from "classnames";
import * as React from "react";
import { Button, ButtonToolbar } from "react-bootstrap";
import {
    TemplateContentFilter,
    TemplateContentFilterOperator,
    TemplateContentFilterProperty,
} from "../../../../common/filters/domain/types";
import { ExaminationPlanning } from "../../domain/examinationsPlanning";
import {
    useAssignExaminationsMutation,
    useOpenExaminationsMutation,
    useScheduleExaminationsMutation,
} from "../../query/examinationsPlanningQueries";
import AssignModal from "../AssignModal";
import CancelModal from "../CancelModal";
import ScheduleModal from "../ScheduleModal";
import "./examinationSchedulesGrid.scss";
import { useExaminationSchedulerPermissions } from "./useExaminationSchedulerPermissions";

const usersFilter: TemplateContentFilter = {
    property: TemplateContentFilterProperty.UserGroup,
    operator: TemplateContentFilterOperator.Any,
    values: ["Examiner"],
};

interface Props {
    organisationId: string;
    selectedItems: ExaminationPlanning[];
}

const ExaminationSchedulesGridActions: React.FC<Props> = ({
    organisationId,
    selectedItems,
}) => {
    const [scheduleActive, setScheduleActive] = React.useState<boolean>(false);
    const [assignActive, setAssignActive] = React.useState<boolean>(false);
    const [cancelActive, setCancelActive] = React.useState<boolean>(false);

    const { canPlanExamination, canCancelExaminations } =
        useExaminationSchedulerPermissions(organisationId);

    const assignMutation = useAssignExaminationsMutation(organisationId);
    const completeMutation = useScheduleExaminationsMutation(organisationId);
    const openMutation = useOpenExaminationsMutation(organisationId);

    const selectedItemsIds = React.useMemo(
        () => selectedItems.map((i) => i.id),
        [selectedItems],
    );

    return (
        <div>
            {scheduleActive && (
                <ScheduleModal
                    examinations={selectedItems}
                    organisationId={organisationId}
                    onClose={() => setScheduleActive(false)}
                />
            )}
            {assignActive && (
                <AssignModal
                    title={"Assign Examiner"}
                    examinations={selectedItems}
                    organisationId={organisationId}
                    usersFilter={usersFilter}
                    assignMutation={assignMutation}
                    onClose={() => setAssignActive(false)}
                />
            )}
            {cancelActive && (
                <CancelModal
                    examinations={selectedItems}
                    organisationId={organisationId}
                    onClose={() => setCancelActive(false)}
                />
            )}

            <ButtonToolbar
                className={cx({
                    invisible: selectedItems.length === 0,
                })}
            >
                {canPlanExamination && (
                    <>
                        <Button
                            className="mr-2"
                            variant="outline-secondary"
                            onClick={() => {
                                setScheduleActive(true);
                            }}
                        >
                            Set Planned Date
                        </Button>
                        <Button
                            className="mr-2"
                            variant="outline-secondary"
                            onClick={() => {
                                setAssignActive(true);
                            }}
                        >
                            Set Examiner
                        </Button>
                        <Button
                            className="mr-2"
                            variant="outline-secondary"
                            onClick={() => {
                                completeMutation.mutate({
                                    processActivityIds: selectedItemsIds,
                                });
                            }}
                        >
                            Schedule Exam
                        </Button>
                        <Button
                            className="mr-2"
                            variant="outline-secondary"
                            onClick={() => {
                                openMutation.mutate({
                                    processActivityIds: selectedItemsIds,
                                });
                            }}
                        >
                            Reset Exam
                        </Button>
                    </>
                )}
                {canCancelExaminations && (
                    <Button
                        variant="outline-secondary"
                        onClick={() => {
                            setCancelActive(true);
                        }}
                    >
                        Cancel Examinations
                    </Button>
                )}
            </ButtonToolbar>
        </div>
    );
};

export default ExaminationSchedulesGridActions;
