import * as React from "react";
import { Button, ButtonToolbar } from "react-bootstrap";
import CoveringLoadingPanel from "../../../../../common/components/CoveringLoadingPanel";
import { AppRoutes } from "../../../../../router/AppRoutes";
import { useNavigation } from "../../../../../router/useNavigate";
import {
    AggregatedRecommendationsData,
    ExaminationTrackingRecommendation,
} from "../../domain/examinationsReview";
import { useGetExaminationRecommendationsQuery } from "../../query/examinationsReviewQueries";
import DefectsGrid from "../ExaminationDefectsGrid/DefectsGrid";
import LoadPastRecommendationsModal from "./LoadPastRecommendationsModal";
import { recommendationsGridMap } from "./recommendationsGridMaps";

interface Props {
    organisationId: string;
    reviewId: string;
}

const dataIsValid = (data: AggregatedRecommendationsData): boolean =>
    Boolean(data) &&
    Boolean(data.processId) &&
    Boolean(data.trackingRecommendationsActivityId) &&
    Boolean(data.recommendations);

const ExaminationRecommendationsGrid: React.FC<Props> = ({
    organisationId,
    reviewId,
}) => {
    const { navigateToWithOrg } = useNavigation();
    const { data, isLoading } = useGetExaminationRecommendationsQuery(
        organisationId,
        reviewId,
    );
    const [pastRecModalActive, setPastRecModalActive] =
        React.useState<boolean>(false);

    if (isLoading) return <CoveringLoadingPanel />;

    return (
        <>
            {pastRecModalActive && (
                <LoadPastRecommendationsModal
                    organisationId={organisationId}
                    recommendationsActivityId={reviewId}
                    onClose={() => setPastRecModalActive(false)}
                />
            )}
            <ButtonToolbar>
                <Button
                    className="mr-1"
                    onClick={() => {
                        navigateToWithOrg(AppRoutes.ProcessActivity, {
                            processId: data.processId,
                            activityId: data.trackingRecommendationsActivityId,
                        });
                    }}
                >
                    Edit
                </Button>
                <Button
                    variant="secondary"
                    onClick={() => {
                        setPastRecModalActive(true);
                    }}
                >
                    Load Past Recommendations
                </Button>
            </ButtonToolbar>
            {dataIsValid(data) ? (
                <DefectsGrid<ExaminationTrackingRecommendation>
                    data={data.recommendations}
                    isLoading={isLoading}
                    processId={data.processId}
                    columnsDefinitions={recommendationsGridMap}
                />
            ) : (
                <div className="p-2">No recommendation to display.</div>
            )}
        </>
    );
};

export default ExaminationRecommendationsGrid;
