import { ChipListDataChangeEvent } from "@progress/kendo-react-buttons";
import { ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import React, { useState } from "react";
import { Col, Row, Tab, Tabs } from "react-bootstrap";
import { useParams } from "react-router";
import AuditActionsList from "../../../common/components/audit/AuditActionsList";
import CoveringLoadingPanel from "../../../common/components/CoveringLoadingPanel";
import Tags from "../../../common/components/Tags";
import { useOrgTitleWithShortName } from "../../../common/hooks/useTitle";
import { useURLQueryParams } from "../../../common/hooks/useURLQueryParams";
import PermissionConstants from "../../../common/permissions/PermissionConstants";
import {
    useGetAssetByIdQuery,
    useGetAssetTagsQuery,
    useRemoveAssetTagMutation,
    useUpdateAssetTagMutation,
} from "../../../store/features/asset/asset-api-slice";
import { hasUserOrgPermission } from "../../../store/features/user/user-api-slice";
import { useOrganisationId } from "../../organisation/hooks/useOrganisationId";
import AssetDetails from "../containers/AssetDetails";
import AssetDetailsForm from "../containers/AssetDetailsForm";
import AssetHierarchy from "../containers/AssetHierarchy";
import AssetTrackingObjectList from "../containers/AssetTrackingObjectList";
import AssetViewBreadcrumbs from "../containers/AssetViewBreadcrumbs";
import useAssetCustomTabs from "../hooks/useAssetCustomTabs";
import AssetPageTitles from "./pageTitles";

const AssetViewPage: React.FC = () => {
    const selectedTab = useURLQueryParams("tab");
    const [activeTabKey, setTabKey] = useState(selectedTab ?? "content");
    const { id, orgShortName } = useParams<{
        id: string;
        orgShortName: string;
    }>();

    const { organisationId, isLoadingOrgId } = useOrganisationId();

    const { data: asset, isLoading: assetIsLoading } = useGetAssetByIdQuery(
        { assetId: id, organisationId: organisationId },
        {
            skip: isLoadingOrgId,
        },
    );

    const { data: assetTags, isLoading: assetTagsIsLoading } =
        useGetAssetTagsQuery(organisationId, { skip: isLoadingOrgId });

    const canCreateAssetTag = hasUserOrgPermission(
        asset?.organisationId,
        PermissionConstants.OrgAssetTag.create,
    ).hasPermission;
    const canDeleteAssetTag = hasUserOrgPermission(
        asset?.organisationId,
        PermissionConstants.OrgAssetTag.delete,
    ).hasPermission;
    const canReadAssetTag = hasUserOrgPermission(
        asset?.organisationId,
        PermissionConstants.OrgAssetTag.read,
    ).hasPermission;

    useOrgTitleWithShortName(AssetPageTitles.Manage);
    const [updateAssetTags] = useUpdateAssetTagMutation();
    const [removeAssetTag] = useRemoveAssetTagMutation();

    const addTag = (event: ComboBoxChangeEvent) => {
        const value = event.value;
        if (!value || !value.text) return;

        updateAssetTags({
            id: value.id,
            organisationId: organisationId,
            assetId: asset.id,
            text: value.text.trim(),
        });
    };

    const removeTag = (event: ChipListDataChangeEvent) => {
        const value = event.value;

        if (!value) return;

        const tagToRemove = asset.tags.filter((tag) => !value.includes(tag))[0];

        removeAssetTag({
            id: tagToRemove.id,
            organisationId: organisationId,
            assetId: asset.id,
            text: tagToRemove.text,
        });
    };

    const { tabElements: assetCustomTabs } = useAssetCustomTabs(
        asset?.id,
        organisationId,
    );

    if (assetIsLoading || isLoadingOrgId || assetTagsIsLoading)
        return <CoveringLoadingPanel />;

    return (
        <>
            <Row className="top10">
                <Col>
                    <AssetViewBreadcrumbs
                        asset={asset}
                        orgShortName={orgShortName}
                    />
                </Col>
            </Row>
            <Row className="bottom10">
                <Col>
                    <h1>{asset.name}</h1>
                    <Tags
                        canCreateTags={canCreateAssetTag}
                        canReadTags={canReadAssetTag}
                        canDeleteTags={canDeleteAssetTag}
                        availableTags={assetTags}
                        elementTags={asset.tags}
                        addTag={addTag}
                        removeTag={removeTag}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Tabs
                        defaultActiveKey="content"
                        id="tab"
                        activeKey={activeTabKey}
                        onSelect={(key) => setTabKey(key)}
                    >
                        <Tab eventKey="content" title="Properties">
                            <AssetDetailsForm asset={asset} />
                        </Tab>
                        <Tab eventKey="details" title="Options">
                            <AssetDetails
                                asset={asset}
                                orgShortName={orgShortName}
                            />
                        </Tab>
                        {assetCustomTabs}
                        <Tab eventKey="children" title="Children">
                            <AssetHierarchy
                                asset={asset}
                                orgShortName={orgShortName}
                            />
                        </Tab>
                        <Tab
                            eventKey="trackingObjects"
                            title="Tracking Objects"
                        >
                            <AssetTrackingObjectList
                                orgShortName={orgShortName}
                                trackingObjects={
                                    asset.trackingObjectsList ?? []
                                }
                            />
                        </Tab>
                        <Tab eventKey="history" title="History">
                            <AuditActionsList
                                auditActions={asset.auditActions}
                            />
                        </Tab>
                    </Tabs>
                </Col>
            </Row>
        </>
    );
};
export default AssetViewPage;
