import {
    MultiSelect,
    MultiSelectChangeEvent,
} from "@progress/kendo-react-dropdowns";
import React from "react";
import { Breadcrumb, Button, Spinner } from "react-bootstrap";
import { UseMutationResult } from "react-query";
import CoveringLoadingPanel from "../../../../common/components/CoveringLoadingPanel";
import { useOrganisationId } from "../../../organisation/hooks/useOrganisationId";
import UserRequestedFileStatusDownload from "../../../user-requested-files/components/UserRequestedFileStatusDownload";
import { RequestedFileDto } from "../../../user-requested-files/queries/types";
import { useGetUserRequestedFileStatus } from "../../../user-requested-files/queries/userRequestedFileQueries";
import { getYearsNames } from "../helpers/contractYearsHelper";

type ExcelReportDownloadProps = {
    pageTitle: string;
    callToAction: string;
    mutationFn: (
        organisationId: string,
        selectedYears: string[],
    ) => UseMutationResult<RequestedFileDto, unknown, unknown>;
    downloadButtonText?: string;
    fileName?: string;
    includeDateInFileName?: boolean;
};

const yearsList = getYearsNames();

function ExcelReportDownload({
    pageTitle,
    callToAction,
    mutationFn,
    downloadButtonText = "Generate Report",
}: ExcelReportDownloadProps): JSX.Element {
    const [selectedYears, setSelectedYears] = React.useState([]);

    const { organisationId } = useOrganisationId();
    const { mutateAsync, data, isLoading } = mutationFn(
        organisationId,
        selectedYears,
    );

    const { data: fileStatusData } = useGetUserRequestedFileStatus(
        organisationId,
        data?.id,
    );

    const isWorkingAsync = () => {
        if (!fileStatusData) return false;

        return (
            fileStatusData?.status !== "Complete" &&
            fileStatusData?.status !== "Failed"
        );
    };

    const onYearChange = React.useCallback(
        ({ value }: MultiSelectChangeEvent) => {
            setSelectedYears(value);
        },
        [],
    );

    return (
        <>
            {isLoading && <CoveringLoadingPanel />}

            {pageTitle && (
                <Breadcrumb className="top10">
                    <Breadcrumb.Item active>{pageTitle}</Breadcrumb.Item>
                </Breadcrumb>
            )}
            <div
                style={{
                    opacity: isLoading ? "0.4" : "1",
                    transition: "opacity 0.3s ease",
                }}
            >
                {pageTitle && <h1>{pageTitle}</h1>}
                {callToAction && <p className="mt-4">{callToAction}</p>}

                <MultiSelect
                    className="mt-1"
                    onChange={onYearChange}
                    style={{ width: "300px" }}
                    data={yearsList}
                />
                <div className="mt-4">
                    <Button
                        disabled={
                            selectedYears.length === 0 ||
                            isLoading ||
                            isWorkingAsync()
                        }
                        variant={
                            selectedYears.length === 0 || isLoading
                                ? "outline-primary"
                                : undefined
                        }
                        onClick={mutateAsync}
                    >
                        {(isLoading || isWorkingAsync()) && (
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                className="mr-1"
                            />
                        )}
                        {downloadButtonText}
                    </Button>
                </div>
                {data && <UserRequestedFileStatusDownload fileId={data.id} />}
            </div>
        </>
    );
}

export default ExcelReportDownload;
