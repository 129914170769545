import { saveAs } from "file-saver";
import authFetch from "../../../helpers/ApiHelper";

interface DownloadFileParams {
    organisationId: string;
    folderId: string;
    fileId: string;
    fileName: string;
    fileVerId?: string;
}

export function downloadFile({
    organisationId,
    folderId,
    fileId,
    fileName,
    fileVerId,
}: DownloadFileParams): Promise<void> {
    const endpoint = fileVerId
        ? `/api/organisation/${organisationId}/folder/${folderId}/file/${fileId}/ver/${fileVerId}`
        : `/api/organisation/${organisationId}/folder/${folderId}/file/${fileId}`;
    return authFetch(endpoint, {
        method: "GET",
    })
        .then((res) => (res.ok ? res.blob() : Promise.reject(res)))
        .then((blob) => saveAs(blob, fileName));
}
