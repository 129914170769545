import { HttpMethod } from "../../../common/http/HttpMethod";
import OrganisationConstants from "../../../Constants/OrganisationConstants";
import {
    Organisation,
    OrganisationCreateRequestData,
    OrganisationSimple,
    OrganisationUpdateRequestData,
    OrganisationUser,
    User,
} from "../../../modules/organisation/domain/organisation";
import { Permission } from "../../../modules/organisation/domain/permission";
import {
    CreateUserGroup,
    UserGroup,
} from "../../../modules/user-management/domain/types";
import { AssetTemplateModel } from "../asset/asset-api-slice";
import { dcpApi, Tags } from "../dcpApi";

export const organisationApi = dcpApi.injectEndpoints({
    endpoints: (builder) => ({
        addOrganisation: builder.mutation<
            { id: string },
            OrganisationCreateRequestData
        >({
            query: (model) => ({
                url: `${OrganisationConstants.RoutePath}`,
                method: "POST",
                body: model,
            }),
            invalidatesTags: () => [
                {
                    type: Tags.Organisations,
                },
            ],
        }),
        updateOrganisation: builder.mutation<
            void,
            { organisationId: string; model: OrganisationUpdateRequestData }
        >({
            query: ({ organisationId, model }) => ({
                url: `${OrganisationConstants.RoutePath}/${organisationId}`,
                method: "PUT",
                body: model,
            }),
            invalidatesTags: (_result, _error, arg) => [
                {
                    type: Tags.Organisation,
                    id: arg.organisationId,
                },
            ],
        }),
        getUsersByOrgId: builder.query<OrganisationUser[], string>({
            query: (organisationId) =>
                `${OrganisationConstants.RoutePath}/${organisationId}${OrganisationConstants.User}`,
            providesTags: (_result, _error, id) => [
                { type: Tags.OrganisationUsers, id },
            ],
        }),
        getOrgByShortName: builder.query<Organisation, string>({
            query: (shortName) =>
                `${OrganisationConstants.RoutePath}/shortname/${shortName}`,
            providesTags: (result, error) =>
                error ? [] : [{ type: Tags.Organisation, id: result.id }],
        }),
        getOrgSimpleByShortName: builder.query<OrganisationSimple, string>({
            query: (shortName) => ({
                url: `${OrganisationConstants.RoutePath}/simple`,
                params: { shortName },
            }),
            providesTags: (result, error) =>
                error ? [] : [{ type: Tags.Organisation, id: result.id }],
        }),
        getOrgSimpleById: builder.query<OrganisationSimple, string>({
            query: (id) => ({
                url: `${OrganisationConstants.RoutePath}/simple`,
                params: { id },
            }),
            providesTags: (result, error) =>
                error ? [] : [{ type: Tags.Organisation, id: result.id }],
        }),
        getOrgById: builder.query<Organisation, string>({
            query: (organisationId) =>
                `${OrganisationConstants.RoutePath}/${organisationId}`,
            providesTags: (_result, _error, id) => [
                { type: Tags.Organisation, id },
            ],
        }),
        addUserGroup: builder.mutation<
            string,
            { orgId: string; model: CreateUserGroup }
        >({
            query: ({ orgId, model }) => ({
                url: `${OrganisationConstants.RoutePath}/${orgId}${OrganisationConstants.userGroupApi}`,
                method: "POST",
                body: model,
            }),
            invalidatesTags: (_, error, { orgId }) =>
                error ? [] : [{ type: Tags.OrganisationUserGroups, id: orgId }],
        }),
        updateUserGroup: builder.mutation<
            void,
            { orgId: string; id: string; model: CreateUserGroup }
        >({
            query: ({ orgId, id, model }) => ({
                url: `${OrganisationConstants.RoutePath}/${orgId}${OrganisationConstants.userGroupApi}/${id}`,
                method: "PUT",
                body: model,
            }),
            invalidatesTags: (_, error, { orgId, id }) =>
                error
                    ? []
                    : [
                          { type: Tags.OrganisationUserGroups, id: orgId },
                          { type: Tags.OrganisationUserGroup, id },
                      ],
        }),
        removeUserGroup: builder.mutation<void, { orgId: string; id: string }>({
            query: ({ orgId, id }) => ({
                url: `${OrganisationConstants.RoutePath}/${orgId}${OrganisationConstants.userGroupApi}/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: (_, error, { orgId }) =>
                error
                    ? []
                    : [
                          { type: Tags.OrganisationUserGroups, id: orgId },
                          ...invalidateUsers({ orgId }),
                      ],
        }),
        getUserGroupById: builder.query<
            UserGroup,
            { orgId: string; id: string }
        >({
            query: ({ orgId, id }) => ({
                url: `${OrganisationConstants.RoutePath}/${orgId}${OrganisationConstants.userGroupApi}/${id}`,
                method: "GET",
            }),
            providesTags: (_result, _error, { id }) => [
                { type: Tags.OrganisationUserGroup, id },
            ],
        }),
        getUserGroupsByOrgId: builder.query<UserGroup[], string>({
            query: (orgId) => ({
                url: `${OrganisationConstants.RoutePath}/${orgId}${OrganisationConstants.userGroupApi}`,
                method: "GET",
            }),
            providesTags: (_, error, arg) =>
                error ? [] : [{ type: Tags.OrganisationUserGroups, id: arg }],
        }),
        removePermissionFromGroup: builder.mutation<
            void,
            { orgId: string; groupId: string; permissionId: string }
        >({
            query: ({ orgId, groupId, permissionId }) => ({
                url: `${OrganisationConstants.RoutePath}/${orgId}${OrganisationConstants.userGroupApi}/${groupId}${OrganisationConstants.Permission}/${permissionId}`,
                method: "DELETE",
            }),
        }),
        addPermissionToGroup: builder.mutation<
            void,
            { orgId: string; groupId: string; body: { permissionId: string } }
        >({
            query: ({ orgId, groupId, body }) => ({
                url: `${OrganisationConstants.RoutePath}/${orgId}${OrganisationConstants.userGroupApi}/${groupId}${OrganisationConstants.Permission}`,
                method: "POST",
                body: body,
            }),
        }),
        addUserToGroup: builder.mutation<
            void,
            { orgId: string; groupId: string; body: { userId: string } }
        >({
            query: ({ orgId, groupId, body }) => ({
                url: `${OrganisationConstants.RoutePath}/${orgId}${OrganisationConstants.userGroupApi}/${groupId}${OrganisationConstants.User}`,
                method: "POST",
                body: body,
            }),
            invalidatesTags: (_, _error, arg) => invalidateUsers(arg),
        }),
        addUserToOrganisation: builder.mutation<
            void,
            {
                organisationId: string;
                body: { userId: string; userGroupIds: string[] };
            }
        >({
            query: ({ organisationId, body }) => ({
                url: `${OrganisationConstants.RoutePath}/${organisationId}${OrganisationConstants.User}`,
                method: "POST",
                body: body,
            }),
            invalidatesTags: (_result, _error, { organisationId }) =>
                invalidateAllUserRelatedTags(organisationId),
        }),
        removeUserFromOrganisation: builder.mutation<
            void,
            {
                organisationId: string;
                userId: string;
            }
        >({
            query: ({ userId, organisationId }) => ({
                url: `${OrganisationConstants.RoutePath}/${organisationId}${OrganisationConstants.User}/${userId}`,
                method: "DELETE",
            }),
            invalidatesTags: (_result, _error, { organisationId }) =>
                invalidateAllUserRelatedTags(organisationId),
        }),
        removeUserFromGroup: builder.mutation<
            void,
            { orgId: string; groupId: string; userId: string }
        >({
            query: ({ orgId, groupId, userId }) => ({
                url: `${OrganisationConstants.RoutePath}/${orgId}${OrganisationConstants.userGroupApi}/${groupId}${OrganisationConstants.User}/${userId}`,
                method: "DELETE",
            }),
            invalidatesTags: (_, _error, arg) => invalidateUsers(arg),
        }),
        getAvailablePermissions: builder.query<Permission[], string>({
            query: (orgId) => ({
                url: `${OrganisationConstants.RoutePath}/${orgId}${OrganisationConstants.AvailablePermissions}`,
                method: "GET",
            }),
            providesTags: (_, error, arg) =>
                error ? [] : [{ type: Tags.OrganisationPermissions, id: arg }],
        }),
        getAvailableUsersToAdd: builder.query<User[], string>({
            query: (orgId) => ({
                url: `${OrganisationConstants.RoutePath}/${orgId}${OrganisationConstants.AvailableUsers}`,
                method: "GET",
            }),
            providesTags: (_, error, arg) =>
                error
                    ? []
                    : [{ type: Tags.OrganisationAvailableUsers, id: arg }],
        }),
        addPermission: builder.mutation<
            string,
            { orgId: string; permissionId: string }
        >({
            query: ({ orgId, permissionId }) => ({
                url: `${OrganisationConstants.RoutePath}/${orgId}${OrganisationConstants.Permission}`,
                method: HttpMethod.Post,
                body: { permissionId },
            }),
            invalidatesTags: (_, error, arg) =>
                error
                    ? []
                    : [
                          { type: Tags.Organisation, id: arg.orgId },
                          { type: Tags.OrganisationPermissions, id: arg.orgId },
                      ],
        }),
        removePermission: builder.mutation<
            string,
            { orgId: string; permissionId: string }
        >({
            query: ({ orgId, permissionId }) => ({
                url: `${OrganisationConstants.RoutePath}/${orgId}${OrganisationConstants.Permission}/${permissionId}`,
                method: HttpMethod.Delete,
            }),
            invalidatesTags: (_, error, arg) =>
                error
                    ? []
                    : [
                          { type: Tags.Organisation, id: arg.orgId },
                          { type: Tags.OrganisationPermissions, id: arg.orgId },
                      ],
        }),
        getTagHints: builder.query<string[], string>({
            query: (orgId) => ({
                url: `${OrganisationConstants.RoutePath}/${orgId}${OrganisationConstants.TagHints}`,
                method: "GET",
            }),
            providesTags: (_, error, arg) =>
                error ? [] : [{ type: Tags.OrganisationTagHints, id: arg }],
        }),
        getAssetsTemplatesByOrgId: builder.query<AssetTemplateModel[], string>({
            query: (organisationId) => ({
                url: `${OrganisationConstants.RoutePath}/${organisationId}/assets/templates`,
                method: "GET",
            }),
        }),
    }),
});

const invalidateUsers = (arg) => [
    { type: Tags.OrganisationUsers, id: arg.orgId },
];

const invalidateAllUserRelatedTags = (organisationId) => [
    { type: Tags.OrganisationUsers, id: organisationId },
    { type: Tags.OrganisationAvailableUsers, id: organisationId },
    { type: Tags.OrganisationUserGroup },
];

export const {
    useGetUsersByOrgIdQuery,
    useGetOrgByShortNameQuery,
    useGetOrgByIdQuery,
    useAddOrganisationMutation,
    useUpdateOrganisationMutation,
    useAddUserGroupMutation,
    useGetUserGroupByIdQuery,
    useGetUserGroupsByOrgIdQuery,
    useUpdateUserGroupMutation,
    useRemoveUserGroupMutation,
    useRemovePermissionFromGroupMutation,
    useAddPermissionToGroupMutation,
    useAddUserToGroupMutation,
    useRemoveUserFromGroupMutation,
    useGetAvailablePermissionsQuery,
    useAddPermissionMutation,
    useRemovePermissionMutation,
    useAddUserToOrganisationMutation,
    useGetAvailableUsersToAddQuery,
    useRemoveUserFromOrganisationMutation,
    useGetTagHintsQuery,
    useGetAssetsTemplatesByOrgIdQuery,
    useGetOrgSimpleByShortNameQuery,
    useGetOrgSimpleByIdQuery,
} = organisationApi;
