import { saveAs } from "file-saver";
import authFetch from "../helpers/ApiHelper";

export const downloadFile = (url: string, fileName: string): Promise<void> => {
    return authFetch(url, {
        method: "GET",
    })
        .then((res) => res.blob())
        .then((blob) => saveAs(blob, fileName));
};

export const downloadFileWithName = (url: string): Promise<void> => {
    return authFetch(url, {
        method: "GET",
        mode: "cors",
    })
        .then(async (res) => {
            let filename: string;
            const filenameRegex = /filename\*?=([^']*'')?([^;]*)/;
            const matches = filenameRegex.exec(
                res.headers.get("Content-Disposition"),
            );
            if (matches != null && matches[1]) {
                filename = matches[2].replace(/['"]/g, "");
            }
            return { blob: await res.blob(), filename };
        })
        .then(({ blob, filename }) => {
            return saveAs(blob, filename);
        });
};

export const downloadFormFile = (url: string): Promise<void> => {
    return authFetch(url, {
        method: "GET",
    })
        .then(async (res) => {
            const filename = res.headers
                .get("Content-Disposition")
                .split("filename=")[1]
                .split(";")[0]
                .replaceAll('"', "");
            return { blob: await res.blob(), filename };
        })
        .then(({ blob, filename }) => {
            return saveAs(blob, filename);
        });
};

export const getFormFile = (url: string): Promise<Blob> => {
    return authFetch(url, {
        method: "GET",
    }).then((res) => res.blob());
};
