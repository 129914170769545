import * as React from "react";
import { Breadcrumb, Col, Row } from "react-bootstrap";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import CoveringLoadingPanel from "../../../common/components/CoveringLoadingPanel";
import { useOrgTitleWithShortName } from "../../../common/hooks/useTitle";
import OrganisationConstants from "../../../Constants/OrganisationConstants";
import { useOrganisationId } from "../../organisation/hooks/useOrganisationId";
import AssetCreateForm from "../containers/AsssetCreateForm";
import AssetPageTitles from "./pageTitles";

const AssetCreatePage: React.FC = () => {
    const params = useParams<{ id: string; orgShortName: string }>();
    const { organisationId, isLoadingOrgId } = useOrganisationId();
    useOrgTitleWithShortName(AssetPageTitles.Create);

    if (isLoadingOrgId) return <CoveringLoadingPanel />;

    return (
        <>
            <Row className="top10">
                <Col>
                    <Breadcrumb>
                        <Breadcrumb.Item linkAs={"div"}>
                            <Link
                                to={`/${params.orgShortName}${OrganisationConstants.Asset}`}
                            >
                                Assets
                            </Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>Create</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h1>Create Asset</h1>
                </Col>
            </Row>
            <Row>
                <Col>
                    <AssetCreateForm organisationId={organisationId} />
                </Col>
            </Row>
        </>
    );
};

export default AssetCreatePage;
