import React from "react";
import { useDispatch } from "react-redux";
import { MultiMutationResultStatus } from "../../common/components/MutationResultStatus";
import { emptyFilterDescriptor } from "../../common/components/table/kendo/columnFilters";
import { formatIsoDate } from "../../helpers/dateTimeHelpers";
import { downloadFormFile } from "../../services/FileDownloadService";
import { setName } from "../../store/features/folder/fileOrFolderNameSlice";
import {
    useChangeProcessActivityFilenameMutation,
    useDeleteProcessActivityFileMutation,
    useUploadProcessActivityFileMutation,
} from "../../store/features/process-activity-file/processActivityFileApiSlice";
import { useAppSelector } from "../../store/hooks";
import { FileAndFoldersGrid } from "../files/components/FileAndFoldersGrid";
import { FileOrFolderContextMenu } from "../files/components/FileOrFolderContextMenu";
import { useContextMenuState } from "../files/hooks/useContextMenu";
import { useFilesPageState } from "../files/hooks/useFilesPageState";
import { FilesPageMode } from "../files/viewModel/FilesPageMode";
import {
    EntryType,
    FileViewEntry,
    GroupedFileViewEntries,
} from "../files/viewModel/FileViewEntry";
import { FileUploadButton } from "../process-activity-form/components/FileUploadButton";
import { ProcessActivityType } from "../process-activity/domain/types";
import { getActivityFileResUrl } from "../process-activity/helpers/urlBuilder";
import { TemplateContentFileRecord } from "../template/domain/types";

interface Props {
    paFiles: TemplateContentFileRecord[];
    activityId: string;
    paType: ProcessActivityType;
    readOnly: boolean;
}

function mapToViewEntries(
    files: TemplateContentFileRecord[],
    mode: FilesPageMode = { mode: "Default" },
): GroupedFileViewEntries {
    const editedId = mode.mode === "EditExisting" ? mode.id : null;
    return {
        virtualEntries: [],
        folderEntries: [],
        fileEntries: files.map(
            (item): FileViewEntry => ({
                type: EntryType.File,
                inEdit: item.id === editedId,
                id: item.id,
                label: item.fileName,
                comment: item.comment,
                createdOn: formatIsoDate(item.createdOn),
                selected: false,
                dateModified: null,
                modifiedBy: null,
            }),
        ),
    };
}

const ProcessActivityFilesSection: React.FC<Props> = ({
    paFiles,
    activityId,
    paType,
    readOnly,
}) => {
    const dispatch = useDispatch();
    const [filter, setFilter] = React.useState(emptyFilterDescriptor);
    const [state, stateActions] = useFilesPageState();
    const { offset, dataItem, closeContextMenu, rowRender } =
        useContextMenuState(() => true);

    const [deleteFile, deleteFileResult] =
        useDeleteProcessActivityFileMutation();
    const [uploadFile, uploadFileResult] =
        useUploadProcessActivityFileMutation();
    const [changeFilename, changeFilenameResult] =
        useChangeProcessActivityFilenameMutation();

    const startEditingFileOrFolder = () => {
        stateActions.startEditing(dataItem.id, dataItem.type);
        dispatch(setName(dataItem.label));
    };

    const filename = useAppSelector((s) => s.fileOrFolderName);

    const submitFileNameChange = (
        event?: React.FormEvent<HTMLFormElement>,
    ): void => {
        if (state.mode === "EditExisting") {
            changeFilename({
                fileId: state.id,
                activityId,
                filename,
                paType,
            }).then(() => setFilter(emptyFilterDescriptor));
            stateActions.goBackToDefaultMode();
            event?.preventDefault();
        }
    };

    const entries = mapToViewEntries(paFiles, state);

    return (
        <form onSubmit={submitFileNameChange}>
            {!readOnly && (
                <div className="pt-4">
                    <FileUploadButton
                        activityId={activityId}
                        uploadFile={uploadFile}
                        paType={paType}
                    />
                </div>
            )}
            <FileOrFolderContextMenu
                dataItem={dataItem}
                offset={offset}
                close={closeContextMenu}
                canDeleteFiles={true}
                canUpdateFiles={true}
                onEditClick={startEditingFileOrFolder}
                onDeleteFileClick={() =>
                    deleteFile({
                        activityId,
                        fileId: dataItem.id,
                        paType,
                    })
                }
                onDownloadClick={() =>
                    downloadFormFile(
                        getActivityFileResUrl(activityId)(dataItem.id),
                    )
                }
            />
            <div className="pt-4">
                <FileAndFoldersGrid
                    entries={entries}
                    additionalColumns={{
                        menu: !readOnly,
                        comment: true,
                        createdOn: true,
                    }}
                    filterState={[filter, setFilter]}
                    rowRender={rowRender}
                />
            </div>
            <MultiMutationResultStatus
                results={[
                    changeFilenameResult,
                    uploadFileResult,
                    deleteFileResult,
                ]}
            />
        </form>
    );
};

export default ProcessActivityFilesSection;
