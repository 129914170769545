import * as React from "react";
import { Row, Col, Button } from "react-bootstrap";
import * as Formik from "formik";
import * as yup from "yup";
import { useParams } from "react-router";
import {
    useAddPermissionMutation,
    useGetAvailablePermissionsQuery,
} from "../../../../store/features/organisation/organisation-api-slice";
import MutationResultStatus from "../../../../common/components/MutationResultStatus";
import { useState } from "react";
import { CustomSelect } from "../../../../common/components/form";
import {
    mapToSelectOptions,
    SelectOptionItem,
} from "../../../../common/types/reactSelect/SelectOptionItem";
import QueryResultStatus from "../../../../common/components/QueryResultStatus";

interface FormValues {
    selectedPermission: SelectOptionItem;
}

const validation = yup.object({
    selectedPermission: yup.object().nullable().required("Required"),
});

const AddPermissionForm: React.FC = () => {
    const { id: organisationId } = useParams<{ id: string }>();
    const [isAdding, setIsAdding] = useState(false);

    const initialFormValues: FormValues = React.useMemo(() => {
        return { selectedPermission: null };
    }, []);

    const { data: availablePermissions, ...availablePermissionsQueryResult } =
        useGetAvailablePermissionsQuery(organisationId);

    const [addPermission, addPermissionResult] = useAddPermissionMutation();

    const onSubmit = (
        values: FormValues,
        { setSubmitting, resetForm },
    ): void => {
        addPermission({
            orgId: organisationId,
            permissionId: values.selectedPermission?.value,
        })
            .unwrap()
            .then(() => resetForm())
            .finally(() => setSubmitting(false));
    };

    if (isAdding) {
        return (
            <div className="pt-2">
                <MutationResultStatus
                    mutationResult={addPermissionResult}
                    showLoading
                />
                <QueryResultStatus
                    queryResult={availablePermissionsQueryResult}
                />
                <Formik.Formik
                    enableReinitialize
                    initialValues={initialFormValues}
                    validationSchema={validation}
                    onSubmit={onSubmit}
                >
                    {({ isSubmitting }): JSX.Element => (
                        <Formik.Form translate="yes">
                            <Row>
                                <Col>
                                    <CustomSelect
                                        name="selectedPermission"
                                        label="Add permission"
                                        placeholder="Select permission..."
                                        options={mapToSelectOptions(
                                            availablePermissions,
                                            (i) => i.id,
                                            (i) => i.name,
                                        )}
                                    />
                                </Col>
                            </Row>
                            <Button
                                variant="primary"
                                type="submit"
                                disabled={isSubmitting}
                            >
                                Add
                            </Button>
                            <Button
                                variant="danger"
                                className="ml-1"
                                onClick={() => setIsAdding(false)}
                            >
                                Cancel
                            </Button>
                        </Formik.Form>
                    )}
                </Formik.Formik>
            </div>
        );
    } else {
        return (
            <div className="d-flex justify-content-end">
                <Button onClick={() => setIsAdding(true)}>
                    Add Permission
                </Button>
            </div>
        );
    }
};

export default AddPermissionForm;
