import { ArrayHelpers } from "formik";
import * as React from "react";
import { Button } from "react-bootstrap";
import { FaPlus, FaTimes } from "react-icons/fa";

interface Props {
    arrayHelpers: ArrayHelpers;
    index: number;
    emptyRowObject: any;
}

const FormTableCellActions: React.FC<Props> = (props) => {
    const { arrayHelpers, index, emptyRowObject } = props;
    // TODO Add disable to buttons when form is in read only state
    return (
        <>
            <Button
                size="sm"
                className="ml-1"
                onClick={() => arrayHelpers.insert(index, emptyRowObject)}
            >
                <FaPlus />
            </Button>
            <Button
                size="sm"
                variant="danger"
                className="ml-1"
                onClick={() => {
                    if (
                        window.confirm(
                            "Are you sure you want to delete this item?",
                        )
                    ) {
                        arrayHelpers.remove(index);
                    }
                }}
            >
                <FaTimes />
            </Button>
        </>
    );
};

export default FormTableCellActions;
