import React, { useCallback } from "react";
import {
    useInfiniteQuery,
    UseInfiniteQueryResult,
    useMutation,
    UseMutationResult,
    useQueryClient,
} from "react-query";
import { useDebounce } from "use-debounce";
import { DataContinuationResult } from "../../../../common/types/DataResult";
import { useInvalidateTags } from "../../../../store/hooks";
import { ExaminationPlanning } from "../domain/examinationsPlanning";
import {
    ExaminationAssignModel,
    ExaminationCancelModel,
    ExaminationScheduleModel,
    ExaminationStateChangeModel,
} from "../domain/examinationsPlanningDtos";
import {
    assignExaminationPlannings,
    cancelExaminationPlannings,
    ExaminationsSearchOptions,
    getExaminationPlannings,
    openExaminationPlannings,
    scheduleExaminationPlannings,
    setExaminationsPlannedDates,
} from "../services/examinationsPlanningService";
import { AssignExaminationMutation } from "./commonQueryTypes";
import examinationQueryKeys from "./examinationQueryKeys";
import { examinationQueryKeysTranslator } from "./examinationQueryKeysTranslator";

const { queryKeysToTags } = examinationQueryKeysTranslator;

export const useGetInfiniteExaminations = (
    organisationId: string,
    query: ExaminationsSearchOptions,
): UseInfiniteQueryResult<DataContinuationResult<ExaminationPlanning>> => {
    const [debouncedQuery] = useDebounce(query, 500);
    const getExaminations = useCallback(
        ({
            pageParam = null,
        }): Promise<DataContinuationResult<ExaminationPlanning>> => {
            const continuationToken = pageParam;

            return getExaminationPlannings({
                organisationId,
                query: { ...debouncedQuery, continuationToken },
            });
        },
        [debouncedQuery, organisationId],
    );

    const queryKeys = React.useMemo(
        () => examinationQueryKeys.filteredExaminations(debouncedQuery),
        [debouncedQuery],
    );

    return useInfiniteQuery<
        DataContinuationResult<ExaminationPlanning>,
        [string, { page: number }]
    >(queryKeys, getExaminations, {
        getNextPageParam: (lastResult) => {
            return lastResult.continuationToken || undefined;
        },
    });
};

type ScheduleMutationResult = UseMutationResult<
    unknown,
    unknown,
    ExaminationScheduleModel
>;
export const useSetExaminationsPlannedDatesMutation = (
    organisationId: string,
): ScheduleMutationResult => {
    const queryClient = useQueryClient();
    const { invalidateTags } = useInvalidateTags();

    const scheduleExaminationsMutationFunction = (
        patchModel: ExaminationScheduleModel,
    ) => setExaminationsPlannedDates({ organisationId, patchModel });

    const queryKeys = React.useMemo(
        () => examinationQueryKeys.allExaminations(),
        [],
    );

    return useMutation(scheduleExaminationsMutationFunction, {
        onSuccess(_result, args) {
            invalidateTags(queryKeysToTags(queryKeys, args.processActivityIds));
            return queryClient.invalidateQueries(queryKeys);
        },
    });
};

export const useAssignExaminationsMutation = (
    organisationId: string,
): AssignExaminationMutation => {
    const queryClient = useQueryClient();
    const { invalidateTags } = useInvalidateTags();

    const assignExaminationsMutationFunction = (
        patchModel: ExaminationAssignModel,
    ) => assignExaminationPlannings({ organisationId, patchModel });

    const queryKeys = React.useMemo(
        () => examinationQueryKeys.allExaminations(),
        [],
    );

    return useMutation(assignExaminationsMutationFunction, {
        onSuccess(_result, args) {
            invalidateTags(queryKeysToTags(queryKeys, args.processActivityIds));
            return queryClient.invalidateQueries(queryKeys);
        },
    });
};

export type CancelExaminationsMutationResult = UseMutationResult<
    unknown,
    unknown,
    ExaminationCancelModel
>;
export const useCancelExaminationsMutation = (
    organisationId: string,
): CancelExaminationsMutationResult => {
    const queryClient = useQueryClient();
    const { invalidateTags } = useInvalidateTags();

    const cancelExaminationsMutationFunction = (
        postModel: ExaminationCancelModel,
    ) => cancelExaminationPlannings({ organisationId, postModel });

    const queryKeys = React.useMemo(
        () => examinationQueryKeys.allExaminations(),
        [],
    );

    return useMutation(cancelExaminationsMutationFunction, {
        onSuccess(_result, args) {
            invalidateTags(queryKeysToTags(queryKeys, args.processActivityIds));
            return queryClient.invalidateQueries(queryKeys);
        },
    });
};

export type ExaminationsStateChangeMutationResult = UseMutationResult<
    unknown,
    unknown,
    ExaminationStateChangeModel
>;

export const useScheduleExaminationsMutation = (
    organisationId: string,
): ExaminationsStateChangeMutationResult =>
    useChangeStateForExaminationsMutation(organisationId, "schedule");

export const useOpenExaminationsMutation = (
    organisationId: string,
): ExaminationsStateChangeMutationResult =>
    useChangeStateForExaminationsMutation(organisationId, "open");

const useChangeStateForExaminationsMutation = (
    organisationId: string,
    type: "schedule" | "open",
): ExaminationsStateChangeMutationResult => {
    const queryClient = useQueryClient();
    const { invalidateTags } = useInvalidateTags();

    const openFunction = (postModel: ExaminationStateChangeModel) =>
        type === "open"
            ? openExaminationPlannings({ organisationId, postModel })
            : scheduleExaminationPlannings({ organisationId, postModel });

    const queryKeys = React.useMemo(
        () => examinationQueryKeys.allExaminations(),
        [],
    );

    return useMutation(openFunction, {
        onSuccess(_result, args) {
            invalidateTags(queryKeysToTags(queryKeys, args.processActivityIds));
            return queryClient.invalidateQueries(queryKeys);
        },
    });
};
