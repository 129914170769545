import { ErrorMessage, FieldAttributes, useField } from "formik";
import React from "react";
import { Form } from "react-bootstrap";

type Props = {
    type?: "date" | "datetime-local" | "time";
    label?: string;
    readOnly?: boolean;
} & FieldAttributes<unknown>;

const DateTimeSelect: React.FC<Props> = ({ readOnly, label, ...props }) => {
    const [field, meta] = useField(props);

    return (
        <Form.Group controlId={field.name}>
            {label && <Form.Label>{label}</Form.Label>}
            <Form.Control
                type={props.type || "datetime-local"}
                name={field.name}
                readOnly={readOnly}
                className={meta.touched && meta.error ? "is-invalid" : ""}
                onBlur={field.onBlur}
                onChange={field.onChange}
                value={field.value?.toString() ?? ""}
            />
            <ErrorMessage
                name={field.name}
                className="invalid-feedback"
                component="div"
            />
        </Form.Group>
    );
};

DateTimeSelect.defaultProps = {
    readOnly: false,
};

export default DateTimeSelect;
