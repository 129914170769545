import * as Formik from "formik";
import * as React from "react";
import TextArea from "../../../common/components/form/TextArea";
import TextInput from "../../../common/components/form/TextInput";
import { MultiMutationResultStatus } from "../../../common/components/MutationResultStatus";
import { useFormActions } from "../../../common/hooks/useFormActions";
import { useUiState } from "../../../common/hooks/useUiState";
import {
    ProcessTemplateCreateRequest,
    ProcessTemplateUpdateRequest,
} from "../../../store/features/process-template/process-template-api-dto";
import TemplateFormEditToolbar from "../../template/components/TemplateFormEditToolbar";
import TemplateFormToolbar from "../../template/components/TemplateFormToolbar";
import { BaseTemplateFormProps } from "../../template/viewModel/templateForm";
import { ProcessTemplateFormValues } from "../viewModel/ProcessTemplateFormValues";
import { validationSchema } from "../viewModel/ProcessTemplateValidation";

interface Props
    extends BaseTemplateFormProps<
        ProcessTemplateFormValues,
        ProcessTemplateCreateRequest,
        ProcessTemplateUpdateRequest
    > {
    disableConfigFields?: boolean;
}

const ProcessTemplateForm: React.FC<Props> = (props) => {
    const {
        templateId,
        initialValues,
        createTemplate,
        updateTemplate,
        deleteTemplate,
        disableConfigFields,
    } = props;

    const uiStateResult = useUiState(templateId);
    const { uiState, setEdit } = uiStateResult;

    const { onSubmit, onCancel, onDelete } = useFormActions(
        props,
        uiStateResult,
        "process",
    );

    const mutationResults = [
        createTemplate?.actionResult,
        updateTemplate?.actionResult,
        deleteTemplate?.actionResult,
    ].filter(Boolean);

    return (
        <>
            {uiState.isView && (
                <TemplateFormToolbar setEdit={setEdit} onDelete={onDelete} />
            )}
            <div className="pt-2">
                <MultiMutationResultStatus
                    showLoading
                    results={mutationResults}
                />
                <Formik.Formik
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {({ values, handleSubmit, isSubmitting, resetForm }) => (
                        <Formik.Form onSubmit={handleSubmit}>
                            <TextInput
                                label="Name"
                                placeholder="Name"
                                description="The name for your process template."
                                name="name"
                                value={values.name}
                                readOnly={uiState.isView}
                            />
                            <TextArea
                                label="Description"
                                name="description"
                                value={values.description}
                                readOnly={uiState.isView}
                                rows={3}
                            />
                            <TextArea
                                label="States JSON Configuration"
                                name="statesConfig"
                                readOnly={disableConfigFields || uiState.isView}
                                rows={10}
                            />
                            <TextArea
                                label="Activities JSON Configuration"
                                name="config"
                                readOnly={disableConfigFields || uiState.isView}
                                rows={20}
                            />
                            <TextArea
                                label="Property Json Configuration"
                                name="propertyConfig"
                                readOnly={disableConfigFields || uiState.isView}
                                rows={15}
                            />
                            <TextArea
                                label="Features Json Configuration"
                                name="featuresConfig"
                                readOnly={disableConfigFields || uiState.isView}
                                rows={10}
                            />
                            {!uiState.isView && (
                                <TemplateFormEditToolbar
                                    {...uiState}
                                    isSubmitting={isSubmitting}
                                    onCancel={onCancel}
                                    resetForm={resetForm}
                                />
                            )}
                        </Formik.Form>
                    )}
                </Formik.Formik>
            </div>
        </>
    );
};

export default ProcessTemplateForm;
