import * as React from "react";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router";
import OrganisationConstants from "../../../Constants/OrganisationConstants";
import { useAddUserGroupMutation } from "../../../store/features/organisation/organisation-api-slice";
import { CreateUserGroup } from "../domain/types";
import { UserGroupForm } from "./UserGroupForm";

interface UpdateUserGroupProps {
    orgId: string;
    orgShortName: string;
}

const CreateUserGroupForm: React.FC<UpdateUserGroupProps> = ({
    orgId,
    orgShortName,
}) => {
    const history = useHistory();
    const [addUserGroup] = useAddUserGroupMutation();

    const onAddGroup = async (model: CreateUserGroup) => {
        const addingResult = await addUserGroup({
            orgId: orgId,
            model,
        });

        history.push(
            `/${orgShortName}/${OrganisationConstants.userGroups}/${
                (addingResult as { data: string }).data
            }`,
        );
    };

    const onCancel = () => {
        history.goBack();
    };

    const userGroupForm: CreateUserGroup = {
        name: String(),
        description: String(),
    };

    return (
        <UserGroupForm userGroup={userGroupForm} onSubmit={onAddGroup} editable>
            {(isSubmitting) => (
                <>
                    <Button
                        className="mr-1"
                        variant="primary"
                        type="submit"
                        disabled={isSubmitting}
                    >
                        Create
                    </Button>
                    <Button
                        variant="outline-secondary"
                        onClick={onCancel}
                        disabled={isSubmitting}
                    >
                        Cancel
                    </Button>
                </>
            )}
        </UserGroupForm>
    );
};

export default CreateUserGroupForm;
