import * as React from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import { FaRegStar, FaStar } from "react-icons/fa";
import { DescriptionList } from "../../../common/components/DescriptionList";
import { IconButton } from "../../../common/components/icon-button/IconButton";
import MutationResultStatus from "../../../common/components/MutationResultStatus";
import PermissionConstants from "../../../common/permissions/PermissionConstants";
import { formatIsoDate } from "../../../helpers/dateTimeHelpers";
import {
    useFavoriteFileMutation,
    useReplaceFileMutation,
} from "../../../store/features/file/fileApiSlice";
import { hasUserOrgPermission } from "../../../store/features/user/user-api-slice";
import * as domain from "../domain/file";
import { downloadFile } from "../services/downloadFile";
import { DirectoryBreadcrumb } from "./DirectoryBreadcrumb";
import { FileReplaceButton } from "./FileReplaceButton";
import FileTags from "./FileTags";

interface FileDetailsProps {
    file: domain.FileDetails;
    folderId: string;
    organisationId: string;
}

export const FileDetails: React.FC<FileDetailsProps> = ({
    file,
    folderId,
    organisationId,
}) => {
    const pathWithFile = file.path.concat([file]);

    const [setFileFavorite] = useFavoriteFileMutation();
    const canSetFavorites = hasUserOrgPermission(
        organisationId,
        PermissionConstants.OrgFilesFile.favorite,
    ).hasPermission;

    const onSetFavorite = () => {
        setFileFavorite({
            fileId: file.id,
            folderId: folderId,
            organisationId: organisationId,
            isFavorite: !file.isFavorite,
        });
    };

    const [replaceFile, replaceFileStatus] = useReplaceFileMutation();
    return (
        <div className="pt-4">
            <DirectoryBreadcrumb path={pathWithFile} activeId={file.id} />
            <MutationResultStatus
                mutationResult={replaceFileStatus}
                showLoading
            />
            <h1 className="pt-4">{file.name}</h1>
            <FileTags
                file={file}
                organisationId={organisationId}
                folderId={folderId}
            />
            <div className="pt-4 d-flex">
                <Button
                    variant="primary"
                    onClick={async () => {
                        window.close();
                        await downloadFile({
                            fileId: file.id,
                            fileName: file.name,
                            folderId,
                            organisationId,
                        });
                    }}
                >
                    Download
                </Button>
                <div className="ml-1">
                    <FileReplaceButton
                        organisationId={organisationId}
                        folderId={folderId}
                        fileId={file.id}
                        replaceFile={replaceFile}
                    />
                </div>
                {file && canSetFavorites && (
                    <ButtonGroup className="ml-auto">
                        <IconButton
                            icon={file.isFavorite ? FaStar : FaRegStar}
                            onClick={onSetFavorite}
                            iconSize="20"
                        />
                    </ButtonGroup>
                )}
            </div>
            <DescriptionList className="pt-4">
                {[
                    ["Created by", file.createdBy],
                    ["Created on", formatIsoDate(file.createdOn)],
                    ["Last updated by", file.lastUpdatedBy ?? "-"],
                    [
                        "Last updated at",
                        formatIsoDate(file.lastUpdatedAt) ?? "-",
                    ],
                    ["Version", `${file.versionNo}`],
                ]}
            </DescriptionList>
        </div>
    );
};
