import * as React from "react";
import { simulateHtmlEvent } from "../../../../helpers/simulateHtmlEvent";
import { EntryType, FileViewEntry } from "../../viewModel/FileViewEntry";
import { FaEllipsisV } from "react-icons/fa";
import { CenteredCell } from "../../../../common/components/table/kendo/CenteredCell";
import { TypedGridCellProps } from "../../../../common/types/TypedGridCellProps";

export const MenuIconCell = ({
    dataItem,
}: TypedGridCellProps<FileViewEntry>): JSX.Element => {
    /* The context menu event is simulated in order not to duplicate the logic/layout that is already used.
     */
    const simulateContextMenuEvent = (
        leftClick: React.MouseEvent<HTMLTableCellElement>,
    ) => {
        leftClick.preventDefault();
        leftClick.stopPropagation();
        simulateHtmlEvent(leftClick.target, "contextmenu", (ev) => {
            const mouseEv =
                ev as unknown as React.MouseEvent<HTMLTableRowElement>;
            mouseEv.clientX = leftClick.clientX;
            mouseEv.clientY = leftClick.clientY;
        });
    };
    const [role, onClick, content] =
        dataItem.type === EntryType.Folder || dataItem.type === EntryType.File
            ? ["button", simulateContextMenuEvent, <FaEllipsisV key={0} />]
            : [undefined, undefined, null];
    return (
        <CenteredCell role={role} onClick={onClick}>
            {content}
        </CenteredCell>
    );
};
