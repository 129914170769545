import React from "react";
import { Button } from "react-bootstrap";
import { useParams } from "react-router";
import { useRemovePermissionMutation } from "../../../../store/features/organisation/organisation-api-slice";
import { TablePermission } from "./PermissionTable";

interface Props {
    permission: TablePermission;
}

const PermissionTableActions: React.FC<Props> = (props) => {
    const { id: organisationId } = useParams<{ id: string }>();
    const [removePermission, removePermissionResult] =
        useRemovePermissionMutation();

    const click = (): void => {
        if (
            window.confirm("Are you sure you want to remove this permission?")
        ) {
            removePermission({
                orgId: organisationId,
                permissionId: props.permission.id,
            });
        }
    };

    return (
        <>
            <Button
                variant="danger"
                className="top5 ml-1"
                onClick={click}
                disabled={removePermissionResult.isLoading}
            >
                Remove
            </Button>
        </>
    );
};

export default PermissionTableActions;
