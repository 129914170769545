import * as React from "react";
import { Nav } from "react-bootstrap";
import { IconType } from "react-icons";

interface NavLinkProps {
    label: string;
    icon: IconType;
    to: string;
    eventKey: string;
    additionalClasses?: string;
}

export default function NavLinkExternal({
    label,
    icon: Icon,
    to,
    eventKey,
    additionalClasses,
}: NavLinkProps): JSX.Element {
    return (
        <Nav.Item className={additionalClasses}>
            <Nav.Link
                href={to}
                eventKey={eventKey}
                className="d-flex align-items-center"
                target={"_blank"}
            >
                <Icon size="16" className="menu-icon" />
                {label}
            </Nav.Link>
        </Nav.Item>
    );
}
