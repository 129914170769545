import * as React from "react";
import { Breadcrumb } from "react-bootstrap";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { MultiQueryResultStatus } from "../../../common/components/QueryResultStatus";
import { useTitle } from "../../../common/hooks/useTitle";
import OrganisationConstants from "../../../Constants/OrganisationConstants";
import TrackingObjectDetailsTitle from "../containers/TrackingObjectDetailsTitle";
import TrackingObjectFiles from "../containers/TrackingObjectFiles";
import TrackingObjectHistory from "../containers/TrackingObjectHistory";
import { useGetTrackingObjectByIdQuery } from "../store/TrackingObjectApiSlice";
import "./tracking-object-details-page.scss";

export const TrackingObjectDetailsPage: React.FC = () => {
    const { trackingObjectId, orgShortName } = useParams<{
        trackingObjectId: string;
        orgShortName: string;
    }>();
    const trackingObjQueryResult =
        useGetTrackingObjectByIdQuery(trackingObjectId);
    const { data: trackingObj } = trackingObjQueryResult;

    useTitle(trackingObj?.name);

    const files = [];
    const contentsWithoutFiles = trackingObj?.contents?.filter((item) => {
        const isFile = item.type === "file";
        if (isFile) files.push(item);
        return !isFile;
    });

    return (
        <>
            <MultiQueryResultStatus results={[trackingObjQueryResult]} />
            {trackingObj && (
                <div className="py-4 tracking-object-details-page">
                    <Breadcrumb>
                        <Breadcrumb.Item
                            linkAs={Link}
                            linkProps={{
                                to: `/${orgShortName}${OrganisationConstants.Asset}`,
                            }}
                        >
                            Assets
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>
                            {trackingObj.name}
                        </Breadcrumb.Item>
                    </Breadcrumb>
                    <TrackingObjectDetailsTitle
                        trackingObj={trackingObj}
                        orgShortName={orgShortName}
                    />
                    <div className="preview">
                        <div className="preview-title">
                            Preview of the latest form
                        </div>
                        {contentsWithoutFiles.map(({ type, label, value }) =>
                            type === "header" ? (
                                <h4 className="category-title">{label}</h4>
                            ) : (
                                <div className="item">
                                    <div>{label}</div>
                                    <div>{value}</div>
                                </div>
                            ),
                        )}
                    </div>
                    {files.length > 0 && (
                        <TrackingObjectFiles
                            files={files}
                            latestProcessActivityId={
                                trackingObj.latestProcessActivityId
                            }
                        />
                    )}
                    {trackingObj.inspectionHistory.length > 0 && (
                        <TrackingObjectHistory
                            inspectionHistory={trackingObj.inspectionHistory}
                            orgShortName={orgShortName}
                        />
                    )}
                </div>
            )}
        </>
    );
};
