import * as React from "react";
import { Figure } from "react-bootstrap";
import { getFormFile } from "../../../../services/FileDownloadService";
import { TypedGridCellProps } from "../../../types/TypedGridCellProps";
import { DynamicFormContext } from "../DynamicFormContextProvider";
import { FormControlFileTableDataItem } from "./FormControlFileTableDataItem";

interface Props {
    cell: TypedGridCellProps<FormControlFileTableDataItem>;
}

const FormControlFileTablePreview: React.FC<Props> = (props) => {
    const { getFileUrl } = React.useContext(DynamicFormContext);

    const { cell } = props;
    const { localFile, id: fileId } = cell.dataItem;

    const [fileBlob, setFileBlob] = React.useState(new Blob([]));

    const url = React.useMemo(() => getFileUrl(fileId), [getFileUrl, fileId]);

    React.useEffect(() => {
        if (url && fileId) {
            getFormFile(url)
                .then((a) => {
                    setFileBlob(a);
                })
                .catch(() => {
                    console.error("Failed to fetch img");
                    setFileBlob(new Blob([]));
                });
        } else {
            setFileBlob(new Blob([]));
        }
    }, [fileId, url]);

    if (localFile) {
        return (
            <Figure className="mb-0">
                <Figure.Image
                    className="mb-0"
                    width={35}
                    src={URL.createObjectURL(localFile)}
                />
            </Figure>
        );
    } else {
        return (
            <Figure className="mb-0">
                <Figure.Image
                    className="mb-0"
                    width={35}
                    src={URL.createObjectURL(fileBlob)}
                />
            </Figure>
        );
    }
};
export default FormControlFileTablePreview;
