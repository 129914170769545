import * as Formik from "formik";
import * as React from "react";

import { Button, ButtonGroup, ButtonToolbar } from "react-bootstrap";
import { CustomSelect, TextInput } from "../../../common/components/form";
import {
    MutationActionResult,
    MutationResult,
} from "../../../common/types/rtkQuery/MutationResult";
import {
    SelectOptionItem,
    mapToSelectOptions,
} from "../../../common/types/reactSelect/SelectOptionItem";

import ButtonSpinner from "../../../common/components/button/ButtonSpinner";
import DateTimeSelect from "../../../common/components/form/DateTimeSelect";
import MutationResultStatus from "../../../common/components/MutationResultStatus";
import { ProcessActivity } from "../domain/types";
import { ProcessActivityForm } from "../../process-activity-form/domain/types";
import { ProcessActivityPatchModel } from "../../../store/features/process-activity/types";
import { resolveActivityState } from "../helpers/activityStateHelpers";
import { useGetUsersByOrgIdQuery } from "../../../store/features/organisation/organisation-api-slice";
import { withUserAssgnmentFeatureFilter } from "../../common/filters/helpers/userFilters";

interface FormValues {
    assignedUser: SelectOptionItem;
    assignedUsers: SelectOptionItem[];
    displayName: string;
    scheduledStart?: Date;
    scheduledEnd?: Date;
}

const validate = (values: FormValues) => {
    const errors: Formik.FormikErrors<FormValues> = {};

    if (values.scheduledStart && !values.scheduledEnd) {
        errors.scheduledEnd = "Scheduled End is required";
    }

    if (values.scheduledEnd && !values.scheduledStart) {
        errors.scheduledStart = "Scheduled Start is required";
    }

    if (values.scheduledEnd && values.scheduledStart) {
        if (values.scheduledEnd <= values.scheduledStart)
            errors.scheduledEnd = "Scheduled End must be after Scheduled Start";
    }
    return errors;
};

export interface UpdateActionProps {
    actionCallback: (
        arg: ProcessActivityPatchModel,
    ) => MutationActionResult<ProcessActivityPatchModel, unknown>;
    actionResult: MutationResult;
}

interface Props {
    organisationId: string;
    activity: ProcessActivity;
    updateProps: UpdateActionProps;
}

const getAssignedUserIds = (values: FormValues, isMulti: boolean): string[] => {
    if (isMulti) return values.assignedUsers.map((x) => x.value);
    return values.assignedUser ? [values.assignedUser.value] : [];
};

const ProcessActivityDetailsForm: React.FC<Props> = ({
    organisationId,
    activity,
    updateProps,
}) => {
    const [isEditing, setIsEditing] = React.useState(false);
    const { canEdit } = resolveActivityState(activity);

    const { data: organisationUsers } = useGetUsersByOrgIdQuery(organisationId);

    const assignedToOptions = React.useMemo(() => {
        const filtered = withUserAssgnmentFeatureFilter(
            organisationUsers,
            activity.userAssignment,
        );

        return mapToSelectOptions(
            filtered,
            (i) => i.userId,
            (i) => i.username,
        );
    }, [activity.userAssignment, organisationUsers]);

    const initialValues: FormValues = React.useMemo(
        () => ({
            displayName: activity.displayName,
            assignedUser: assignedToOptions.find((o) =>
                activity.assignedUsers.some((x) => x.id === o.value),
            ),
            assignedUsers: assignedToOptions.filter((o) =>
                activity.assignedUsers.some((x) => x.id === o.value),
            ),
            scheduledStart: activity.scheduledStart,
            scheduledEnd: activity.scheduledEnd,
        }),
        [activity, assignedToOptions],
    );

    return (
        <div className="mt-3">
            <MutationResultStatus mutationResult={updateProps.actionResult} />
            <Formik.Formik
                enableReinitialize
                initialValues={initialValues}
                validate={validate}
                onSubmit={async (values: FormValues, { setSubmitting }) => {
                    updateProps
                        .actionCallback({
                            activityId: activity.id,
                            processId: activity.processId,
                            assignedUserIds: getAssignedUserIds(
                                values,
                                activity.userAssignment.isMulti,
                            ),
                            displayName: values.displayName,
                            scheduledStart: values.scheduledStart || null,
                            scheduledEnd: values.scheduledEnd || null,
                            assetIds: (activity as ProcessActivityForm)
                                ?.assetIds,
                        })
                        .unwrap()
                        .then(() => {
                            setIsEditing(false);
                        })
                        .finally(() => {
                            setSubmitting(false);
                        });
                }}
            >
                {({ values, handleSubmit, resetForm, isSubmitting }) => (
                    <Formik.Form onSubmit={handleSubmit}>
                        {activity.renameEnabled && (
                            <TextInput
                                label="Name"
                                name="displayName"
                                readOnly={!isEditing}
                            ></TextInput>
                        )}
                        {activity.scheduleEnabled && (
                            <DateTimeSelect
                                label="Scheduled Start"
                                name="scheduledStart"
                                value={values.scheduledStart?.toString()}
                                readOnly={
                                    !isEditing || !activity.scheduleEditable
                                }
                            ></DateTimeSelect>
                        )}
                        {activity.scheduleEnabled && (
                            <DateTimeSelect
                                label="Scheduled End"
                                name="scheduledEnd"
                                value={values.scheduledEnd?.toString()}
                                readOnly={
                                    !isEditing || !activity.scheduleEditable
                                }
                            ></DateTimeSelect>
                        )}
                        {activity.userAssignment.enabled && (
                            <CustomSelect
                                name={
                                    activity.userAssignment.isMulti
                                        ? "assignedUsers"
                                        : "assignedUser"
                                }
                                label="Select Assigned User"
                                placeholder="Select user..."
                                readOnly={
                                    !isEditing ||
                                    !activity.userAssignment.editable
                                }
                                options={assignedToOptions}
                                isMulti={activity.userAssignment.isMulti}
                            />
                        )}
                        {canEdit && (
                            <ButtonToolbar className="justify-content-end">
                                {isEditing ? (
                                    <ButtonGroup>
                                        <Button
                                            variant="primary"
                                            type="submit"
                                            disabled={isSubmitting}
                                        >
                                            Update{" "}
                                            {updateProps.actionResult
                                                .isLoading && <ButtonSpinner />}
                                        </Button>
                                        <Button
                                            variant="danger"
                                            className="ml-1"
                                            disabled={isSubmitting}
                                            onClick={() => {
                                                setIsEditing(false);
                                                resetForm();
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                    </ButtonGroup>
                                ) : (
                                    <Button
                                        variant="primary"
                                        onClick={(): void => setIsEditing(true)}
                                    >
                                        Edit
                                    </Button>
                                )}
                            </ButtonToolbar>
                        )}
                    </Formik.Form>
                )}
            </Formik.Formik>
        </div>
    );
};

export default ProcessActivityDetailsForm;
