export type LabelValuePair = [string, string];

export function mapDataToLabelValuePairs(
    data: unknown,
    summaryLabelsMap: Record<string, string>,
): LabelValuePair[] {
    return Object.entries(data).reduce<LabelValuePair[]>(
        (accumulator, [key, value]) => {
            const label = summaryLabelsMap[key];
            if (label)
                accumulator.push([label || key, value?.toString() ?? ""]);

            return accumulator;
        },
        [],
    );
}
