export function getYearsNames(startingYear = 2021): string[] {
    const today = new Date();
    const currentYear = today.getFullYear();
    const lastYear = currentYear + 2;

    const yearsNames: string[] = [];

    for (let i = startingYear; i <= lastYear; i++) {
        yearsNames.push(`${i}/${i + 1}`);
    }

    return yearsNames;
}
