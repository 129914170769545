import * as React from "react";
import { Nav } from "react-bootstrap";
import { IconType } from "react-icons";

export interface NavGroupLinkProps {
    label: string;
    icon: IconType;
    eventKey: string;
    additionalClasses?: string;
}

export function NavGroupLink({
    label,
    icon: Icon,
    eventKey,
    additionalClasses,
}: NavGroupLinkProps): JSX.Element {
    return (
        <Nav.Item className={additionalClasses}>
            <Nav.Link
                //as={Link}
                //disabled={true}
                eventKey={eventKey}
                className="d-flex align-items-center"
            >
                <Icon size="16" className="menu-icon" />
                {label}
            </Nav.Link>
        </Nav.Item>
    );
}
