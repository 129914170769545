import { push } from "connected-react-router";
import * as Formik from "formik";
import { useFormikContext } from "formik";
import { get } from "lodash";
import * as React from "react";
import { generatePath, useRouteMatch } from "react-router";
import * as yup from "yup";
import TextArea from "../../../common/components/form/TextArea";
import TextInput from "../../../common/components/form/TextInput";
import MutationResultStatus from "../../../common/components/MutationResultStatus";
import { useFormActions } from "../../../common/hooks/useFormActions";
import { useUiState } from "../../../common/hooks/useUiState";
import { parseJson } from "../../../helpers/jsonHelper";
import { useAppDispatch } from "../../../store/hooks";
import TemplateFormEditToolbar from "../components/TemplateFormEditToolbar";
import TemplateFormToolbar from "../components/TemplateFormToolbar";
import { BaseTemplateFormProps } from "../viewModel/templateForm";
import {
    TemplateFormValidationSchema,
    TemplateFormValues,
} from "../viewModel/TemplateFormValues";

const baseValidationSchema = yup.object({
    name: yup.string().required("Required"),
    config: yup
        .string()
        .required("Required")
        .test("not-null", "'Null' not allowed", (config) => config !== "null")
        .test(
            "is-json",
            "Json parse error",
            (config) => parseJson(config) !== undefined,
        ),
});

export type ValidatedCallback = ({
    value,
    isValid,
}: {
    value: string;
    isValid: boolean;
}) => void;

interface TemplateFormProps<T1, T2>
    extends BaseTemplateFormProps<TemplateFormValues, T1, T2> {
    validationSchema?: TemplateFormValidationSchema;
    disableConfigFields?: boolean;
}

/*** Disable eslint rules for {} in extend that is common to make generic component} ***/
// eslint-disable-next-line @typescript-eslint/ban-types
const TemplateForm = <T1 extends {}, T2 extends {}>(
    props: TemplateFormProps<T1, T2>,
): JSX.Element => {
    const appDispatch = useAppDispatch();
    const match = useRouteMatch();

    const formValidationSchema = React.useMemo(
        () =>
            props.validationSchema
                ? baseValidationSchema.concat(props.validationSchema)
                : baseValidationSchema,
        [props.validationSchema],
    );

    const uiStateResult = useUiState(props.templateId);
    const { uiState, setEdit } = uiStateResult;

    const { onSubmit, onCancel, onDelete } = useFormActions(props, {
        ...uiStateResult,
        setView: (result) => {
            uiStateResult.setView();
            const id = get(result, "id");
            if (id) {
                appDispatch(push(generatePath(match.path, { id })));
            }
        },
    });

    return (
        <>
            {uiState.isView && (
                <TemplateFormToolbar setEdit={setEdit} onDelete={onDelete} />
            )}
            <div className="pt-2">
                {props.createTemplate && (
                    <MutationResultStatus
                        mutationResult={props.createTemplate.actionResult}
                        showLoading
                    />
                )}
                {props.updateTemplate && (
                    <MutationResultStatus
                        mutationResult={props.updateTemplate.actionResult}
                        showLoading
                    />
                )}
                {props.deleteTemplate && (
                    <MutationResultStatus
                        mutationResult={props.deleteTemplate.actionResult}
                        showLoading
                    />
                )}

                <Formik.Formik
                    enableReinitialize
                    initialValues={props.initialValues}
                    validationSchema={formValidationSchema}
                    onSubmit={onSubmit}
                >
                    {({ values, handleSubmit, isSubmitting, resetForm }) => (
                        <Formik.Form onSubmit={handleSubmit}>
                            {props.onConfigValidated && (
                                <PostConfigValidation
                                    onValidated={props.onConfigValidated}
                                />
                            )}
                            <TextInput
                                label="Name"
                                placeholder="Name"
                                description="The name for your template."
                                name="name"
                                value={values.name}
                                readOnly={uiState.isView}
                            />
                            <TextArea
                                label="Description"
                                name="description"
                                value={values.description}
                                readOnly={uiState.isView}
                                rows={3}
                            />
                            <TextArea
                                label="Json Configuration"
                                name="config"
                                readOnly={
                                    props.disableConfigFields || uiState.isView
                                }
                                rows={20}
                            />
                            {props.initialValues.trackedObjects && (
                                <TextArea
                                    label="Tracked Objects"
                                    name="trackedObjects"
                                    readOnly={uiState.isView}
                                    rows={10}
                                />
                            )}
                            {!uiState.isView && (
                                <TemplateFormEditToolbar
                                    {...uiState}
                                    isSubmitting={isSubmitting}
                                    onCancel={onCancel}
                                    resetForm={resetForm}
                                />
                            )}
                        </Formik.Form>
                    )}
                </Formik.Formik>
            </div>
        </>
    );
};

const PostConfigValidation: React.FC<{
    onValidated: ValidatedCallback;
}> = ({ onValidated }) => {
    const { values, isValidating, errors, setErrors } =
        useFormikContext<TemplateFormValues>();

    React.useEffect(() => {
        // Trick that allows to catch all config filed changes after its validation,
        // when errors are updated (even if the error content hasn't changed)
        setErrors({ ...errors, config: "" });
    }, [errors, setErrors, values.config]);

    React.useEffect(() => {
        if (!isValidating) {
            onValidated({ value: values.config, isValid: !errors.config });
        }
    }, [errors, isValidating, onValidated, values.config]);

    return null;
};

export default TemplateForm;
