import PermissionConstants from "../../../common/permissions/PermissionConstants";
import React from "react";
import { Button } from "react-bootstrap";
import { hasUserOrgPermission } from "../../../store/features/user/user-api-slice";
import { useDeleteProcessByIdMutation } from "../../../store/features/process/process-api-slice";
import { ProcessListItem } from "../domain/types";
import ButtonSpinner from "../../../common/components/button/ButtonSpinner";
import { TypedGridCellProps } from "../../../common/types/TypedGridCellProps";

type Props = {
    organisationId: string;
    cell: TypedGridCellProps<ProcessListItem>;
};

const ProcessesListTableRowActions: React.FC<Props> = ({
    organisationId,
    cell,
}) => {
    // eslint-disable-next-line react/display-name
    const [deleteProcess, { isLoading: isDeleteLoading }] =
        useDeleteProcessByIdMutation();

    const canProcessDelete = hasUserOrgPermission(
        organisationId,
        PermissionConstants.OrgProcess.delete,
    ).hasPermission;

    const deleteClick = () => {
        if (window.confirm("Are you sure you want to delete this process?")) {
            deleteProcess(cell.dataItem.id);
        }
    };

    return (
        <td>
            {canProcessDelete && (
                <Button
                    variant="outline-danger"
                    className="top5 ml-1"
                    onClick={deleteClick}
                >
                    Delete
                    {isDeleteLoading && <ButtonSpinner />}
                </Button>
            )}
        </td>
    );
};

export default ProcessesListTableRowActions;
