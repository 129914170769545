import * as React from "react";
import { Col, Row } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import ProcessTemplateConstants from "../../../../Constants/ProcessTemplateConstants";
import ProcessTemplateList from "./ProcessTemplatesList";

const ProcessTemplateManagement: React.FC = () => {
    const { id: organisationId } = useParams<{ id: string }>();

    const searchParams = new URLSearchParams({
        organisationId: organisationId,
    });

    return (
        <Row>
            <Col>
                <Row className="top10">
                    <Col>
                        <Link
                            to={`${ProcessTemplateConstants.RoutePathCreate}?${searchParams}`}
                            className="btn btn-primary float-right"
                        >
                            Create
                        </Link>
                    </Col>
                </Row>
                <Row className="top10">
                    <Col>
                        <ProcessTemplateList></ProcessTemplateList>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default ProcessTemplateManagement;
