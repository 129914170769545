import * as React from "react";
import { useParams } from "react-router";
import { MultiQueryResultStatus } from "../../../common/components/QueryResultStatus";
import { useTitle } from "../../../common/hooks/useTitle";
import { useGetFileQuery } from "../../../store/features/file/fileApiSlice";
import { useOrganisationId } from "../../organisation/hooks/useOrganisationId";
import { FileComments } from "../components/FileComments";
import { FileDetails } from "../components/FileDetails";
import OlderFileVersionsGrid from "../components/OlderFileVersionsGrid";
import { FileRouteParams } from "../viewModel/FileRouteParams";

export const FileDetailsPage: React.FC = () => {
    const { folderId, fileId } = useParams<FileRouteParams>();

    const { organisationId, isLoadingOrgId } = useOrganisationId();
    const fileQueryResult = useGetFileQuery(
        {
            organisationId: organisationId,
            folderId,
            fileId,
        },
        { skip: !isLoadingOrgId || !fileId },
    );
    const { data: file } = fileQueryResult;

    useTitle(file?.name);

    return (
        <>
            <MultiQueryResultStatus results={[fileQueryResult]} />
            {file && (
                <>
                    <FileDetails
                        file={file}
                        organisationId={organisationId}
                        folderId={folderId}
                    />
                    <FileComments
                        fileId={fileId}
                        folderId={folderId}
                        organisationId={organisationId}
                    />
                </>
            )}
            {file?.olderFileVersions && (
                <OlderFileVersionsGrid
                    olderFileVersions={file.olderFileVersions}
                    fileId={file.id}
                    folderId={folderId}
                    organisationId={organisationId}
                />
            )}
        </>
    );
};
