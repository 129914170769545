import {
    ListItemProps,
    MultiSelect,
    MultiSelectChangeEvent,
    MultiSelectProps,
} from "@progress/kendo-react-dropdowns";
import * as React from "react";
import { useCallback, useEffect, useMemo, useState } from "react";

interface Props extends MultiSelectProps {
    defaultOptions: string[];
}

const CustomMultiSelect: React.FC<Props> = (props) => {
    const selectAllText = "Select all";
    const [isSelectedAll, setIsSelectedAll] = useState(false);

    const enrichedData = useMemo(
        () => [selectAllText].concat(props.data),
        [props.data],
    );

    useEffect(() => {
        const totalValuesCount =
            props.value.length + props.defaultOptions.length;
        const optionsCount = props.data.length;
        if (totalValuesCount === optionsCount && !isSelectedAll) {
            setIsSelectedAll(true);
        } else if (totalValuesCount !== optionsCount && isSelectedAll) {
            setIsSelectedAll(false);
        }
    }, [
        isSelectedAll,
        props.value.length,
        props.data.length,
        props.defaultOptions.length,
    ]);

    const onChange = (event: MultiSelectChangeEvent) => {
        const values = event.value as string[];
        const containsSelectAll = values.includes(selectAllText);
        if (containsSelectAll && !isSelectedAll) {
            setIsSelectedAll(true);
            event.value = props.data;
        } else if (containsSelectAll && isSelectedAll) {
            setIsSelectedAll(false);
            event.value = [];
        }

        props.onChange(event);
    };
    const renderer = useCallback(
        (
            li: React.ReactElement<
                HTMLLIElement,
                string | React.JSXElementConstructor<unknown>
            >,
            itemProps: ListItemProps,
        ) => {
            const { dataItem, selected } = itemProps;
            const isDefault = props.defaultOptions.includes(dataItem);
            const isSelectAll = dataItem === selectAllText && isSelectedAll;

            const child = (
                <span>
                    <input
                        type="checkbox"
                        name={dataItem}
                        checked={isDefault || isSelectAll || selected}
                        disabled={isDefault}
                        onChange={(e) => {
                            if (!isDefault)
                                itemProps.onClick(
                                    itemProps.index,
                                    e as unknown as React.MouseEvent<
                                        HTMLLIElement,
                                        MouseEvent
                                    >,
                                );
                        }}
                    />
                    &nbsp;{li.props.children}
                </span>
            );

            return isDefault ? (
                <li className="k-item" style={{ color: "#d1d1d1" }}>
                    {child}
                </li>
            ) : (
                React.cloneElement(li, li.props, child)
            );
        },
        [props.defaultOptions, isSelectedAll],
    );

    return (
        <MultiSelect
            {...props}
            data={enrichedData}
            onChange={onChange}
            itemRender={renderer}
            autoClose={false}
        />
    );
};

export default CustomMultiSelect;
