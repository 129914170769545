import { FieldArray } from "formik";
import React from "react";
import FormTemplatesConstants from "../../../Constants/FormTemplatesConstants";
import FormControlEmployees from "../../../modules/ssow/FormControlEmployees/components/FormControlEmployees";
import FormControlRisk from "../../../modules/ssow/FormControlRisk/components/FormControlRisk";
import FormControlSiteRisk from "../../../modules/ssow/FormControlSiteRisk/components/FormControlSiteRisk";
import {
    TemplateContent,
    TemplateContentFile,
    TemplateContentSelect,
    TemplateContentTable,
    TemplateContentTextTemplate,
    TemplateContentUserSelector,
} from "../../../modules/template/domain/types";
import FormControlCheckbox from "./FormControlCheckBox";
import FormControlDate from "./FormControlDate";
import FormControlFile from "./FormControlFile/FormControlFile";
import FormControlLocation from "./FormControlLocation";
import FormControlNumber from "./FormControlNumber";
import FormControlText from "./FormControlText";
import FormControlTime from "./FormControlTime";
import FormControlUserSelect from "./FormControlUserSelect";
import FormControlWhat3Words from "./FormControlWhat3Words";
import FormHeaderComponent from "./FormHeaderComponent";
import FormTable from "./FormTable";
import FormUriComponent from "./FormUriComponent";
import FormControlSelect from "./Select/FormControlSelect";
import FormControlTextArea from "./TextArea/TextAreaComponent";
import TextAreaConfigBuilder from "./TextArea/TextAreaConfigBuilder";
import FormControlTextTemplateSelect from "./TextTemplate/FormControlTextTemplate";
import TextTemplateConfigRowsBuilder from "./TextTemplate/TextTemplateConfigRowsBuilder";

interface Props {
    config: TemplateContent;
    isFormReadOnly: boolean;
}

const FormContentBuilder: React.FC<Props> = ({
    config,
    isFormReadOnly,
}: Props) => {
    switch (config.type) {
        case FormTemplatesConstants.ContentType.Text:
            return (
                <FormControlText
                    config={{
                        field: config.field,
                        label: config.label,
                        style: config.style,
                        prompt: config.prompt,
                        uri: config.uri,
                        isReadOnly: config.readOnly || isFormReadOnly,
                        showConditions: config.showConditions,
                    }}
                />
            );
        case FormTemplatesConstants.ContentType.Checkbox:
            return (
                <FormControlCheckbox
                    config={{
                        field: config.field,
                        label: config.label,
                        style: config.style,
                        prompt: config.prompt,
                        uri: config.uri,
                        isReadOnly: config.readOnly || isFormReadOnly,
                        showConditions: config.showConditions,
                    }}
                />
            );
        case FormTemplatesConstants.ContentType.Number:
            return (
                <FormControlNumber
                    config={{
                        field: config.field,
                        label: config.label,
                        style: config.style,
                        prompt: config.prompt,
                        uri: config.uri,
                        isReadOnly: config.readOnly || isFormReadOnly,
                        showConditions: config.showConditions,
                    }}
                />
            );
        case FormTemplatesConstants.ContentType.Select:
            return (
                <FormControlSelect
                    config={{
                        field: (config as TemplateContentSelect).field,
                        label: (config as TemplateContentSelect).label,
                        style: (config as TemplateContentSelect).style,
                        options: (config as TemplateContentSelect).options,
                        prompt: config.prompt,
                        uri: config.uri,
                        isReadOnly:
                            (config as TemplateContentSelect).readOnly ||
                            isFormReadOnly,
                        showConditions: config.showConditions,
                        isMulti: (config as TemplateContentSelect).isMulti,
                    }}
                />
            );
        case FormTemplatesConstants.ContentType.Header:
            return (
                <FormHeaderComponent
                    config={{
                        field: config.field,
                        style: config.style,
                        value: config.label,
                        prompt: config.prompt,
                        uri: config.uri,
                        showConditions: config.showConditions,
                    }}
                />
            );
        case FormTemplatesConstants.ContentType.Uri:
            return (
                <FormUriComponent
                    config={{
                        field: config.field,
                        style: config.style,
                        value: config.label,
                        prompt: config.prompt,
                        uri: config.uri,
                        showConditions: config.showConditions,
                    }}
                />
            );
        case FormTemplatesConstants.ContentType.Table:
            return SwitchTable({ config, isFormReadOnly });
        case FormTemplatesConstants.ContentType.Date:
            return (
                <FormControlDate
                    config={{
                        field: config.field,
                        label: config.label,
                        style: config.style,
                        prompt: config.prompt,
                        uri: config.uri,
                        isReadOnly: config.readOnly || isFormReadOnly,
                        showConditions: config.showConditions,
                    }}
                    type="date"
                />
            );
        case FormTemplatesConstants.ContentType.DateTime:
            return (
                <FormControlDate
                    config={{
                        field: config.field,
                        label: config.label,
                        style: config.style,
                        prompt: config.prompt,
                        uri: config.uri,
                        isReadOnly: config.readOnly || isFormReadOnly,
                        showConditions: config.showConditions,
                    }}
                    type="datetime"
                />
            );
        case FormTemplatesConstants.ContentType.Time:
            return (
                <FormControlTime
                    config={{
                        field: config.field,
                        label: config.label,
                        style: config.style,
                        prompt: config.prompt,
                        uri: config.uri,
                        isReadOnly: config.readOnly || isFormReadOnly,
                        showConditions: config.showConditions,
                    }}
                />
            );
        case FormTemplatesConstants.ContentType.TextArea:
            return (
                <FormControlTextArea
                    config={TextAreaConfigBuilder.BuildConfig(config, {
                        isReadOnly: isFormReadOnly,
                    })}
                />
            );
        case FormTemplatesConstants.ContentType.File:
            return (
                <FormControlFile
                    config={{
                        field: config.field,
                        label: config.label,
                        style: config.style,
                        prompt: config.prompt,
                        uri: config.uri,
                        files: (config as TemplateContentFile).files,
                        validationsFile: (config as TemplateContentFile)
                            .validationsFile,
                        validationsComment: (config as TemplateContentFile)
                            .validationsComment,
                        isReadOnly: config.readOnly || isFormReadOnly,
                        mediaOnly: (config as TemplateContentFile).mediaOnly,
                        multiple: (config as TemplateContentFile).multiple,
                        showConditions: config.showConditions,
                    }}
                />
            );
        case FormTemplatesConstants.ContentType.UserSelector:
            return (
                <FormControlUserSelect
                    config={{
                        field: config.field,
                        label: config.label,
                        style: config.style,
                        prompt: config.prompt,
                        uri: config.uri,
                        isReadOnly:
                            (config as TemplateContentUserSelector).readOnly ||
                            isFormReadOnly,
                        isMulti: (config as TemplateContentUserSelector)
                            .isMulti,
                        filter: (config as TemplateContentUserSelector).filter,
                        showConditions: config.showConditions,
                    }}
                />
            );
        case FormTemplatesConstants.ContentType.TextTemplate:
            return (
                <FormControlTextTemplateSelect
                    config={{
                        field: (config as TemplateContentTextTemplate).field,
                        label: (config as TemplateContentTextTemplate).label,
                        style: (config as TemplateContentTextTemplate).style,
                        options: (config as TemplateContentTextTemplate)
                            .options,
                        prompt: config.prompt,
                        uri: config.uri,
                        isReadOnly:
                            (config as TemplateContentTextTemplate).readOnly ||
                            isFormReadOnly,
                        showConditions: config.showConditions,
                        rows: parseInt(
                            config.configOptions?.find(
                                (a) =>
                                    a.option ===
                                    TextTemplateConfigRowsBuilder
                                        .SupportedOptions.Rows,
                            )?.value ?? "1",
                        ),
                    }}
                />
            );
        case FormTemplatesConstants.ContentType.Location:
            return (
                <FormControlLocation
                    config={{
                        field: config.field,
                        label: config.label,
                        style: config.style,
                        prompt: config.prompt,
                        uri: config.uri,
                        isReadOnly:
                            (config as TemplateContentUserSelector).readOnly ||
                            isFormReadOnly,
                        showConditions: config.showConditions,
                    }}
                />
            );
        case FormTemplatesConstants.ContentType.What3Words:
            return (
                <FormControlWhat3Words
                    config={{
                        field: config.field,
                        label: config.label,
                        style: config.style,
                        prompt: config.prompt,
                        uri: config.uri,
                        isReadOnly: config.readOnly || isFormReadOnly,
                        showConditions: config.showConditions,
                    }}
                />
            );
        case FormTemplatesConstants.ContentType.System:
            return <></>;
        default:
            return <div>Unsupported field</div>;
    }
};

const SwitchTable: React.FC<Props> = ({ config, isFormReadOnly }: Props) => {
    switch (config.field) {
        case "riskGrid":
            return (
                <FormControlRisk
                    config={{
                        field: config.field,
                        label: config.label,
                        style: config.style,
                        prompt: config.prompt,
                        uri: config.uri,
                        rows: (config as TemplateContentTable).rows,
                        isReadOnly: config.readOnly || isFormReadOnly,
                        multiple: true,
                        showConditions: config.showConditions,
                    }}
                />
            );
        case "siteRiskGrid":
            return (
                <FormControlSiteRisk
                    config={{
                        field: config.field,
                        label: config.label,
                        style: config.style,
                        prompt: config.prompt,
                        uri: config.uri,
                        rows: (config as TemplateContentTable).rows,
                        isReadOnly: config.readOnly || isFormReadOnly,
                        multiple: true,
                        showConditions: config.showConditions,
                    }}
                />
            );
        case "employeeGrid":
            return (
                <FormControlEmployees
                    config={{
                        field: config.field,
                        label: config.label,
                        style: config.style,
                        prompt: config.prompt,
                        uri: config.uri,
                        rows: (config as TemplateContentTable).rows,
                        isReadOnly: config.readOnly || isFormReadOnly,
                        multiple: true,
                        showConditions: config.showConditions,
                    }}
                />
            );
        default:
            return (
                <FieldArray name={config.field}>
                    {(arrayHelpers) => (
                        <FormTable
                            config={{
                                field: config.field,
                                label: config.label,
                                prompt: config.prompt,
                                uri: config.uri,
                                columns: (config as TemplateContentTable)
                                    .columns,
                                rows: (config as TemplateContentTable).rows,
                                isReadOnly: config.readOnly || isFormReadOnly,
                                columnGroups: (config as TemplateContentTable)
                                    .columnGroups,
                                showConditions: config.showConditions,
                            }}
                            arrayHelpers={arrayHelpers}
                        />
                    )}
                </FieldArray>
            );
    }
};

export default FormContentBuilder;
