import { DateTime } from "luxon";
import { ExaminationDto } from "./examinationDto";
import {
    ExaminationPlanning,
    ExaminationScheduleProgressStatus,
    ExaminationStatusType,
} from "./examinationsPlanning";

export interface ExaminationPlanningDto extends ExaminationDto {
    carrsGuid?: string;
    carrsExamId?: string;
    contractLotNumber?: string;
    chains?: string;

    nrRequestedDate?: string;
    plannedStartDate?: string;
    plannedExaminer?: string;
    status: ExaminationStatusType;
    processTemplateName: string;
    programmeDate?: string;
    baselineDate?: string;
    examinationScheduleStatus: ExaminationScheduleProgressStatus;
    errorMessage: string;
    processState: string;
}

export const mapToExaminationPlanning = (
    dto: ExaminationPlanningDto,
): ExaminationPlanning => ({
    ...dto,
    nrRequestedDate: dto.nrRequestedDate
        ? DateTime.fromISO(dto.nrRequestedDate).toJSDate()
        : null,
    plannedStartDate: dto.plannedStartDate
        ? DateTime.fromISO(dto.plannedStartDate).toJSDate()
        : null,
    programmeDate: dto.programmeDate
        ? DateTime.fromISO(dto.programmeDate).toJSDate()
        : null,
    baselineDate: dto.baselineDate
        ? DateTime.fromISO(dto.baselineDate).toJSDate()
        : null,
});

export interface ExaminationScheduleModel {
    processActivityIds: string[];
    scheduledStart?: Date;
    scheduledEnd?: Date;
}

export interface ExaminationAssignModel {
    processActivityIds: string[];
    assignedUserIds?: string[];
}

export interface ExaminationCancelModel {
    processActivityIds: string[];
    nrCrNumber: string;
    supplementaryInfo: string;
}

export interface ExaminationStateChangeModel {
    processActivityIds: string[];
}
