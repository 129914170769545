import * as React from "react";
import { Breadcrumb, Row, Col, Card } from "react-bootstrap";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import CoveringLoadingPanel from "../../../../common/components/CoveringLoadingPanel";
import { AppRoutes } from "../../../../router/AppRoutes";
import { useOrganisationId } from "../../../organisation/hooks/useOrganisationId";
import { useGetProcessActivityGroupByIdQuery } from "../../../process-activity-group/store/processActivityGroupApiSlice";
import ProcessActivityAlertBar from "../../../process-activity/components/ProcessActivityAlertBar";
import EngineerAssignedAlert from "../components/EngineerAssignedAlert";
import ExaminationApproval from "../components/ExaminationApproval/ExaminationApproval";
import ExaminationDefectsGrid from "../components/ExaminationDefectsGrid/ExaminationDefectsGrid";
import ExaminationRecommendationsGrid from "../components/ExaminationRecommendationsGrid/ExaminationRecommendationsGrid";
import ReviewFindings from "../components/ReviewFindings/ReviewFindings";
import ReviewSummary from "../components/ReviewSummary/ReviewSummary";
import UnexaminedParts from "../components/UnexaminedParts/UnexaminedParts";
import { useGetExaminationReviewById } from "../query/examinationsReviewQueries";
import pageTitles from "./pageTitles";

const ExaminationReviewDetailsPage: React.FC = () => {
    const { reviewId, orgShortName } = useParams<{
        reviewId: string;
        orgShortName: string;
    }>();

    const { organisationId, isLoadingOrgId } = useOrganisationId();

    const { data: review, isFetching: isLoadingReview } =
        useGetExaminationReviewById(organisationId, reviewId);

    const { data: reviewPaGroup, isLoading: isLoadingReviewPaGroup } =
        useGetProcessActivityGroupByIdQuery(
            {
                activityId: reviewId,
                processId: review?.processId,
            },
            {
                skip: !review?.processId ?? !"",
            },
        );

    if (isLoadingOrgId || isLoadingReview || isLoadingReviewPaGroup)
        return <CoveringLoadingPanel />;

    if (!review) return <></>;

    return (
        <>
            <div className="mt-2">
                <Breadcrumb>
                    <Breadcrumb.Item linkAs={"div"}>
                        <Link
                            to={AppRoutes.EngineerReview.toLink({
                                orgShortName,
                            })}
                        >
                            {pageTitles.reviewExaminations}
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>
                        {pageTitles.reviewExaminationDetails}
                    </Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <div>
                <h1>
                    {review.examType} {review.elr} {review.miles} {review.yards}{" "}
                    {review.chains} {review.structureName}
                </h1>
                <h3>{pageTitles.reviewExaminationDetails}</h3>
            </div>
            <EngineerAssignedAlert review={review} />
            {reviewPaGroup && (
                <ProcessActivityAlertBar activity={reviewPaGroup} />
            )}
            <Row>
                <Col className="mb-2">
                    <Card>
                        <Card.Header as="h5">Summary</Card.Header>
                        <Card.Body>
                            <ReviewSummary
                                processId={review.processId}
                                reivew={review}
                            />
                        </Card.Body>
                    </Card>
                </Col>
                <Col className="mb-2">
                    <Card>
                        <Card.Header as="h5">Report Actions</Card.Header>
                        <Card.Body>
                            <ExaminationApproval
                                orgShortName={orgShortName}
                                processId={review.processId}
                                activityId={review.approvalActivityId}
                            ></ExaminationApproval>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col className="mb-2">
                    <Card>
                        <Card.Header as="h5">Defects</Card.Header>
                        <Card.Body>
                            <ExaminationDefectsGrid
                                organisationId={organisationId}
                                reviewId={reviewId}
                            />
                        </Card.Body>
                    </Card>
                </Col>
                <Col className="mb-2">
                    <Card>
                        <Card.Header as="h5">Recommendations</Card.Header>
                        <Card.Body>
                            <ExaminationRecommendationsGrid
                                organisationId={organisationId}
                                reviewId={reviewId}
                            />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col className="mb-2">
                    <Card>
                        <Card.Header as="h5">Unexamined Parts</Card.Header>
                        <Card.Body>
                            <UnexaminedParts
                                processId={review.processId}
                                reviewId={review.id}
                                organisationId={organisationId}
                            ></UnexaminedParts>
                        </Card.Body>
                    </Card>
                </Col>
                <Col className="mb-2">
                    <Card>
                        <Card.Header as="h5">Engineers Comments</Card.Header>
                        <Card.Body>
                            <ReviewFindings
                                reviewId={review.id}
                                processId={review.processId}
                                organisationId={organisationId}
                            ></ReviewFindings>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default ExaminationReviewDetailsPage;
