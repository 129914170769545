import { State } from "@progress/kendo-data-query";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import {
    Grid,
    GridColumn,
    GridNoRecords,
    GridToolbar,
} from "@progress/kendo-react-grid";
import React from "react";
import { Button } from "react-bootstrap";
import CustomPagingFooter from "../../../../common/components/table/kendo/CustomPagingFooter";
import { MultiTextFilterCell } from "../../../../common/components/table/kendo/filterCells/MultiTextFilterCell";
import {
    GridFieldDef,
    GridFieldType,
} from "../../../../common/components/table/kendo/GridFieldsMap";
import GridLoadingPanel from "../../../../common/components/table/kendo/GridLoadingPanel";
import { resolveSortAndFilterOptions } from "../../../../common/components/table/kendo/helpers";
import useInfiniteGridProps from "../../../../common/components/table/kendo/useInfiniteGridProps";
import useInfiniteDataResult from "../../../../common/hooks/useInfiniteDataResult";
import { getYearsNames } from "../../common/helpers/contractYearsHelper";
import { useGetInfiniteExamRequests } from "../query/examRequestsQueries";
import {
    downloadExamRequestsCsv,
    ExamRequestsSearchOptions,
} from "../services/examRequestsService";
import "./examRequestsInfiniteGrid.scss";
import {
    gridFieldsMap,
    gridFilterOperators,
} from "./examRequestsInfiniteGridConfiguration";

const gridColProps = (field: GridFieldDef, index: number) => ({
    key: index,
    field: field.field,
    title: field.label || field.field,
    width: 300,
    sortable: (field.sortable ?? true) && !field.isCustomField,
    filterable: field.filterable ?? true,
});

const renderColumn = (field: GridFieldDef, index: number): JSX.Element => {
    switch (field.fieldType) {
        case GridFieldType.MultiText: {
            return (
                <GridColumn
                    {...gridColProps(field, index)}
                    filterCell={MultiTextFilterCell}
                />
            );
        }
        case GridFieldType.Date: {
            if (field.field === "recordModifiedDate")
                return (
                    <GridColumn
                        {...gridColProps(field, index)}
                        filterable={false}
                        format="{0:dd/MM/yyyy HH:mm:ss}"
                    />
                );
            return (
                <GridColumn
                    {...gridColProps(field, index)}
                    filter={"date"}
                    format="{0:dd/MM/yyyy}"
                />
            );
        }
        case GridFieldType.Number: {
            return (
                <GridColumn
                    {...gridColProps(field, index)}
                    filter={"numeric"}
                />
            );
        }
        default: {
            return <GridColumn {...gridColProps(field, index)} />;
        }
    }
};

const constractYearOptions = getYearsNames();

interface Props {
    organisationId: string;
}

const ExamRequestsInfiniteGrid: React.FC<Props> = ({ organisationId }) => {
    const wrapperRef = React.createRef<HTMLElement>();
    const [dataState, setDataState] = React.useState<State>({});
    const [contractYear, setContractYear] = React.useState<string | null>(null);
    const [isExporting, setIsExporting] = React.useState(false);

    const query: ExamRequestsSearchOptions = React.useMemo(
        () => ({
            ...resolveSortAndFilterOptions(dataState, gridFieldsMap),
            contractYear,
        }),
        [dataState, contractYear],
    );

    const { data, fetchNextPage, hasNextPage, isFetching, isFetchingNextPage } =
        useGetInfiniteExamRequests(organisationId, query);

    const { flatData, totalCount, dataCount } = useInfiniteDataResult(data);

    const isLoading = isFetching || isFetchingNextPage;

    const gridProps = useInfiniteGridProps({
        data: flatData,
        dataState,
        hasNextPage,
        setDataState,
        loading: isLoading,
        fetchMore: () => {
            fetchNextPage();
        },
        gridRef: wrapperRef,
    });

    const handleExport = async (): Promise<void> => {
        setIsExporting(true);
        try {
            await downloadExamRequestsCsv({
                organisationId,
                contractYear,
            });
        } finally {
            setIsExporting(false);
        }
    };

    return (
        <div
            ref={wrapperRef as React.RefObject<HTMLDivElement>}
            className="exam-requests-grid pt-2"
        >
            <Grid
                {...gridProps}
                filterable
                filterOperators={gridFilterOperators}
                className="exam-requests-grid"
            >
                <GridToolbar>
                    <Button
                        className="ml-1"
                        variant="primary"
                        onClick={handleExport}
                        disabled={isExporting}
                    >
                        Export to CSV
                    </Button>
                    <div className="flex-grow-1 justify-content-end">
                        <ComboBox
                            data={constractYearOptions}
                            placeholder="Select contract year"
                            value={contractYear}
                            onChange={(event) => setContractYear(event.value)}
                            style={{ width: "300px" }}
                            allowCustom={false}
                        />
                    </div>
                </GridToolbar>
                <GridNoRecords>There is no data available</GridNoRecords>
                {Object.values(gridFieldsMap).map(renderColumn)}
            </Grid>

            {isLoading && <GridLoadingPanel gridRef={wrapperRef} />}
            <CustomPagingFooter
                total={totalCount}
                loading={isLoading}
                dataCount={dataCount}
            />
        </div>
    );
};

export default ExamRequestsInfiniteGrid;
