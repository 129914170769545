import * as React from "react";
import { Navbar, Nav } from "react-bootstrap";
import "./NavMenu.css";
import { Link } from "react-router-dom";
import LoginMenu from "./LoginMenu";
import { WithOrgName } from "../../types/WithOrg";
import { useGetConfigQuery } from "../../../store/config/configApi";

const OrganisationNavMenu: React.FC<WithOrgName> = ({ orgName }) => {
    const { data: config } = useGetConfigQuery();

    let title = "DCP";

    if (
        config.Branding?.Title !== undefined &&
        config.Branding?.Title !== null
    ) {
        title = config.Branding.Title;
    }

    return (
        <Navbar
            className="navbar-expand-sm navbar-toggleable-sm border-bottom box-shadow mb-3 fixed-top"
            bg="light"
            expand="lg"
        >
            <Navbar.Brand as={Link} className="col-sm-3 col-md-2 mr-0" to="/">
                {title}
            </Navbar.Brand>
            <Navbar.Brand as={Link} to="/">
                {orgName}
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ml-auto">
                    <LoginMenu />
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
};

export default OrganisationNavMenu;
