import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import * as Formik from "formik";
import * as React from "react";
import { Button, ButtonToolbar } from "react-bootstrap";
import * as yup from "yup";
import ButtonSpinner from "../../../common/components/button/ButtonSpinner";
import { CustomSelect, TextInput } from "../../../common/components/form";
import {
    mapToSelectOptions,
    SelectOptionItem,
} from "../../../common/types/reactSelect/SelectOptionItem";
import {
    ActivityActionParams,
    PostNotApplicableParams,
} from "../../../store/features/process-activity/types";
import { ProcessActivity, ProcessActivityType } from "../domain/types";
import { resolveActivityState } from "../helpers/activityStateHelpers";
interface ActionResult {
    isLoading: boolean;
    isError: boolean;
    error?: unknown;
}
export interface ActionBarActionProps {
    actionCallback: (arg: ActivityActionParams) => Promise<unknown> | void;
    actionResult: ActionResult;
}

export interface ActionBarNotApplicableActionProps {
    actionCallback: (arg0: PostNotApplicableParams) => Promise<unknown> | void;
    actionResult: ActionResult;
}

interface Props {
    activity: ProcessActivity;
    complete: ActionBarActionProps;
    notApplicable: ActionBarNotApplicableActionProps;
}

interface NotApplicableFormValues {
    comment: string;
    reason: SelectOptionItem;
}

const ProcessActivityActionBar: React.FC<Props> = (props) => {
    const [showDialog, setShowDialog] = React.useState<boolean>(false);

    const { activity, complete, notApplicable } = props;
    const { isComplete, isNotApplicable, canChangeStatus } =
        resolveActivityState(activity);

    if (isComplete || isNotApplicable) return null;

    const displayNotApplicable =
        activity.notApplicableFeature?.enabled ?? false;
    const displayComplete = activity.type !== ProcessActivityType.Approval;

    const clickComplete = (): void => {
        if (
            window.confirm("Are you sure you want to complete this activity?")
        ) {
            complete.actionCallback({ activity });
        }
    };

    const clickNotApplicable = (formValues: NotApplicableFormValues): void => {
        notApplicable.actionCallback({
            activity,
            reason: formValues.reason?.value,
            comment: formValues.comment,
        });

        setShowDialog(false);
    };

    const initialFormValues: NotApplicableFormValues = {
        comment: "",
        reason: null,
    };
    const validation = yup.object({
        reason: activity.notApplicableFeature.reasons
            ? yup.object().nullable().required("Required")
            : null,
    });

    if (!canChangeStatus) return null;

    return (
        <>
            <div className="py-2">
                <ButtonToolbar className="justify-content-end">
                    {displayComplete && (
                        <Button
                            className="ml-1"
                            variant="success"
                            onClick={clickComplete}
                            disabled={complete.actionResult.isLoading}
                        >
                            Complete
                            {complete.actionResult.isLoading && (
                                <ButtonSpinner />
                            )}
                        </Button>
                    )}
                    {displayNotApplicable && (
                        <Button
                            className="ml-1"
                            variant="secondary"
                            onClick={() => setShowDialog(true)}
                            disabled={notApplicable.actionResult.isLoading}
                        >
                            Not Applicable
                            {notApplicable.actionResult.isLoading && (
                                <ButtonSpinner />
                            )}
                        </Button>
                    )}
                </ButtonToolbar>
            </div>
            {showDialog && (
                <Dialog
                    title="Please confirm"
                    onClose={() => setShowDialog(false)}
                >
                    <div className="my-2">
                        <p>
                            Are you sure you want to mark this activity as not
                            applicable?
                        </p>
                        <p>
                            All sub activities will also be marked not
                            applicable.
                        </p>
                    </div>

                    <Formik.Formik
                        initialValues={initialFormValues}
                        validationSchema={validation}
                        onSubmit={clickNotApplicable}
                    >
                        {() => (
                            <Formik.Form>
                                {activity.notApplicableFeature.reasons && (
                                    <CustomSelect
                                        name="reason"
                                        label="Reason"
                                        placeholder="Select reason..."
                                        options={mapToSelectOptions(
                                            activity.notApplicableFeature
                                                .reasons,
                                            (i) => i,
                                            (i) => i,
                                        )}
                                    />
                                )}
                                <TextInput
                                    label="Comment (optional)"
                                    name="comment"
                                    placeholder="Write a comment..."
                                />
                                <DialogActionsBar layout="end">
                                    <Button
                                        variant="link"
                                        onClick={() => setShowDialog(false)}
                                    >
                                        Back
                                    </Button>
                                    <Button variant="primary" type="submit">
                                        Confirm
                                    </Button>
                                </DialogActionsBar>
                            </Formik.Form>
                        )}
                    </Formik.Formik>
                </Dialog>
            )}
        </>
    );
};

export default ProcessActivityActionBar;
