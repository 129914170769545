import { useEffect, useState } from "react";

export interface HookUiResult {
    uiState: {
        isEdit: boolean;
        isView: boolean;
        isCreate: boolean;
    };
    setCreate: () => void;
    setEdit: () => void;
    setView: () => void;
}

export const useUiState = (templateId: string): HookUiResult => {
    const [uiState, setUiState] = useState<"view" | "edit" | "create">("view");

    const setCreate = () => setUiState("create");

    const setEdit = () => setUiState("edit");

    const setView = () => setUiState("view");

    useEffect(() => {
        if (!templateId && uiState !== "create") {
            setCreate();
        }
    }, [templateId, uiState]);

    return {
        uiState: {
            isEdit: uiState === "edit",
            isView: uiState === "view",
            isCreate: uiState === "create",
        },
        setCreate,
        setEdit,
        setView,
    };
};
