import * as React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import ProcessActivityCard from "../../process-activity/components/ProcessActivityCard";
import ProcessActivityDropdownMenu from "../../process-activity/components/ProcessActivityDropdownMenu";
import { resolveActivityStatusFlags } from "../../process-activity/helpers/activityStateHelpers";
import { ProcessActivityListItemModel } from "../domain/types";

interface Props {
    activity: ProcessActivityListItemModel;
    activityUrl: string;
    readonly: boolean;
    onDelete: (activityItemId: string) => void;
}

const ProcessActivityListListItem: React.FC<Props> = ({
    activity,
    activityUrl,
    readonly,
    onDelete,
}) => {
    const { isDisabled } = resolveActivityStatusFlags(activity);
    const dropdownListDisabled = readonly || !activity.canDelete;

    const clickDelete = (): void => {
        //todo: Introduce confirmation popup compoenent to replace window.confirm
        if (window.confirm("Are you sure you want to delete this item?")) {
            onDelete(activity.id);
        }
    };

    return (
        <ProcessActivityCard activity={activity}>
            <ProcessActivityDropdownMenu
                onDelete={clickDelete}
                isReadonly={dropdownListDisabled}
            />
            <p className="card-text">{activity.description}</p>
            <div className="d-flex justify-content-end">
                {isDisabled ? (
                    <Button disabled>Disabled</Button>
                ) : (
                    <>
                        <Link to={activityUrl} className="btn btn-primary">
                            View
                        </Link>
                    </>
                )}
            </div>
        </ProcessActivityCard>
    );
};
export default ProcessActivityListListItem;
