import * as React from "react";
import { Alert, Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import ButtonSpinner from "../../../common/components/button/ButtonSpinner";
import InfiniteScrollWrapper from "../../../common/components/InfiniteScrollWrapper";
import InlineCenteredLoader from "../../../common/components/InlineCenteredLoader";
import MutationResultStatus from "../../../common/components/MutationResultStatus";
import { SortAndFilterOptions } from "../../../common/types/SortAndFilterOptions";
import { resolveActivityState } from "../../process-activity/helpers/activityStateHelpers";
import { useActivityNavigation } from "../../process-activity/hooks/useActivityNavigation";
import {
    ProcessActivityList,
    ProcessActivityListItemModel,
} from "../domain/types";
import { useGetInfiniteProcessActivityListItems } from "../queries/processActivityListQueries";
import {
    useDeleteProcessActivityListItemMutation,
    usePostProcessActivityListCreateListItemMutation,
} from "../store/processActivityListApiSlice";
import ProcessActivityListListItem from "./ProcessActivityListListItem";

interface Props {
    activity: ProcessActivityList;
    orgShortName: string;
}

const ProcessActivityListList: React.FC<Props> = ({
    activity,
    orgShortName,
}) => {
    const [searchFilter, setSearchFilter] = React.useState("");
    const [showNewItemLink, setShowNewItemLink] = React.useState(false);
    const { canEdit } = resolveActivityState(activity);

    const { getActivityUrl } = useActivityNavigation(
        orgShortName,
        activity.processId,
    );

    const [createNewItem, createNewItemResult] =
        usePostProcessActivityListCreateListItemMutation();
    const [deleteListItem, deleteListItemResult] =
        useDeleteProcessActivityListItemMutation();

    const handleCreate = (): void => {
        createNewItem(activity.id);
        setShowNewItemLink(true);
    };

    const handleDelete = (activityItemId: string): void => {
        deleteListItem({
            activityId: activity.id,
            listItemActivityId: activityItemId,
        });
        setShowNewItemLink(false);
    };

    const query = React.useMemo((): SortAndFilterOptions => {
        if (searchFilter === "") return {};
        return {
            filterParam: [
                {
                    field: "displayName",
                    dataType: "string",
                    isCustomField: false,
                    value: searchFilter,
                    operator: "contains",
                },
            ],
        };
    }, [searchFilter]);

    const { data, fetchNextPage, hasNextPage, isFetching, isLoading } =
        useGetInfiniteProcessActivityListItems(activity.id, query);

    const listItems = React.useMemo(() => {
        if (!data) return [];
        return data?.pages?.map((page) => page.data).flat() || [];
    }, [data]);

    return (
        <>
            <div className="mt-3 d-flex justify-content-between align-items-center">
                <div className="w-75">
                    <Form.Control
                        type="text"
                        placeholder="Search All ..."
                        onChange={(e) => setSearchFilter(e.target.value)}
                        value={searchFilter}
                        aria-describedby="basic-addon1"
                    />
                </div>
                {canEdit && (
                    <div className="ml-3">
                        <Button
                            variant="primary"
                            onClick={handleCreate}
                            disabled={
                                createNewItemResult.isLoading ||
                                activity.listReadonly
                            }
                        >
                            Create{" "}
                            {createNewItemResult.isLoading && <ButtonSpinner />}
                        </Button>
                    </div>
                )}
            </div>

            {createNewItemResult.isSuccess && showNewItemLink && (
                <div className="pt-2">
                    <Alert variant="success">
                        Create success{" "}
                        <Alert.Link>
                            <Link
                                to={getActivityUrl(
                                    createNewItemResult.data.activityId,
                                )}
                            >
                                Go to new item
                            </Link>
                        </Alert.Link>
                    </Alert>
                </div>
            )}

            <div className="pt-2">
                <MutationResultStatus mutationResult={createNewItemResult} />
                <MutationResultStatus mutationResult={deleteListItemResult} />
                <InfiniteScrollWrapper
                    fetchNextPage={fetchNextPage}
                    hasNextPage={hasNextPage}
                >
                    {listItems.map(
                        (item: ProcessActivityListItemModel, index: number) => (
                            <ProcessActivityListListItem
                                key={index.toString()}
                                activity={item}
                                activityUrl={getActivityUrl(item.id)}
                                readonly={!canEdit || activity.listReadonly}
                                onDelete={handleDelete}
                            />
                        ),
                    )}
                </InfiniteScrollWrapper>
                {!isFetching && listItems.length === 0 && (
                    <Alert variant="primary">
                        No items found {searchFilter && "in search results"}
                    </Alert>
                )}
                {isLoading && <InlineCenteredLoader />}
            </div>
        </>
    );
};

export default ProcessActivityListList;
