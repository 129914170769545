import { Button } from "@progress/kendo-react-buttons";
import { GridFilterCellProps } from "@progress/kendo-react-grid";
import React from "react";
import { Form } from "react-bootstrap";

interface Props extends GridFilterCellProps {
    value: string;
}

export const MultiTextContainsFilterCell: React.FC<Props> = (props) => {
    return <MultiTextFilterCell {...props} isExactMatch={false} />;
};

export const MultiTextFilterCell: React.FC<
    Props & { isExactMatch: boolean }
> = ({ onChange, value, isExactMatch = true }) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange({
            value: event.target.value as string,
            operator: isExactMatch ? "isIn" : "isInContains",
            syntheticEvent: event,
        });
    };

    const onClear: React.MouseEventHandler<HTMLButtonElement> = (event) => {
        event.preventDefault();
        onChange({
            value: "",
            operator: isExactMatch ? "isIn" : "isInContains",
            syntheticEvent: event,
        });
    };

    return (
        <div className="k-filtercell">
            <div className="k-filtercell-wrapper">
                <Form.Control
                    as="textarea"
                    rows={4}
                    className="k-textbox flex-grow-1"
                    onChange={handleChange}
                    value={value}
                />
                <Button
                    title="Clear"
                    disabled={!value}
                    onClick={onClear}
                    icon="filter-clear"
                />
            </div>
        </div>
    );
};
