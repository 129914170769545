import { Grid, GridColumn } from "@progress/kendo-react-grid";
import * as React from "react";
import { Button } from "react-bootstrap";
import CoveringLoadingPanel from "../../../../../common/components/CoveringLoadingPanel";
import {
    ContainsMenuFilter,
    emptyFilterDescriptor,
} from "../../../../../common/components/table/kendo/columnFilters";
import useGridProps from "../../../../../common/components/table/kendo/useGridProps";
import { useNavigation } from "../../../../../router/useNavigate";
import { useGetUnexaminedPartsByReviewId } from "../../query/examinationsReviewQueries";

interface Props {
    processId: string;
    reviewId: string;
    organisationId: string;
}

const UnexaminedParts: React.FC<Props> = ({
    processId,
    reviewId,
    organisationId,
}) => {
    const { navigateToOrgPath } = useNavigation();

    const { data: unexaminedParts, isFetching } =
        useGetUnexaminedPartsByReviewId(organisationId, reviewId);

    const { gridProps } = useGridProps(unexaminedParts?.data, {
        filterable: true,
        initialFilter: emptyFilterDescriptor,
        sortable: true,
        pageable: false,
    });

    if (isFetching) return <CoveringLoadingPanel />;

    return (
        <>
            <div className="pt-2">
                <Button
                    className="mr-1"
                    onClick={() => {
                        navigateToOrgPath(
                            `/:orgShortName/process/${processId}/activity/${unexaminedParts.activityId}`,
                        );
                    }}
                >
                    Edit
                </Button>
            </div>
            <div className="pt-2">
                <Grid {...gridProps}>
                    <GridColumn
                        field="part"
                        title="Part"
                        columnMenu={ContainsMenuFilter}
                    />
                    <GridColumn
                        field="reason"
                        title="Reason"
                        columnMenu={ContainsMenuFilter}
                    />
                </Grid>
            </div>
        </>
    );
};

export default UnexaminedParts;
