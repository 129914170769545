import * as React from "react";
import { Button } from "react-bootstrap";
import { FaDownload, FaTimes } from "react-icons/fa";
import { TypedGridCellProps } from "../../../types/TypedGridCellProps";
import { FormControlFileTableDataItem } from "./FormControlFileTableDataItem";

interface Props {
    onDownload: (dataItem: FormControlFileTableDataItem) => void;
    onRemove: (dataItem: FormControlFileTableDataItem) => void;
    readonly: boolean;
}

const FormControlFileTableActions: React.FC<
    TypedGridCellProps<FormControlFileTableDataItem> & Props
> = ({ dataItem, onDownload, onRemove, readonly }) => {
    const { localFile, id: fileId } = dataItem;

    const isRemote = !!fileId;
    const isLocal = !!localFile;

    const handleRemove = () => {
        if (window.confirm("Are you sure you want to delete this item?")) {
            onRemove(dataItem);
        }
    };

    return (
        <>
            {isRemote && (
                <div className="d-flex justify-content-between align-items-center">
                    <Button
                        size="sm"
                        variant="secondary"
                        className="ml-1  my-1"
                        onClick={() => {
                            onDownload(dataItem);
                        }}
                    >
                        <FaDownload />
                    </Button>
                    {!readonly && (
                        <Button
                            size="sm"
                            variant="danger"
                            className="ml-1"
                            onClick={handleRemove}
                        >
                            <FaTimes />
                        </Button>
                    )}
                </div>
            )}
            {isLocal && (
                <Button
                    size="sm"
                    variant="danger"
                    className="ml-1"
                    onClick={handleRemove}
                >
                    <FaTimes />
                </Button>
            )}
        </>
    );
};

export default FormControlFileTableActions;
