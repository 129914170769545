import React, { useCallback } from "react";
import { useInfiniteQuery, UseInfiniteQueryResult } from "react-query";
import { useDebounce } from "use-debounce";
import { DataContinuationResult } from "../../../common/types/DataResult";
import { ProcessListItem } from "../domain/types";
import {
    getInfiniteProcesses,
    ProcessSearchOptions,
} from "../services/processService";
import processQueryKeys from "./processQueryKeys";

export const useGetInfiniteProcesses = (
    organisationId: string,
    query: ProcessSearchOptions,
): UseInfiniteQueryResult<DataContinuationResult<ProcessListItem>> => {
    const [debouncedQuery] = useDebounce(query, 1000);
    const getExaminations = useCallback(
        ({
            pageParam = null,
        }): Promise<DataContinuationResult<ProcessListItem>> => {
            const continuationToken = pageParam;

            return getInfiniteProcesses({
                organisationId,
                query: { ...debouncedQuery, continuationToken },
            });
        },
        [debouncedQuery, organisationId],
    );

    const queryKeys = React.useMemo(
        () => processQueryKeys.filteredProcesses(debouncedQuery),
        [debouncedQuery],
    );

    return useInfiniteQuery<
        DataContinuationResult<ProcessListItem>,
        [string, { page: number }]
    >(queryKeys, getExaminations, {
        getNextPageParam: (lastResult) => {
            return lastResult.continuationToken || undefined;
        },
    });
};
