import {
    LoadPastRecommendationsPostModel,
    PastRecommendation,
} from "./../domain/PastRecommendation";
import React, { useCallback } from "react";
import {
    QueryKey,
    useInfiniteQuery,
    UseInfiniteQueryResult,
    useMutation,
    UseMutationResult,
    useQuery,
    useQueryClient,
    UseQueryResult,
} from "react-query";
import { DataPageResult } from "../../../../common/types/DataResult";
import { useInvalidateTags } from "../../../../store/hooks";
import { ExaminationAssignModel } from "../domain/examinationsPlanningDtos";
import {
    AggregatedDefectData,
    AggregatedRecommendationsData,
    ExaminationReview,
    ReviewFindings,
    UnexaminedParts,
} from "../domain/examinationsReview";
import {
    assignExaminationReviews,
    ExaminationReviewsSearchOptions,
    ExaminationsCount,
    getDefects,
    getExaminationReviewById,
    getExaminationReviews,
    getExaminationsCountAggregatedByType,
    getPastRecommendations,
    getRecommendations,
    getReviewFindingsByReviewId,
    getUnexaminedPartsByReviewId,
    loadPastRecommendations,
} from "../services/examinationsReviewService";
import { AssignExaminationMutation } from "./commonQueryTypes";
import examinationQueryKeys from "./examinationQueryKeys";
import { examinationQueryKeysTranslator } from "./examinationQueryKeysTranslator";

const { queryKeysToTags } = examinationQueryKeysTranslator;

export const useGetUnexaminedPartsByReviewId = (
    organisationId: string,
    reviewId: string,
): UseQueryResult<UnexaminedParts> => {
    const getUnexaminedParts = useCallback(() => {
        return getUnexaminedPartsByReviewId({ organisationId, reviewId });
    }, [organisationId, reviewId]);

    const queryKeys = React.useMemo(
        () => examinationQueryKeys.examinationReviewUnexaminedParts(reviewId),
        [reviewId],
    );

    return useQuery(queryKeys, getUnexaminedParts);
};

export const useGetExaminationReviewById = (
    organisationId: string,
    reviewId: string,
): UseQueryResult<ExaminationReview> => {
    const getReview = useCallback(() => {
        return getExaminationReviewById({ organisationId, reviewId });
    }, [organisationId, reviewId]);

    const queryKeys = React.useMemo(
        () => examinationQueryKeys.examinationsReview(reviewId),
        [reviewId],
    );

    return useQuery(queryKeys, getReview);
};

export const useGetInfiniteExaminationReviews = (
    organisationId: string,
    query: ExaminationReviewsSearchOptions,
): UseInfiniteQueryResult<DataPageResult<ExaminationReview>> => {
    const getExaminations = useCallback(
        ({ pageParam = null }): Promise<DataPageResult<ExaminationReview>> => {
            const nextPage = pageParam;

            return getExaminationReviews({
                organisationId,
                query: { ...query, nextPage },
            });
        },
        [query, organisationId],
    );

    const queryKeys = React.useMemo(
        () => examinationQueryKeys.filteredExaminationReviews(query),
        [query],
    );

    return useInfiniteQuery<
        DataPageResult<ExaminationReview>,
        [string, { page: number }]
    >(queryKeys, getExaminations, {
        getNextPageParam: (lastResult) => {
            return lastResult.nextPage || undefined;
        },
    });
};

export const useGetExaminationDefectsQuery = (
    organisationId: string,
    reviewId: string,
): UseQueryResult<AggregatedDefectData> => {
    const queryKeys = React.useMemo(
        () => examinationQueryKeys.examinationDefects(reviewId),
        [reviewId],
    );
    return useQuery(queryKeys, () => getDefects({ organisationId, reviewId }));
};

export const useGetExaminationRecommendationsQuery = (
    organisationId: string,
    reviewId: string,
): UseQueryResult<AggregatedRecommendationsData> => {
    const queryKeys = React.useMemo(
        () => examinationQueryKeys.examinationRecommendations(reviewId),
        [reviewId],
    );
    return useQuery(queryKeys, () =>
        getRecommendations({ organisationId, reviewId }),
    );
};

export const useGetReviewFindingsFormByReviewId = (
    organisationId: string,
    reviewId: string,
): UseQueryResult<ReviewFindings> => {
    const queryKeys = React.useMemo(
        () => examinationQueryKeys.examinationReviewFindings(reviewId),
        [reviewId],
    );

    return useQuery(queryKeys, () =>
        getReviewFindingsByReviewId({ organisationId, reviewId }),
    );
};

export const useAssignExaminationReviewsMutation = (
    organisationId: string,
): AssignExaminationMutation => {
    const queryClient = useQueryClient();
    const { invalidateTags } = useInvalidateTags();

    const assignExaminationsMutationFunction = (
        patchModel: ExaminationAssignModel,
    ) => assignExaminationReviews({ organisationId, patchModel });

    const queryKeys = React.useMemo(
        () => examinationQueryKeys.allExaminations(),
        [],
    );

    return useMutation(assignExaminationsMutationFunction, {
        onSuccess(_result, args) {
            invalidateTags(queryKeysToTags(queryKeys, args.processActivityIds));
            return queryClient.invalidateQueries(queryKeys);
        },
    });
};

export const useGetExaminationsCountAggregatedByType = (
    organisationId: string,
): UseQueryResult<ExaminationsCount> => {
    const queryKeys = React.useMemo(
        () => examinationQueryKeys.examinationsCount(),
        [],
    );

    return useQuery(queryKeys, () =>
        getExaminationsCountAggregatedByType({ organisationId }),
    );
};

export const useGetPastRecommendations = (
    activityId: string,
    organisationId: string,
): UseQueryResult<PastRecommendation[]> => {
    const queryKeys = React.useMemo(
        () => pastRecommendationQueryKeys(activityId),
        [activityId],
    );

    return useQuery(queryKeys, () =>
        getPastRecommendations({
            activityId,
            organisationId,
        }),
    );
};

const pastRecommendationQueryKeys = (activityId: string): QueryKey => [
    "past-recommendations",
    activityId,
];

export const useLoadPastRecommendationMutation = (
    organisationId: string,
    activityId: string,
): UseMutationResult<unknown, unknown, LoadPastRecommendationsPostModel> => {
    const queryClient = useQueryClient();

    const loadPastRecommendationFunc = (
        postModel: LoadPastRecommendationsPostModel,
    ) => loadPastRecommendations({ activityId, organisationId, postModel });

    const queryKeys = React.useMemo(
        () => pastRecommendationQueryKeys(activityId),
        [activityId],
    );

    return useMutation(loadPastRecommendationFunc, {
        onSuccess(_result, _args) {
            // invalidate cache for recommendation grid
            queryClient.invalidateQueries(
                examinationQueryKeys.examinationRecommendations(activityId),
            );
            // invalidate cache for past recommendation grid
            return queryClient.invalidateQueries(queryKeys);
        },
    });
};
