import { ShowCondition } from "../../../modules/template/domain/types";
import Select, { OptionTypeBase } from "react-select";
import * as React from "react";
import FormControlBase from "./FormControlBase";
import { Form } from "react-bootstrap";
import { useGetUsersByOrgIdQuery } from "../../../store/features/organisation/organisation-api-slice";
import { TemplateContentFilter } from "../../../modules/common/filters/domain/types";
import { filterItems } from "../../../modules/common/filters/helpers/filtersHelper";
import { useOrganisationId } from "../../../modules/organisation/hooks/useOrganisationId";
import FormPromptComponent from "./FormPromptComponent";
import { useFormikContext } from "formik";

interface FormControlUserSelectConfig {
    field: string;
    label: string;
    prompt: string;
    uri: string;
    style: unknown;
    isReadOnly: boolean;
    isMulti: boolean;
    showConditions: ShowCondition[];
    filter: TemplateContentFilter;
}

interface Props {
    config: FormControlUserSelectConfig;
}

const FormControlUserSelect: React.FC<Props> = ({ config }) => {
    const formik = useFormikContext();
    const { organisationId, isLoadingOrgId } = useOrganisationId();
    const { data: users } = useGetUsersByOrgIdQuery(organisationId, {
        skip: isLoadingOrgId,
    });

    const value = () => {
        const currentValue = formik.values[config.field] as string;

        if (!currentValue || !items) {
            return "";
        }

        if (config.isMulti) {
            const newValues = currentValue.split(",");

            return (
                (items.filter((item) =>
                    newValues.includes(item.value),
                ) as OptionTypeBase[]) || ""
            );
        }

        return items.find((item) => item.value === currentValue) || "";
    };

    const mapUsersMultipleItems = (newValue: OptionTypeBase[]) => {
        formik.setFieldValue(
            config.field,
            newValue.map((item) => item.value).toString(),
        );
    };

    const mapUsersSingleItem = (newValue: OptionTypeBase) => {
        formik.setFieldValue(config.field, newValue.value);
    };

    const setValueOnChange = (
        currentValue: OptionTypeBase | OptionTypeBase[],
    ) => {
        if (!currentValue) {
            return "";
        }

        config.isMulti
            ? mapUsersMultipleItems(currentValue as OptionTypeBase[])
            : mapUsersSingleItem(currentValue as OptionTypeBase);
    };

    const items = users
        ?.filter((i) => (config.filter ? filterItems(config.filter, i) : true))
        .map((i) => ({
            label: i.username,
            value: i.userId,
        }));

    return (
        <FormControlBase
            contentConfig={{
                field: config.field,
                showConditions: config.showConditions,
            }}
        >
            <Form.Label htmlFor={config.field}>{config.label}</Form.Label>
            <Select
                value={value()}
                onChange={(currentValue: OptionTypeBase | OptionTypeBase[]) =>
                    setValueOnChange(currentValue)
                }
                name={config.field}
                options={items || []}
                isDisabled={config.isReadOnly}
                classNamePrefix="react-select"
                className={formik.errors[config.field] ? "is-invalid" : ""}
                isMulti={config.isMulti}
            />
            <FormPromptComponent config={config} />
        </FormControlBase>
    );
};

export default FormControlUserSelect;
