import { GridFieldsMap } from "../../../../../common/components/table/kendo/GridFieldsMap";

export const examinationDefectGridMap: GridFieldsMap = {
    level1: { field: "level1", label: "Level 1", dataType: "string" },
    level1Number: {
        field: "level1Number",
        label: "Level 1 Number",
        dataType: "string",
    },
    level2: { field: "level2", label: "Level 2", dataType: "string" },
    level2Number: {
        field: "level2Number",
        label: "Level 2 Number",
        dataType: "string",
    },
    level3: { field: "level3", label: "Level 3", dataType: "string" },
    level3Number: {
        field: "level3Number",
        label: "Level 3 Number",
        dataType: "string",
    },
    lowHighMilage: {
        field: "lowHighMilage",
        label: "Low High Milage",
        dataType: "string",
    },
    upDownSide: {
        field: "upDownSide",
        label: "Up Down Side",
        dataType: "string",
    },
    defectMaterial: {
        field: "defectMaterial",
        label: "Defect Material",
        dataType: "string",
    },
    defectCategory: {
        field: "defectCategory",
        label: "Defect Category",
        dataType: "string",
    },
    defectType: {
        field: "defectType",
        label: "Defect Type",
        dataType: "Date",
    },
    length: {
        field: "length",
        label: "Length",
        dataType: "string",
    },
    width: {
        field: "width",
        label: "Width",
        dataType: "string",
    },
    depth: {
        field: "depth",
        label: "Depth",
        dataType: "string",
    },
    measurementType: {
        field: "measurementType",
        label: "Measurement Type",
        dataType: "string",
    },
    urgent: {
        field: "urgent",
        label: "Urgent",
        dataType: "string",
    },
    ugentDefectNo: {
        field: "ugentDefectNo",
        label: "Ugent Defect No",
        dataType: "string",
    },
};

export const examinationTrackingObjectsGridMap: GridFieldsMap = {
    defectNumber: {
        field: "defectNumber",
        label: "Defect Number",
        dataType: "string",
    },
    description: {
        field: "description",
        label: "Description",
        dataType: "string",
    },
    location: { field: "location", label: "Location", dataType: "string" },
    close: {
        field: "close",
        label: "Close",
        dataType: "string",
    },
    examDate: { field: "examDate", label: "Exam Date", dataType: "Date" },
    examType: {
        field: "examType",
        label: "Exam Type",
        dataType: "string",
    },
    accessGained: {
        field: "accessGained",
        label: "Access Gained",
        dataType: "string",
    },
    riskScore: {
        field: "riskScore",
        label: "Risk Score",
        dataType: "string",
    },
    recRaised: {
        field: "recRaised",
        label: "Rec Raised",
        dataType: "string",
    },
    nrActioned: {
        field: "nrActioned",
        label: "NR Actioned",
        dataType: "string",
    },
    accessRecd: {
        field: "accessRecd",
        label: "Access Recd",
        dataType: "Date",
    },
    deterioration: {
        field: "deterioration",
        label: "Deterioration",
        dataType: "string",
    },
    repaired: {
        field: "repaired",
        label: "Repaired",
        dataType: "string",
    },
    comments: {
        field: "comments",
        label: "Comments",
        dataType: "string",
    },
};
