import * as React from "react";
import { useParams } from "react-router";
import QueryResultStatus from "../../../common/components/QueryResultStatus";
import { useGetActivityPreviewQuery } from "../../../store/features/process-activity/processactivity-api-slice";
import ProcessActivityApprovalWrapper from "../../process-activity-approval/components/ProcessActivityApprovalWrapper";
import ProcessActivityArtefactGenerationWrapper from "../../process-activity-artefact-generation/components/ProcessActivityArtefactGenerationWrapper";
import ProcessActivityFormWrapper from "../../process-activity-form/components/ProcessActivityFormWrapper";
import ProcessActivityGroupWrapper from "../../process-activity-group/components/ProcessActivityGroupWrapper";
import ProcessActivityListWrapper from "../../process-activity-list/components/ProcessActivityListWrapper";
import ActivityLayout from "../components/ActivityLayout";
import NotSupportedActivity from "../components/NotSupportedActivity";
import { ProcessActivityType } from "../domain/types";
import "./ProcessActivityPage.css";

const ProcessActivityPage: React.FC = () => {
    const params = useParams<{
        processId: string;
        orgShortName: string;
        activityId: string;
    }>();

    const { data: activityPreview, ...typeResult } = useGetActivityPreviewQuery(
        params.activityId,
        { skip: !params.activityId },
    );

    const switchActivityTypes = (): JSX.Element => {
        switch (activityPreview.type) {
            case ProcessActivityType.Form: {
                return (
                    <ProcessActivityFormWrapper
                        processId={params.processId}
                        orgShortName={params.orgShortName}
                        activityId={params.activityId}
                    />
                );
            }
            case ProcessActivityType.List: {
                return (
                    <ProcessActivityListWrapper
                        processId={params.processId}
                        orgShortName={params.orgShortName}
                        activityId={params.activityId}
                    />
                );
            }
            case ProcessActivityType.Group: {
                return (
                    <ProcessActivityGroupWrapper
                        processId={params.processId}
                        orgShortName={params.orgShortName}
                        activityId={params.activityId}
                    />
                );
            }
            case ProcessActivityType.Approval: {
                return (
                    <ProcessActivityApprovalWrapper
                        processId={params.processId}
                        orgShortName={params.orgShortName}
                        activityId={params.activityId}
                    />
                );
            }
            case ProcessActivityType.ArtefactGeneration: {
                return (
                    <ProcessActivityArtefactGenerationWrapper
                        processId={params.processId}
                        orgShortName={params.orgShortName}
                        activityId={params.activityId}
                    />
                );
            }
            default: {
                return <NotSupportedActivity />;
            }
        }
    };

    if (!activityPreview || typeResult.isLoading || typeResult.isFetching)
        return (
            <div className="h-100">
                <QueryResultStatus queryResult={typeResult} />
            </div>
        );

    return (
        <ActivityLayout activity={activityPreview}>
            {switchActivityTypes()}
        </ActivityLayout>
    );
};

export default ProcessActivityPage;
