import { goBack } from "connected-react-router";
import * as Formik from "formik";
import * as React from "react";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {
    CustomSelect,
    DateTimeSelect,
    TextArea,
    TextInput,
} from "../../../common/components/form";
import { Errors } from "../../../common/types/Errors";
import {
    mapToSelectOptions,
    SelectOptionItem,
} from "../../../common/types/reactSelect/SelectOptionItem";
import OrganisationConstants from "../../../Constants/OrganisationConstants";
import { AppRoutes } from "../../../router/AppRoutes";
import { useNavigation } from "../../../router/useNavigate";
import { addSuccess } from "../../../store/features/notifications/notificationsSlice";
import { useGetUsersByOrgIdQuery } from "../../../store/features/organisation/organisation-api-slice";
import { useGetProcessTemplatesByOrgIdQuery } from "../../../store/features/process-template/process-template-api-slice";
import { useCreateProcessMutation } from "../../../store/features/process/process-api-slice";
import { AssignedToWrapper } from "../components/AssignedToWrapper";
import { DateTimeWrapper } from "../components/DateTimeWrapper";

export interface FormValues {
    name: string;
    description: string;
    assignedTo: SelectOptionItem;
    processTemplate: SelectOptionItem;
    scheduledStart: string;
    scheduledEnd: string;
}

const validate = (values: FormValues) => {
    const errors: Errors = {};

    if (!values.name) {
        errors.name = "Required";
    }

    if (!values.processTemplate?.value) {
        errors.processTemplate = "Required";
    }

    if (values.scheduledEnd && values.scheduledStart) {
        if (new Date(values.scheduledEnd) <= new Date(values.scheduledStart))
            errors.scheduledEnd = "Scheduled End must be after Scheduled Start";
    }

    return errors;
};

export interface OrgProcessCreateFormProps {
    scheduledStart: string;
    organisationId: string;
}

const ProcessCreateForm: React.FC<OrgProcessCreateFormProps> = (props) => {
    const dispatch = useDispatch();
    const { navigateToOrgPath: navigateToPath, navigateToWithOrg: navigateTo } =
        useNavigation();

    const [createProcess] = useCreateProcessMutation();

    const { data: orgUsersData, isLoading: orgUsersIsLoading } =
        useGetUsersByOrgIdQuery(props.organisationId, {
            skip: !props.organisationId,
        });

    const {
        data: orgProcessTemplatesData,
        isLoading: orgProcessTemplatesIsLoading,
    } = useGetProcessTemplatesByOrgIdQuery(props.organisationId, {
        skip: !props.organisationId,
    });

    const scheduledStart = props.scheduledStart
        ? props.scheduledStart.split(".")[0]
        : "";

    const shouldRedirectToScheduler = props.scheduledStart ? true : false;

    const createSuccessful = (id: string, scheduledStart: string) => {
        shouldRedirectToScheduler && scheduledStart
            ? navigateTo(AppRoutes.SchedulerFrom, {
                  scheduledStart,
              })
            : navigateToPath(
                  `/:orgShortName${OrganisationConstants.Process}/${id}`,
              );
    };

    const initialValues = {
        name: "",
        description: "",
        assignedTo: null,
        processTemplate: null,
        scheduledStart: scheduledStart,
        scheduledEnd: scheduledStart,
    };

    const cancel = () => {
        dispatch(goBack());
    };

    const onSubmit = (
        values: FormValues,
        { setSubmitting }: Formik.FormikHelpers<FormValues>,
    ) => {
        setSubmitting(true);
        createProcess({
            name: values.name,
            description: values.description,
            organisationId: props.organisationId,
            assignedToId: values.assignedTo?.value,
            processTemplateId: values.processTemplate?.value,
            scheduledStart: values.scheduledStart || null,
            scheduledEnd: values.scheduledEnd || null,
        })
            .unwrap()
            .then((data) => {
                dispatch(addSuccess("Process created successfully."));
                createSuccessful(data.id, values.scheduledStart);
            })
            .catch(() => {
                setSubmitting(false);
            });
    };

    return (
        <Formik.Formik
            enableReinitialize
            initialValues={initialValues}
            validate={validate}
            onSubmit={onSubmit}
        >
            {(formikProps) => (
                <Formik.Form>
                    <TextInput
                        label="Name"
                        placeholder="Name"
                        name="name"
                        description="The name for your process."
                    />
                    <TextArea label="Description" name="description" rows={3} />
                    <CustomSelect
                        name="processTemplate"
                        label="Select Template"
                        placeholder="Select template..."
                        readOnly={orgProcessTemplatesIsLoading}
                        options={mapToSelectOptions(
                            orgProcessTemplatesData,
                            (i) => i.id,
                            (i) => i.name,
                        )}
                    />
                    <DateTimeWrapper
                        formik={formikProps}
                        processTemplates={orgProcessTemplatesData}
                    >
                        <DateTimeSelect
                            label="Scheduled Start"
                            name="scheduledStart"
                        />
                        <DateTimeSelect
                            label="Scheduled End"
                            name="scheduledEnd"
                        />
                    </DateTimeWrapper>
                    <AssignedToWrapper
                        formik={formikProps}
                        processTemplates={orgProcessTemplatesData}
                    >
                        <CustomSelect
                            name="assignedTo"
                            label="Select Assigned User"
                            placeholder="Select user..."
                            readOnly={orgUsersIsLoading}
                            options={mapToSelectOptions(
                                orgUsersData,
                                (i) => i.userId,
                                (i) => i.username,
                            )}
                        />
                    </AssignedToWrapper>
                    <div className="d-flex justify-content-end">
                        <Button
                            variant="primary"
                            type="submit"
                            disabled={formikProps.isSubmitting}
                        >
                            Create
                        </Button>
                        <Button
                            variant="danger"
                            className="ml-1"
                            onClick={cancel}
                        >
                            Cancel
                        </Button>
                    </div>
                </Formik.Form>
            )}
        </Formik.Formik>
    );
};

export default ProcessCreateForm;
