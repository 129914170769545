import * as React from "react";
import { Row, Col } from "react-bootstrap";
import BaseTable from "../../../common/components/table/BaseTable";
import UsersTableActions from "./UsersTableActions";
import { CellProps } from "react-table";
import {
    useGetOrgByIdQuery,
    useGetUsersByOrgIdQuery,
} from "../../../store/features/organisation/organisation-api-slice";
import { OrganisationUser } from "../../organisation/domain/types";

interface UsersTableProps {
    organisationId: string;
}
/**
 * Options object for conditional rendering per user
 */
export interface TableUser extends OrganisationUser {
    isOwner: boolean;
}

/**
 *  Table to display an ogranisation's users and actions
 */
const UsersTable: React.FC<UsersTableProps> = (props) => {
    const orgId = props.organisationId;
    const { data: organisation } = useGetOrgByIdQuery(orgId, {
        skip: !orgId,
    });
    const ownerId = organisation?.ownerId || null;

    const { data: users } = useGetUsersByOrgIdQuery(orgId, {
        skip: !orgId,
    });

    const columns = React.useMemo(
        () => [
            {
                Header: "User Name",
                accessor: (row: TableUser): string => row.username,
            },
            {
                Header: "User Groups",
                accessor: (row: TableUser): string => row.userGroups.join(", "),
            },
            {
                Header: "Actions",
                accessor: (row: TableUser): string => row.userId,
                Cell: ({ row }: CellProps<TableUser>) => {
                    return (
                        <UsersTableActions
                            user={row.original}
                            organisationId={orgId}
                        />
                    );
                },
            },
        ],
        [orgId],
    );

    const data = React.useMemo(
        () =>
            users?.map((a): TableUser => {
                return {
                    ...a,
                    isOwner: ownerId === a.userId,
                };
            }) ?? [],
        [ownerId, users],
    );

    return (
        <Row>
            <Col>
                <BaseTable columns={columns} data={data} />
            </Col>
        </Row>
    );
};

export default UsersTable;
