import { QueryKey } from "react-query";
import { searchOptionsKey } from "../../../../common/types/SortAndFilterOptions";
import { ExaminationsSearchOptions } from "../services/examinationsPlanningService";
import { ExaminationReviewsSearchOptions } from "../services/examinationsReviewService";

export const examinationsKey = "examinations";
export const examinationTrackingObjectsKey = "examination-tracking-objects";
export const examinationDefectsKey = "examination-defects";
export const examinationRecommendationsKey = "examination-recommendations";
export const examinationsReviewKey = "examinations-review";
export const examinationsBulkUpdates = "examinations-bulk-updates";
export const examinationMassRemovals = "examination-mass-removals";

const examinationQueryKeys = {
    allExaminations: (): QueryKey => examinationsKey,
    filteredExaminations: (
        searchOptions: ExaminationsSearchOptions,
    ): QueryKey => [
        examinationsKey,
        ...searchOptionsKey(searchOptions),
        `onlyUnallocated:${searchOptions.onlyUnallocated}`,
    ],
    filteredExaminationReviews: (
        searchOptions: ExaminationReviewsSearchOptions,
    ): QueryKey => [examinationsKey, ...searchOptionsKey(searchOptions)],
    examinationDefects: (reviewId: string): QueryKey => [
        examinationsKey,
        examinationTrackingObjectsKey,
        `${examinationDefectsKey}-${reviewId}`,
    ],
    examinationRecommendations: (reviewId: string): QueryKey => [
        examinationsKey,
        examinationTrackingObjectsKey,
        `${examinationRecommendationsKey}-${reviewId}`,
    ],
    examinationsReview: (id: string): QueryKey => [
        examinationsKey,
        `examination-review-${id}`,
    ],
    examinationReviewUnexaminedParts: (id: string): QueryKey => [
        examinationsKey,
        `examination-review-${id}`,
        "unexamined-parts",
    ],
    examinationReviewFindings: (id: string): QueryKey => [
        examinationsKey,
        `examination-review-${id}`,
        "review-findings",
    ],
    examinationsCount: (): QueryKey => [examinationsKey, "examinations-count"],
    examinationsBulkUpdates: (orgId: string): QueryKey => [
        `${examinationsBulkUpdates}-${orgId}`,
    ],
    examinationMassRemovals: (orgId: string): QueryKey => [
        `${examinationMassRemovals}-${orgId}`,
    ],
};

export default examinationQueryKeys;
