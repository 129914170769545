import { Form, Formik } from "formik";
import { noop } from "lodash";
import * as React from "react";
import PropertyDynamicField from "../components/PropertyDynamicField";
import { ProcessProperty } from "../domain/types";

interface Props {
    properties: ProcessProperty[];
}

const ProcessPropertiesList: React.FC<Props> = ({ properties }) => {
    const propertyData = React.useMemo(() => {
        const pPropertyData = {};

        properties.forEach(({ field, value }) => {
            pPropertyData[field] = value;
        });

        return pPropertyData;
    }, [properties]);

    return (
        <Formik initialValues={propertyData} onSubmit={noop}>
            <Form>
                {properties.map((property) => (
                    <PropertyDynamicField key={property.field} {...property} />
                ))}
            </Form>
        </Formik>
    );
};

export default ProcessPropertiesList;
