import * as React from "react";
import { Row, Col } from "react-bootstrap";
import PermissionTableActions from "./PermissionTableActions";
import { CellProps } from "react-table";
import BaseTable from "../../../../common/components/table/BaseTable";
import { useParams } from "react-router";
import { useGetOrgByIdQuery } from "../../../../store/features/organisation/organisation-api-slice";
import MutationResultStatus from "../../../../common/components/MutationResultStatus";

/**
 * Options object for conditional rendering per permission
 */
export interface TablePermission {
    id: string;
    name: string;
}

/**
 *  Table to display an ogranisation's permissions and actions
 */
const PermissionTable: React.FC = () => {
    const { id: organisationId } = useParams<{ id: string }>();
    const organisationQuery = useGetOrgByIdQuery(organisationId);
    const permissions = organisationQuery.data?.permissions ?? [];

    const tableItem = permissions.map((a): TablePermission => {
        return {
            id: a.id,
            name: a.name,
        };
    });

    const columns = React.useMemo(
        () => [
            {
                Header: "Permission",
                accessor: (row: TablePermission): string => row.name,
            },
            {
                Header: "Actions",
                accessor: (row: TablePermission): string => row.name,

                Cell: function RowActions({
                    row,
                }: CellProps<TablePermission>): JSX.Element {
                    return (
                        <PermissionTableActions
                            permission={row.original}
                        ></PermissionTableActions>
                    );
                },
            },
        ],
        [],
    );

    const data = React.useMemo(() => tableItem, [tableItem]);

    return (
        <Row>
            <Col>
                <MutationResultStatus
                    mutationResult={organisationQuery}
                    showLoading
                />
                <BaseTable columns={columns} data={data}></BaseTable>
            </Col>
        </Row>
    );
};

export default PermissionTable;
